import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  percentage: PropTypes.string,
  children: PropTypes.element,
};

const ProfileProgressHeader = ({ title, subtitle, percentage, children }) => {
  return (
    <div className="px-4 md:px-0 pt-9 pb-4 md:pt-16 md:pb-10 relative">
      <div className="md:h-32 md:text-center">
        <h2 className="text-4xl md:text-5xl font-bold">{title}</h2>
        <h3 className="pt-5 text-base">{subtitle}</h3>
      </div>
      {percentage ? (
        <div className="pt-4 md:pt-0 w-52 mx-auto">
          {/* Progress bar */}
          <div className="overflow-hidden rounded-full bg-coolGrey">
            <div
              style={{ width: `${percentage}%` }}
              className="h-2.5 rounded-full bg-fadeOrange"
            ></div>
          </div>
        </div>
      ) : null}
      {children}
    </div>
  );
};

ProfileProgressHeader.propTypes = propTypes;

export default ProfileProgressHeader;
