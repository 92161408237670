import { memo, useCallback, useContext, useEffect, useState } from 'react';

import { AuthContext } from '../../context/auth/auth.context';

import { PULSE_INTERVAL } from '../../utils/constants';

import { sendSyncPulse } from './sync-pulse.service';

const handleSyncPulse = authToken => {
  // Check last sync stamp in local storage
  const lastPulseStorage = localStorage.getItem('LAST_PULSE');
  const lastPulseTime = lastPulseStorage
    ? parseInt(lastPulseStorage, 10)
    : null;

  if (lastPulseTime && lastPulseTime > Date.now() - PULSE_INTERVAL) {
    return;
  }
  sendSyncPulse(authToken);
  localStorage.setItem('LAST_PULSE', `${Date.now()}`);
};

const SyncPulse = memo(() => {
  const { authToken } = useContext(AuthContext);
  const [syncPulseIntervalId, setSyncPulseIntervalId] = useState(null);

  const startSyncPulse = useCallback(() => {
    if (syncPulseIntervalId) {
      return;
    }
    handleSyncPulse(authToken);
    setSyncPulseIntervalId(setInterval(handleSyncPulse, 1000 * 15));
  }, [authToken, syncPulseIntervalId]);

  const stopSyncPulse = useCallback(() => {
    if (syncPulseIntervalId) {
      clearInterval(syncPulseIntervalId);
      setSyncPulseIntervalId(null);
    }
  }, [syncPulseIntervalId]);

  useEffect(() => {
    if (authToken) {
      startSyncPulse();
    } else {
      stopSyncPulse();
    }
  }, [authToken, startSyncPulse, stopSyncPulse]);

  return null;
});

export default SyncPulse;
