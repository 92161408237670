import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { useContext } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom';

import AdminUserDetailsPage from './admin/pages/admin-user-details/admin-user-details-page.component';
import AdminUserManagement from './admin/pages/admin-user-management/admin-user-management.page';
import AcceptTerms from './pages/accept-terms/accept-terms.page';
import Account from './pages/account/account.page';
import BecomeProPage from './pages/become-pro/become-pro.page';
import BillingPeriodPayment from './pages/billing-period-payment/billing-period-payment.page';
import CalendarPage from './pages/calendar/calendar.page';
import CancelSubscription from './pages/cancel-subscription/cancel-subscription.page';
import ContactDetails from './pages/contact-details/contact-details.page';
import Contacts from './pages/contacts/contacts.page';
import Dashboard from './pages/dashboard/dashboard.page';
import EditProfile from './pages/edit-profile/edit-profile.page';
import NoMatch from './pages/error-404/error-404.page';
import FollowUps from './pages/follow-ups/follow-ups.page';
import ForgotPassword from './pages/forgot-password/forgot-password.page';
import GlobalSearch from './pages/global-search/global-search.page';
import Login from './pages/login/login.page';
import Notes from './pages/notes/notes.page';
import Onboarding from './pages/onboarding/onboarding.page';
import PickUrl from './pages/pick-url/pick-url.page';
import PlanSelector from './pages/plan-selector/plan-selector.page';
import PreviewProfile from './pages/preview-profile/preview-profile.page';
import PublicProfile from './pages/public-profile/public-profile.page';
import ResetPassword from './pages/reset-password/reset-password.page';
import Rotations from './pages/rotations/rotations.page';
import ShareYourProfile from './pages/share-your-profile/share-your-profile.page';
import SignUpProvenPathElements from './pages/sign-up-proven-path/sign-up-proven-path.page';
import SignUp from './pages/sign-up/sign-up.page';
import SpecialDates from './pages/special-dates/special-dates.page';
import Subscription from './pages/subscription/subscription.page';
import UnloggedInteractions from './pages/unlogged-interactions/unlogged-interactions.page';
import UpdateCreditCard from './pages/update-credit-card/update-credit-card.page';
import WelcomePage from './pages/welcome/welcome.page';

import ScrollToTop from './components/scroll-to-top/scroll-to-top.component';
import SubscriptionNotifications from './components/subscription-notifications/subscription-notifications.component';
import WebAppLayoutLoading from './components/web-app-layout-loading/web-app-layout-loading.component';

import SWRFirestore from './firebase/swr-firestore.component';
import SyncPulse from './services/sync-pulse/sync-pulse.component';

import useFeaturesSwitch from './hooks/useFeaturesSwitch';
import useGaTracker from './hooks/useGaTracker';
import useTryToken from './hooks/useTryToken';

import { AuthContext, AuthContextProvider } from './context/auth/auth.context';
import { PaywallDialoguesContextProvider } from './context/paywall/paywall-dialogues.context';
import { PaywallContextProvider } from './context/paywall/paywall.context';
import { RemoteConfigContextProvider } from './context/remote-config/remote-config.context';
import { SharedDialoguesContextProvider } from './context/shared-dialogues/shared-dialogues.context';
import { SnackbarContextProvider } from './context/snackbar/snackbar.context';

import { useStatsTotals } from './firebase/firebase.utils';

import './styles/index.css';
import theme from './styles/mui-theme';

const PrivateRoute = ({ isWebAppRoute, isAdminRoute, isSubscriptionPage }) => {
  const { authToken, userData, isPremium } = useContext(AuthContext);
  const { web_app_free } = useFeaturesSwitch();

  const location = useLocation();

  const { isPerformingTryToken, tryTokenError } = useTryToken();

  // This can be safely removed when `web_app_free` is not needed anymore
  const webAppHideConditions = !web_app_free && isWebAppRoute && !isPremium;

  const state = userData?.state;
  const isSubscribed =
    state === 'Permanent' ||
    state === 'Subscribed' ||
    state === 'NotRenewing' ||
    state === 'Overdue';

  if (isPerformingTryToken) return <WebAppLayoutLoading hiddenHeader />;

  if (!authToken || tryTokenError)
    return <Navigate to="/signin" state={{ from: location }} />;

  if (webAppHideConditions || (isAdminRoute && userData?.profile !== 'Admin'))
    return <Navigate to="/404" state={{ from: location }} />;

  if (isSubscriptionPage && isSubscribed)
    return <Navigate to="/manage-account" />;

  if (isWebAppRoute)
    return (
      <PaywallContextProvider>
        <PaywallDialoguesContextProvider>
          <SharedDialoguesContextProvider>
            <Outlet />
          </SharedDialoguesContextProvider>
        </PaywallDialoguesContextProvider>
      </PaywallContextProvider>
    );

  return <Outlet />;
};

// Similar to `PrivateRoute`, this routes may be visible by logged in users as
// well. If this is the case (`(!userData && authToken)`), sign them in first
const SemiPublicRoute = () => {
  const { isPerformingTryToken } = useTryToken();

  if (isPerformingTryToken) return <WebAppLayoutLoading hiddenHeader />;

  return <Outlet />;
};

const GATracker = () => {
  useGaTracker();

  return null;
};

const SlashRedirect = () => {
  const { userId } = useContext(AuthContext);
  const { statsTotals } = useStatsTotals(userId);

  if (!userId || !statsTotals) return <WebAppLayoutLoading hiddenHeader />;

  return statsTotals.exists &&
    (statsTotals.data().notes ||
      statsTotals.data().followups ||
      statsTotals.data().specialdates ||
      statsTotals.data().rotations) ? (
    <Navigate replace to="/dashboard" />
  ) : (
    <Navigate replace to="/welcome" />
  );
};

const App = () => {
  return (
    <SWRFirestore>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <ScrollToTop />
          {process.env.REACT_APP_ENVIRONMENT === 'production' ? (
            <GATracker />
          ) : null}
          <RemoteConfigContextProvider>
            <AuthContextProvider>
              <SnackbarContextProvider>
                <SyncPulse />
                <SubscriptionNotifications />
                <Routes>
                  {/* Public Profile */}
                  <Route path="/" element={<PrivateRoute />}>
                    <Route exact index element={<SlashRedirect />} />
                    <Route
                      exact
                      path="edit-profile"
                      element={<EditProfile />}
                    />
                    <Route
                      exact
                      path="preview-profile"
                      element={<PreviewProfile />}
                    />
                    <Route
                      exact
                      path="my-profile"
                      element={<PublicProfile />}
                    />
                    <Route exact path="pick-url" element={<PickUrl />} />
                    <Route
                      exact
                      path="share-your-profile"
                      element={<ShareYourProfile />}
                    />
                    <Route exact path="manage-account" element={<Account />} />
                    <Route
                      exact
                      path="manage-account/subscription"
                      element={<Subscription />}
                    />
                    <Route
                      exact
                      path="manage-account/subscription/update-credit-card"
                      element={<UpdateCreditCard />}
                    />
                    <Route
                      exact
                      path="manage-account/subscription/cancel-subscription"
                      element={<CancelSubscription />}
                    />
                    <Route exact path="welcome" element={<WelcomePage />} />
                    <Route
                      exact
                      path="accept-terms"
                      element={<AcceptTerms />}
                    />
                  </Route>
                  {/* Subscription Routes */}
                  <Route element={<PrivateRoute isSubscriptionPage />}>
                    <Route
                      exact
                      path="select-your-plan"
                      element={<PlanSelector />}
                    />
                    <Route
                      exact
                      path="become-pro"
                      element={<BecomeProPage />}
                    />
                    <Route
                      exact
                      path="billing-period-payment"
                      element={<BillingPeriodPayment />}
                    />
                  </Route>

                  {/* Web App */}
                  <Route element={<PrivateRoute isWebAppRoute />}>
                    <Route exact path="dashboard" element={<Dashboard />} />
                    <Route
                      exact
                      path="unlogged-interactions"
                      element={<UnloggedInteractions />}
                    />
                    <Route exact path="contacts" element={<Contacts />} />
                    <Route
                      exact
                      path="contacts/:contactId"
                      element={<ContactDetails />}
                    />
                    <Route exact path="notes" element={<Notes />} />
                    <Route exact path="follow-ups" element={<FollowUps />} />
                    <Route exact path="rotations" element={<Rotations />} />
                    <Route
                      exact
                      path="special-dates"
                      element={<SpecialDates />}
                    />
                    <Route exact path="search" element={<GlobalSearch />} />
                    <Route exact path="calendar" element={<CalendarPage />} />
                  </Route>

                  {/* Admin Routes */}
                  <Route path="/admin" element={<PrivateRoute isAdminRoute />}>
                    <Route
                      exact
                      path="user-management"
                      element={<AdminUserManagement />}
                    />
                    <Route
                      exact
                      path="user-management/:userId"
                      element={<AdminUserDetailsPage />}
                    />
                  </Route>

                  {/* Public Routes */}
                  <Route path="sign-in" exact element={<Login />} />
                  <Route path="signin" exact element={<Login />} />
                  <Route
                    path="forgot-password"
                    exact
                    element={<ForgotPassword />}
                  />
                  <Route path="reset" exact element={<ForgotPassword />} />
                  <Route path="forgot" exact element={<ForgotPassword />} />
                  <Route
                    path="reset/:token"
                    exact
                    element={<ResetPassword />}
                  />
                  <Route
                    path="proven-path-checkout"
                    exact
                    element={<SignUpProvenPathElements />}
                  />
                  <Route path="signup" exact element={<SignUp />} />
                  <Route path="sign-up" exact element={<SignUp />} />
                  <Route element={<SemiPublicRoute />}>
                    <Route exact path="onboarding" element={<Onboarding />} />
                    <Route
                      exact
                      path="hi/:profile"
                      element={<PublicProfile />}
                    />
                    {/* 404 */}
                    <Route path="404" element={<NoMatch />} />
                    <Route path="*" element={<NoMatch />} />
                  </Route>
                </Routes>
              </SnackbarContextProvider>
            </AuthContextProvider>
          </RemoteConfigContextProvider>
        </Router>
      </ThemeProvider>
    </SWRFirestore>
  );
};

export default App;
