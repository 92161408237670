import React, { useContext, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import CMButton from '../cm-button/cm-button.component';
import CMDialog from '../cm-dialog/cm-dialog.component';

import { RemoteConfigContext } from '../../context/remote-config/remote-config.context';
import { SnackbarContext } from '../../context/snackbar/snackbar.context';

import { introduceYourself } from '../../services/users/users.service';

const IntroduceYourselfDialog = ({
  username = '',
  open = false,
  onClose,
  profile,
}) => {
  const initialState = {
    firstname: '',
    lastname: '',
    phonenumber: '',
    email: '',
    aboutme: '',
  };

  const formRef = useRef(null);

  const {
    configValues: { privacy_policy_url },
  } = useContext(RemoteConfigContext);

  const { showSnackbar } = useContext(SnackbarContext);

  const [captchaValue, setCaptchaValue] = useState();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [formData, setFormData] = useState(initialState);

  const closeDialog = () => {
    setFormData(initialState);
    onClose();
  };

  const handleChange = (event, field) => {
    const val = event.target.value;
    setFormData({
      ...formData,
      [field]: val,
    });
  };

  const shouldEnableSendButton = () => {
    const { aboutme, email, firstname, lastname, phonenumber } = formData;
    return (
      !aboutme ||
      !email ||
      !firstname ||
      !lastname ||
      !phonenumber ||
      !captchaValue
    );
  };

  const handleError = () => {
    showSnackbar({
      severity: 'error',
      message: 'Something went wrong.',
    });
  };

  const handleSubmit = () => {
    if (captchaValue) {
      const { aboutme, email, firstname, lastname, phonenumber } = formData;

      introduceYourself({
        lead: {
          email,
          firstname,
          lastname,
          phonenumber,
          aboutme,
        },
        to_profileurl: profile,
        recaptcha_token: captchaValue,
      })
        .then(() => {
          setConfirmDialogOpen(true);
          closeDialog();
        })
        .catch(() => handleError());
    }
  };

  return (
    <>
      <CMDialog
        width="xs"
        title="Thank you for submitting your information."
        open={confirmDialogOpen}
        handleClose={() => setConfirmDialogOpen(false)}
        renderContent={
          <p className="mt-4">
            An email with your introduction will be sent to {username} shortly.
          </p>
        }
      ></CMDialog>
      <CMDialog
        title={`Introduce yourself to ${username}`}
        open={open}
        handleClose={closeDialog}
        width="xs"
        renderFooterContent={
          <p className="px-6 pb-6">
            By entering your details, you agree to receive our communications in
            accordance with our{' '}
            <a
              target="_blank"
              className="underline font-bold"
              rel="noreferrer"
              href={privacy_policy_url}
            >
              Privacy Policy
            </a>
            . You may opt out anytime.
          </p>
        }
        renderBottomActions={
          <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-4">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN}
              onChange={val => setCaptchaValue(val)}
            />
            <CMButton
              disabled={shouldEnableSendButton()}
              onClick={handleSubmit}
            >
              Send
            </CMButton>
          </div>
        }
        renderContent={
          <div>
            <ValidatorForm
              ref={formRef}
              onSubmit={handleSubmit}
              onError={handleError}
              noValidate
            >
              <div className="pt-4 space-y-4 md:space-y-0 md:grid grid-cols-2 gap-4">
                <TextValidator
                  label="FIRST NAME"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  id="firstname"
                  type="text"
                  onChange={e => handleChange(e, 'firstname')}
                  value={formData.name}
                  required
                  validators={['required']}
                  errorMessages={['This field is required']}
                />
                <TextValidator
                  label="LAST NAME"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  id="lastname"
                  type="text"
                  onChange={e => handleChange(e, 'lastname')}
                  value={formData.name}
                  required
                  validators={['required']}
                  errorMessages={['This field is required']}
                />
                <TextValidator
                  label="MY PHONE"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  id="phonenumber"
                  fullWidth
                  type="text"
                  onChange={e => handleChange(e, 'phonenumber')}
                  value={formData.phonenumber}
                  required
                  validators={['required']}
                  errorMessages={['This field is required']}
                />
                <TextValidator
                  label="MY EMAIL"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  fullWidth
                  id="email"
                  type="email"
                  onChange={e => handleChange(e, 'email')}
                  value={formData.email}
                  required
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    'This field is required',
                    'Not a valid email',
                  ]}
                />
              </div>
              <div className="mt-4">
                <TextValidator
                  label="ABOUT ME"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  id="aboutme"
                  onChange={e => handleChange(e, 'aboutme')}
                  fullWidth
                  value={formData.aboutme}
                  validators={['required']}
                  errorMessages={['This field is required']}
                  multiline={true}
                  rows={4}
                  required
                />
              </div>
            </ValidatorForm>

            <hr className="my-10" />

            <p>Hi {username},</p>
            <p>
              My name is <span className="font-semibold">[my name]</span>.
            </p>
            <p>
              I would like you to know{' '}
              <span className="font-semibold">[about me]</span>.
            </p>
            <p>
              You can contact me at:{' '}
              <span className="font-semibold">[my phone]</span>, or at:{' '}
              <span className="font-semibold">[my email]</span>.
            </p>
            <p className="mt-5">Looking forward to hearing from you,</p>
            <p className="font-semibold">[my name]</p>
          </div>
        }
      />
    </>
  );
};

export default IntroduceYourselfDialog;
