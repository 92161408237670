import React, { memo, useContext } from 'react';

import HTMLText from '../../components/html-text/html-text.component';
import ReadMore from '../../components/read-more/read-more.component';
import SmallItemCard from '../../components/small-item-card/small-item-card.component';

import { AuthContext } from '../../context/auth/auth.context';
import { SharedDialoguesContext } from '../../context/shared-dialogues/shared-dialogues.context';

import { useContact } from '../../firebase/firebase.utils';

import { formatDate } from '../../utils/dates.utils';
import { getContactDisplayName } from '../../utils/list.utils';

type IProps = {
  followupTimestamp: number;
  body: string;
  timeBased: boolean;
  testid: string;
  followUpId: string;
  contactId: string;
};

const DashboardFollowUpItem: React.FC<IProps> = memo(
  ({ followupTimestamp, body, timeBased, testid, followUpId, contactId }) => {
    const { userId } = useContext(AuthContext);
    const { data: dataContact } = useContact(userId, contactId);
    const { openFollowUpDialog } = useContext(SharedDialoguesContext);

    const handleItemClick = () =>
      openFollowUpDialog({
        followUpId,
        contactId,
        isEditing: false,
        isDone: false,
        closeOnCancelEdit: false,
      });

    return (
      <SmallItemCard
        date={formatDate(
          new Date(Math.round(followupTimestamp * 1000)),
          timeBased
        )}
        contactDisplayStatus={dataContact?.displayStatus}
        contactDisplayName={
          dataContact
            ? getContactDisplayName(
                dataContact?.fullName,
                dataContact?.company,
                dataContact?.phones[0]?.number,
                dataContact?.emails[0]?.email
              )
            : 'Loading...'
        }
        mainContent={
          <ReadMore>
            <HTMLText testid={`${testid}.body`} text={body} />
          </ReadMore>
        }
        onItemClick={handleItemClick}
        testid={testid}
      />
    );
  }
);

export default DashboardFollowUpItem;
