import React from 'react';

import CMButton from '../../components/cm-button/cm-button.component';

import Coupon from '../../models/coupon.model';
import Plan from '../../models/plan.model';

type IProps = {
  plans: Plan[];
  currentSelectedPlan: Plan | null;
  onPlanChange: (plan: Plan) => void;
  coupon: Coupon | null;
};

const BillingPeriodSelector: React.FC<IProps> = ({
  plans = [],
  currentSelectedPlan,
  onPlanChange,
  coupon,
}): JSX.Element => (
  <div className="flex space-x-4">
    {plans.map((plan: Plan, index: number): JSX.Element => {
      const isPlanSelected: boolean = currentSelectedPlan?.id === plan.id;
      const monthlyPlan: Plan = plans.find((p: Plan) => p.isMonthly)!;
      const couponAppliedForCurrentPlan = coupon?.canApplyToProduct(plan.key);
      const couponTerms = coupon?.metadata?.terms;
      const couponForMonthly = coupon?.canApplyToProduct(monthlyPlan.key);

      return (
        <div key={index} className="w-full self-end">
          {!plan.isMonthly &&
          !couponAppliedForCurrentPlan &&
          !couponForMonthly ? (
            <div
              className={`h-6 ${
                isPlanSelected
                  ? 'border-softPurple bg-softPurple'
                  : 'border-slate bg-slate'
              } text-center text-white text-sm rounded-t-md border-2`}
            >
              2 months free - Save{' '}
              {plan.getAnnualPlanSavings(monthlyPlan.parsedAmount)}%!
            </div>
          ) : null}

          <div
            className={`border-2 rounded-t-none p-2 text-center space-y-4 flex flex-col min-h-[215px] ${
              isPlanSelected
                ? plan.isMonthly
                  ? 'border-fadeOrange'
                  : 'border-softPurple'
                : 'border-slate'
            }`}
          >
            <h3 className="text-left font-bold">
              {plan.isMonthly ? 'Monthly' : 'Annual'} Subscription
            </h3>

            <div>
              {couponAppliedForCurrentPlan && (
                <span
                  className={`${
                    !coupon?.timeBased && 'line-through'
                  } text-errorStrong`}
                >
                  ${plan.getMonthlyPrice()}
                  {coupon?.timeBased
                    ? ` off for ${coupon.duration_in_months} months`
                    : '/month'}
                </span>
              )}
              <p>
                <span className="font-bold text-2xl">
                  ${plan.getMonthlyPrice(coupon!)}
                </span>
                /month
              </p>
              {!plan.isMonthly && <p className="text-sm">when paid annually</p>}
            </div>

            <p className="grow text-sm">
              (1 payment of{' '}
              <span
                className={`${
                  couponAppliedForCurrentPlan && 'line-through text-errorStrong'
                }`}
              >
                ${plan.parsedAmount}
              </span>
              {couponAppliedForCurrentPlan && (
                <span className="font-bold">
                  ${plan.getPriceWithDiscount(coupon!)}
                </span>
              )}
              /{plan.interval})
            </p>

            {couponAppliedForCurrentPlan && (
              <div className="bg-coolGrey text-white text-left p-1 text-sm font-bold">
                Promo code: <br />
                {coupon?.name} {couponTerms ? ` - ${couponTerms}` : ''}
              </div>
            )}

            <div className="m-auto">
              <CMButton
                outline={!isPlanSelected}
                color={plan.isMonthly ? 'primary' : 'secondary'}
                onClick={() => onPlanChange(plan)}
              >
                {isPlanSelected
                  ? 'Selected'
                  : `Go ${plan.isMonthly ? 'Monthly' : 'Annual'}`}
              </CMButton>
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

export default BillingPeriodSelector;
