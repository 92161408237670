import _partition from 'lodash/partition';
import React, { memo, useContext } from 'react';

import FollowUpItem from '../follow-up-item/follow-up-item.component';
import PaginatedList from '../paginated-list/paginated-list.component';
import PaywallHiddenItemsMessage from '../paywall-hidden-items-message/paywall-hidden-items-message.component';

import { PaywallContext } from '../../context/paywall/paywall.context';

const renderFollowUpItem = (
  { id, contactId, followupTimestamp, body, timeBased },
  testid,
  page,
  index
) => (
  <FollowUpItem
    key={id}
    id={id}
    contactId={contactId}
    followupTimestamp={followupTimestamp}
    body={body}
    timeBased={timeBased}
    testid={`${testid}.paginatedList.page${page}.item${index}`}
  />
);

const FollowUpsList = memo(
  ({ list, listSize = 25, searchTerm, filter, hidePaywallItems, testid }) => {
    const { latestFollowUpsIDs, hasReachedMaxFollowUps } =
      useContext(PaywallContext);

    const [availableFollowUps, archivedFollowUps] =
      hidePaywallItems && hasReachedMaxFollowUps
        ? _partition(list, ({ id }) => latestFollowUpsIDs.includes(id))
        : [list, []];

    return (
      <PaginatedList
        shouldSetURLParam={false}
        list={availableFollowUps}
        listSize={listSize}
        paginationColor="secondary"
        renderItem={renderFollowUpItem}
        searchTerm={searchTerm}
        filter={filter}
        showHiddenItemsMessage={hidePaywallItems && archivedFollowUps.length}
        hiddenItemsMessage={
          <PaywallHiddenItemsMessage
            outstandingItems={archivedFollowUps.length}
          />
        }
        testid={testid}
      />
    );
  }
);

export default FollowUpsList;
