import React from 'react';

import { IColors } from '../../components/cm-text-button/cm-text-button.component';

const CalendarEvent: React.FC<{
  itemColor: IColors;
  content: JSX.Element;
}> = ({ itemColor, content }): JSX.Element => (
  <>
    <div
      className={`rounded-full h-2 w-2 ${
        itemColor === 'secondary'
          ? 'bg-softPurple'
          : itemColor === 'tertiary'
          ? 'bg-blueGrey'
          : 'bg-fadeOrange'
      }`}
    ></div>
    <div className="whitespace-nowrap overflow-hidden	text-ellipsis">
      {content}
    </div>
  </>
);

export default CalendarEvent;
