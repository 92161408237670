import React from 'react';

import ContactCard from '../contact-card/contact-card.component';

import adrian from '../../assets/images/headshots/adrian.jpg';
import elizabeth from '../../assets/images/headshots/elizabeth.jpg';
import thomas from '../../assets/images/headshots/thomas.jpg';
import tom from '../../assets/images/headshots/tom.jpg';

const inspirationProfiles = [
  {
    fullName: 'Tom Chenault',
    img: tom,
    subtitle: 'CSO and Co-founder @ Contact Mapping',
    isFeatured: false,
    key: 1,
    profileUrl: '/hi/tom-example',
  },
  {
    fullName: 'Adrian Chenault',
    img: adrian,
    subtitle: 'CEO and Co-founder @ Contact Mapping',
    isFeatured: false,
    key: 2,
    profileUrl: '/hi/adrian-example',
  },
  {
    fullName: 'Elizabeth Larson',
    img: elizabeth,
    subtitle: 'Chief of Client Success @ Contact Mapping',
    isFeatured: false,
    key: 3,
    profileUrl: '/hi/elizabeth-example',
  },
  {
    fullName: 'Thomas Vela',
    img: thomas,
    subtitle: 'Chief Product Officer @ Contact Mapping',
    isFeatured: false,
    key: 4,
    profileUrl: '/hi/thomas-example',
  },
];

const InspirationProfilesList = () => (
  <div className="grid md:grid-cols-2 gap-8">
    {inspirationProfiles.map(({ ...params }) => (
      <ContactCard {...params} />
    ))}
  </div>
);

export default InspirationProfilesList;
