import { TParsedToken } from './types';

export const parseJwt = (token: string | undefined): TParsedToken | null => {
  if (!token) return null;

  const base64Url = token.split('.')[1];

  if (base64Url === undefined) {
    return null;
  }

  const returnStr = base64Url.replace('-', '+').replace('_', '/');

  try {
    return JSON.parse(window.atob(returnStr));
  } catch (e) {
    return null;
  }
};
