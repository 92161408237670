import { Tab, Tabs } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import ContactsList from '../../components/contacts-list/contacts-list.component';
import FollowUpsList from '../../components/follow-ups-list/follow-ups-list.component';
import NotesList from '../../components/notes-list/notes-list.component';
import PaywallToast from '../../components/paywall-toast/paywall-toast.component';
import SecondarySearch from '../../components/secondary-search/secondary-search.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import { AuthContext } from '../../context/auth/auth.context';

import {
  useContacts,
  useFollowUps,
  useNotes,
} from '../../firebase/firebase.utils';

import { sortAndFilterContactList } from '../../utils/list.utils';

const GlobalSearch = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParam = new URLSearchParams(useLocation().search).get('query');
  const typeParam = new URLSearchParams(useLocation().search).get('type');

  const { userId } = useContext(AuthContext);

  const [searchTerm, setSearchTerm] = useState(queryParam || '');
  const [selectedTab, setSelectedTab] = useState(typeParam || 'All');

  const { data: dataContacts } = useContacts(userId);
  const { data: dataNotes } = useNotes(userId);
  const { data: dataFollowUps } = useFollowUps(userId);

  const updateSearchParams = (param, newValue) => {
    searchParams.set(param, newValue);
    setSearchParams(searchParams);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    updateSearchParams('type', newValue);
  };

  const handleSearchTermChange = newValue => {
    setSearchTerm(newValue);
    updateSearchParams('query', newValue);
  };

  const loading = !dataContacts || !dataNotes || !dataFollowUps;

  if (loading) return <WebAppLayoutLoading />;

  const filteredContacts = searchTerm
    ? sortAndFilterContactList(dataContacts, searchTerm)
    : [];

  const filteredNotes = searchTerm
    ? dataNotes.filter(({ body }) =>
        // If search term is present filter by `body`
        body.toLowerCase().includes(searchTerm.toLowerCase().trim())
      )
    : [];

  const filteredFollowUps = searchTerm
    ? dataFollowUps.filter(({ body, contactName }) => {
        // If search term is present filter by `body` or `contactaName`
        const search = searchTerm.toLocaleLowerCase().trim();
        return (
          body.toLowerCase().includes(search) ||
          contactName?.toLowerCase().includes(search)
        );
      })
    : [];

  // If selected Tab has 0 results send users to 'All'
  if (
    (filteredContacts.length === 0 && selectedTab === 'Contacts') ||
    (filteredNotes.length === 0 && selectedTab === 'Notes') ||
    (filteredFollowUps.length === 0 && selectedTab === 'FollowUps')
  )
    setSelectedTab('All');

  return (
    <WebAppLayout>
      <PaywallToast />
      <h1 className="text-2xl md:text-5xl font-bold">Search Results</h1>

      <div className="flex mt-8 md:mt-16">
        {/* Tabs */}
        <div className="bg-white rounded md:shadow-md shrink-0 md:px-8 w-full md:w-auto">
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            data-testid="searchResults.tabs"
          >
            <Tab label="All" value="All" data-testid="searchResults.tabs.all" />
            <Tab
              disabled={!filteredContacts.length}
              label={`Contacts (${filteredContacts.length})`}
              value="Contacts"
              data-testid="searchResults.tabs.contacts"
            />
            <Tab
              disabled={!filteredNotes.length}
              label={`Notes (${filteredNotes.length})`}
              value="Notes"
              data-testid="searchResults.tabs.notes"
            />
            <Tab
              disabled={!filteredFollowUps.length}
              label={`Follow-Ups (${filteredFollowUps.length})`}
              value="FollowUps"
              data-testid="searchResults.tabs.followUps"
            />
          </Tabs>
        </div>

        {/* Search */}
        <div className="ml-8 flex-grow hidden md:block">
          <SecondarySearch
            searchTerm={searchTerm}
            handleOnChange={handleSearchTermChange}
            placeholder="Search Contact Mapping"
            testid="searchResults"
          />
        </div>
      </div>

      {searchTerm === '' ? (
        <div
          className="text-black text-xl pt-8"
          data-testid="searchResults.emptySearchTermPhrase"
        >
          Please enter a search term
        </div>
      ) : null}

      {searchTerm !== '' &&
      filteredContacts.length === 0 &&
      filteredNotes.length === 0 &&
      filteredFollowUps.length === 0 ? (
        <div
          className="text-black text-xl pt-8"
          data-testid="searchResults.emptyResultsPhrase"
        >
          No results with "
          <strong className="text-fadeOrange">{searchTerm.trim()}</strong>"
        </div>
      ) : null}

      {['All', 'Contacts'].includes(selectedTab) && filteredContacts.length ? (
        <>
          <div
            className="text-black text-xl pt-8"
            data-testid="searchResults.contacts.searchPhrase"
          >
            Contacts with: "
            <strong className="text-fadeOrange">{searchTerm.trim()}</strong>" (
            {filteredContacts.length})
          </div>
          <div className="mt-8">
            <ContactsList
              list={filteredContacts}
              listSize={selectedTab === 'All' ? 5 : 25}
              searchTerm={searchTerm}
              filter={selectedTab}
              testid="searchResults.contacts"
            />
          </div>
        </>
      ) : null}

      {['All', 'Notes'].includes(selectedTab) && filteredNotes.length ? (
        <>
          <div
            className="text-black text-xl pt-8"
            data-testid="searchResults.notes"
          >
            Notes and Drafts with: "
            <strong className="text-fadeOrange">{searchTerm.trim()}</strong>" (
            {filteredNotes.length})
          </div>
          <div className="mt-8">
            <NotesList
              list={filteredNotes}
              listSize={selectedTab === 'All' ? 3 : 25}
              searchTerm={searchTerm}
              filter={selectedTab}
              hidePaywallItems
              testid="searchResults.notes"
            />
          </div>
        </>
      ) : null}

      {['All', 'FollowUps'].includes(selectedTab) &&
      filteredFollowUps.length ? (
        <>
          <div
            className="text-black text-xl pt-8"
            data-testid="searchResults.followUps.searchPhrase"
          >
            Follow-ups with: "
            <strong className="text-fadeOrange">{searchTerm.trim()}</strong>" (
            {filteredFollowUps.length})
          </div>
          <div className="mt-8">
            <FollowUpsList
              list={filteredFollowUps}
              listSize={selectedTab === 'All' ? 3 : 10}
              searchTerm={searchTerm}
              filter={selectedTab}
              hidePaywallItems
              testid="searchResults.followUps"
            />
          </div>
        </>
      ) : null}
    </WebAppLayout>
  );
};

export default GlobalSearch;
