import { useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import {
  BsCalendarEvent,
  BsPeopleFill,
  BsPersonFill,
  BsQuestionCircleFill,
} from 'react-icons/bs';
import { FaRegCalendar, FaRegStickyNote } from 'react-icons/fa';
import { MdAdminPanelSettings, MdClose, MdHome } from 'react-icons/md';
import { Link, NavLink } from 'react-router-dom';

import useFeaturesSwitch from '../../hooks/useFeaturesSwitch';

import { AuthContext } from '../../context/auth/auth.context';

import { getGoPremiumRoute } from '../../utils/helpers';

const linkClassNames = 'text-white py-3 pl-4 block';
const linkClassNamesLg =
  'lg:text-slate lg:py-4 lg:flex lg:text-base lg:items-center lg:space-x-3 lg:pl-4 lg:border-l-4 lg:border-transparent lg:hover:border-fadeOrange lg:hover:bg-dirtyWhite lg:hover:font-bold';

const SideBarLink = ({ label, icon, to, external }) => {
  const Icon = icon;

  return (
    <li>
      {external ? (
        <a
          href={to}
          target="_blank"
          rel="noreferrer"
          data-testid={`sidebar.list.item.${label.toLowerCase()}`}
          className={`${linkClassNames} ${linkClassNamesLg}`}
        >
          <span className="hidden lg:block">
            {icon ? <Icon size="21px" /> : null}
          </span>
          <span>{label}</span>
          <span className="hidden lg:block">
            <BiLinkExternal />
          </span>
        </a>
      ) : (
        <NavLink
          to={to}
          data-testid={`sidebar.list.item.${label.toLowerCase()}`}
          className={({ isActive }) =>
            `${linkClassNames} ${linkClassNamesLg} ${
              isActive
                ? 'font-bold bg-[#2a3c4a] lg:bg-dirtyWhite lg:cursor-default lg:border-l-4 lg:border-opacity-100 lg:border-fadeOrange'
                : ''
            }`
          }
        >
          <span className="hidden lg:block">
            {icon ? <Icon size="21px" /> : null}
          </span>{' '}
          <span>{label}</span>
        </NavLink>
      )}
    </li>
  );
};

const Sidebar = ({ open, toggleMenu }) => {
  const { calendar_view } = useFeaturesSwitch();
  const { userData, isPremium, authToken, signOut } = useContext(AuthContext);
  const { web_app_free, members_portal_migration } = useFeaturesSwitch();

  const matchesLg = useMediaQuery(theme => theme.breakpoints.up('lg'));

  const navClassNames =
    'absolute w-full max-w-sm bg-slate min-h-full h-auto top-0 z-50';
  const navClassNamesLg =
    'lg:block lg:sticky lg:h-screen lg:min-h-screen lg:bg-white lg:border-r lg:border-silver lg:w-64 lg:shrink-0';

  // If above 'lg' breakpoint and not premium, hide the menu
  // This can be safely removed when `web_app_free` is not needed anymore
  if (matchesLg && !web_app_free && !isPremium) return null;

  return (
    <div
      className={`${navClassNames} ${navClassNamesLg} ${
        open ? 'block' : 'hidden'
      }`}
      data-testid="sidebar"
    >
      <div className="lg:hidden text-right">
        <button className="p-3 text-white" onClick={toggleMenu}>
          <MdClose size="24px" />
        </button>
      </div>
      <nav className="font-inter font-bold lg:font-normal">
        {/* This can be safely removed when `web_app_free` is not needed anymore */}
        {web_app_free || isPremium ? (
          <>
            <ul className="text-xl" data-testid="sidebar.list">
              <SideBarLink
                external={false}
                to="/dashboard"
                label="Dashboard"
                icon={MdHome}
              />
              <SideBarLink
                external={false}
                to="/contacts"
                label="Contacts"
                icon={BsPersonFill}
              />
              <SideBarLink
                external={false}
                to="/notes"
                label="Notes"
                icon={FaRegStickyNote}
              />
              <SideBarLink
                external={false}
                to="/follow-ups"
                label="Follow-ups"
                icon={BsCalendarEvent}
              />
              {calendar_view && matchesLg ? (
                <SideBarLink
                  external={false}
                  to="/calendar"
                  label="Calendar"
                  icon={FaRegCalendar}
                />
              ) : null}
              <SideBarLink
                external={true}
                to="https://community.contactmapping.com/"
                label="Community"
                icon={BsPeopleFill}
              />
              {userData.profile === 'Admin' ? (
                <SideBarLink
                  testid="sidebar.list"
                  to="/admin/user-management"
                  label="Admin"
                  icon={MdAdminPanelSettings}
                />
              ) : null}
            </ul>
          </>
        ) : null}

        {/* Mobile submenu */}
        <ul className="lg:hidden" data-testid="sidebar.secondaryList">
          {!isPremium ? (
            <li>
              {members_portal_migration ? (
                <Link
                  className={`${linkClassNames} ${linkClassNamesLg}`}
                  data-testid="sidebar.secondaryList.item.goPremium"
                  to={getGoPremiumRoute(userData.state)}
                >
                  Go Pro
                </Link>
              ) : (
                <a
                  className={`${linkClassNames} ${linkClassNamesLg}`}
                  href={`${process.env.REACT_APP_MEMBERS_PORTAL_URL}/manage-account/${authToken}`}
                  data-testid="sidebar.secondaryList.item.goPremium"
                >
                  Go Pro
                </a>
              )}
            </li>
          ) : null}
          <SideBarLink
            testid="sidebar.secondaryList"
            to="/my-profile"
            label="Digital Business Card"
          />
          <SideBarLink
            testid="sidebar.secondaryList"
            to="/manage-account"
            label="Your Account"
          />
          <li>
            <div className="lg:hidden border-b border-steel m-4"></div>
          </li>
          <li>
            <button
              className={`${linkClassNames} ${linkClassNamesLg} font-bold`}
              onClick={signOut}
              data-testid="sidebar.secondaryList.item.logout"
            >
              Logout
            </button>
          </li>
        </ul>

        {/* Bottom Links */}
        <ul className="lg:fixed lg:bottom-0">
          <li>
            <div className="lg:hidden border-b border-steel m-4"></div>
          </li>
          <SideBarLink
            external={true}
            to="https://www.contactmapping.com/contact/"
            label="Support"
            icon={BsQuestionCircleFill}
          />
          <SideBarLink
            external={true}
            to="https://www.contactmapping.com/tos/"
            label="Terms and conditions"
          />
          <SideBarLink
            external={true}
            to="https://www.contactmapping.com/privacy/"
            label="Privacy policy"
          />
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
