import { Pagination } from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const PaginatedList = memo(
  ({
    list,
    renderItem,
    listSize = 10,
    paginationColor,
    paginationSize = 'large',
    listContainerClassName,
    searchTerm,
    filter,
    showHiddenItemsMessage,
    hiddenItemsMessage,
    testid,
    shouldSetURLParam,
  }) => {
    const pages = Math.ceil(list.length / listSize);

    const [page, setPage] = useState(1);
    const [searchParams, setSearchParams] = useSearchParams();

    const initialSearchTerm = useRef(searchTerm);
    const initialFilter = useRef(filter);
    const scrollDiv = useRef();

    useEffect(() => {
      const filterChanged = initialFilter.current !== filter;
      const searchTermChanged = initialSearchTerm.current !== searchTerm;
      const urlPageParam = Number(searchParams.get('activePage'));
      const setPageFromURLParam = urlPageParam && urlPageParam !== page;

      // Set first page as the active one when the filters change
      // and current page is not page #1
      if (
        (filterChanged || searchTermChanged) &&
        shouldSetURLParam &&
        page !== 1
      ) {
        setPage(1);
        // setTimeout is needed to make setSearchParams not to be fired at the same time
        // as the component is rerendered. Otherwise it would be overwritten and
        // it would not update the URL param
        setTimeout(() => {
          searchParams.set('activePage', 1);
          setSearchParams(searchParams);
        });
      } else if (setPageFromURLParam) {
        setPage(urlPageParam);
      }
      // We need to disable the dependency array so it only runs when the searchTerm
      // and filters change. Otherwise it would be executed everytime searchParams change causing unwanted side effects
      // eslint-disable-next-line
    }, [searchTerm, filter, setSearchParams]);

    const handleChange = (event, value) => {
      scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
      setPage(value);

      if (shouldSetURLParam) {
        searchParams.set('activePage', value);
        setSearchParams(searchParams);
      }
    };

    const range = page === 1 ? 0 : (page - 1) * listSize;

    return (
      <div ref={scrollDiv} className="scroll-m-14">
        <div className={listContainerClassName}>
          {list
            .slice(range, page * listSize)
            .map((item, index) => renderItem(item, testid, page, index))}
        </div>
        {/* If on last page, render hidden items message (if any) */}
        {(page === pages || pages === 0) && showHiddenItemsMessage ? (
          <div className="py-5">{hiddenItemsMessage}</div>
        ) : null}
        {/* Only show pagination controls if there is more than 1 page */}
        {pages > 1 ? (
          <Pagination
            count={pages}
            page={page}
            classes={{
              root: 'flex justify-center pt-4',
            }}
            onChange={handleChange}
            color={paginationColor}
            size={paginationSize}
            data-testid={`${testid}.paginatedList.paginationButtons`}
          />
        ) : null}
      </div>
    );
  }
);

export default PaginatedList;
