import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Resizer from 'react-image-file-resizer';

import CMAvatar from '../../components/cm-avatar/cm-avatar.component';
import CMButton from '../../components/cm-button/cm-button.component';
import CMDialog from '../../components/cm-dialog/cm-dialog.component';

const ProfilePic = ({ image, handleSaveImage }) => {
  const imageInputRef = useRef(null);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [completedCrop, setCompletedCrop] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [profileImageModal, setProfileImageModal] = useState(false);
  const [blob, setBlob] = useState(null);
  const [crop, setCrop] = useState({
    unit: '%',
    height: 50,
    aspect: 1,
  });
  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const onSelectFile = e => {
    if (e.target.files[0]) {
      try {
        Resizer.imageFileResizer(
          e.target.files[0],
          1000,
          1000,
          'JPEG',
          80,
          0,
          uri => {
            setSelectedImage(uri);
            setProfileImageModal(true);
          },
          'base64'
        );
      } catch (err) {
        // TODO: error handling
        console.log(err);
      }
    }
  };

  const saveImage = (canvas, crop) => {
    if (!crop || !canvas) {
      return;
    }
    const file = new File([blob], 'fileName.jpg', { type: 'image/jpeg' });
    handleSaveImage(canvas.toDataURL(), file);
    setProfileImageModal(false);
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    canvas.toBlob(blob => {
      setBlob(new File([blob], 'fileName.jpg', { type: 'image/jpeg' }));
    }, 'image/jpeg');
  }, [completedCrop]);

  return (
    <>
      <div className="text-center space-y-3 md:space-y-0 md:flex md:space-x-5 md:items-center">
        <div className="mx-auto md:mx-0 w-20">
          <CMAvatar img={image} />
        </div>
        <div>
          <CMButton
            color="tertiary"
            outline={!!image}
            onClick={() => imageInputRef.current.click()}
          >
            {image ? 'Update Profile Picture' : 'Upload a Profile Picture'}
          </CMButton>
          <input
            ref={imageInputRef}
            accept="image/*"
            type="file"
            onChange={onSelectFile}
            className="hidden"
          />
        </div>
      </div>
      {/* Update Profile Pic Modal */}
      <CMDialog
        title="Update your Picture"
        renderContent={
          <>
            <ReactCrop
              src={selectedImage}
              crop={crop}
              ruleOfThirds
              onImageLoaded={onLoad}
              onChange={c => setCrop(c)}
              onComplete={c => setCompletedCrop(c)}
              circularCrop
            />
            <canvas
              className="hidden"
              ref={previewCanvasRef}
              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
              style={{
                width: Math.round(completedCrop?.width ?? 0),
                height: Math.round(completedCrop?.height ?? 0),
              }}
            />
          </>
        }
        renderBottomActions={
          <div className="space-x-4 flex w-full">
            <CMButton
              outline
              fullWidth
              onClick={() => {
                setProfileImageModal(false);
              }}
            >
              Cancel
            </CMButton>
            <CMButton
              onClick={() => saveImage(previewCanvasRef.current, completedCrop)}
              fullWidth
            >
              Confirm
            </CMButton>
          </div>
        }
        open={profileImageModal}
        handleClose={() => {
          setProfileImageModal(false);
        }}
      />
    </>
  );
};

export default ProfilePic;
