import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import React, { memo, useEffect, useState } from 'react';

import {
  amPm,
  getHours24,
  hours12,
  minutes600s,
} from '../../utils/dates.utils';

type IProps = {
  /** Time string in 24 hour format HH:MM */
  time: string;
  setTime: React.Dispatch<React.SetStateAction<string>>;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 80,
    },
  },
};

const TimeDropdown = memo(({ time, setTime }: IProps) => {
  const localDate = new Date(`1970-01-01T${time}:00`);

  const [values, setValues] = useState({
    hour: `${localDate.getHours() % 12 || 12}`.padStart(2, '0'),
    minutes: `${localDate.getMinutes()}`.padStart(2, '0'),
    amOrPm: localDate.toLocaleTimeString('en-us').slice(-2),
  });

  const handleChange =
    (field: string) => (event: SelectChangeEvent<string>) => {
      setValues(prevState => ({
        ...prevState,
        [field]: event.target.value,
      }));
    };

  useEffect(() => {
    setTime(
      `${getHours24(values.hour, values.amOrPm === 'AM')}:${values.minutes}`
    );
  }, [values, setTime]);

  return (
    <div className="flex items-center">
      <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
        <Select
          labelId="hour"
          id="hour"
          value={values.hour}
          onChange={handleChange('hour')}
          MenuProps={MenuProps}
        >
          {hours12.map(h => (
            <MenuItem key={h} value={h}>
              {h}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <span>:</span>
      <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
        <Select
          labelId="minutes"
          id="minutes"
          value={values.minutes}
          onChange={handleChange('minutes')}
          MenuProps={MenuProps}
        >
          {minutes600s.map(m => (
            <MenuItem key={m} value={m}>
              {m}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
        <Select
          labelId="am-pm"
          id="am-pm"
          value={values.amOrPm}
          onChange={handleChange('amOrPm')}
          MenuProps={MenuProps}
        >
          {amPm.map(e => (
            <MenuItem key={e} value={e}>
              {e}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
});

export default TimeDropdown;
