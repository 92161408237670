import React, { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Link, Navigate, useParams } from 'react-router-dom';

import CMAvatar from '../../components/cm-avatar/cm-avatar.component';
import CMButton from '../../components/cm-button/cm-button.component';
import CMDialog from '../../components/cm-dialog/cm-dialog.component';
import ContactDownloadedConfirmationDialog from '../../components/contact-downloaded-confirmation-dialog/contact-downloaded-confirmation-dialog.component';
import DownloadContactInfo from '../../components/download-contact-info/download-contact-info.component';
import HTMLText from '../../components/html-text/html-text.component';
import IntroduceYourselfDialog from '../../components/introduce-yourself-dialog/introduce-yourself-dialog.component';
import ProfileProgressHeader from '../../components/profile-progress-header/profile-progress-header.component';
import ReadMore from '../../components/read-more/read-more.component';
import ShareProfileDialog from '../../components/share-profile-dialog/share-profile-dialog.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import { AuthContext } from '../../context/auth/auth.context';

import { getUserByUrl } from '../../services/users/users.service';

import { profileNetworks } from '../../utils/networks';

import logo from '../../assets/images/logo-light.png';
import ShareImage from '../../assets/images/share-image-profiles.png';

import { contactDetails } from './contact-details';

export const ProfileContent = ({ pp, isPreview, renderActionButtons }) => {
  const [bio, setBio] = useState(false);
  const viewBio = () => setBio(!bio);
  const { about, emails, firstname, lastname, image, title, socialprofiles } =
    pp;
  const shouldShowAboutMeCard = about || isPreview;

  return (
    <>
      <div
        className={`${
          shouldShowAboutMeCard
            ? 'grid grid-cols-1 md:grid-cols-2 gap-4'
            : 'lg:w-1/2 sm:w-full mx-auto'
        }`}
      >
        <div className="bg-slate bg-contact-card bg-no-repeat bg-blend-multiply bg-contain p-4 shadow-md rounded-lg text-dirtyWhite flex flex-row space-x-4 items-center justify-center h-48 md:h-auto">
          <div>
            <CMAvatar img={image} />
          </div>
          <div>
            <h1
              className="font-normal"
              data-testid="profile.mainDetails.fullName"
            >
              {firstname} {lastname}
            </h1>
            <div
              className="font-base"
              data-testid="profile.mainDetails.jobTitle"
            >
              {title}
            </div>
          </div>
        </div>
        {shouldShowAboutMeCard && (
          <div className="bg-white p-4 shadow-md rounded-lg text-slate space-y-1">
            <h2 className="uppercase font-bold font-lg">About me</h2>
            <div
              className="font-rasa text-xl leading-6"
              data-testid="profile.aboutMe.content"
            >
              {isPreview && !about ? (
                'bio goes here'
              ) : (
                <ReadMore
                  renderActionButton={
                    <button
                      className="text-fadeOrange font-medium"
                      onClick={viewBio}
                      data-testid="profile.aboutMe.seeMoreButton"
                    >
                      See More
                    </button>
                  }
                  maxLines={5}
                >
                  <HTMLText text={about} />
                </ReadMore>
              )}
            </div>
          </div>
        )}
      </div>

      {renderActionButtons}

      <div className="md:flex justify-between py-4 md:py-10 -mx-4 md:mx-0 px-4 md:px-0 whitespace-nowrap overflow-y-auto space-x-4 md:space-x-0">
        {profileNetworks.map((item, index) => {
          const baseClassName =
            'inline-flex rounded-full h-16 w-16 items-center justify-center shadow-md text-white';
          const socialProfiles = [...(socialprofiles || []), ...(emails || [])];
          const { networkUrl } = item.data(socialProfiles);

          if (!networkUrl) {
            return (
              <div
                key={`network-${index}`}
                className={`${baseClassName} bg-coolGrey`}
              >
                {item.icon}
              </div>
            );
          }
          return (
            <a
              key={`network-${index}`}
              target="_blank"
              href={networkUrl}
              className={`${baseClassName} bg-slate hover:opacity-95`}
              rel="noreferrer"
            >
              {item.icon}
            </a>
          );
        })}
      </div>

      <div className="bg-white p-4 rounded-lg text-slate shadow-md">
        <h2 className="uppercase font-semibold text-lg">Contact details</h2>
        <ul>
          {contactDetails.map((detail, index) => {
            if (detail.content(pp)) {
              return (
                <li
                  className="flex flex-row items-center space-x-4 my-4"
                  key={`detail-${index}`}
                >
                  <div>{detail.icon}</div>
                  <div>
                    <small className="uppercase text-xs text-steel">
                      {detail.label}
                    </small>
                    <div
                      className="block -top-1 relative"
                      data-testid={`profile.contactDetails.item.${detail.label.toLowerCase()}`}
                    >
                      {detail.content(pp)}
                    </div>
                  </div>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </div>

      <CMDialog
        title={`${firstname} ${lastname}'s BIO`}
        renderContent={
          <div className="font-rasa text-xl">
            <HTMLText text={about} />
          </div>
        }
        renderBottomActions={
          <CMButton onClick={() => setBio(false)}>Close</CMButton>
        }
        open={bio}
        handleClose={() => {
          setBio(false);
        }}
      />
    </>
  );
};

const PublicProfile = () => {
  const { userData } = useContext(AuthContext);
  const { profile } = useParams();

  const [isOwn, setIsOwn] = useState(false);
  const [data, setData] = useState();
  const [invalidUser, setInvalidUser] = useState(false);
  const [shareProfileDialogOpen, setShareProfileDialogOpen] = useState(false);
  const [intoductionDialogOpen, setIntroductionDialogOpen] = useState(false);
  const [contactDownloadedOpen, setContactDownloadedOpen] = useState(false);

  useEffect(() => {
    const isOwnProfile =
      profile?.toLowerCase() === userData?.profileurl?.toLowerCase();

    if (profile && !isOwnProfile) {
      getUserByUrl(profile)
        .then(response => setData(response.data))
        .catch(() => setInvalidUser(true))
        .finally(() => {
          if (!invalidUser) setIsOwn(false);
        });
    } else {
      setIsOwn(!profile || isOwnProfile);
      setData(userData);
    }
  }, [userData, profile, invalidUser]);

  const toggleShareProfileDialogOpen = () =>
    setShareProfileDialogOpen(!shareProfileDialogOpen);

  if (!data && !invalidUser) return <WebAppLayoutLoading />; // Loading URL

  if (invalidUser) return <Navigate replace to="/404" />;

  // If a user doesn't have a public profile yet, just send them to /edit-profile
  if (isOwn && userData?.dbc_enabled !== 'Y')
    return <Navigate replace to="/edit-profile" />;

  const descriptionData =
    data.about?.length >= 195 ? `${data.about.slice(0, 195)}...` : data.about;

  return (
    <WebAppLayout fullWidth>
      {/* Set `<head>` values based on user data. To make this work for search
      engines and Social Media Sharing we are using prerender.io which is being
      setup in server.js */}
      <Helmet>
        <title>{`${data.firstname} ${data.lastname} | Digital Business Card`}</title>
        <meta name="description" content={descriptionData} />
        {/* OG */}
        <meta
          property="og:title"
          content={`${data.firstname} ${data.lastname} | Digital Business Card`}
        />
        <meta property="og:description" content={descriptionData} />
        <meta property="og:image" content={data.image || ShareImage} />
        {/* Twitter and LinkedIn */}
        <meta
          property="twitter:title"
          content={`${data.firstname} ${data.lastname} | Digital Business Card`}
        />
        <meta property="twitter:description" content={descriptionData} />
        <meta property="twitter:image" content={data.image || ShareImage} />
      </Helmet>

      {isOwn ? (
        <div className="shadow-md pb-12">
          <ProfileProgressHeader
            title="Your digital business card"
            subtitle="You are now ready to share your digital business card with the world"
          />
          <div className="max-w-3xl mx-auto space-y-6 px-4 lg:px-0 md:flex md:space-x-9 md:space-y-0">
            <CMButton
              component={Link}
              color="secondary"
              fullWidth
              to="/edit-profile"
            >
              Keep editing
            </CMButton>
            <CMButton
              component={Link}
              color="tertiary"
              fullWidth
              to="/pick-url"
            >
              Change URL
            </CMButton>
            <CMButton fullWidth onClick={toggleShareProfileDialogOpen}>
              Share my card
            </CMButton>
          </div>
        </div>
      ) : null}

      <div className="max-w-3xl mx-auto py-9 px-4 lg:px-0 bg-paleGreen md:bg-transparent">
        <div className="mb-16">
          <ProfileContent
            isPreview={false}
            pp={data}
            renderActionButtons={
              <div
                className={`pt-4 md:pt-10 md:mx-auto space-y-4 md:flex md:space-y-0 md:space-x-9  ${
                  !isOwn && data?.role === 'Premium'
                    ? ''
                    : 'text-center md:w-2/5'
                }`}
              >
                {!isOwn && data?.role === 'Premium' ? (
                  <CMButton
                    fullWidth
                    onClick={() => setIntroductionDialogOpen(true)}
                    data-testid="profile.header.introduceYourselfButton"
                  >
                    Introduce yourself
                  </CMButton>
                ) : null}
                {/* Download Contact Info Button */}
                <DownloadContactInfo
                  onAfterDownload={() => {
                    if (!isOwn && data?.role === 'Premium') {
                      setContactDownloadedOpen(true);
                    }
                  }}
                  testid="profile.header.downloadContactInfo"
                  info={data}
                />
              </div>
            }
          />

          {/* Footer */}
          <div className="font-inter mt-4 md:mt-10 bg-slate bg-contact-card bg-no-repeat bg-blend-multiply bg-contain p-6 shadow-lg rounded-lg text-white flex flex-col md:flex-row justify-between items-center">
            <div>
              <h3 className="font-bold text-2xl pb-6">
                Join Contact Mapping for free
              </h3>
              <ul className="text-dirtyWhite list-disc ml-4">
                <li>Get your own online business card</li>
                <li>Improve your relationships</li>
                <li>Remember everything about everyone</li>
                <li>Get training and support to grow your business</li>
              </ul>
            </div>
            <div className="text-center">
              <div className="md:block items-center mb-4 hidden">
                <img src={logo} alt="Contact Mapping" className="w-[180px]" />
              </div>
              <div className="pt-6 md:pt-0">
                <CMButton
                  component="a"
                  href="https://members.contactmapping.com/sign-up?utm_source=contactmapping&utm_medium=webapp&utm_content=dbcfooter"
                  onClick={() =>
                    ReactGA.event({
                      transport: 'beacon', // Added for Outbound link
                      category: 'digital business card',
                      action: 'sign up',
                      label: data.profileUrl,
                    })
                  }
                  data-testid="profile.footer.signUpButton"
                >
                  Sign up
                </CMButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Share Modal */}
      <ShareProfileDialog
        open={shareProfileDialogOpen}
        handleClose={toggleShareProfileDialogOpen}
        url={data.profileurl}
      />
      {data.referrallink ? (
        <iframe
          title="referrall"
          src={`https://members.contactmapping.com/?via=${data.referrallink}`}
          style={{ width: 0, height: 0 }}
        ></iframe>
      ) : null}

      <IntroduceYourselfDialog
        username={`${data.firstname} ${data.lastname}`}
        open={intoductionDialogOpen}
        profile={profile}
        onClose={() => setIntroductionDialogOpen(false)}
      />

      <ContactDownloadedConfirmationDialog
        username={`${data.firstname} ${data.lastname}`}
        open={contactDownloadedOpen}
        onClose={shouldIntroduceYourselfOpen => {
          setContactDownloadedOpen(false);
          setIntroductionDialogOpen(shouldIntroduceYourselfOpen);
        }}
      />
    </WebAppLayout>
  );
};
export default PublicProfile;
