import TextField from '@mui/material/TextField';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';

import { CMPasswordInput } from '../../components/change-password/change-password.component';
import CMButton from '../../components/cm-button/cm-button.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import useFeaturesSwitch from '../../hooks/useFeaturesSwitch';
import useTryToken from '../../hooks/useTryToken';

import { AuthContext } from '../../context/auth/auth.context';

import { signInRequest } from '../../services/auth/auth.service';

import logo from '../../assets/images/logo-dark.png';

const Login = () => {
  const { state } = useLocation();
  const { userData, performCommonSignInTasks } = useContext(AuthContext);

  const { members_portal_migration } = useFeaturesSwitch();

  const navigate = useNavigate();

  const [isPerformingSignIn, setIsPerformingSignIn] = useState(false);
  const [signInError, setSignInError] = useState(false);

  const webAppFromLocation = state?.from
    ? `${state?.from.pathname}${state?.from?.search ?? ''}`
    : '/';

  const { isPerformingTryToken } = useTryToken();

  const signIn = payload => {
    setSignInError(false);
    setIsPerformingSignIn(true);
    signInRequest(payload)
      .then(({ userData, authToken, settings }) => {
        setSignInError(false);
        setIsPerformingSignIn(false);
        performCommonSignInTasks(userData, authToken, settings);
        navigate(webAppFromLocation);
      })
      .catch(() => {
        setSignInError(true);
        setIsPerformingSignIn(false);
      });
  };

  useEffect(() => () => setSignInError(false), [setSignInError]);

  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
  });

  const handleChange = (event, key) => {
    setValues({
      ...values,
      [key]: event.target.value,
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const payload = {
      email: values.email,
      password: values.password,
    };

    signIn(payload, webAppFromLocation);
  };

  if (isPerformingTryToken) return <WebAppLayoutLoading hiddenHeader />;

  if (userData) return <Navigate replace to={webAppFromLocation} />;

  return (
    <WebAppLayout hiddenHeader fullWidth>
      <Helmet>
        <title>Contact Mapping</title>
      </Helmet>
      <div className="bg-slate bg-login bg-blend-multiply bg-no-repeat bg-center w-full h-screen items-center flex">
        <form
          className="mx-auto h-full w-full flex justify-center items-center"
          onSubmit={handleSubmit}
        >
          <div className="bg-white shadow-lg rounded-lg px-12 pt-12 pb-8 m-auto md:w-3/5 lg:w-1/3 space-y-8">
            <img
              src={logo}
              alt="Contact Mapping"
              className="w-[180px] mx-auto"
            />
            <div>
              <TextField
                label="Email"
                className="w-full"
                variant="outlined"
                id="email"
                onChange={ev => handleChange(ev, 'email')}
                value={values.email}
                inputProps={{
                  style: { fontSize: '1rem' },
                  'data-testid': 'login.emailInput',
                }}
                InputLabelProps={{
                  style: { fontSize: '1rem' },
                }}
              />
            </div>
            <div>
              <CMPasswordInput
                label="Password"
                id="password"
                value={values.password}
                onChange={ev => handleChange(ev, 'password')}
                InputLabelProps={{}} // Override shrink: true
                testid="login.passwordInput"
              />
            </div>
            <div className="space-y-6">
              {signInError && (
                <div
                  className="text-error font-bold text-xs text-center"
                  data-testid="signIn.errorText.incorrectPassword"
                >
                  There's been an error, please check your email and password.
                </div>
              )}
              <CMButton
                disabled={isPerformingSignIn}
                loading={isPerformingSignIn}
                id="sign-in"
                type="submit"
                fullWidth
                data-testid="login.loginButton"
              >
                Sign in
              </CMButton>
              <hr />
              <div className="flex flex-row justify-evenly">
                {members_portal_migration ? (
                  <>
                    <Link
                      to="/forgot-password"
                      data-testid="login.resetPasswordLink"
                      className="text-fadeOrange text-sm font-semibold underline"
                    >
                      Can't Login?
                    </Link>
                    <Link
                      to="/signup"
                      data-testid="login.signupLink"
                      className="text-fadeOrange text-sm font-semibold underline"
                    >
                      Create an account
                    </Link>
                  </>
                ) : (
                  <>
                    <a
                      href={`${process.env.REACT_APP_MEMBERS_PORTAL_URL}/forgot`}
                      id="forgot-password"
                      className="text-fadeOrange text-sm font-semibold underline"
                    >
                      Can't Login?
                    </a>
                    <a
                      href={`${process.env.REACT_APP_MEMBERS_PORTAL_URL}/sign-up`}
                      data-testid="login.createAccountLink"
                      className="text-fadeOrange text-sm font-semibold underline"
                    >
                      Create an account
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </WebAppLayout>
  );
};

export default Login;
