import React, { memo } from 'react';

const SkeletonLoader = memo(() => {
  const items = ['w-8/12', 'w-9/12', 'w-10/12', 'w-11/12'];

  return items.map((e, i) => {
    const getRandomWidth = () =>
      items[Math.floor(Math.random() * items.length)];
    const baseColClassNames = 'h-12 md:h-[74px] flex items-center';
    const baseColClassNamesMobile = 'hidden md:flex';
    const baseSkeletonClassNames = 'bg-silver h-5 animate-pulse';

    return (
      <div className="md:grid grid-cols-8 md:px-8" key={`loader-${i}`}>
        <div className={`${baseColClassNames} col-span-3`}>
          <div
            className={`${getRandomWidth()} ${baseSkeletonClassNames}`}
          ></div>
        </div>
        <div className={`${baseColClassNames} ${baseColClassNamesMobile}`}>
          <div
            className={`${getRandomWidth()} ${baseSkeletonClassNames}`}
          ></div>
        </div>
        <div
          className={`${baseColClassNames} ${baseColClassNamesMobile} col-span-2`}
        >
          <div
            className={`${getRandomWidth()} ${baseSkeletonClassNames}`}
          ></div>
        </div>
        <div
          className={`${baseColClassNames} ${baseColClassNamesMobile} col-span-2`}
        >
          <div
            className={`${getRandomWidth()} ${baseSkeletonClassNames}`}
          ></div>
        </div>
      </div>
    );
  });
});

export default SkeletonLoader;
