import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { ChangePasswordForm } from '../../components/change-password/change-password.component';
import CMButton from '../../components/cm-button/cm-button.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import { AuthContext } from '../../context/auth/auth.context';
import {
  ISnackbarContext,
  SnackbarContext,
} from '../../context/snackbar/snackbar.context';

import { signInRequest } from '../../services/auth/auth.service';
import {
  createUser,
  setNewUserPassword,
} from '../../services/users/users.service';

import { PLATFORM_IDENTIFIER } from '../../utils/constants';
import { getTimezoneOffset } from '../../utils/dates.utils';

import logo from '../../assets/images/logo-dark.png';

const SignUp: React.FC<{}> = (): JSX.Element => {
  const navigate = useNavigate();

  const formRef = useRef(null);

  const { setAuthToken } = useContext(AuthContext);
  const { showSnackbar } = useContext<ISnackbarContext>(SnackbarContext);

  const [error, setError] = useState<boolean>(false);
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPasswordStep, setShowPasswordStep] = useState<boolean>(false);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phonenumber: '',
    autogeneratedPassword: `${(Math.random() + 1)
      .toString(36)
      .substring(2)}${Math.random()}`,
  });
  const [pathParam, setPathParam] = useState<boolean>(false);
  const [couponParam, setCouponParam] = useState('');
  const [referralToken, setReferralToken] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setReferralToken(window.Rewardful?.affiliate?.token);
  }, []);

  useEffect(() => {
    const pathParam: string | null = searchParams.get('path');
    if (pathParam && pathParam.toLowerCase() === 'pro') {
      setPathParam(true);
    }
    const couponParam: string | null = searchParams.get('coupon');
    if (couponParam && couponParam.length) {
      setCouponParam(couponParam);
    }
  }, [searchParams]);

  const submitUserCreation = e => {
    e.preventDefault();

    const { firstname, email, phonenumber, lastname, autogeneratedPassword } =
      userData;

    if (!email || !firstname || !acceptedTerms) {
      return;
    }

    setLoading(true);

    const newUser: {
      firstname: string;
      email: string;
      phonenumber: string;
      lastname: string;
      password: string;
      flag_provisionalpassword: 'Y' | 'N';
      utcoffset: string;
      bonvera_k?: string;
      referral?: string;
      termsaccepted: number;
      platform: string;
    } = {
      firstname,
      email,
      phonenumber,
      lastname,
      password: autogeneratedPassword,
      flag_provisionalpassword: 'Y',
      utcoffset: getTimezoneOffset(),
      termsaccepted: Math.floor(Date.now() / 1000),
      platform: PLATFORM_IDENTIFIER,
    };

    const bonvera_k: string = Cookies.get('bonvera-k');

    if (bonvera_k !== undefined) {
      newUser.bonvera_k = bonvera_k;
    }

    if (referralToken) {
      newUser.referral = referralToken;
    }

    createUser(newUser)
      .then(({ data }) => {
        setUserId(data.id);
        return signInRequest({
          email,
          password: autogeneratedPassword,
        });
      })
      .then(({ authToken }) => {
        setToken(authToken);
        setShowPasswordStep(true);
      })
      .catch(err => {
        if (err?.response?.status === 422) {
          showSnackbar({
            severity: 'error',
            message: `${email} ${err?.response?.data?.email?.[0]}.`,
          });
        } else {
          setError(true);
        }
      })
      .finally(() => setLoading(false));
  };

  const submitPassword = (password: string) =>
    setNewUserPassword(userId, password, token)
      .then(() => {
        setShowPasswordStep(false);
        setAuthToken(token);
        if (pathParam) {
          navigate(
            couponParam.length
              ? '/billing-period-payment?coupon=' + couponParam
              : '/billing-period-payment'
          );
        } else {
          navigate('/select-your-plan');
        }
      })
      .catch(() => {
        showSnackbar({
          severity: 'error',
          message: 'Something wrong happened.',
        });
      });

  const handleUserDataChange =
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setUserData(prevState => ({
        ...prevState,
        [field]: e.target.value,
      }));
    };

  return (
    <WebAppLayout hiddenHeader fullWidth>
      <div className="bg-slate bg-login bg-blend-multiply bg-no-repeat bg-center w-full h-screen items-center flex">
        <div className="h-full w-full flex justify-center items-center mx-8">
          <div className="bg-white shadow-lg rounded-lg px-12 pt-12 pb-8 m-auto md:w-3/5 lg:w-1/3 space-y-8">
            <img
              src={logo}
              alt="Contact Mapping"
              className="w-[180px] mx-auto"
            />
            {!showPasswordStep ? (
              <ValidatorForm
                noValidate
                ref={formRef}
                onSubmit={submitUserCreation}
                className="space-y-6 text-center"
              >
                <h1 className="text-3xl font-bold">Welcome!</h1>

                <TextValidator
                  label="FIRST NAME"
                  className="w-full"
                  id="firstname"
                  data-testid="signup.firstNameInput"
                  onChange={handleUserDataChange('firstname')}
                  value={userData.firstname}
                  required
                  validators={['required']}
                  errorMessages={['This field is required']}
                  InputLabelProps={{ shrink: true }}
                />
                <TextValidator
                  label="LAST NAME"
                  className="w-full"
                  id="lastname"
                  data-testid="signup.lastNameInput"
                  onChange={handleUserDataChange('lastname')}
                  value={userData.lastname}
                  InputLabelProps={{ shrink: true }}
                />
                <TextValidator
                  label="YOUR EMAIL"
                  className="w-full"
                  id="email"
                  data-testid="signup.emailInput"
                  onChange={handleUserDataChange('email')}
                  value={userData.email}
                  required
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    'This field is required',
                    'Not a valid email',
                  ]}
                  InputLabelProps={{ shrink: true }}
                />
                <TextValidator
                  label="MOBILE"
                  className="w-full"
                  id="phonenumber"
                  data-testid="signup.phoneNumberInput"
                  type="number"
                  required
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={handleUserDataChange('phonenumber')}
                  value={userData.phonenumber}
                  InputLabelProps={{ shrink: true }}
                />
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-testid="signup.acceptTermsCheckbox"
                        onChange={e => setAcceptedTerms(e.target.checked)}
                      />
                    }
                    label={
                      <div>
                        I accept the{' '}
                        <a
                          className="text-fadeOrange underline "
                          href="https://www.contactmapping.com/tos"
                          target="_blank"
                          rel="noreferrer"
                          data-testid="signup.tosLink"
                        >
                          Terms and Conditions
                        </a>
                      </div>
                    }
                  />
                </FormGroup>

                <CMButton
                  disabled={loading || !acceptedTerms}
                  loading={loading}
                  fullWidth
                  id="sign-up"
                  type="submit"
                  data-testid="signup.firstStepNextButton"
                >
                  Next
                </CMButton>
                <hr />
                <div className="mt-10">
                  <Link
                    to="/signin"
                    data-testid="signup.signinLink"
                    className="text-fadeOrange text-sm font-semibold underline uppercase"
                  >
                    I already have an account
                  </Link>
                </div>
              </ValidatorForm>
            ) : (
              <>
                <div className="text-center">
                  <h1 className="text-3xl font-bold">
                    Let's set up your password
                  </h1>
                </div>
                <ChangePasswordForm
                  submitButtonText="Next"
                  showHelper
                  submitMethod={submitPassword}
                  testid="resetPassword"
                />
              </>
            )}
            {error && (
              <div
                className="text-error font-bold text-xs text-center"
                data-testid="signup.errorText"
              >
                There's been an error, please try again.
              </div>
            )}
          </div>
        </div>
      </div>
    </WebAppLayout>
  );
};

export default SignUp;
