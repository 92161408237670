import React from 'react';
import { Link } from 'react-router-dom';

import CMButton from '../../components/cm-button/cm-button.component';
import InspirationProfilesList from '../../components/inspiration-profiles-list/inspiration-profiles-list.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import './onboarding.styles.css';

const Onboarding = () => {
  const startBuildingButton = (
    <div className="w-full md:w-80 mx-auto">
      <CMButton
        fullWidth
        component={Link}
        to="/edit-profile"
        data-testid="onboarding.startBuildingButton"
      >
        I Want My Card!
      </CMButton>
    </div>
  );

  return (
    <WebAppLayout digitalBussinessCard>
      <div className="px-4 md:px-0 bg-white md:bg-transparent">
        <h2 className="text-3xl font-bold md:text-center py-14">
          New! The Easiest Way to Exchange Contact Information
        </h2>
        <div className="video-container">
          <iframe
            width="768"
            height="384"
            src="https://www.youtube.com/embed/q04aIjHvZ8c"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <h4 className="text-2xl font-bold pt-14 pb-3">
          Why a digital business card powered by Contact Mapping?
        </h4>
        <ul className="list-disc pl-5 text-base">
          <li className="pb-3">
            <strong>Confidence:</strong> Put your best foot forward with a
            professional look & feel.
          </li>
          <li className="pb-3">
            <strong>Speed:</strong> Share ALL your social media and contact info
            in one link.
          </li>
          <li>
            <strong>An Easier Ask:</strong> People are more willing to share a
            phone number or email to get your info.
          </li>
        </ul>
        <div className="py-20 md:text-center">
          <h5 className="text-xl font-bold pb-4">Ready to Get Started?</h5>
          {startBuildingButton}
        </div>
        <h4 className="text-2xl md:text-center font-bold pb-4">
          Click on the following digital business cards for inspiration.
        </h4>
        <InspirationProfilesList />
        <div className="py-20 md:text-center">
          <h4 className="text-2xl font-bold pb-7">Ready to Get Started?</h4>
          {startBuildingButton}
        </div>
      </div>
    </WebAppLayout>
  );
};

export default Onboarding;
