export default class Coupon {
  id: string;
  object: string;
  amount_off: number;
  created: number;
  currency: string;
  duration: string;
  duration_in_months: number;
  livemode: boolean;
  max_redemptions: number;
  metadata: { terms: string };
  name: string;
  percent_off: number;
  percent_off_precise: number;
  redeem_by: number;
  times_redeemed: number;
  valid: boolean;
  applies_to: { products: string[] };

  constructor(data: Object) {
    Object.assign(this, data);
  }

  get discountAmount(): number {
    return this.isPercentType() ? this.percent_off : this.amount_off / 100;
  }

  isPercentType(): boolean {
    return !!this.percent_off;
  }

  canApplyToProduct(productId: string): boolean {
    if (!this.valid) {
      return false;
    }

    if (!this.applies_to) {
      return true;
    }

    return this.applies_to.products.some(
      (product: string) => product === productId
    );
  }

  get timeBased(): boolean {
    return this.duration_in_months > 0;
  }

  get saveDescription(): string {
    const description: string = this.isPercentType()
      ? `${this.discountAmount}%`
      : `$${this.discountAmount}`;
    return `${description} off ${this.duration}`;
  }
}
