import React, { useContext } from 'react';
import { IoMdCalendar } from 'react-icons/io';
import { MdCake } from 'react-icons/md';

import SmallItemCard from '../../components/small-item-card/small-item-card.component';

import { AuthContext } from '../../context/auth/auth.context';
import { SharedDialoguesContext } from '../../context/shared-dialogues/shared-dialogues.context';

import Contact from '../../models/contact.model';
import SpecialDate from '../../models/special-dates.model';

import { useContact } from '../../firebase/firebase.utils';

const SpecialDateView: React.FC<{ specialDate: SpecialDate }> = ({
  specialDate,
}): JSX.Element => {
  const { userId } = useContext(AuthContext);
  const { data: dataContact } = useContact(userId, specialDate.contactId);
  const { openSpecialDateViewDialog } = useContext(SharedDialoguesContext);

  const handleItemClick = () =>
    openSpecialDateViewDialog({
      specialDateId: specialDate.id,
      contactId: specialDate.contactId,
    });

  const contact = new Contact(dataContact || {});

  return (
    <SmallItemCard
      date={specialDate.dateLabel}
      contactDisplayStatus={contact.displayStatus}
      contactDisplayName={contact.displayName}
      mainContent={
        <div className="flex items-center space-x-4">
          {specialDate.isBirthday ? (
            <MdCake size="24px" />
          ) : (
            <IoMdCalendar size="24px" />
          )}
          <div>{specialDate.typeLabel}</div>
        </div>
      }
      onItemClick={handleItemClick}
      testid={''}
    />
  );
};

export default SpecialDateView;
