import { OrderByDirection } from '@firebase/firestore-types';
import { fuego, useCollection, useDocument } from '@nandorojo/swr-firestore';
import firebase from 'firebase/app';
import { useEffect, useMemo, useState } from 'react';

import Contact from '../models/contact.model';
import Rotation from '../models/rotation.model';
import SpecialDate from '../models/special-dates.model';

// This method is supposed to be used when we can't use the `useCollection` hook
const useFirestoreCollection = (query: firebase.firestore.Query) => {
  const [data, setData] = useState<firebase.firestore.DocumentData[] | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = query.onSnapshot(
      (snapshot: firebase.firestore.QuerySnapshot) => {
        const collection: firebase.firestore.DocumentData[] = [];

        snapshot.docs.forEach((doc: firebase.firestore.DocumentSnapshot) => {
          const obj = { id: doc.id, ...doc.data() };
          collection.push(obj);
        });

        setData(collection);

        if (loading) {
          setLoading(false);
        }
      }
    );

    return () => unsubscribe();
  }, [query, loading]);

  return { data, loading };
};

// Notes on SWR Firestore:
// - In useDocument and useCollection, remember to double check when the param
//   can be null. For example: `useDocument(url && `profileUrls/${url}`)`,
//   otherwise they will try to get data based on a non existing document

export const firebaseSignInWithCustomToken = token => {
  return fuego.auth().signInWithCustomToken(token);
};

// In this case it's better to get the stats data once and not create a subscription
// Using useDocument would also cause the data not to refresh until the users refreshes
// The tab, causing issues with the data it has in memory and causing unwanted effects
export const useStatsTotals = (userId: number) => {
  const [statsTotals, setStatsTotals] = useState<any>();

  useEffect(() => {
    if (!userId) return;

    firebase
      .firestore()
      .doc(`users/${userId}/stats/totals`)
      .get()
      .then(doc => {
        setStatsTotals(doc);
      });
  }, [userId]);

  return {
    statsTotals,
  };
};

export const useContact = (userId: number, contactId: string) => {
  return useDocument<Contact>(
    userId && contactId ? `users/${userId}/contacts/${contactId}` : null,
    {
      listen: true,
    }
  );
};

export const useContactNotes = (userId, contactId, limit) => {
  return useCollection(userId && `users/${userId}/notes`, {
    where: ['contactId', '==', contactId],
    limit,
    orderBy: ['createdTimestamp', 'desc'],
    listen: true,
  });
};

export const useContactFollowUps = (userId, contactId, ready, limit) => {
  const endOfTodayUnix = Math.floor(
    new Date().setHours(23, 59, 59, 999) / 1000
  );

  return useCollection(userId && `users/${userId}/followups`, {
    where: [
      ['contactId', '==', contactId],
      // ready === true -> Past follow ups
      // ready === false -> Upcoming follow ups
      ['followupTimestamp', ready ? '<=' : '>=', endOfTodayUnix],
      ['done', '==', false],
    ],
    limit,
    orderBy: ['followupTimestamp', 'asc'],
    listen: true,
  });
};

export const useFollowUps = (
  userId,
  limit?,
  orderBy: OrderByDirection = 'desc'
) => {
  return useCollection(userId && `users/${userId}/followups`, {
    where: ['done', '==', false],
    limit,
    orderBy: ['followupTimestamp', orderBy],
    listen: true,
  });
};

export const useFollowUp = (userId, followUpId) => {
  return useDocument(
    userId && followUpId && `users/${userId}/followups/${followUpId}`,
    {
      listen: true,
    }
  );
};

export const useNotes = (userId, limit) => {
  return useCollection(userId && `users/${userId}/notes`, {
    limit,
    orderBy: ['createdTimestamp', 'desc'],
    listen: true,
  });
};

export const useNote = (userId, noteId) => {
  return useDocument(userId && noteId && `users/${userId}/notes/${noteId}`, {
    listen: true,
  });
};

export const useContacts = (userId: number, limit?: number) => {
  return useCollection<Contact>(userId ? `users/${userId}/contacts` : null, {
    limit,
    listen: true,
  });
};

export const useContactsMappedToday = (userId, limit) => {
  const startOfTodayUnix = Math.floor(new Date().setHours(0, 0, 0, 0) / 1000);

  return useCollection(userId && `users/${userId}/contacts`, {
    where: ['firstNoteTimestamp', '>=', startOfTodayUnix],
    limit,
    listen: true,
  });
};

export const useContactsUnlogged = (userId, timeFrame, limit) => {
  const startOfTodayUnix = Math.floor(new Date().setHours(0, 0, 0, 0) / 1000);
  const startOfOneMonthAgoUnix = Math.floor(
    new Date(new Date().setHours(0, 0, 0, 0)).setMonth(
      new Date().getMonth() - 1
    ) / 1000
  );

  const where = {
    TODAY: ['unloggedTimestamp', '>=', startOfTodayUnix],
    LAST_MONTH: ['unloggedTimestamp', '>=', startOfOneMonthAgoUnix],
  };

  return useCollection(userId && `users/${userId}/contacts`, {
    where: [
      where[timeFrame],
      ['unloggedInteractionReason', 'in', ['new_contact', 'followup_complete']],
    ],
    orderBy: ['unloggedTimestamp', 'desc'],
    limit,
    listen: true,
  });
};

export const useFollowUpsDueToday = (userId, limit) => {
  const endOfTodayUnix = Math.floor(
    new Date().setHours(23, 59, 59, 999) / 1000
  );

  return useCollection(userId && `users/${userId}/followups`, {
    where: [
      ['followupTimestamp', '<=', endOfTodayUnix],
      ['done', '==', false],
    ],
    orderBy: ['followupTimestamp', 'desc'],
    limit,
    listen: true,
  });
};

export const useDrafts = (userId, limit) => {
  return useCollection(userId && `users/${userId}/notes`, {
    where: ['contactId', '==', null],
    limit,
    orderBy: ['createdTimestamp', 'desc'],
    listen: true,
  });
};

export const saveImageToBucket = (file, userId) => {
  return fuego
    .storage()
    .ref()
    .child(`profilePics/${userId}/profile.jpg`)
    .put(file, { contentType: 'image/jpeg' })
    .then(snapshot => snapshot.ref.getDownloadURL());
};

export const addNewContact = (contact, userId) =>
  fuego.db
    .collection(`users/${userId}/contacts`)
    .add(contact)
    .then(docRef => docRef.id);

export const useContactSpecialDates = (contactId, userId) => {
  return useCollection(userId && `users/${userId}/specialdates`, {
    where: [['contactId', '==', contactId]],
    listen: true,
  });
};

export const useSpecialDates = (
  userId: number,
  filterByRange: boolean = false
) => {
  const query = useMemo(() => {
    // We need to get all dates between the range of two days ago and seven days ahead.
    // If a Special Date was dismissed this year/period of time,
    // we don't want to fetch it until the next one
    // Therefore we want to fetch all dates that were dismissed before two days ago
    // and that are in the range of dates described above
    const dates: string[] = SpecialDate.getSpecialDatesRange();
    const date = new Date();
    date.setDate(date.getDate() - dates.length);
    // We show special dates up to 7 days in the future. This is to prevent showing them
    // If they have been dismissed within the current period.
    const lastDimissedDate = firebase.firestore.Timestamp.fromDate(date);

    if (!filterByRange) {
      return firebase
        .firestore()
        .collection(`users/${userId}/specialdates`)
        .where('dismissedAt', '<', lastDimissedDate);
    }

    // SWR has some known issues with sending Timestamps inside queries
    // So we are using vanilla Firebase for this specific use case.
    return firebase
      .firestore()
      .collection(`users/${userId}/specialdates`)
      .where('shortDate', 'in', dates)
      .where('dismissedAt', '<', lastDimissedDate);
  }, [userId, filterByRange]);

  return useFirestoreCollection(query);
};

export const useSpecialDate = (userId, specialDateId: string) => {
  return useDocument(
    userId && specialDateId && `users/${userId}/specialdates/${specialDateId}`,
    {
      listen: true,
    }
  );
};

export const useRotations = (
  userId: number,
  limit?: number,
  orderBy: OrderByDirection = 'desc'
) => {
  return useCollection<Rotation>(userId ? `users/${userId}/rotations` : null, {
    limit,
    listen: true,
    orderBy: ['nextIterationDate', orderBy],
  });
};

export const useRotation = (userId: number, rotationId: string) => {
  return useDocument<Rotation>(
    userId && rotationId ? `users/${userId}/rotations/${rotationId}` : null,
    {
      listen: true,
    }
  );
};

export const useContactRotation = (userId: number, contactId: string) => {
  return useCollection<Rotation>(userId ? `users/${userId}/rotations` : null, {
    where: ['contactId', '==', contactId],
    listen: true,
  });
};

export const useRotationsDueToday = (userId: number) => {
  const endOfTodayUnix = Math.floor(
    new Date().setHours(23, 59, 59, 999) / 1000
  );

  return useCollection<Rotation>(userId ? `users/${userId}/rotations` : null, {
    where: [['nextIterationDate', '<=', endOfTodayUnix]],
    listen: true,
    orderBy: ['nextIterationDate', 'desc'],
  });
};
