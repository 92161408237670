import axios from 'axios';

import { API_URL } from '../../utils/constants';
import { getTimezoneOffset } from '../../utils/dates.utils';

import { updateProfile } from '../auth/auth.service';

export const getUserByUrl = url => {
  if (!url) return;
  return axios.get(`${API_URL}/publicprofiles/${url}`);
};

export const introduceYourself = payload =>
  axios.post(`${API_URL}/leads`, payload);

export const getUsersForAdmin = (offset = 0, searchTerm, authToken) => {
  return axios
    .get(`${API_URL}/reports/users?offset=${offset}&contains=${searchTerm}`, {
      headers: { Authorization: authToken },
    })
    .then(res => ({
      totalCount: Number(res.headers['x-total-count'] ?? 0),
      users: res.data,
    }));
};

export const getUserByIdForAdmin = (userId, authToken) => {
  return axios
    .get(`${API_URL}/reports/user/${userId}`, {
      headers: { Authorization: authToken },
    })
    .then(res => res.data);
};

export const updateUserDetails = (userId, data, authToken) => {
  return axios
    .post(
      `${API_URL}/tasks`,
      {
        task: {
          name: 'modify_user',
          args: {
            user_id: userId,
            ...data,
          },
        },
      },
      {
        headers: { Authorization: authToken },
      }
    )
    .then(res => res.data);
};

export const cancelSubscription = (userId, authToken) => {
  return axios
    .post(
      `${API_URL}/tasks`,
      {
        task: {
          name: 'cancel_membership',
          args: {
            user_id: userId,
          },
        },
      },
      {
        headers: { Authorization: authToken },
      }
    )
    .then(res => res.data);
};

export const extendTrial = (userId, until, authToken) => {
  return axios
    .post(
      `${API_URL}/tasks`,
      {
        task: {
          name: 'extend_on_stripe',
          args: {
            user_id: userId,
            trial_end: until,
          },
        },
      },
      {
        headers: { Authorization: authToken },
      }
    )
    .then(res => res.data);
};

export const makePermanent = (userId, authToken) => {
  return axios
    .post(
      `${API_URL}/tasks`,
      {
        task: {
          name: 'make_permanent',
          args: {
            user_id: userId,
          },
        },
      },
      {
        headers: { Authorization: authToken },
      }
    )
    .then(res => res.data);
};

export const repeatPretrial = (userId, authToken) => {
  return axios
    .post(
      `${API_URL}/tasks`,
      {
        task: {
          name: 'repeat_pretrial',
          args: {
            user_id: userId,
          },
        },
      },
      {
        headers: { Authorization: authToken },
      }
    )
    .then(res => res.data);
};

export const exportData = (user_id, email, authToken, self) => {
  return axios
    .post(
      `${API_URL}/tasks`,
      {
        task: {
          name: self ? 'export_csv_by_self_email' : 'export_csv_by_email',
          args: {
            user_id,
            email,
          },
        },
      },
      {
        headers: { Authorization: authToken },
      }
    )
    .then(res => res.data);
};

export const unlockDevice = (userId, authToken) => {
  return axios
    .post(
      `${API_URL}/tasks`,
      {
        task: {
          name: 'unlock_device',
          args: {
            user_id: userId,
          },
        },
      },
      {
        headers: { Authorization: authToken },
      }
    )
    .then(res => res.data);
};

export const deleteData = (user_id, authToken) => {
  return axios
    .post(
      `${API_URL}/tasks`,
      {
        task: {
          name: 'truncate_user',
          args: {
            user_id,
          },
        },
      },
      {
        headers: { Authorization: authToken },
      }
    )
    .then(res => res.data);
};

export const createUser = user =>
  axios.post(`${API_URL}/users`, {
    user,
  });

export const setNewUserPassword = (userId, password, authToken) =>
  axios.patch(
    `${API_URL}/users/${userId}`,
    {
      user: { password },
    },
    {
      headers: { Authorization: authToken },
    }
  );

export const acceptLatestTerms = (userId, authToken) =>
  axios.patch(
    `${API_URL}/users/${userId}/legaldocsstatus`,
    {
      legaldocsstatus: {
        3: 'Y',
        4: 'Y',
      },
    },
    {
      headers: { Authorization: authToken },
    }
  );

export const getUserSettings = (userId, authToken) => {
  return axios
    .get(`${API_URL}/users/${userId}/settings`, {
      headers: { Authorization: authToken },
    })
    .then(res => res.data);
};

export const handleTimezone = (userId, userUTCOffset, authToken) => {
  const utcoffset = getTimezoneOffset();

  if (utcoffset === userUTCOffset) {
    return Promise.resolve(null);
  }

  return updateProfile({ id: userId, utcoffset }, authToken);
};
