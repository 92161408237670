import { useRef } from 'react';

// Once we start implementing a wider set of keyboard shortcuts we should look
// for a suitable library to make things easier
const useKeySave = (handler, conditions = true) => {
  const keyPress = useRef({});

  const keyDownHandler = e => {
    const pressedCtrl =
      keyPress.current['17'] || // Control key
      keyPress.current['91'] || // Left command key
      keyPress.current['93']; // Right command key

    if (conditions && pressedCtrl && e.keyCode === 13) {
      keyPress.current = {};
      handler();
    }

    keyPress.current[e.keyCode] = e.type === 'keydown';
  };

  const keyUpHandler = () => (keyPress.current = {});

  return {
    keyDownHandler,
    keyUpHandler,
  };
};

export default useKeySave;
