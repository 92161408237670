import React, { useContext } from 'react';

import CalendarEvent from './calendar-event.component';

import { AuthContext } from '../../context/auth/auth.context';

import Contact from '../../models/contact.model';

import { useContact } from '../../firebase/firebase.utils';

import { EventTypes } from '../../utils/enums';

const CalendarEventDetails: React.FC<{
  contactId: string;
  title: string;
  eventType: EventTypes;
}> = ({ contactId, title, eventType }): JSX.Element => {
  const { userId } = useContext(AuthContext);
  const { data: dataContact } = useContact(userId, contactId);
  const contact = new Contact(dataContact || {});
  const isFollowUp = eventType === EventTypes.FollowUp;

  return (
    <CalendarEvent
      itemColor={
        isFollowUp
          ? 'secondary'
          : eventType === EventTypes.SpecialDate
          ? 'tertiary'
          : 'primary'
      }
      content={
        <>
          {contact.displayName}'s {isFollowUp ? 'Follow-up' : title}
        </>
      }
    />
  );
};

export default CalendarEventDetails;
