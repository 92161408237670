import LockIcon from '@mui/icons-material/Lock';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { MdLink } from 'react-icons/md';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import CMButton from '../../components/cm-button/cm-button.component';
import CMTextButton from '../../components/cm-text-button/cm-text-button.component';

import { AuthContext } from '../../context/auth/auth.context';
import {
  ISnackbarContext,
  SnackbarContext,
} from '../../context/snackbar/snackbar.context';

type IFormData = {
  firstname: string;
  lastname: string;
  phonenumber: string;
};

const AccountDetails = () => {
  const formRef = useRef(null);

  const {
    userData: { firstname, lastname, email, referrallink, phonenumber, id },
    updateUserData,
  } = useContext(AuthContext);
  const { showSnackbar } = useContext<ISnackbarContext>(SnackbarContext);

  const [isSavingUserData, setIsSavingUserData] = useState<boolean>(false);
  const [formData, setFormData] = useState<IFormData>({
    firstname,
    lastname,
    phonenumber,
  });

  const handleChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setFormData(prevState => ({
        ...prevState,
        [field]: event.target.value,
      }));
    };

  const handleCopyReferralLink = useCallback(() => {
    navigator.clipboard
      .writeText(`https://www.contactmapping.com/?via=${referrallink}`)
      .then(
        () => {
          showSnackbar({
            severity: 'success',
            message: 'Affiliate link has been copied to the clipboard.',
          });
        },
        () => {
          showSnackbar({
            severity: 'error',
            message: 'Something went wrong while copying your link.',
          });
        }
      );
  }, [referrallink, showSnackbar]);

  const handleAccountDetailsSubmit = () => {
    setIsSavingUserData(true);
    updateUserData({ ...formData, id })
      .then(() => {
        showSnackbar({
          severity: 'success',
          message: 'Changes saved succesfully.',
        });
      })
      .catch(() => {
        showSnackbar({
          severity: 'error',
          message: 'Something went wrong.',
        });
      })
      .finally(() => {
        setIsSavingUserData(false);
      });
  };

  return (
    <div className="mt-8 md:mt-16 bg-white text-slate rounded-lg p-8">
      <h3 className="text-3xl font-bold pb-8">Your account details</h3>
      <p>
        Changing your phone number <strong>won't</strong> change the phone
        numbers in your Digital Business Card
      </p>
      <ValidatorForm
        className="md:grid grid-cols-2 gap-8 pt-8 space-y-8 md:space-y-0"
        ref={formRef}
        onSubmit={handleAccountDetailsSubmit}
        noValidate
      >
        <TextValidator
          autoFocus
          fullWidth
          label="FIRST NAME"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          id="firstname"
          type="text"
          onChange={handleChange('firstname')}
          value={formData.firstname}
          required
          validators={['required']}
          errorMessages={['This field is required']}
        />
        <TextValidator
          fullWidth
          label="LAST NAME"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          id="lastname"
          type="text"
          onChange={handleChange('lastname')}
          value={formData.lastname ?? ''}
          required
          validators={['required']}
          errorMessages={['This field is required']}
        />
        <TextField
          fullWidth
          label="AFFILIATE ID"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          id="referrallink"
          type="text"
          disabled
          defaultValue={referrallink}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="contact support" disabled edge="end">
                  <LockIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className="text-sm flex items-center">
          {referrallink?.length ? (
            <CMTextButton
              icon={MdLink}
              onClick={handleCopyReferralLink}
              type="button"
            >
              Copy link
            </CMTextButton>
          ) : (
            <p>
              Not an affiliate yet? <br />
              Contact{' '}
              <a
                href={`mailto:affiliates@contactmapping.com?subject=I would like to become an affiliate&body=I would like to get a Contact Mapping Affiliate link.%0DUser Id: ${id}%0DName: ${firstname} ${
                  lastname || ''
                }%0DEmail: ${email}%0DPhone Number: ${phonenumber}%0DThanks!
                `}
                className="text-fadeOrange underline"
              >
                affiliates@contactmapping.com
              </a>
            </p>
          )}
        </div>
        <TextField
          fullWidth
          label="EMAIL"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          id="email"
          type="text"
          disabled
          defaultValue={email}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="contact support" disabled edge="end">
                  <LockIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <div className="text-sm flex items-center">
          <p>
            If you need to update your email address contact us at{' '}
            <a
              href={`mailto:support@contactmapping.com?subject=Changing my email address&body=Hi, Please change my email address from ${email} to `}
              className="text-fadeOrange underline"
            >
              support@contactmapping.com
            </a>
          </p>
        </div>
        <TextValidator
          fullWidth
          label="MOBILE"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          id="phonenumber"
          type="text"
          onChange={handleChange('phonenumber')}
          value={formData.phonenumber ?? ''}
          required
          validators={['required']}
          errorMessages={['This field is required']}
        />
        <div className="col-start-1">
          <CMButton
            type="submit"
            disabled={isSavingUserData}
            loading={isSavingUserData}
            fullWidth
          >
            Update Details
          </CMButton>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default AccountDetails;
