import axios from 'axios';

import { firebaseSignInWithCustomToken } from '../../firebase/firebase.utils';

import { API_URL } from '../../utils/constants';

import { getUserSettings, handleTimezone } from '../users/users.service';

const usersRequest = token => {
  return axios.get(`${API_URL}/users`, {
    headers: { Authorization: token },
  });
};

const firebaseAndUserRequests = tokens => {
  return Promise.all([
    usersRequest(tokens.auth_token),
    firebaseSignInWithCustomToken(tokens.firebase_token),
  ]).then(([userResponse]) => {
    const userData = userResponse.data[0];
    const authToken = tokens.auth_token;

    // Using `allSettled` to avoid throwing an error if one of the promises fails
    return Promise.allSettled([
      getUserSettings(userData.id, authToken),
      handleTimezone(userData.id, userData.utcoffset, authToken),
    ]).then(([settings, newUserData]) => {
      const settingsValue = settings?.value ?? {};
      const newUserDataValue = newUserData?.value;

      return {
        userData: newUserDataValue ?? userData,
        authToken,
        settings: settingsValue,
      };
    });
  });
};

export const updateProfile = (payload, token) => {
  return axios
    .patch(`${API_URL}/users/${payload.id}`, payload, {
      headers: { Authorization: token },
    })
    .then(response => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Unable to update profile');
      }
    });
};

export const authRequest = payload => {
  return axios.post(`${API_URL}/authenticate`, payload).then(response => {
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Unable to authorize user');
    }
  });
};

export const signInRequest = payload => {
  return axios
    .post(`${API_URL}/authenticate2`, payload)
    .then(response => {
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Unable to sign in');
      }
    })
    .then(firebaseAndUserRequests);
};

export const tryTokenRequest = authToken => {
  return axios
    .get(`${API_URL}/firebaseauth`, {
      headers: { Authorization: authToken },
    })
    .then(response => {
      if (response.status === 200) {
        return {
          auth_token: authToken,
          firebase_token: response.data.firebase_token,
        };
      } else {
        throw new Error('Unable to sign in');
      }
    })
    .then(firebaseAndUserRequests);
  // At this point, in any given promise, if we want to do something with errors,
  // such as crashlitycs, we should execute the action and throw to continue with
  // `.catch` chain, or just remove the catch. Example:
  // .catch(() => {
  //   console.log('error');
  //   crashlytics().recordError(new Error(error));
  //   throw new Error('error');
  // });
};

export const getMyActiveSubscription = (userId, authToken) => {
  return axios
    .get(`${API_URL}/users/${userId}/active-subscription`, {
      headers: { Authorization: authToken },
    })
    .then(res => res.data);
};

export const cancelSubscription = (subscriptionId, authToken) =>
  axios.patch(
    `${API_URL}/subscriptions/${subscriptionId}`,
    {
      subscription: {
        status: 'N',
      },
    },
    {
      headers: { Authorization: authToken },
    }
  );

export const requestPasswordReset = email =>
  axios.post(`${API_URL}/reset`, { email });
