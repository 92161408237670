import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import AddNewContactDialog from '../../components/shared-dialogues/add-new-contact-dialog/add-new-contact-dialog.component';
import DailyEventsDialog from '../../components/shared-dialogues/daily-events-dialog/daily-events-dialog.component';
import FollowUpDialog from '../../components/shared-dialogues/follow-up-dialog/follow-up-dialog.component';
import NextRotationDialog from '../../components/shared-dialogues/next-rotation-dialog/next-rotation-dialog.component';
import NoteDialog from '../../components/shared-dialogues/note-dialog/note-dialog.component';
import RotationCompleteDialog from '../../components/shared-dialogues/rotation-complete-dialog/rotation-complete-dialog.component';
import RotationDialog from '../../components/shared-dialogues/rotation-dialog/rotation-dialog.component';
import RotationViewDialog from '../../components/shared-dialogues/rotation-view-dialog/rotation-view-dialog.component';
import SelectContactDialog from '../../components/shared-dialogues/select-contact-dialog/select-contact-dialog.component';
import SpecialDateCompleteDialog from '../../components/shared-dialogues/special-date-complete-dialog/special-date-complete-dialog.component';
import SpecialDateViewDialog from '../../components/shared-dialogues/special-date-view-dialog/special-date-view-dialog.component';

import { PaywallDialoguesContext } from '../paywall/paywall-dialogues.context';
import { PaywallContext } from '../paywall/paywall.context';

const initialState = {
  // Follow Up Dialog
  followUpDialogData: {
    followUpId: null,
    contactId: null,
    isEditing: false,
    isDone: false,
    closeOnCancelEdit: false,
  },
  followUpDialogOpen: false,
  // Rotation
  rotationDialogData: {
    rotationId: null,
    contactId: null,
  },
  rotationDialogOpen: true,
  // Rotation View Dialog
  rotationViewDialogOpen: false,
  rotationViewDialogData: {
    rotationId: null,
    contactId: null,
  },
  // Rotation Complete Dialog
  rotationCompleteDialogOpen: false,
  // Next Rotation Dialog
  nextRotationDialogOpen: false,
  // Select Contact Dialog
  selectedContactId: null,
  selectedContactNoteId: null,
  selectContactDialogOpen: false,
  fromDraftToContact: false,
  // Add new contact Dialog
  addNewContactDialogOpen: false,
  // Special Date View Dialog
  specialDateViewDialogOpen: false,
  specialDateDialogData: {
    specialDateId: null,
    contactId: null,
  },
  // Special Date Complete Dialog
  specialDateCompleteDialogOpen: false,
  // Daily Events Dialog
  dailyEventsDialogOpen: false,
  dailyEventsDialogData: {
    date: '',
    eventsType: '',
    detailedEvents: [],
  },
  // Note Dialog
  noteDialogData: {
    noteId: null,
    contactId: null,
    isEditing: false,
    closeOnCancelEdit: false,
  },
  noteDialogOpen: false,
  noteContentChanged: false,
  assignNoteToContact: null,
};

const actions = {
  // Follow Up Dialog
  OPEN_FOLLOW_UP_DIALOG: 'OPEN_FOLLOW_UP_DIALOG',
  CLOSE_FOLLOW_UP_DIALOG: 'CLOSE_FOLLOW_UP_DIALOG',
  // Rotation Dialog
  OPEN_ROTATION_DIALOG: 'OPEN_ROTATION_DIALOG',
  CLOSE_ROTATION_DIALOG: 'CLOSE_ROTATION_DIALOG',
  // Rotation View Dialog
  OPEN_ROTATION_VIEW_DIALOG: 'OPEN_ROTATION_VIEW_DIALOG',
  CLOSE_ROTATION_VIEW_DIALOG: 'CLOSE_ROTATION_VIEW_DIALOG',
  // Rotation Complete Dialog
  OPEN_ROTATION_COMPLETE_DIALOG: 'OPEN_ROTATION_COMPLETE_DIALOG',
  CLOSE_ROTATION_COMPLETE_DIALOG: 'CLOSE_ROTATION_COMPLETE_DIALOG',
  // Next Rotation Dialog
  OPEN_NEXT_ROTATION_DIALOG: 'OPEN_NEXT_ROTATION_DIALOG',
  CLOSE_NEXT_ROTATION_DIALOG: 'CLOSE_NEXT_ROTATION_DIALOG',
  // Select Contact Dialog
  OPEN_SELECT_CONTACT_DIALOG: 'OPEN_SELECT_CONTACT_DIALOG',
  CLOSE_SELECT_CONTACT_DIALOG: 'CLOSE_SELECT_CONTACT_DIALOG',
  // Add new contact Dialog
  TOGGLE_ADD_NEW_CONTACT_DIALOG: 'TOGGLE_ADD_NEW_CONTACT_DIALOG',
  // Special Date View Dialog
  OPEN_SPECIAL_DATE_VIEW_DIALOG: 'OPEN_SPECIAL_DATE_VIEW_DIALOG',
  CLOSE_SPECIAL_DATE_VIEW_DIALOG: 'CLOSE_SPECIAL_DATE_VIEW_DIALOG',
  // Special Date Complete Dialog
  OPEN_SPECIAL_DATE_COMPLETE_DIALOG: 'OPEN_SPECIAL_DATE_COMPLETE_DIALOG',
  CLOSE_SPECIAL_DATE_COMPLETE_DIALOG: 'CLOSE_SPECIAL_DATE_COMPLETE_DIALOG',
  // Note Dialog
  OPEN_NOTE_DIALOG: 'OPEN_NOTE_DIALOG',
  CLOSE_NOTE_DIALOG: 'CLOSE_NOTE_DIALOG',
  // Daily Events Dialog
  OPEN_DAILY_EVENTS_DIALOG: 'OPEN_DAILY_EVENTS_DIALOG',
  CLOSE_DAILY_EVENTS_DIALOG: 'CLOSE_DAILY_EVENTS_DIALOG',
};

const reducer = (state, action) => {
  switch (action.type) {
    // Follow Up Dialog
    case actions.OPEN_FOLLOW_UP_DIALOG:
      return {
        ...state,
        followUpDialogOpen: true,
        selectedContactId: null,
        followUpDialogData: action.data,
      };
    case actions.CLOSE_FOLLOW_UP_DIALOG:
      return {
        ...state,
        followUpDialogOpen: false,
        fromDraftToContact: false,
      };

    // Rotation Dialog
    case actions.OPEN_ROTATION_DIALOG:
      return {
        ...state,
        rotationDialogOpen: true,
        rotationDialogData: action.data,
      };
    case actions.CLOSE_ROTATION_DIALOG:
      return {
        ...state,
        rotationDialogOpen: false,
      };

    // Rotation View Dialog
    case actions.OPEN_ROTATION_VIEW_DIALOG:
      return {
        ...state,
        rotationViewDialogOpen: true,
        rotationViewDialogData: action.data,
      };

    case actions.CLOSE_ROTATION_VIEW_DIALOG:
      return {
        ...state,
        rotationViewDialogOpen: false,
      };

    // Rotation Complete Dialog
    case actions.OPEN_ROTATION_COMPLETE_DIALOG:
      return {
        ...state,
        rotationCompleteDialogOpen: true,
        rotationViewDialogData: action.data,
      };

    case actions.CLOSE_ROTATION_COMPLETE_DIALOG:
      return {
        ...state,
        rotationCompleteDialogOpen: false,
      };

    // Next Rotation Dialog
    case actions.OPEN_NEXT_ROTATION_DIALOG:
      return {
        ...state,
        nextRotationDialogOpen: true,
        rotationViewDialogData: action.data,
      };

    case actions.CLOSE_NEXT_ROTATION_DIALOG:
      return {
        ...state,
        nextRotationDialogOpen: false,
      };

    // Daily Events Dialog
    case actions.OPEN_DAILY_EVENTS_DIALOG:
      return {
        ...state,
        dailyEventsDialogOpen: true,
        dailyEventsDialogData: action.data,
      };
    case actions.CLOSE_DAILY_EVENTS_DIALOG:
      return {
        ...state,
        dailyEventsDialogOpen: false,
      };

    // Select Contact Dialog
    case actions.OPEN_SELECT_CONTACT_DIALOG:
      return {
        ...state,
        selectContactDialogOpen: true,
        selectedContactNoteId: action.noteId ?? null,
      };
    case actions.CLOSE_SELECT_CONTACT_DIALOG:
      return {
        ...state,
        fromDraftToContact: Boolean(action.contactId),
        selectedContactId: action.contactId ?? null,
        selectContactDialogOpen: false,
      };

    // Add new contact Dialog
    case actions.TOGGLE_ADD_NEW_CONTACT_DIALOG:
      return {
        ...state,
        addNewContactDialogOpen: action.toggle,
      };

    // Special Date View Dialog
    case actions.OPEN_SPECIAL_DATE_VIEW_DIALOG:
      return {
        ...state,
        specialDateViewDialogOpen: true,
        specialDateDialogData: action.data,
      };

    case actions.CLOSE_SPECIAL_DATE_VIEW_DIALOG:
      return {
        ...state,
        specialDateViewDialogOpen: false,
      };

    // Special Date Complete Dialog
    case actions.OPEN_SPECIAL_DATE_COMPLETE_DIALOG:
      return {
        ...state,
        specialDateCompleteDialogOpen: true,
        specialDateDialogData: action.data,
      };

    case actions.CLOSE_SPECIAL_DATE_COMPLETE_DIALOG:
      return {
        ...state,
        specialDateCompleteDialogOpen: false,
      };

    // Note Dialog
    case actions.OPEN_NOTE_DIALOG:
      return {
        ...state,
        noteDialogOpen: true,
        selectedContactId: null,
        selectedContactNoteId: null,
        noteDialogData: action.data,
      };
    case actions.CLOSE_NOTE_DIALOG:
      return {
        ...state,
        noteDialogOpen: false,
        fromDraftToContact: false,
      };

    default:
      return state;
  }
};

export const SharedDialoguesContext = createContext();

export const SharedDialoguesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { hasReachedMaxFollowUps, hasReachedMaxRotations } =
    useContext(PaywallContext);
  const { openNewFollowUpLimitDialog, openNewRotationLimitDialog } = useContext(
    PaywallDialoguesContext
  );

  // Select Contact Dialog
  const openSelectContactDialog = useCallback(noteId => {
    dispatch({ type: actions.OPEN_SELECT_CONTACT_DIALOG, noteId });
  }, []);

  const closeSelectContactDialog = useCallback(contactId => {
    dispatch({
      type: actions.CLOSE_SELECT_CONTACT_DIALOG,
      contactId,
    });
  }, []);

  // Follow Up Dialog
  const openFollowUpDialog = useCallback(
    data => {
      // If there's no followUpId it means we are trying to add a new follow-up.
      // In this case, and if it has reached max follow ups, open the limit dialog
      if (!data?.followUpId && hasReachedMaxFollowUps) {
        openNewFollowUpLimitDialog();
        return;
      }

      // If there's no followUpId and no selectedContactId in state it means we
      // are trying to add a new follow-up, but we need to select a contact first
      if (!data?.followUpId && !data?.contactId) {
        openSelectContactDialog();
        return;
      }

      dispatch({ type: actions.OPEN_FOLLOW_UP_DIALOG, data });
    },
    [
      hasReachedMaxFollowUps,
      openNewFollowUpLimitDialog,
      openSelectContactDialog,
    ]
  );

  const closeFollowUpDialog = useCallback(() => {
    dispatch({ type: actions.CLOSE_FOLLOW_UP_DIALOG });
  }, []);

  // Rotation Dialog
  const openRotationDialog = useCallback(
    data => {
      // If there's no rotationId it means we are trying to add a new rotation.
      // In this case, and if it has reached max rotations, open the limit dialog
      if (!data?.rotationId && hasReachedMaxRotations) {
        openNewRotationLimitDialog();
        return;
      }

      dispatch({ type: actions.OPEN_ROTATION_DIALOG, data });
    },
    [hasReachedMaxRotations, openNewRotationLimitDialog]
  );

  const closeRotationDialog = useCallback(() => {
    dispatch({ type: actions.CLOSE_ROTATION_DIALOG });
  }, []);

  // Rotation View Dialog
  const openRotationViewDialog = useCallback(data => {
    dispatch({ type: actions.OPEN_ROTATION_VIEW_DIALOG, data });
  }, []);

  const closeRotationViewDialog = useCallback(() => {
    dispatch({ type: actions.CLOSE_ROTATION_VIEW_DIALOG });
  }, []);

  // Rotation Complete Dialog
  const openRotationCompleteDialog = useCallback(data => {
    dispatch({ type: actions.OPEN_ROTATION_COMPLETE_DIALOG, data });
  }, []);

  const closeRotationCompleteDialog = useCallback(() => {
    dispatch({ type: actions.CLOSE_ROTATION_COMPLETE_DIALOG });
  }, []);

  // Next Rotation Dialog
  const openNextRotationDialog = useCallback(data => {
    dispatch({ type: actions.OPEN_NEXT_ROTATION_DIALOG, data });
  }, []);

  const closeNextRotationDialog = useCallback(() => {
    dispatch({ type: actions.CLOSE_NEXT_ROTATION_DIALOG });
  }, []);

  // Add new contact Dialog
  const openAddNewContactDialog = useCallback(() => {
    dispatch({ type: actions.TOGGLE_ADD_NEW_CONTACT_DIALOG, toggle: true });
  }, []);

  const closeAddNewContactDialog = useCallback(() => {
    dispatch({ type: actions.TOGGLE_ADD_NEW_CONTACT_DIALOG, toggle: false });
  }, []);

  // Special Date View Dialog
  const openSpecialDateViewDialog = useCallback(data => {
    dispatch({ type: actions.OPEN_SPECIAL_DATE_VIEW_DIALOG, data });
  }, []);

  const closeSpecialDateViewDialog = useCallback(() => {
    dispatch({ type: actions.CLOSE_SPECIAL_DATE_VIEW_DIALOG });
  }, []);

  // Special Date Complete Dialog
  const openSpecialDateCompleteDialog = useCallback(data => {
    dispatch({ type: actions.OPEN_SPECIAL_DATE_COMPLETE_DIALOG, data });
  }, []);

  const closeSpecialDateCompleteDialog = useCallback(() => {
    dispatch({ type: actions.CLOSE_SPECIAL_DATE_COMPLETE_DIALOG });
  }, []);

  // Note Dialog
  const openNoteDialog = useCallback(data => {
    dispatch({ type: actions.OPEN_NOTE_DIALOG, data });
  }, []);

  const closeNoteDialog = useCallback(() => {
    dispatch({ type: actions.CLOSE_NOTE_DIALOG });
  }, []);

  // Daily Events Dialog
  const openDailyEventsDialog = useCallback(data => {
    dispatch({ type: actions.OPEN_DAILY_EVENTS_DIALOG, data });
  }, []);

  const closeDailyEventsDialog = useCallback(() => {
    dispatch({ type: actions.CLOSE_DAILY_EVENTS_DIALOG });
  }, []);

  // Effects when selecting contact
  // Open Follow-Up Dialog or Assign Contact to Note
  // This might be moved to `closeSelectContactDialog` and instead of doing
  // `openFollowUpDialog` and `openNoteDialog` just send the objects inside them
  // as new state. This would save us from this `useEffect` listening to changes
  useEffect(() => {
    if (state.selectedContactId && !state.selectedContactNoteId) {
      openFollowUpDialog({
        contactId: state.selectedContactId,
        isEditing: true,
        closeOnCancelEdit: true,
      });
      return;
    }

    if (state.selectedContactId && state.selectedContactNoteId) {
      openNoteDialog({
        noteId:
          state.selectedContactNoteId === 'draft'
            ? null
            : state.selectedContactNoteId,
        contactId: state.selectedContactId,
        isEditing: true,
        closeOnCancelEdit: true,
      });
    }
  }, [
    state.selectedContactId,
    state.selectedContactNoteId,
    openFollowUpDialog,
    openNoteDialog,
  ]);

  const value = useMemo(
    () => ({
      // State
      followUpDialogData: state.followUpDialogData,
      followUpDialogOpen: state.followUpDialogOpen,
      rotationDialogData: state.rotationDialogData,
      rotationDialogOpen: state.rotationDialogOpen,
      rotationViewDialogOpen: state.rotationViewDialogOpen,
      rotationViewDialogData: state.rotationViewDialogData,
      rotationCompleteDialogOpen: state.rotationCompleteDialogOpen,
      nextRotationDialogOpen: state.nextRotationDialogOpen,
      selectedContactId: state.selectedContactId,
      selectedContactNoteId: state.selectedContactNoteId,
      selectContactDialogOpen: state.selectContactDialogOpen,
      fromDraftToContact: state.fromDraftToContact,
      addNewContactDialogOpen: state.addNewContactDialogOpen,
      specialDateCompleteDialogOpen: state.specialDateCompleteDialogOpen,
      specialDateViewDialogOpen: state.specialDateViewDialogOpen,
      specialDateDialogData: state.specialDateDialogData,
      noteDialogData: state.noteDialogData,
      noteDialogOpen: state.noteDialogOpen,
      noteContentChanged: state.noteContentChanged,
      assignNoteToContact: state.assignNoteToContact,
      dailyEventsDialogOpen: state.dailyEventsDialogOpen,
      dailyEventsDialogData: state.dailyEventsDialogData,
      // Actions
      openFollowUpDialog,
      closeFollowUpDialog,
      openRotationDialog,
      closeRotationDialog,
      openRotationViewDialog,
      closeRotationViewDialog,
      openRotationCompleteDialog,
      closeRotationCompleteDialog,
      openNextRotationDialog,
      closeNextRotationDialog,
      openSelectContactDialog,
      closeSelectContactDialog,
      openAddNewContactDialog,
      closeAddNewContactDialog,
      openSpecialDateViewDialog,
      closeSpecialDateViewDialog,
      openSpecialDateCompleteDialog,
      closeSpecialDateCompleteDialog,
      openNoteDialog,
      closeNoteDialog,
      openDailyEventsDialog,
      closeDailyEventsDialog,
    }),
    [
      state.followUpDialogData,
      state.followUpDialogOpen,
      state.rotationDialogData,
      state.rotationDialogOpen,
      state.rotationViewDialogOpen,
      state.rotationViewDialogData,
      state.rotationCompleteDialogOpen,
      state.nextRotationDialogOpen,
      state.selectedContactId,
      state.selectedContactNoteId,
      state.selectContactDialogOpen,
      state.fromDraftToContact,
      state.addNewContactDialogOpen,
      state.specialDateCompleteDialogOpen,
      state.specialDateViewDialogOpen,
      state.specialDateDialogData,
      state.noteDialogData,
      state.noteDialogOpen,
      state.noteContentChanged,
      state.assignNoteToContact,
      state.dailyEventsDialogOpen,
      state.dailyEventsDialogData,
      openFollowUpDialog,
      closeFollowUpDialog,
      openRotationDialog,
      closeRotationDialog,
      openRotationViewDialog,
      closeRotationViewDialog,
      openRotationCompleteDialog,
      closeRotationCompleteDialog,
      openNextRotationDialog,
      closeNextRotationDialog,
      openSelectContactDialog,
      closeSelectContactDialog,
      openAddNewContactDialog,
      closeAddNewContactDialog,
      openSpecialDateViewDialog,
      closeSpecialDateViewDialog,
      openSpecialDateCompleteDialog,
      closeSpecialDateCompleteDialog,
      openNoteDialog,
      closeNoteDialog,
      openDailyEventsDialog,
      closeDailyEventsDialog,
    ]
  );

  return (
    <SharedDialoguesContext.Provider value={value}>
      <FollowUpDialog />
      <NoteDialog />
      <SelectContactDialog />
      <AddNewContactDialog />
      <SpecialDateViewDialog />
      <SpecialDateCompleteDialog />
      <RotationDialog />
      <DailyEventsDialog />
      <RotationViewDialog />
      <RotationCompleteDialog />
      <NextRotationDialog />
      {children}
    </SharedDialoguesContext.Provider>
  );
};
