import React, { useContext } from 'react';

import CMButton from '../cm-button/cm-button.component';
import CMContactSocialProfiles from '../cm-contact-social-profiles/cm-contact-social-profiles.component';
import ContactAvatar from '../contact-avatar/contact-avatar.component';

import { AuthContext } from '../../context/auth/auth.context';

import Contact from '../../models/contact.model';

import { useContact } from '../../firebase/firebase.utils';

import { unifyInstantMessageAddresses } from '../../utils/instant-message.utils';

type IProps = {
  contactId: string;
  disableActions?: boolean;
  title: string;
  renderContent: React.ReactNode;
  renderAdditionalActionButtons?: React.ReactNode;
  closeDialog: () => void;
  handleTouch: () => void;
  handleNote: () => void;
};

const DateViewDialogContent: React.FC<IProps> = ({
  contactId,
  disableActions,
  title,
  renderContent,
  renderAdditionalActionButtons,
  closeDialog,
  handleTouch,
  handleNote,
}) => {
  const { userId } = useContext(AuthContext);

  const { data: dataContact } = useContact(userId, contactId);

  const onAvatarClick = (goToContactDetails: () => void) => {
    closeDialog();
    goToContactDetails();
  };

  if (!dataContact) return null;

  const contact = new Contact(dataContact);

  const socialProfiles = unifyInstantMessageAddresses(
    contact.instantMessageAddresses,
    contact.instantMessageAddressesCM
  );

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold text-center">{title}</h2>
      <div className="flex items-center">
        <ContactAvatar
          onAvatarClick={onAvatarClick}
          contactId={contact?.id}
          contactDisplayStatus={contact?.displayStatus}
          contactName={contact?.displayName}
        />
      </div>
      <div className="border-y border-silver py-6">{renderContent}</div>
      <div>
        <h2 className="text-xl font-bold text-left mb-6">Get in touch:</h2>
        <CMContactSocialProfiles socialProfiles={socialProfiles} />
      </div>
      <div>
        <h2 className="text-xl font-bold text-left mb-6">Complete?</h2>
        <div className="flex space-x-4">
          <CMButton
            disabled={disableActions}
            onClick={handleTouch}
            color="tertiary"
          >
            Touch
          </CMButton>
          <CMButton
            disabled={disableActions}
            onClick={handleNote}
            color="tertiary"
          >
            Note
          </CMButton>
          {renderAdditionalActionButtons}
        </div>
      </div>
    </div>
  );
};

export default DateViewDialogContent;
