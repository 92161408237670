import { InputAdornment } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { BiWorld } from 'react-icons/bi';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useNavigate, useNavigationType } from 'react-router-dom';

import CMButton from '../../components/cm-button/cm-button.component';
import ProfileProgressHeader from '../../components/profile-progress-header/profile-progress-header.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import ProfilePic from './profile-pic.component';

import { AuthContext } from '../../context/auth/auth.context';
import { RemoteConfigContext } from '../../context/remote-config/remote-config.context';

import { saveImageToBucket } from '../../firebase/firebase.utils';

import { formFormat } from './form-format';

const keyValue = {
  emails: 'email',
  socialprofiles: 'username',
  phones: 'number',
};

const BuildField = ({ field, values, handleChange }) => {
  const getValue = name => {
    const parts = name.split('.');
    if (parts.length > 1 && values[parts[0]]) {
      if (parts.length === 3) {
        for (let i = 0; i < values[parts[0]].length; i++) {
          if (values[parts[0]][i][parts[1]] === parts[2]) {
            return values[parts[0]][i][keyValue[parts[0]]];
          }
        }
      }
      if (parts.length === 2) {
        return values[parts[0]][parts[1]];
      }
    }
    if (values[name]) return values[name];
    return '';
  };

  let size = null;
  if (field.half) {
    size = 'md:col-span-2 w-full md:w-1/2';
  }
  if (!field.half) {
    size = 'md:col-span-2 w-full';
  }
  if (field.inline) {
    size = 'col-span-1 w-full m-0';
  }
  const value = getValue(field.name);

  return (
    <div className={`${size} flex space-x-2 mt-4`} key={`field-${field.name}`}>
      {!field.multiline && (
        <div className="w-8 hidden md:block pt-4">{field.icon}</div>
      )}

      <TextValidator
        label={`${field.label}${
          !field.required ? ' (optional)' : ''
        }`.toUpperCase()}
        fullWidth
        containerProps={{
          className: 'w-full',
        }}
        withRequiredValidator
        placeholder={field.placeholder}
        variant="outlined"
        id={field.name}
        name={field.name}
        multiline={field.multiline ? true : false}
        rows={field.multiline ? field.multiline : 1}
        value={value || ''}
        onChange={event => {
          handleChange(event, field.name);
        }}
        InputLabelProps={{
          shrink: true,
          style: { fontSize: '1rem' },
        }}
        type="text"
        required={field.required}
        validators={field.validators}
        errorMessages={field.errorMessages}
        InputProps={
          field.startAdornment
            ? {
                style: { fontSize: '1rem' },
                startAdornment: (
                  <InputAdornment
                    position="start"
                    disableTypography
                    className="text-steel"
                    style={{ marginRight: 0 }}
                  >
                    {field.startAdornment}
                  </InputAdornment>
                ),
              }
            : { style: { fontSize: '1rem' } }
        }
      />
      <div className="pt-4">
        {field.public && <BiWorld size="24px" className="m-auto text-steel" />}
      </div>
    </div>
  );
};

const EditProfile = () => {
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  const formRef = useRef(null);

  const [formError, setFormError] = useState(false);
  const [isSavingProfile, setIsSavingProfile] = useState(false);

  // Values for the form
  const [values, setValues] = useState({});
  const [profilePic, setProfilePic] = useState(null);
  const [profilePicFile, setProfilePicFile] = useState(null);

  const { userData, updateUserData } = useContext(AuthContext);

  // Load Public data
  useEffect(() => {
    if (userData) {
      setValues(userData);
      setProfilePic(userData.image);
    }
  }, [userData]);

  const setPublicProfilesUserDoc = payload => {
    updateUserData(payload)
      .then(() => {
        setIsSavingProfile(false);
        navigate('/preview-profile');
      })
      .catch(err => {
        setIsSavingProfile(false);
        // TODO: error handling?
        console.log(err);
      });
  };

  const handleSubmit = () => {
    setFormError(false);
    setIsSavingProfile(true);

    if (profilePicFile) {
      // If the user uploaded a profile picture we need to save the picture first
      // and then set public profile data
      saveImageToBucket(profilePicFile, userData.id).then(url => {
        const saveValues = { ...values, image: url };
        setPublicProfilesUserDoc(saveValues);
      });

      return;
    }

    setPublicProfilesUserDoc(values);
  };

  const handleChange = (event, key) => {
    const parts = key.split('.');
    let newValue = null;
    if (parts.length > 1) {
      newValue = values[parts[0]];
      if (parts.length === 3) {
        let found = false;
        if (newValue && newValue.length > 0) {
          for (let i = 0; i < newValue.length; i++) {
            if (newValue[i][parts[1]] === parts[2]) {
              found = true;
              newValue[i][keyValue[parts[0]]] = event.target.value;
            }
          }
        }
        if (!found) {
          // init first value
          if (!newValue) newValue = [];

          newValue.push({
            [parts[1]]: parts[2],
            [keyValue[parts[0]]]: event.target.value,
          });
        }
      }
      if (parts.length === 2) {
        newValue = values[parts[0]] ?? {};
        newValue[parts[1]] = event.target.value;
      }
      setValues({
        ...values,
        [parts[0]]: newValue,
      });
    } else {
      setValues({
        ...values,
        [key]: event.target.value,
      });
    }
  };

  const handleSaveImage = (data, file) => {
    setProfilePic(data);
    setProfilePicFile(file);
  };

  const {
    configValues: { bio_help_url },
  } = useContext(RemoteConfigContext);

  return (
    <WebAppLayout digitalBussinessCard>
      {userData?.dbc_enabled !== 'Y' ? (
        <div className="bg-white md:bg-transparent">
          <ProfileProgressHeader
            title="Enter Your Card Information"
            percentage="25"
          />
        </div>
      ) : null}

      <div className="md:mb-16 md:mt-4 bg-white md:shadow-lg md:rounded-lg text-slate">
        {/*  Title  */}
        <div className="p-6 flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0">
          <h1>Your Card</h1>
        </div>
        {/* Picture */}
        <div className="p-4 md:p-8 border-t border-b">
          <ProfilePic image={profilePic} handleSaveImage={handleSaveImage} />
        </div>
        {/* Form */}
        <ValidatorForm
          ref={formRef}
          onSubmit={handleSubmit}
          onError={() => {
            setFormError(true);
            window.scrollTo(0, 0);
          }}
          noValidate
          // className={classes.root}
        >
          {formError ? (
            <div className="p-4 pb-0 md:p-8 md:pb-0 uppercase text-error">
              Form contains errors, please fix them and try again
            </div>
          ) : null}
          {formFormat.map((block, i) => {
            return (
              <div className="p-4 md:p-8" key={`section-${i}`}>
                <h3 className="text-fadeOrange text-base uppercase font-medium">
                  {block.section}
                </h3>
                <div className="pt-2 md:pt-4 grid grid-cols-1 md:grid-cols-2">
                  {block.fields &&
                    block.fields.map(field => (
                      <BuildField
                        field={field}
                        values={values}
                        handleChange={handleChange}
                        key={field.name}
                      />
                    ))}
                </div>
              </div>
            );
          })}
          <div className="md:pb-8 md:pl-8">
            {bio_help_url ? (
              <a
                className="font-bold text-fadeOrange underline uppercase inline-block "
                href={bio_help_url}
                target="_blank"
                rel="noreferrer"
              >
                Need some help?
              </a>
            ) : null}
          </div>
          <div className="border-t">
            <div className="p-4 md:p-8 md:flex space-y-4 md:space-y-0 md:space-x-4 md:w-5/6 mx-auto">
              {navigationType === 'PUSH' ? (
                <div className="md:w-1/3 mx-auto">
                  <CMButton
                    outline
                    fullWidth
                    type="button"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </CMButton>
                </div>
              ) : null}
              <div className="md:w-2/3 mx-auto">
                <CMButton
                  disabled={isSavingProfile}
                  loading={isSavingProfile}
                  fullWidth
                  type="submit"
                >
                  Save changes and preview
                </CMButton>
              </div>
            </div>
          </div>
        </ValidatorForm>
      </div>
    </WebAppLayout>
  );
};

export default EditProfile;
