export const API_URL =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'https://contact-mapping-api.herokuapp.com/api/v1'
    : 'https://contact-mapping-api-staging.herokuapp.com/api/v1';
export const PASSWORD_REGEX = /(?=.*\d+)(?=.*[a-zA-Z]).{8,}/;

// From https://stackoverflow.com/a/66050903/620308
export const URL_REGEX =
  /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%/&=?.]+\.[a-z]{2,4}\/?([^\s<>#%",{}\\|\\^[\]`]+)?)/gi;

export const PULSE_INTERVAL = 24 * 60 * 60 * 1000;

export const PLATFORM_IDENTIFIER = `webapp-${process.env.REACT_APP_VERSION}`;
