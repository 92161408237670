import { BillPeriods } from '../utils/enums';

import Coupon from './coupon.model';

export default class Plan {
  id: any;
  name: string;
  amount: number;
  interval: BillPeriods;
  gateway: string;
  key: string; // This will be used to match coupons with products
  pricekey: string; // This will be used to send to the subscription endpoint to point which product it should buy

  constructor(data: Object) {
    Object.assign(this, data);
  }

  get parsedAmount(): number {
    return this.amount / 100;
  }

  get isMonthly(): boolean {
    return this.interval === 'month';
  }

  getAnnualPlanSavings(monthlyPrice: number): number {
    const monthlyPriceAnnually = monthlyPrice * 12;
    const savings =
      ((monthlyPriceAnnually - this.parsedAmount) / monthlyPriceAnnually) * 100;
    return Number(savings.toFixed(0));
  }

  getPriceWithDiscount(coupon?: Coupon): number {
    const canApplyCoupon = coupon?.canApplyToProduct(this.key);

    if (!canApplyCoupon || !coupon) {
      return this.parsedAmount;
    }

    const price = coupon.isPercentType()
      ? this.parsedAmount - (this.parsedAmount * coupon.discountAmount) / 100
      : this.parsedAmount - coupon.discountAmount;

    return Number(Plan.getTruncatedPrice(price * 100));
  }

  getMonthlyPrice(coupon?: Coupon): number {
    const price = this.getPriceWithDiscount(coupon);

    if (this.isMonthly) {
      return price;
    }

    return Number(Plan.getTruncatedPrice((price / 12) * 100));
  }

  static getTruncatedPrice = (price: number): string =>
    (Math.trunc(price) / 100).toLocaleString('en', {
      useGrouping: false,
      minimumFractionDigits: 2,
    });
}
