import axios from 'axios';

import { API_URL, PLATFORM_IDENTIFIER } from '../../utils/constants';

export const sendSyncPulse = token => {
  // Sends POST to CM API syncs endpoint with no data to sync
  // to keep regular record of app version and platform for this user

  // Highest possible value to make sure we get no server changes
  const serverMaxLastKnown = {
    server_id: 9223372036854775807,
    server_vn: 9223372036854775807,
  };
  const syncObj = {
    sync: {
      input: {
        contact: {
          last_known: serverMaxLastKnown,
          client_changes: [],
        },
        note: {
          last_known: serverMaxLastKnown,
          client_changes: [],
        },
        followup: {
          last_known: serverMaxLastKnown,
          client_changes: [],
        },
      },
      device: 'WEB-APP',
      app_version: PLATFORM_IDENTIFIER,
    },
  };

  return axios
    .post(`${API_URL}/syncs`, syncObj, {
      headers: { Authorization: token },
    })
    .then(response => {
      if (response.status === 201 || response.status === 200) {
        return response.data;
      } else {
        throw new Error('Unable to POST sync pulse');
      }
    });
};
