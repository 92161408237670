import firebase from 'firebase/app';
import { useContext } from 'react';

import { AuthContext } from '../context/auth/auth.context';

import { useSpecialDate } from '../firebase/firebase.utils';

import { PLATFORM_IDENTIFIER } from '../utils/constants';

import useNoteActions from './useNoteActions';

// Hook with shared logic for special dates actions
const useSpecialDatesActions = (specialDateId: string, contactId: string) => {
  const { userId } = useContext(AuthContext);

  const { data: dataSpecialDate, update: updateSpecialDate } = useSpecialDate(
    userId,
    specialDateId
  );
  const { handleSaveNote } = useNoteActions(userId, undefined, contactId);

  const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

  const handleDismissSpecialDate = (noteBody: string) => {
    return handleSaveNote(noteBody, contactId, 'add', true).then(() =>
      updateSpecialDate({
        dismissedAt: serverTimestamp,
        updatedAt: serverTimestamp,
        updatedBy: PLATFORM_IDENTIFIER,
      })
    );
  };

  return {
    dataSpecialDate,
    handleDismissSpecialDate,
  };
};

export default useSpecialDatesActions;
