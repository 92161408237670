import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import CMButton from '../../cm-button/cm-button.component';
import CMDialog from '../../cm-dialog/cm-dialog.component';

import useFeaturesSwitch from '../../../hooks/useFeaturesSwitch';

import { AuthContext } from '../../../context/auth/auth.context';
import { PaywallDialoguesContext } from '../../../context/paywall/paywall-dialogues.context';
import { RemoteConfigContext } from '../../../context/remote-config/remote-config.context';

import { getGoPremiumRoute } from '../../../utils/helpers';

const OutstandingFollowUpDialog = () => {
  const navigate = useNavigate();
  const { members_portal_migration } = useFeaturesSwitch();

  const { authToken, userData } = useContext(AuthContext);
  const {
    configValues: { paywall_max_followups },
  } = useContext(RemoteConfigContext);
  const { outstandingFollowUpDialogOpen, closeOutstandingFollowUpDialog } =
    useContext(PaywallDialoguesContext);

  const handleGoPro = () => {
    if (!members_portal_migration) {
      window.location.href = `${process.env.REACT_APP_MEMBERS_PORTAL_URL}/manage-account/${authToken}`;
      return;
    }

    closeOutstandingFollowUpDialog();
    navigate(getGoPremiumRoute(userData.state));
  };

  return (
    <CMDialog
      width="sm"
      renderContent={
        <div>
          <h2 className="text-2xl font-bold text-center pb-6">Follow-up</h2>
          <div className="bg-darkSlate p-7 -mx-6 -mb-[20px] text-white rounded-t-md">
            <p className="text-2xl font-bold">
              You’ve reached your free account limit:
            </p>
            <p className="py-8">
              {paywall_max_followups} outstanding Follow-ups{' '}
            </p>
            <p className="text-xl font-bold">
              We have saved your note, but let’s make sure you don’t let this
              relationship fall through the cracks.
            </p>
            <div className="flex justify-between pt-8">
              <CMButton outline onClick={closeOutstandingFollowUpDialog}>
                Ignore
              </CMButton>
              <CMButton onClick={handleGoPro}>Go Pro</CMButton>
            </div>
          </div>
        </div>
      }
      open={outstandingFollowUpDialogOpen}
      handleClose={closeOutstandingFollowUpDialog}
    />
  );
};

export default OutstandingFollowUpDialog;
