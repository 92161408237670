import { LinearProgress } from '@mui/material';
import React from 'react';

import WebAppLayout from '../web-app-layout/web-app-layout.component';

const WebAppLayoutLoading = props => {
  return (
    <WebAppLayout fullWidth {...props}>
      <LinearProgress
        data-testid={`${
          props.testid ? props.testid + '.' : ''
        }pageLoadingIndicator`}
      />
    </WebAppLayout>
  );
};

export default WebAppLayoutLoading;
