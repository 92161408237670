import { useMediaQuery, useTheme } from '@mui/material';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  PaymentMethod,
  SetupIntentResult,
  loadStripe,
} from '@stripe/stripe-js';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { useNavigate } from 'react-router-dom';

import CMButton, { TCMButtonProps } from '../cm-button/cm-button.component';

import { AuthContext } from '../../context/auth/auth.context';
import {
  ISnackbarContext,
  SnackbarContext,
} from '../../context/snackbar/snackbar.context';

import {
  createSubscription,
  setupPaymentIntent,
  updateSubscription,
} from '../../services/plans/plans.service';

import Coupon from '../../models/coupon.model';
import Plan from '../../models/plan.model';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

type IProps = {
  currentSelectedPlan?: Plan;
  subscriptionId?: string;
  coupon?: Coupon;
  onCancel: () => void;
  cancelButtonText: string;
  cancelButtonProps: TCMButtonProps;
  submitButtonText: string;
  submitButtonProps: TCMButtonProps;
  submitRedirectTo?: string;
};

const CardDetails = ({
  currentSelectedPlan,
  subscriptionId,
  coupon,
  onCancel,
  cancelButtonText,
  cancelButtonProps,
  submitButtonText,
  submitButtonProps,
  submitRedirectTo = '/manage-account',
}: IProps) => {
  const { showSnackbar } = useContext<ISnackbarContext>(SnackbarContext);
  const { authToken, performCommonSignInTasks, userData } =
    useContext(AuthContext);

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

  const navigate = useNavigate();

  const formRef = useRef(null);
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState<boolean>(false);
  const [subscriptionError, setSubscriptionError] = useState<string>(null);
  const [paymentData, setPaymentData] = useState({
    email: userData?.email || '',
    fullname: '',
  });
  const [referralToken, setReferralToken] = useState<string>('');

  useEffect(() => {
    setReferralToken((window as any).Rewardful?.affiliate?.token);
  }, []);

  const handleError = () => {
    setLoading(false);
    showSnackbar({
      severity: 'error',
      message: 'Something went wrong.',
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const subscriptionPromise = subscriptionId
      ? updateSubscription
      : createSubscription;

    setLoading(true);

    const clientSecret = await setupPaymentIntent(
      currentSelectedPlan?.getPriceWithDiscount(coupon) * 100,
      authToken
    );

    const intentRequest: SetupIntentResult = await stripe.confirmCardSetup(
      clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: paymentData.fullname,
            email: paymentData.email,
          },
        },
      }
    );

    if (intentRequest.error !== undefined) {
      setSubscriptionError(intentRequest?.error?.message!);
      handleError();
      return;
    }

    const params: {
      subscription: {
        stripeemail: string;
        stripepaymentmethod: string | PaymentMethod;
        stripeplan: string;
        stripecoupon?: string;
        bonvera_k?: string;
        referral?: string;
      };
    } = {
      subscription: {
        stripeemail: paymentData.email,
        stripepaymentmethod: intentRequest.setupIntent.payment_method!,
        stripeplan: currentSelectedPlan?.pricekey!,
        stripecoupon: coupon?.id,
        referral: referralToken,
      },
    };

    const bonvera_k = Cookies.get('bonvera-k');

    if (bonvera_k !== undefined) {
      params.subscription.bonvera_k = bonvera_k;
    }

    subscriptionPromise(params, authToken, subscriptionId)
      .then(() => {
        setLoading(false);
        performCommonSignInTasks({ ...userData, role: 'Premium' }, authToken);
        showSnackbar({
          severity: 'success',
          message: 'Credit card details updated successfuly.',
        });
        navigate(submitRedirectTo);
      })
      .catch(handleError);
  };

  const handlePaymentDataChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPaymentData(prevState => ({
        ...prevState,
        [field]: event.target.value,
      }));
    };

  return (
    <>
      <div className="mt-8 md:mt-16 bg-white text-slate rounded-lg p-8">
        <h3 className="text-3xl font-bold pb-8">Card details</h3>

        {currentSelectedPlan ? (
          <>
            <p>
              Your selected billing period is:{' '}
              <span className="font-bold">
                {currentSelectedPlan.isMonthly ? 'Monthly' : 'Annual'}
              </span>
            </p>
            <p>
              <span className="font-bold">
                ${currentSelectedPlan.getPriceWithDiscount(coupon)}
              </span>{' '}
              will be charged to your card once a {currentSelectedPlan.interval}
            </p>
          </>
        ) : null}
        <ValidatorForm noValidate ref={formRef} onSubmit={handleSubmit}>
          <div className="pt-4 space-y-6 text-center">
            <TextValidator
              label="EMAIL"
              className="w-full"
              id="email"
              name="email"
              onChange={handlePaymentDataChange('email')}
              value={paymentData.email}
              required
              validators={['required', 'isEmail']}
              errorMessages={['This field is required', 'Not a valid email']}
              InputLabelProps={{ shrink: true }}
            />

            <TextValidator
              label="NAME ON CARD"
              className="w-full"
              id="fullname"
              name="fullname"
              onChange={handlePaymentDataChange('fullname')}
              value={paymentData.fullname}
              required
              validators={['required']}
              errorMessages={['This field is required']}
              InputLabelProps={{ shrink: true }}
            />
            <div className="p-4 border border-gray rounded-md">
              <CardElement />
              {subscriptionError && !loading ? (
                <div className="text-error font-bold text-xs text-left">
                  {subscriptionError}
                </div>
              ) : null}
            </div>
          </div>

          <hr className="my-8" />

          <div className="space-y-4 md:space-y-0 md:flex justify-between">
            <CMButton
              type="button"
              onClick={onCancel}
              disabled={loading}
              fullWidth={!matchesMd}
              {...cancelButtonProps}
            >
              {cancelButtonText}
            </CMButton>
            <CMButton
              type="submit"
              loading={loading}
              disabled={loading || !paymentData.fullname || !paymentData.email}
              fullWidth={!matchesMd}
              {...submitButtonProps}
            >
              {submitButtonText}
            </CMButton>
          </div>
        </ValidatorForm>
      </div>
    </>
  );
};

const CardDetailsElements = props => (
  <Elements stripe={stripePromise}>
    <CardDetails {...props} />
  </Elements>
);

export default CardDetailsElements;
