import React, { memo } from 'react';
import sanitizeHtml from 'sanitize-html';

import { URL_REGEX } from '../../utils/constants';

type IProps = {
  text: string;
  size?: number;
  testid: string;
  htmlElement?: string;
  removeLinks?: boolean;
};

const HTMLText: React.FC<IProps> = memo(
  ({ text, size, testid, htmlElement = 'p', removeLinks }) => {
    const HTMLElement: any = htmlElement;

    const sanitizedText = sanitizeHtml(text);

    const parsedText = `${
      removeLinks
        ? sanitizedText
        : sanitizedText.replace(URL_REGEX, url => {
            const newUrl = url.indexOf('http') === -1 ? `http://${url}` : url;
            return `<a target="_blank" rel="noreferrer" class="underline font-bold" href="${newUrl}">${url}</a>`;
          })
    }`
      .substring(0, size)
      .replace(/(\r\n|\r|\n)/g, '<br>')
      .trim()
      .concat(size && text.length > size ? '…' : '');

    return (
      <HTMLElement
        className="break-words"
        data-testid={testid}
        dangerouslySetInnerHTML={{
          __html: parsedText,
        }}
      ></HTMLElement>
    );
  }
);

export default HTMLText;
