import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import firebase from 'firebase/app';
import React, { useContext, useEffect, useState } from 'react';
import { IoMdCalendar } from 'react-icons/io';
import { MdCake, MdCancel, MdEdit } from 'react-icons/md';
import { useParams } from 'react-router-dom';

import CMButton from '../cm-button/cm-button.component';
import CMTextButton from '../cm-text-button/cm-text-button.component';

import { AuthContext } from '../../context/auth/auth.context';
import { SnackbarContext } from '../../context/snackbar/snackbar.context';

import SpecialDate from '../../models/special-dates.model';

import { PLATFORM_IDENTIFIER } from '../../utils/constants';
import { daysInMonthAsArray, monthNames } from '../../utils/dates.utils';
import { SpecialDatesTypes } from '../../utils/enums';

enum DateParts {
  Day = 'day',
  Month = 'month',
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MonthMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
};

const DayMenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 50,
    },
  },
};

const SpecialDatesBuilder = ({
  dates = [],
}: {
  dates: SpecialDate[];
}): JSX.Element => {
  const { contactId } = useParams();

  const { userId } = useContext(AuthContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const { anniversary, birthday } = SpecialDate.getDatesByType(
    dates.map(d => new SpecialDate(d))
  );

  const initialDates = [
    anniversary ||
      SpecialDate.createDate(SpecialDatesTypes.Anniversary, contactId!),
    birthday || SpecialDate.createDate(SpecialDatesTypes.Birthday, contactId!),
  ];

  const [specialDates, setSpecialDates] = useState<SpecialDate[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [daysInMonth, setDaysInMonth] = useState({});

  useEffect(() => {
    setSpecialDates(initialDates);

    const anniversaryMonth = initialDates.find(
      d => d.type === SpecialDatesTypes.Anniversary
    )?.month;
    const birthdayMonth = initialDates.find(
      d => d.type === SpecialDatesTypes.Birthday
    )?.month;

    setDaysInMonth({
      [SpecialDatesTypes.Anniversary]:
        anniversaryMonth !== ''
          ? daysInMonthAsArray(Number(anniversaryMonth))
          : [],
      [SpecialDatesTypes.Birthday]:
        birthdayMonth !== '' ? daysInMonthAsArray(Number(birthdayMonth)) : [],
    });
    // eslint-disable-next-line
  }, [dates]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);

    const db = firebase.firestore();
    const batch = db.batch();
    const firestoreTimestamp = firebase.firestore.FieldValue.serverTimestamp();

    specialDates.forEach((specialDate: SpecialDate) => {
      const { month, day, type } = specialDate;
      const originalDate = dates.find((d: SpecialDate) => d.type === type);

      const userSelectedDate = month !== '' && day !== '';

      const dateToSave = {
        contactId,
        type,
        shortDate: userSelectedDate ? `${month}-${day}` : '',
        // `dismissedAt` set to 1970-01-01 as default instead of `null`
        dismissedAt: firebase.firestore.Timestamp.fromDate(new Date(0)),
      } as SpecialDate;

      const shouldAdd =
        originalDate === undefined && dateToSave.shortDate !== '';

      if (shouldAdd) {
        const addRef = db.collection(`users/${userId}/specialdates`).doc();
        batch.set(addRef, {
          ...dateToSave,
          createdAt: firestoreTimestamp,
          createdBy: PLATFORM_IDENTIFIER,
        });
        return;
      }

      if (!originalDate) return;

      const docRef = db
        .collection(`users/${userId}/specialdates`)
        .doc(originalDate.id);

      const shouldDelete =
        originalDate.shortDate && dateToSave.shortDate === '';
      const shouldUpdate = originalDate.shortDate !== dateToSave.shortDate;

      if (shouldDelete) {
        batch.delete(docRef);
      } else if (shouldUpdate) {
        batch.set(
          docRef,
          {
            ...dateToSave,
            updatedAt: firestoreTimestamp,
            updatedBy: PLATFORM_IDENTIFIER,
          },
          { merge: true }
        );
      }
    });

    batch
      .commit()
      .then(() => setIsEditing(false))
      .catch(() => {
        showSnackbar({
          message: 'Something went wrong.',
          severity: 'error',
        });
      })
      .finally(() => setIsLoading(false));
  };

  const handleCancel = () => {
    setSpecialDates(initialDates);
    setIsEditing(false);
  };

  const handleChange =
    (type: SpecialDatesTypes, valueToChange: DateParts) =>
    (e: SelectChangeEvent<string>) => {
      const datesCopy = [...specialDates];
      const dateToUpdate = datesCopy.find(d => d.type === type)!;
      const val = e.target.value;
      dateToUpdate[valueToChange] = val;

      if (valueToChange === DateParts.Month) {
        const monthSelected = val !== '';
        dateToUpdate.day = monthSelected ? '01' : '';

        setDaysInMonth({
          ...daysInMonth,
          [type]: monthSelected ? daysInMonthAsArray(Number(val)) : [],
        });
      }

      setSpecialDates(datesCopy);
    };

  if (isEditing)
    return (
      <form onSubmit={handleSubmit}>
        <h2 className="text-xl font-bold">Special Dates</h2>
        <ul className="grid grid-cols-2 gap-y-6 gap-x-4 mt-8">
          {specialDates.map((specialDate: SpecialDate, index: number) => (
            <li className="flex items-center space-x-4" key={index}>
              {specialDate.isBirthday ? (
                <MdCake size="24px" />
              ) : (
                <IoMdCalendar size="24px" />
              )}
              <FormControl sx={{ width: 130 }} size="medium">
                <InputLabel variant="outlined" htmlFor="month">
                  MONTH
                </InputLabel>
                <Select
                  MenuProps={MonthMenuProps}
                  label="MONTH"
                  id="month"
                  value={specialDate.month?.toString()}
                  onChange={handleChange(specialDate.type, DateParts.Month)}
                >
                  <MenuItem value={''}>None</MenuItem>
                  {monthNames.map((month: string, index: number) => (
                    <MenuItem
                      key={index}
                      value={(index + 1).toString().padStart(2, '0')}
                    >
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={{ width: 70 }} size="medium">
                <InputLabel variant="outlined" htmlFor="day">
                  DAY
                </InputLabel>
                <Select
                  MenuProps={DayMenuProps}
                  label="Day"
                  id="day"
                  value={specialDate.day?.toString()}
                  onChange={handleChange(specialDate.type, DateParts.Day)}
                >
                  <MenuItem value={''}>None</MenuItem>
                  {daysInMonth[specialDate.type].map((day: number) => (
                    <MenuItem
                      key={day + 1}
                      value={(day + 1).toString().padStart(2, '0')}
                    >
                      {day + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </li>
          ))}
        </ul>
        <div className="pt-4 flex items-center space-x-4 justify-center flex-row-reverse space-x-reverse">
          <CMButton
            type="submit"
            disabled={isLoading}
            loading={isLoading}
            data-testid="contactDetails.contactDetailsViewEdit.editSpecialDatesUpdateButton"
          >
            Update
          </CMButton>
          <CMTextButton
            icon={MdCancel}
            onClick={handleCancel}
            data-testid="contactDetails.contactDetailsViewEdit.editSpecialDatesCancelButton"
          >
            Cancel
          </CMTextButton>
        </div>
      </form>
    );

  return (
    <>
      <div className="flex justify-between pb-6">
        <h2 className="text-xl font-bold">Special Dates</h2>
        <CMTextButton
          icon={MdEdit}
          onClick={() => setIsEditing(true)}
          data-testid="contactDetails.contactDetailsViewEdit.editSpecialDatesEditButton"
        >
          Edit
        </CMTextButton>
      </div>

      <ul className="grid grid-cols-2 gap-4">
        {initialDates.map((date: SpecialDate, index: number) => (
          <li className="flex items-center space-x-4" key={index}>
            {date.isBirthday ? (
              <MdCake size="24px" />
            ) : (
              <IoMdCalendar size="24px" />
            )}
            <div>
              <div className="font-bold uppercase">{date.typeLabel}</div>
              <div>{date.dateAsString}</div>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default SpecialDatesBuilder;
