import React, { useContext } from 'react';
import { IoMdCalendar } from 'react-icons/io';
import { MdCake } from 'react-icons/md';

import CMDialog from '../../cm-dialog/cm-dialog.component';
import DateViewDialogContent from '../../date-view-dialog-content/date-view-dialog-content.component';

import useSpecialDatesActions from '../../../hooks/useSpecialDatesActions';

import { SharedDialoguesContext } from '../../../context/shared-dialogues/shared-dialogues.context';

import SpecialDate from '../../../models/special-dates.model';

const SpecialDateViewDialog: React.FC = () => {
  const {
    specialDateViewDialogOpen,
    closeSpecialDateViewDialog,
    specialDateDialogData,
    openSpecialDateCompleteDialog,
  } = useContext(SharedDialoguesContext);

  const { contactId, specialDateId } = specialDateDialogData;

  const { dataSpecialDate, handleDismissSpecialDate } = useSpecialDatesActions(
    specialDateId,
    contactId
  );

  const onDismiss = () => {
    handleDismissSpecialDate(`${specialDate.typeLabel} touch`).then(
      closeSpecialDateViewDialog
    );
  };

  if (!dataSpecialDate) return null;

  const specialDate = new SpecialDate(dataSpecialDate);

  return (
    <CMDialog
      width="sm"
      renderContent={
        <DateViewDialogContent
          contactId={contactId}
          title="Special date"
          closeDialog={closeSpecialDateViewDialog}
          disableActions={specialDate.isOutsideOfCurrentRange}
          handleNote={() =>
            openSpecialDateCompleteDialog({
              specialDateId: specialDate.id,
              contactId: specialDate.contactId,
            })
          }
          handleTouch={onDismiss}
          renderContent={
            <div className="flex items-center">
              {specialDate.isBirthday ? (
                <MdCake size="24px" />
              ) : (
                <IoMdCalendar size="24px" />
              )}{' '}
              <span className="pl-2">{specialDate.dateAsString}</span>
            </div>
          }
        />
      }
      open={specialDateViewDialogOpen}
      handleClose={closeSpecialDateViewDialog}
    />
  );
};

export default SpecialDateViewDialog;
