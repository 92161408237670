import React from 'react';

import CMDialog from '../cm-dialog/cm-dialog.component';
import ShareProfileContent, {
  ShareProfileFooter,
} from '../share-profile-content/share-profile-content.component';

import styles from './share-profile-dialog.module.css';

const ShareProfileDialog = ({ open, handleClose, url }) => {
  const originUrl = window.location.origin;
  const fullUrl = `${originUrl}/hi/${url}`;

  return (
    <CMDialog
      classes={{ paper: styles['dialog-paper'] }}
      contentProps={{ classes: { root: styles['dialog-content-root'] } }}
      renderContent={<ShareProfileContent url={fullUrl} profileUrl={url} />}
      renderBottomActions={
        <div className="-mx-2">
          <ShareProfileFooter />
        </div>
      }
      open={open}
      handleClose={handleClose}
    />
  );
};

export default ShareProfileDialog;
