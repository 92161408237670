import PropTypes from 'prop-types';
import React from 'react';

import CMAvatar from '../cm-avatar/cm-avatar.component';

const propTypes = {
  img: PropTypes.string,
  fullName: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  isFeatured: PropTypes.bool,
  profileUrl: PropTypes.string,
};

const ContactCard = ({
  img,
  fullName,
  subtitle,
  isFeatured,
  profileUrl = '#',
}) => (
  <a
    className="h-40 bg-slate flex items-center rounded-xl p-5 relative bg-contact-card bg-no-repeat bg-blend-multiply hover:bg-opacity-90 transition-all"
    href={profileUrl}
  >
    {/* featured */}
    {isFeatured ? (
      <div className="uppercase absolute bg-blueGrey text-white font-medium rounded-md px-2 py-0.5 shadow-md top-3 -left-1.5">
        Featured
      </div>
    ) : null}

    {/* image */}
    <CMAvatar img={img} />
    {/* full name */}
    <div className="text-white flex-row space-y-2.5 pl-4">
      <div className="text-xl font-medium">{fullName}</div>
      {/* subtitle */}
      {subtitle ? <div>{subtitle}</div> : null}
    </div>
  </a>
);

ContactCard.propTypes = propTypes;
export default ContactCard;
