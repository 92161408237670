import React from 'react';
import { Link } from 'react-router-dom';

import CMButton from '../../components/cm-button/cm-button.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

const NoMatch = () => (
  <WebAppLayout>
    <div className="text-center py-20">
      <h1 className="text-9xl">404</h1>
      <h2 className="text-2xl pb-10">
        Oops! The page you are looking for can't be found!
      </h2>
      <CMButton component={Link} to="/">
        Go to homepage
      </CMButton>
    </div>
  </WebAppLayout>
);

export default NoMatch;
