// Staging
export const firebaseConfigStaging = {
  apiKey: 'AIzaSyBqfE2MXbvDvW9_iESkejrGbiiMsd1lClA',
  authDomain: 'contact-mapping-staging.firebaseapp.com',
  databaseURL: 'https://contact-mapping-staging.firebaseio.com',
  projectId: 'contact-mapping-staging',
  storageBucket: 'contact-mapping-staging.appspot.com',
  messagingSenderId: '330472677228',
  appId: '1:330472677228:web:3f5cd08db17fa4ebc65d49',
  measurementId: 'G-6W1916GJ6R',
};

// Production
export const firebaseConfigProd = {
  apiKey: 'AIzaSyBRRL4hmzukX-6W9HD3wsWqPWYXVqMCO7Q',
  authDomain: 'contact-mapping-2a458.firebaseapp.com',
  databaseURL: 'https://contact-mapping-2a458.firebaseio.com',
  projectId: 'contact-mapping-2a458',
  storageBucket: 'contact-mapping-2a458.appspot.com',
  messagingSenderId: '821264573075',
  appId: '1:821264573075:web:553b94b23b53e770372366',
  measurementId: 'G-93DGXSP9GS',
};
