import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material';
import React, { memo, useContext, useEffect, useRef, useState } from 'react';

import CMButton from '../cm-button/cm-button.component';
import CMDialog from '../cm-dialog/cm-dialog.component';

import { AuthContext } from '../../context/auth/auth.context';

import { authRequest } from '../../services/auth/auth.service';

import { PASSWORD_REGEX } from '../../utils/constants';

type ICMPasswordInputProps = TextFieldProps & {
  testid: string;
};

export const CMPasswordInput = ({
  testid,
  ...props
}: ICMPasswordInputProps) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <TextField
      className="w-full"
      variant="outlined"
      type={passwordVisible ? 'text' : 'password'}
      InputLabelProps={{ shrink: true }}
      inputProps={{
        style: { fontSize: '1rem' },
        'data-testid': `${testid}`,
      }}
      // Yes... inputProps and InputProps are different...
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setPasswordVisible(!passwordVisible)}
              edge="end"
              size="large"
            >
              {passwordVisible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

type IChangePasswordFormProps = {
  passwordLabel?: string;
  testid: string;
  submitButtonText?: string;
  submitMethod: (password: string) => Promise<any>;
  showHelper?: boolean;
  renderCancelButton?: React.ReactNode;
  validateCurrentPassword?: boolean;
};

type IValues = {
  currentPassword: string;
  password: string;
  repeatPassword: string;
  currentPasswordHelperText: string;
  passwordHelperText: string;
  repeatPasswordHelperText: string;
  currentPasswordError: boolean;
  passwordError: boolean;
  repeatPasswordError: boolean;
};

export const ChangePasswordForm = memo(
  ({
    passwordLabel = 'Password',
    testid,
    submitButtonText = 'Save',
    submitMethod,
    showHelper,
    renderCancelButton,
    validateCurrentPassword = false,
  }: IChangePasswordFormProps) => {
    const initialValues = {
      currentPassword: '',
      password: '',
      repeatPassword: '',
      currentPasswordHelperText: '',
      passwordHelperText: '',
      repeatPasswordHelperText: '',
      currentPasswordError: false,
      passwordError: false,
      repeatPasswordError: false,
    };

    const { userData } = useContext(AuthContext);

    const [values, setValues] = useState<IValues>(initialValues);
    const [savingPassword, setSavingPassword] = useState<boolean>(false);

    const mounted = useRef<boolean>(false);

    // We'll use this effect later to avoid setting state if the component has
    // been unmounted, for example: when navigating away from the page that
    // contains this component
    useEffect(() => {
      mounted.current = true;

      return () => {
        mounted.current = false;
      };
    }, []);

    const handleChange =
      (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues(prevState => ({
          ...prevState,
          [field]: event.target.value,
        }));
      };

    const handleResetPasswordSubmit = () => {
      const passwordOk = !!values.password.match(PASSWORD_REGEX);
      const repeatPasswordOk = values.password === values.repeatPassword;
      const currentPasswordOk = !!values.currentPassword.length;

      setValues(values => ({
        ...values,
        currentPasswordHelperText: currentPasswordOk
          ? ''
          : 'This field is required',
        currentPasswordError: validateCurrentPassword && !currentPasswordOk,
        passwordHelperText: passwordOk
          ? ''
          : 'Password must be at least 8 characters long and contain both letters and numbers',
        passwordError: !passwordOk,
        repeatPasswordHelperText: repeatPasswordOk
          ? ''
          : "Passwords don't match",
        repeatPasswordError: !repeatPasswordOk,
      }));

      if (validateCurrentPassword && !currentPasswordOk) return;

      const savePasswordPromise = () =>
        validateCurrentPassword
          ? authRequest({
              email: userData?.email,
              password: values.currentPassword,
            }).catch(error => {
              setValues(values => ({
                ...values,
                currentPasswordHelperText: "We couldn't validate your password",
                currentPasswordError: true,
              }));
              throw new Error(error);
            })
          : Promise.resolve();

      if (passwordOk && repeatPasswordOk) {
        setSavingPassword(true);
        return savePasswordPromise()
          .then(() => submitMethod(values.password))
          .finally(() => {
            if (mounted.current) setSavingPassword(false);
          });
      }
    };

    return (
      <>
        {showHelper ? (
          <div>
            Minimum password requirements
            <ul className="list-disc list-inside">
              <li>8 characters</li>
              <li>1 letter</li>
              <li>1 number</li>
            </ul>
          </div>
        ) : null}
        {validateCurrentPassword ? (
          <CMPasswordInput
            label="CURRENT PASSWORD"
            id="currentPassword"
            error={values.currentPasswordError}
            helperText={values.currentPasswordHelperText}
            value={values.currentPassword}
            onChange={handleChange('currentPassword')}
            testid={`${testid}.currentPasswordInput`}
          />
        ) : null}
        <CMPasswordInput
          label={passwordLabel?.toUpperCase()}
          id="password"
          error={values.passwordError}
          helperText={values.passwordHelperText}
          value={values.password}
          onChange={handleChange('password')}
          testid={`${testid}.passwordInput`}
        />
        <CMPasswordInput
          label={`Repeat ${passwordLabel}`.toUpperCase()}
          id="repeatPassword"
          error={values.repeatPasswordError}
          helperText={values.repeatPasswordHelperText}
          value={values.repeatPassword}
          onChange={handleChange('repeatPassword')}
          testid={`${testid}.repeatPasswordInput`}
        />
        <div className="flex flex-col w-full">
          <div className="space-y-4 md:space-y-0 md:flex md:space-x-4 w-full">
            {renderCancelButton}
            <CMButton
              data-testid={`${testid}.saveButton`}
              disabled={savingPassword}
              loading={savingPassword}
              onClick={handleResetPasswordSubmit}
              fullWidth
            >
              {submitButtonText}
            </CMButton>
          </div>
        </div>
      </>
    );
  }
);

type IChangePasswordProps = IChangePasswordFormProps & {
  dialogTitle: string;
  renderButton: (openResetPasswordDialog: () => void) => React.ReactNode;
};

const ChangePassword = memo(
  ({
    testid,
    renderButton,
    dialogTitle,
    submitMethod,
    showHelper,
    ...props
  }: IChangePasswordProps) => {
    const [resetPasswordDialogOpen, setResetPasswordDialogOpen] =
      useState<boolean>(false);

    const openResetPasswordDialog = () => {
      setResetPasswordDialogOpen(true);
    };

    const closeResetPasswordDialog = () => {
      setResetPasswordDialogOpen(false);
    };

    const handleSubmit = (password: string) =>
      submitMethod(password).then(() => {
        closeResetPasswordDialog();
      });

    return (
      <>
        {renderButton(openResetPasswordDialog)}
        <CMDialog
          width="xs"
          title={dialogTitle}
          renderContent={
            <div className="pt-8 space-y-8">
              <ChangePasswordForm
                testid={testid}
                submitMethod={handleSubmit}
                showHelper={showHelper}
                renderCancelButton={
                  <CMButton
                    data-testid={`${testid}.cancelButton`}
                    onClick={closeResetPasswordDialog}
                    fullWidth
                  >
                    Cancel
                  </CMButton>
                }
                {...props}
              />
            </div>
          }
          open={resetPasswordDialogOpen}
          handleClose={closeResetPasswordDialog}
        />
      </>
    );
  }
);

export default ChangePassword;
