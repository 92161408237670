import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  PaymentMethod,
  SetupIntentResult,
  loadStripe,
} from '@stripe/stripe-js';
import Cookies from 'js-cookie';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';

import { CMPasswordInput } from '../../components/change-password/change-password.component';
import CMButton from '../../components/cm-button/cm-button.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import { AuthContext } from '../../context/auth/auth.context';
import { RemoteConfigContext } from '../../context/remote-config/remote-config.context';
import {
  ISnackbarContext,
  SnackbarContext,
} from '../../context/snackbar/snackbar.context';

import { signInRequest } from '../../services/auth/auth.service';
import { getPlans } from '../../services/plans/plans.service';
import {
  createSubscription,
  setupPaymentIntent,
} from '../../services/plans/plans.service';
import { createUser } from '../../services/users/users.service';

import Coupon from '../../models/coupon.model';
import Plan from '../../models/plan.model';

import { PLATFORM_IDENTIFIER } from '../../utils/constants';
import { PASSWORD_REGEX } from '../../utils/constants';
import { getTimezoneOffset } from '../../utils/dates.utils';

import logo from '../../assets/images/logo-dark.png';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const SignUpProvenPath: React.FC<{}> = (): JSX.Element => {
  const initialValues = {
    password: '',
    repeatPassword: '',
    passwordHelperText: '',
    repeatPasswordHelperText: '',
    passwordError: false,
    repeatPasswordError: false,
  };

  const formRef = useRef(null);
  const { authToken, setAuthToken, performCommonSignInTasks } =
    useContext(AuthContext);
  const { showSnackbar } = useContext<ISnackbarContext>(SnackbarContext);

  const [error, setError] = useState<boolean>(false);
  const [userId, setUserId] = useState(null);
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phonenumber: '',
  });
  const [referralToken, setReferralToken] = useState('');
  const [values, setValues] = useState<IValues>(initialValues);
  const [currentSelectedPlan, setCurrentSelectedPlan] = useState<Plan | null>(
    null
  );
  const [coupon] = useState<Coupon | undefined>(undefined);
  const [subscriptionError, setSubscriptionError] = useState<string>(null);
  const stripe = useStripe();
  const [paymentData, setPaymentData] = useState({
    fullname: '',
  });
  const elements = useElements();
  const {
    configValues: { proven_path_payment_terms, proven_path_pricekey },
  } = useContext(RemoteConfigContext);

  useEffect(() => {
    setReferralToken(window.Rewardful?.affiliate?.token);
    getPlans().then((response: Plan[]) => {
      const index = response.findIndex(
        plan => plan.gateway === 'Stripe' && plan.interval === 'month'
      );
      setCurrentSelectedPlan(index !== -1 ? response[index] : response[0]);
    });
  }, []);

  useEffect(() => {
    if (authToken && userId && settings) submitCardPayment();
  }, [authToken, userId, settings]);

  const handleError = () => {
    setLoading(false);
    showSnackbar({
      severity: 'error',
      message: 'Something went wrong.',
    });
  };

  const handlePaymentDataChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPaymentData(prevState => ({
        ...prevState,
        [field]: event.target.value,
      }));
    };

  const submitUserCreation = e => {
    e.preventDefault();

    const { firstname, email, phonenumber, lastname } = userData;

    if (!email || !firstname || !acceptedTerms) {
      return;
    }

    const passwordOk = !!values.password.match(PASSWORD_REGEX);
    const repeatPasswordOk = values.password === values.repeatPassword;

    setValues(values => ({
      ...values,
      passwordHelperText: passwordOk
        ? ''
        : 'Password must be at least 8 characters long and contain both letters and numbers',
      passwordError: !passwordOk,
      repeatPasswordHelperText: repeatPasswordOk ? '' : "Passwords don't match",
      repeatPasswordError: !repeatPasswordOk,
    }));

    if (!passwordOk || !repeatPasswordOk) return;

    setLoading(true);

    const newUser: {
      firstname: string;
      email: string;
      phonenumber: string;
      lastname: string;
      password: string;
      flag_provisionalpassword: 'Y' | 'N';
      utcoffset: string;
      bonvera_k?: string;
      referral?: string;
      termsaccepted: number;
      platform: string;
    } = {
      firstname,
      email,
      phonenumber,
      lastname,
      password: values.password,
      flag_provisionalpassword: 'Y',
      utcoffset: getTimezoneOffset(),
      termsaccepted: Math.floor(Date.now() / 1000),
      platform: PLATFORM_IDENTIFIER,
    };

    const bonvera_k: string = Cookies.get('bonvera-k');

    if (bonvera_k !== undefined) {
      newUser.bonvera_k = bonvera_k;
    }

    if (referralToken) {
      newUser.referral = referralToken;
    }

    createUser(newUser)
      .then(({ data }) => {
        setUserId(data.id);
        setUserData(data);
        return signInRequest({
          email,
          password: values.password,
        });
      })
      .then(({ userData, authToken, settings }) => {
        setSettings(settings);
        setAuthToken(authToken);
      })
      .catch(err => {
        if (err?.response?.status === 422) {
          showSnackbar({
            severity: 'error',
            message: `${email} ${err?.response?.data?.email?.[0]}.`,
          });
        } else {
          setError(true);
        }
      })
      .finally(() => setLoading(false));
  };

  const submitCardPayment = async () => {
    const subscriptionPromise = createSubscription;

    setLoading(true);

    const clientSecret = await setupPaymentIntent(
      currentSelectedPlan?.getPriceWithDiscount(coupon) * 100,
      authToken
    );

    const intentRequest: SetupIntentResult = await stripe.confirmCardSetup(
      clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: paymentData.fullname,
            email: userData.email,
          },
        },
      }
    );

    if (intentRequest.error !== undefined) {
      setSubscriptionError(intentRequest?.error?.message!);
      handleError();
      return;
    }

    const params: {
      subscription: {
        stripeemail: string;
        stripepaymentmethod: string | PaymentMethod;
        stripeplan: string;
        stripeinvoiceitem: string;
        stripecoupon?: string;
        bonvera_k?: string;
        referral?: string;
      };
    } = {
      subscription: {
        stripeemail: userData.email,
        stripepaymentmethod: intentRequest.setupIntent.payment_method!,
        stripeplan: currentSelectedPlan?.pricekey!,
        stripeinvoiceitem: proven_path_pricekey,
        stripecoupon: coupon?.id,
        referral: referralToken,
      },
    };

    const bonvera_k = Cookies.get('bonvera-k');

    if (bonvera_k !== undefined) {
      params.subscription.bonvera_k = bonvera_k;
    }

    subscriptionPromise(params, authToken)
      .then(() => {
        setLoading(false);
        performCommonSignInTasks(
          { ...userData, role: 'Premium' },
          authToken,
          settings
        );
        window.location.replace('https://go.contactmapping.com/thank-you');
      })
      .catch(handleError);
  };

  const handleUserDataChange =
    (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setUserData(prevState => ({
        ...prevState,
        [field]: e.target.value,
      }));
    };

  const handlePasswordChange =
    (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues(prevState => ({
        ...prevState,
        [field]: event.target.value,
      }));
    };

  return (
    <WebAppLayout hiddenHeader fullWidth>
      <div className="bg-slate bg-login bg-blend-multiply bg-no-repeat bg-center w-full h-screen items-center flex">
        <div className="h-full md:w-full flex justify-center mx-8">
          <ValidatorForm
            noValidate
            ref={formRef}
            onSubmit={submitUserCreation}
            className="bg-white rounded-lg space-y-6 text-center"
          >
            <img
              src={logo}
              alt="Contact Mapping"
              className="w-[180px] mx-auto mt-5"
            />
            <div className="md:flex bg-white">
              <div className=" rounded-lg px-12 pb-8 m-auto mt-0 md:w-3/5 space-y-8">
                <TextValidator
                  label="FIRST NAME"
                  className="w-full"
                  id="firstname"
                  data-testid="signup.firstNameInput"
                  onChange={handleUserDataChange('firstname')}
                  value={userData.firstname}
                  required
                  validators={['required']}
                  errorMessages={['This field is required']}
                  InputLabelProps={{ shrink: true }}
                />
                <TextValidator
                  label="LAST NAME"
                  className="w-full"
                  id="lastname"
                  data-testid="signup.lastNameInput"
                  onChange={handleUserDataChange('lastname')}
                  value={userData.lastname}
                  InputLabelProps={{ shrink: true }}
                />
                <TextValidator
                  label="YOUR EMAIL"
                  className="w-full"
                  id="email"
                  data-testid="signup.emailInput"
                  onChange={handleUserDataChange('email')}
                  value={userData.email}
                  required
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    'This field is required',
                    'Not a valid email',
                  ]}
                  InputLabelProps={{ shrink: true }}
                />
                <TextValidator
                  label="MOBILE"
                  className="w-full"
                  id="phonenumber"
                  data-testid="signup.phoneNumberInput"
                  type="number"
                  required
                  validators={['required']}
                  errorMessages={['This field is required']}
                  onChange={handleUserDataChange('phonenumber')}
                  value={userData.phonenumber}
                  InputLabelProps={{ shrink: true }}
                />
                <CMPasswordInput
                  label={'Password'}
                  id="password"
                  error={values.passwordError}
                  helperText={values.passwordHelperText}
                  value={values.password}
                  onChange={handlePasswordChange('password')}
                  testid={`resetPassword.passwordInput`}
                />
                <CMPasswordInput
                  label={`Repeat password`}
                  id="repeatPassword"
                  error={values.repeatPasswordError}
                  helperText={values.repeatPasswordHelperText}
                  value={values.repeatPassword}
                  onChange={handlePasswordChange('repeatPassword')}
                  testid={`resetPassword.repeatPasswordInput`}
                />
              </div>
              <div className="rounded-lg md:px-12 pb-8 m-auto mt-0 md:w-3/5 space-y-8">
                <div className="bg-white text-slate rounded-lg p-8 pt-0 pb-0">
                  <h3 className="text-3xl font-bold pb-8">Card details</h3>
                  {currentSelectedPlan && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: proven_path_payment_terms,
                      }}
                    ></p>
                  )}

                  <div className="pt-4 space-y-6 text-center">
                    <TextValidator
                      label="NAME ON CARD"
                      className="w-full"
                      id="fullname"
                      name="fullname"
                      onChange={handlePaymentDataChange('fullname')}
                      value={paymentData.fullname}
                      required
                      validators={['required']}
                      errorMessages={['This field is required']}
                      InputLabelProps={{ shrink: true }}
                    />
                    <div className="p-4 border border-gray rounded-md">
                      <CardElement />
                      {subscriptionError && !loading ? (
                        <div className="text-error font-bold text-xs text-left">
                          {subscriptionError}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-testid="signup.acceptTermsCheckbox"
                        onChange={e => setAcceptedTerms(e.target.checked)}
                      />
                    }
                    label={
                      <div>
                        I accept the{' '}
                        <a
                          className="text-fadeOrange underline "
                          href="https://www.contactmapping.com/tos"
                          target="_blank"
                          rel="noreferrer"
                          data-testid="signup.tosLink"
                        >
                          Terms and Conditions
                        </a>
                      </div>
                    }
                  />
                </FormGroup>
                <CMButton
                  disabled={loading || !acceptedTerms}
                  loading={loading}
                  fullWidth
                  id="sign-up"
                  type="submit"
                  data-testid="signup.firstStepNextButton"
                >
                  Sign up
                </CMButton>
                <hr />
                <div className="mt-10">
                  <a
                    className="text-fadeOrange text-sm font-semibold underline uppercase"
                    href="https://buy.stripe.com/bIY7u4feZbGWcW4dR8"
                    target="_blank"
                    rel="noreferrer"
                    data-testid="signup.signinLink"
                  >
                    I already have an account
                  </a>
                </div>
              </div>
            </div>
          </ValidatorForm>
          {error && (
            <div
              className="text-error font-bold text-xs text-center"
              data-testid="signup.errorText"
            >
              There's been an error, please try again.
            </div>
          )}
        </div>
      </div>
    </WebAppLayout>
  );
};

const SignUpProvenPathElements = props => (
  <Elements stripe={stripePromise}>
    <SignUpProvenPath {...props} />
  </Elements>
);

export default SignUpProvenPathElements;
