import React, { memo, useContext, useRef, useState } from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import CMButton from '../../../components/cm-button/cm-button.component';
import CMDialog from '../../../components/cm-dialog/cm-dialog.component';
import CMTextButton from '../../../components/cm-text-button/cm-text-button.component';

import { AuthContext } from '../../../context/auth/auth.context';
import { SnackbarContext } from '../../../context/snackbar/snackbar.context';

import { deleteData } from '../../../services/users/users.service';

const AdminDeleteUserData = memo(({ userId, fullName, testid }) => {
  const { authToken } = useContext(AuthContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const formRef = useRef(null);

  const [deleteDataAnswer, setDeleteDataAnswer] = useState('');
  const [savingUpdates, setSavingUpdates] = useState(false);
  const [saveRequestError, setSaveRequestError] = useState('');
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [openDeleteDataDialogOpen, setOpenDeleteDataDialogOpen] =
    useState(false);

  const openDeleteDataDialog = () => {
    setDeleteDataAnswer('');
    setOpenDeleteDataDialogOpen(true);
  };

  const handleRequestError = message => {
    showSnackbar({
      severity: 'error',
      message: 'Something wrong happened.',
    });

    if (message) {
      setSaveRequestError(message);
    }
  };

  const handleDeleteDataSubmit = () => {
    if (deleteDataAnswer.toLocaleLowerCase() !== 'yes') return;

    setSavingUpdates(true);
    deleteData(userId, authToken)
      .then(response => {
        if (response.status === 'N') {
          handleRequestError(response.output);
          return;
        }

        showSnackbar({
          severity: 'success',
          message: 'User data deletion request succesful',
        });

        setConfirmationDialogOpen(true);
        setOpenDeleteDataDialogOpen(false);
      })
      .catch(err => {
        const errorMessage = err?.response?.data?.args?.[0] ?? '';
        const formattedErrorMessage = errorMessage
          .replace('! check_args - ', '')
          .replace(/[{}]/g, '');
        handleRequestError(formattedErrorMessage);
      })
      .finally(() => {
        setSavingUpdates(false);
      });
  };

  return (
    <>
      <CMTextButton
        data-testid={`${testid}.deleteDataButton`}
        onClick={openDeleteDataDialog}
      >
        Delete Data
      </CMTextButton>
      {/* Delete data dialog */}
      <CMDialog
        width="xs"
        open={openDeleteDataDialogOpen}
        handleClose={() => {
          setOpenDeleteDataDialogOpen(false);
          setSaveRequestError('');
        }}
        title="Delete data"
        renderContent={
          <ValidatorForm
            noValidate
            ref={formRef}
            onSubmit={handleDeleteDataSubmit}
          >
            <div className="space-y-6 mt-4">
              <p>
                All the contacts, notes, and follow-ups for this user will be
                deleted. Type Yes if you are sure you want to continue.
              </p>
              <TextValidator
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                type="text"
                onChange={e => setDeleteDataAnswer(e.target.value)}
                value={deleteDataAnswer}
                required
                validators={['required']}
                errorMessages={['Please type "yes" to confirm data deletion']}
              />
            </div>
            {saveRequestError && (
              <p className="mt-4 text-error font-bold">{saveRequestError}</p>
            )}
            <div className="space-y-4 md:space-y-0 md:flex md:space-x-4 mt-8">
              <CMButton
                data-testid="admin.deleteData.cancelButton"
                fullWidth
                type="reset"
                onClick={() => {
                  setOpenDeleteDataDialogOpen(false);
                }}
              >
                Cancel
              </CMButton>
              <CMButton
                data-testid="admin.deleteData.saveButton"
                loading={savingUpdates}
                type="submit"
                disabled={deleteDataAnswer.toLowerCase() !== 'yes'}
                fullWidth
              >
                Send
              </CMButton>
            </div>
          </ValidatorForm>
        }
      />
      {/* Confirmation dialog */}
      <CMDialog
        width="sm"
        open={confirmationDialogOpen}
        handleClose={() => setConfirmationDialogOpen(false)}
        title="Delete data"
        renderContent={
          <>
            <div className="pt-8 space-x-4 flex items-center">
              <MdCheckCircle size="45px" />
              <div>
                <p>
                  The data of <strong>{fullName}</strong> is being deleted.
                </p>
                <p className="py-4">
                  Remember: the process can take from 10 seconds to 5 minutes
                </p>
              </div>
            </div>
            <div className="text-right">
              <CMButton onClick={() => setConfirmationDialogOpen(false)}>
                Close
              </CMButton>
            </div>
          </>
        }
      />
    </>
  );
});

export default AdminDeleteUserData;
