import React, { memo } from 'react';

const NoResults = memo(({ testid }) => (
  <div
    className="py-14 px-8 text-center text-2xl font-bold bg-white rounded border border-silver"
    data-testid={testid}
  >
    No results match your filter and/or search term.
  </div>
));

export default NoResults;
