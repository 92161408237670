import { CircularProgress } from '@mui/material';
import React, { memo } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdSearch } from 'react-icons/md';

const SecondarySearch = memo(
  ({
    searchTerm,
    type = 'text',
    handleOnChange,
    handleAdditionalOnChange,
    handleOnEnterKeyPress,
    handleClearValue,
    placeholder,
    testid,
    showButton,
    loading,
    handleOnButtonClick,
  }) => {
    const handleInputChange = e => {
      handleOnChange(e.target.value);

      if (handleAdditionalOnChange instanceof Function)
        handleAdditionalOnChange(e.currentTarget);
    };

    const handleClearSearch = () => {
      handleOnChange('');

      if (handleAdditionalOnChange instanceof Function)
        handleAdditionalOnChange(null);

      if (handleClearValue instanceof Function) handleClearValue();
    };

    const handleOnKeyPress = e => {
      if (
        e.key === 'Enter' &&
        searchTerm.length &&
        handleOnEnterKeyPress instanceof Function
      ) {
        handleOnEnterKeyPress();
      }
    };

    return (
      <div className="w-full relative">
        <MdSearch size="24px" className="absolute top-3 left-3 text-paleTeal" />
        <input
          className={`block rounded-full h-12 pl-10 w-full bg-white border border-slate focus:border-fadeOrange focus:outline-none ${
            !showButton ? 'pr-10' : 'pr-32'
          }`}
          type={type}
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleInputChange}
          onKeyPress={handleOnKeyPress}
          data-testid={`${testid}.searchInput`}
        />
        {searchTerm.length ? (
          loading ? (
            <CircularProgress
              size="24px"
              className={`absolute top-3 right-3 ${
                !showButton ? 'right-3' : 'right-[105px]'
              }`}
              color="inherit"
              data-testid={`${testid}.searchInput.loadingIndicator`}
            />
          ) : (
            <button
              onClick={handleClearSearch}
              className={`absolute top-3 ${
                !showButton ? 'right-3' : 'right-[105px]'
              }`}
              data-testid={`${testid}.searchInput.clearButton`}
            >
              <IoMdCloseCircle size="24px" />
            </button>
          )
        ) : null}
        {showButton ? (
          <button
            onClick={handleOnButtonClick}
            disabled={!searchTerm.length || loading}
            className={`absolute top-[3px] right-[3px] bottom-[3px] w-24 text-white rounded-full uppercase font-bold text-sm transition ${
              !searchTerm.length || loading
                ? 'bg-coolGrey cursor-default'
                : 'bg-slate'
            }`}
            data-testid={`${testid}.searchInput.searchButton`}
          >
            Search
          </button>
        ) : null}
      </div>
    );
  }
);

export default SecondarySearch;
