import React from 'react';
import { BiCalendarEvent, BiCalendarX } from 'react-icons/bi';

type IProps = {
  targetDate: string;
  selectedDate: string;
  label: string;
  onDateClick: () => void;
  isDateRemove?: boolean;
  testid: string;
};

const DateButton: React.FC<IProps> = ({
  targetDate,
  selectedDate,
  label,
  onDateClick,
  isDateRemove,
  testid,
}) => {
  const dateClassName = 'p-2 flex items-center transition-all';
  const hoverDateClassName =
    'hover:cursor-pointer hover:text-softPurple hover:bg-softPurple hover:bg-opacity-10';
  const activeDateClassName = 'text-softPurple bg-softPurple bg-opacity-10';

  const matchesDate = (date: string) => date === selectedDate;

  return (
    <div
      onClick={onDateClick}
      className={`${dateClassName} ${
        matchesDate(targetDate)
          ? `${activeDateClassName}`
          : `${hoverDateClassName}`
      }`}
      data-testid={`${testid}.${label}Button`}
    >
      {!isDateRemove ? (
        <BiCalendarEvent size="24px" />
      ) : (
        <BiCalendarX size="24px" />
      )}
      <strong className="pl-2">{label}</strong>
    </div>
  );
};

export default DateButton;
