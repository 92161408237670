import React, { memo } from 'react';
import { Link } from 'react-router-dom';

import CMButton from '../../components/cm-button/cm-button.component';

import SpecialDateView from '../special-date-view/special-date-view.component';

import SpecialDate from '../../models/special-dates.model';

const DashboardSpecialDates: React.FC<{ specialDates: SpecialDate[] }> = memo(
  ({ specialDates }) => {
    const dates: SpecialDate[] = specialDates.map(val => new SpecialDate(val));

    const { todayItems, recentItems, upcomingItems } =
      SpecialDate.getGroupedByTime(dates);

    const specialDatesByOrder = [
      ...todayItems,
      ...recentItems,
      ...upcomingItems,
    ];

    return (
      <section className="pt-16">
        <h2 className="text-2xl font-bold">Special dates</h2>
        <div className="pt-6">
          <div className="md:grid grid-cols-4 gap-4">
            {specialDatesByOrder.slice(0, 4).map((specialDate: SpecialDate) => (
              <SpecialDateView specialDate={specialDate} key={specialDate.id} />
            ))}
          </div>
          <div className="pt-8 md:w-56">
            <CMButton
              component={Link}
              to="/special-dates/"
              color="secondary"
              fullWidth
              outline
            >
              View all
            </CMButton>
          </div>
        </div>
      </section>
    );
  }
);

export default DashboardSpecialDates;
