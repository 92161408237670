import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import Breadcrumb from '../../components/breadcrumb/breadcrumb.component';
import CardDetailsElements from '../../components/card-details/card-details.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import useActiveSubscriptionData from '../../hooks/useActiveSubscriptionData';

const UpdateCreditCard = () => {
  const navigate = useNavigate();

  const { loadingSubscription, activeSubscriptionData } =
    useActiveSubscriptionData();

  if (loadingSubscription)
    return <WebAppLayoutLoading darkBackground hiddenSidebar />;

  // If there's no active subscription, send users back to /account
  // Example: Free user typing /account/subscription on the address bar
  if (!activeSubscriptionData) return <Navigate replace to="/manage-account" />;

  return (
    <WebAppLayout darkBackground hiddenSidebar digitalBussinessCard>
      <Breadcrumb
        color="light"
        label="Manage your subscription"
        to="/manage-account/subscription"
      />
      <h1 className="text-2xl md:text-5xl font-bold">
        Update Credit Card Details
      </h1>

      <CardDetailsElements
        subscriptionId={activeSubscriptionData.subscription_id}
        onCancel={() => navigate('/manage-account/subscription')}
        cancelButtonText="Cancel"
        cancelButtonProps={{
          outline: true,
          color: 'secondary',
        }}
        submitButtonText="Update credit card details"
        submitButtonProps={{
          color: 'secondary',
        }}
        submitRedirectTo="/manage-account/subscription"
      />
    </WebAppLayout>
  );
};

export default UpdateCreditCard;
