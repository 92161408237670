import React, { memo, useContext } from 'react';
import { BiCalendarAlt } from 'react-icons/bi';
import { GiPartyPopper } from 'react-icons/gi';
import { MdStar } from 'react-icons/md';
import { RiContactsLine, RiStickyNoteLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import CMAvatar from '../../components/cm-avatar/cm-avatar.component';
import CMButton from '../../components/cm-button/cm-button.component';
import NoteItem from '../../components/note-item/note-item.component';
import PaywallToast from '../../components/paywall-toast/paywall-toast.component';
import RotationItem from '../../components/rotation-item/rotation-item.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import DashboardEmptyState from './dashboard-empty-state.component';
import DashboardFollowUpItem from './dashboard-follow-up-item.component';
import DashboardSpecialDates from './dashboard-special-dates.component';

import useFeaturesSwitch from '../../hooks/useFeaturesSwitch';

import { AuthContext } from '../../context/auth/auth.context';
import { PaywallContext } from '../../context/paywall/paywall.context';
import { SharedDialoguesContext } from '../../context/shared-dialogues/shared-dialogues.context';

import {
  useContactsMappedToday,
  useContactsUnlogged,
  useDrafts,
  useFollowUpsDueToday,
  useRotations,
  useRotationsDueToday,
  useSpecialDates,
} from '../../firebase/firebase.utils';

import { timeGreeting } from '../../utils/dates.utils';

const paperClassNames =
  'border-b md:border border-silver py-5 md:p-5 rounded bg-white flex flex-col';

const DashboardContactList = memo(
  ({ contactList, title, emptyState, linkTo }) => {
    return (
      <section className={paperClassNames}>
        <h4 className="text-xl pb-4">
          <strong>{contactList.length}</strong> {title}
        </h4>
        {contactList.length ? (
          <div className="grid grid-cols-5 gap-2">
            {contactList
              .slice(0, 5)
              .map(({ fullName, displayStatus, company, id }) => (
                <Link
                  className="flex flex-col items-center"
                  to={{
                    pathname: `/contacts/${id}`,
                    state: { contactFullName: fullName || company },
                  }}
                  key={id}
                >
                  <CMAvatar size="sm" displayStatus={displayStatus} />
                  <div className="pt-2 w-full text-center overflow-hidden text-ellipsis text-sm">
                    {fullName || company}
                  </div>
                </Link>
              ))}
          </div>
        ) : (
          emptyState
        )}
        {contactList.length > 5 ||
        // View all link is always present if there are Unlogged interactions
        (contactList.length && title === 'unlogged interactions') ? (
          <div className="flex justify-center pt-2 h-8">
            <Link className="uppercase text-fadeOrange" to={linkTo}>
              View all
            </Link>
          </div>
        ) : null}
      </section>
    );
  }
);

const Dashboard = () => {
  const { userData, userId } = useContext(AuthContext);
  const { openFollowUpDialog, openNoteDialog, openAddNewContactDialog } =
    useContext(SharedDialoguesContext);
  const {
    latestFollowUpsIDs,
    latestRotationsIDs,
    hasReachedMaxFollowUps,
    hasReachedMaxRotations,
  } = useContext(PaywallContext);

  const { special_dates, rotation } = useFeaturesSwitch();

  const { data: dataContactsMappedToday } = useContactsMappedToday(userId);
  const { data: dataContactsUnlogged } = useContactsUnlogged(
    userId,
    'LAST_MONTH'
  );
  const { data: dataFollowUpsDueToday } = useFollowUpsDueToday(userId);
  const { data: dataDrafts } = useDrafts(userId);
  const { data: dataRotations } = useRotationsDueToday(userId);
  const { data: dataSpecialDates } = useSpecialDates(userId, true);

  // The total amount of rotations should be taken from the stats using `useStatsTotals`
  // but since the stats don't count Rotations yet we'll get the entire collection
  const { data: dataTotalRotations } = useRotations(userId);

  const loading =
    !dataContactsMappedToday ||
    !dataContactsUnlogged ||
    !dataFollowUpsDueToday ||
    !dataDrafts ||
    !dataRotations ||
    !dataSpecialDates ||
    !dataTotalRotations;

  const handleOpenNoteDialog = () =>
    openNoteDialog({
      isEditing: true,
      closeOnCancelEdit: true,
      isAddMode: true,
    });

  if (loading) return <WebAppLayoutLoading testid="dashboardPage" />;

  const availableFollowUpsDueToday = hasReachedMaxFollowUps
    ? dataFollowUpsDueToday.filter(({ id }) => latestFollowUpsIDs.includes(id))
    : dataFollowUpsDueToday;

  const availableRotations = hasReachedMaxRotations
    ? dataRotations.filter(({ id }) => latestRotationsIDs.includes(id))
    : dataRotations;

  const dataContactsUnloggedToday = dataContactsUnlogged.filter(
    ({ unloggedTimestamp }) =>
      unloggedTimestamp >= Math.floor(new Date().setHours(0, 0, 0, 0) / 1000)
  );

  const hasFutureRotations = dataTotalRotations.length > 0;

  return (
    <WebAppLayout>
      <PaywallToast />
      <h1 className="text-2xl md:text-5xl font-bold">
        Good {timeGreeting()} {userData.firstname}!
      </h1>
      <section className="md:pt-12">
        <h2 className="hidden md:block text-2xl font-bold">Today</h2>
        <div className="md:grid grid-cols-3 gap-4 md:pt-6">
          {/* Contacts Mapped */}
          <DashboardContactList
            contactList={dataContactsMappedToday}
            title="contacts mapped"
            emptyState={
              <div className="text-center">
                <p>For sure you have something to add...</p>
                <Link
                  className="font-bold text-fadeOrange underline uppercase inline-block pt-2"
                  to="/contacts"
                >
                  Find a Contact
                </Link>
              </div>
            }
            linkTo="/contacts"
          />

          {/* Unlogged Interactions */}
          <DashboardContactList
            contactList={dataContactsUnloggedToday}
            title="unlogged interactions"
            emptyState={
              <div className="text-center">
                <GiPartyPopper size="48px" className="mx-auto text-paleTeal" />
                <div className="text-center text-xl font-bold">Good Job!!</div>
                {dataContactsUnlogged.length ? (
                  <Link
                    className="font-bold text-paleTeal underline uppercase inline-block pt-2"
                    to="/unlogged-interactions"
                  >
                    Check past unlogged interactions
                  </Link>
                ) : null}
              </div>
            }
            linkTo="/unlogged-interactions"
          />

          {/* Actions Desktop */}
          <div className="hidden md:grid grid-cols-3 items-center gap-2">
            <div
              className="flex flex-col items-center hover:cursor-pointer group"
              onClick={openFollowUpDialog}
              data-testid="dashboard.newFollowUpButton"
            >
              <div className="w-14 h-14 rounded-full flex items-center justify-center shadow-md bg-softPurple transition-all group-hover:bg-opacity-80 group-hover:shadow-lg">
                <BiCalendarAlt size="24px" className="text-white" />
              </div>
              <span className="text-sm pt-2 text-softPurple underline font-bold text-center">
                New Follow-up
              </span>
            </div>
            <div
              className="flex flex-col items-center hover:cursor-pointer group"
              onClick={handleOpenNoteDialog}
              data-testid="dashboard.newNoteButton"
            >
              <div className="w-14 h-14 rounded-full flex items-center justify-center shadow-md bg-fadeOrange transition-all group-hover:bg-opacity-80 group-hover:shadow-lg">
                <RiStickyNoteLine size="24px" className="text-white" />
              </div>
              <span className="text-sm pt-2 text-fadeOrange underline font-bold text-center">
                New Note
              </span>
            </div>
            <div
              className="flex flex-col items-center hover:cursor-pointer group"
              onClick={openAddNewContactDialog}
              data-testid="dashboard.newContactButton"
            >
              <div className="w-14 h-14 rounded-full flex items-center justify-center shadow-md bg-paleTeal transition-all group-hover:bg-opacity-80 group-hover:shadow-lg">
                <RiContactsLine size="24px" className="text-white" />
              </div>
              <span className="text-sm pt-2 text-paleTeal underline font-bold text-center">
                New Contact
              </span>
            </div>
          </div>
        </div>
      </section>

      {/* Follow-ups */}
      <section className="pt-16">
        <h2 className="text-2xl font-bold">
          {/*
            Show dataFollowUpsDueToday.length instead of availableFollowUpsDueToday.length
            so that it represents the real amount of follow-ups ready instead of just
            paywall items
           */}
          Follow-ups ready for action ({dataFollowUpsDueToday.length})
        </h2>
        <div className="pt-6">
          {availableFollowUpsDueToday.length ? (
            <>
              <div className="md:grid grid-cols-4 gap-4">
                {availableFollowUpsDueToday
                  .slice(0, 4)
                  .map(
                    (
                      { followupTimestamp, body, timeBased, id, contactId },
                      index
                    ) => {
                      return (
                        <DashboardFollowUpItem
                          key={id}
                          followupTimestamp={followupTimestamp}
                          body={body}
                          timeBased={timeBased}
                          followUpId={id}
                          contactId={contactId}
                          testid={`dashboard.followUps.item${index}`}
                        />
                      );
                    }
                  )}
              </div>
              {availableFollowUpsDueToday.length > 4 ? (
                <div className="pt-8 md:w-56">
                  <CMButton
                    component={Link}
                    to="/follow-ups?displayStatus=Ready"
                    color="secondary"
                    fullWidth
                    data-testid="dashboard.followUps.viewAllButton"
                    outline
                  >
                    View all
                  </CMButton>
                </div>
              ) : null}
            </>
          ) : (
            <DashboardEmptyState
              renderContent={
                <p className="flex items-center space-x-2">
                  <MdStar size="32px" />
                  <span>You are a star!</span>
                </p>
              }
              renderActions={
                <CMButton
                  fullWidth
                  to="/follow-ups?displayStatus=Upcoming"
                  data-testid="dashboard.followUps.viewUpcomingButton"
                  component={Link}
                  outline
                >
                  View upcoming follow-ups
                </CMButton>
              }
            />
          )}
        </div>
      </section>

      {special_dates && dataSpecialDates.length ? (
        <DashboardSpecialDates specialDates={dataSpecialDates} />
      ) : null}

      {/* Rotation */}
      {rotation ? (
        <section className="pt-16">
          <h2 className="text-2xl font-bold">
            {/*
            Show dataRotations.length instead of availableRotations.length
            so that it represents the real amount of Rotations instead of just
            paywall items
           */}
            Rotation{dataRotations.length > 1 ? 's' : ''} of Remember (
            {dataRotations.length})
          </h2>
          <div className="pt-6">
            {availableRotations.length ? (
              <>
                <div className="md:grid grid-cols-4 gap-4">
                  {availableRotations
                    .slice(0, 4)
                    .map(
                      (
                        {
                          nextIterationDate,
                          frequency,
                          interval,
                          id,
                          contactId,
                        },
                        index
                      ) => {
                        return (
                          <RotationItem
                            key={id}
                            nextIterationDate={nextIterationDate}
                            frequency={frequency}
                            interval={interval}
                            rotationId={id}
                            contactId={contactId}
                            testid={`dashboard.rotations.item${index}`}
                          />
                        );
                      }
                    )}
                </div>
                <div className="pt-8 md:w-56">
                  <CMButton
                    component={Link}
                    to="/rotations"
                    color="secondary"
                    fullWidth
                    data-testid="dashboard.rotations.viewAllButton"
                    outline
                  >
                    View all
                  </CMButton>
                </div>
              </>
            ) : hasFutureRotations ? (
              <DashboardEmptyState
                renderContent={
                  <p>You don’t have contacts to reach out today</p>
                }
                renderActions={
                  <CMButton
                    fullWidth
                    to="/rotations"
                    data-testid="dashboard.rotations.viewUpcomingButton"
                    component={Link}
                    color="secondary"
                    outline
                  >
                    View upcoming rotations
                  </CMButton>
                }
              />
            ) : (
              <DashboardEmptyState
                renderContent={
                  <>
                    <p>
                      Rotations ensure you consistently stay in touch with the
                      people who matter most.
                    </p>
                    <p className="font-normal text-lg">
                      Whether it’s keeping in frequent contact with key
                      relationships, or ensuring those periodic checkpoints with
                      connections in your wider circle, Rotations of Remember
                      make sure no one ever falls through the cracks.
                    </p>
                  </>
                }
                renderActions={
                  <CMButton
                    fullWidth
                    to="/contacts"
                    data-testid="dashboard.rotations.chooseContactButton"
                    component={Link}
                    color="secondary"
                    outline
                  >
                    Choose a Contact
                  </CMButton>
                }
              />
            )}
          </div>
        </section>
      ) : null}

      {/* Drafts */}
      <section className="pt-16">
        <h2 className="text-2xl font-bold">Drafts ({dataDrafts.length})</h2>
        <div className="pt-6">
          {dataDrafts.length ? (
            <div className="max-w-4xl">
              {dataDrafts
                .slice(0, 4)
                .map(({ createdTimestamp, body, id }, index) => (
                  <NoteItem
                    key={id}
                    id={id}
                    contactId={null}
                    createdTimestamp={createdTimestamp}
                    body={body}
                    testid={`dashboard.draftNotes.item${index}`}
                  />
                ))}
              {dataDrafts.length > 4 ? (
                <div className="pt-8 md:w-56">
                  <CMButton
                    component={Link}
                    to="/notes?displayStatus=Drafts"
                    fullWidth
                    data-testid="dashboard.drafts.viewAllButton"
                  >
                    View all
                  </CMButton>
                </div>
              ) : null}
            </div>
          ) : (
            <DashboardEmptyState
              renderContent={<p>Nothing to see here</p>}
              renderActions={
                <CMButton
                  fullWidth
                  onClick={handleOpenNoteDialog}
                  data-testid="dashboard.drafts.addANoteButton"
                  outline
                >
                  Add a note
                </CMButton>
              }
            />
          )}
        </div>
      </section>
    </WebAppLayout>
  );
};

export default Dashboard;
