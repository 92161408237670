import React from 'react';
import { BsFillPersonCheckFill } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';

import CMButton from '../cm-button/cm-button.component';
import CMDialog from '../cm-dialog/cm-dialog.component';
import CMTextButton from '../cm-text-button/cm-text-button.component';

const ContactDownloadedConfirmationDialog = ({
  username = '',
  open = false,
  onClose,
}) => (
  <CMDialog
    open={open}
    handleClose={() => onClose(false)}
    width="sm"
    renderBottomActions={
      <div className="flex flex-col w-full">
        <div className="md:flex md:w-full md:justify-between block w-1/2 mx-auto text-center">
          <div className="mb-2">
            <CMTextButton icon={MdCancel} onClick={() => onClose(false)}>
              No, thanks
            </CMTextButton>
          </div>
          <div>
            <CMButton onClick={() => onClose(true)}>Yes, please</CMButton>
          </div>
        </div>
      </div>
    }
    renderContent={
      <div>
        <h1>{username}'s Contact Card was downloaded to your files!</h1>
        <div className="my-5">
          <div className="w-14 h-14 rounded-full flex items-center justify-center shadow-md bg-paleTeal text mx-auto my-10">
            <BsFillPersonCheckFill color="white" size="24px" />
          </div>
          <p className="mb-8">
            Just tap or click on the file to add it to your contacts.
          </p>
          <p>
            Now that you have {username}'s contact information, would you like
            to introduce yourself back?
          </p>
        </div>
      </div>
    }
  />
);

export default ContactDownloadedConfirmationDialog;
