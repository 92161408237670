import React from 'react';
import { useNavigate } from 'react-router-dom';

import CMAvatar from '../cm-avatar/cm-avatar.component';

const ContactAvatar = ({
  contactDisplayStatus,
  contactName,
  isDraftNote = false,
  contactId,
  onAvatarClick,
}) => {
  const navigate = useNavigate();

  const goToContactDetails = () =>
    contactId && navigate(`/contacts/${contactId}`);

  const handleAvatarClick = () => {
    if (!contactId) return;

    if (onAvatarClick instanceof Function) {
      onAvatarClick(goToContactDetails);
    } else {
      goToContactDetails();
    }
  };

  return (
    <div
      className={`flex items-center ${contactId ? 'cursor-pointer' : null}`}
      onClick={handleAvatarClick}
    >
      <CMAvatar
        size="sm"
        isDraftNote={isDraftNote}
        displayStatus={contactDisplayStatus}
      />{' '}
      <div
        className="font-bold pl-4 text-xl"
        data-testid="noteDialog.contactName"
      >
        {!isDraftNote ? contactName : 'Draft'}
      </div>
    </div>
  );
};
export default ContactAvatar;
