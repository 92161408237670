import firebase from 'firebase/app';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MdCancel } from 'react-icons/md';

import CMButton from '../../cm-button/cm-button.component';
import CMDialog from '../../cm-dialog/cm-dialog.component';
import CMTextButton from '../../cm-text-button/cm-text-button.component';
import ContactAvatar from '../../contact-avatar/contact-avatar.component';
import DateButton from '../../date-button/date-button.component';

import useRotationActions from '../../../hooks/useRotationActions';

import { AuthContext } from '../../../context/auth/auth.context';
import {
  IPaywallDialoguesContext,
  PaywallDialoguesContext,
} from '../../../context/paywall/paywall-dialogues.context';
import { PaywallContext } from '../../../context/paywall/paywall.context';
import { SharedDialoguesContext } from '../../../context/shared-dialogues/shared-dialogues.context';

import Contact from '../../../models/contact.model';
import Rotation from '../../../models/rotation.model';

import {
  useContact,
  useContactRotation,
} from '../../../firebase/firebase.utils';

import { PLATFORM_IDENTIFIER } from '../../../utils/constants';
import { dateToInputDateFormat } from '../../../utils/dates.utils';

const ROTATION_TARGETS = {
  none: '0,0',
  weekly: '2,1',
  biweekly: '2,2',
  monthly: '1,1',
  trimonthly: '1,3',
  biannual: '1,6',
  yearly: '0,1',
};

type TRotationTargets = keyof typeof ROTATION_TARGETS;
type TRotationTargetsValues = typeof ROTATION_TARGETS[TRotationTargets];

type IEditRotationProps = {
  contactId: string;
  rotationId?: string;
  frequency?: number;
  interval?: number;
  handleClose: (afterSave?: boolean) => void;
  updateRotation: ((rotation: Rotation) => Promise<void> | null) | null;
  deleteRotation: (() => Promise<void> | null) | null;
  addRotation: (rotation: Rotation) => Promise<void> | null;
  setRotationChanged: React.Dispatch<React.SetStateAction<boolean>>;
};

const getRotationLabelByTargetValue = (targetValue: TRotationTargetsValues) => {
  return (Object.keys(ROTATION_TARGETS).find(
    key => ROTATION_TARGETS[key] === targetValue
  ) ?? 'none') as TRotationTargets;
};

const EditRotation: React.FC<IEditRotationProps> = ({
  contactId,
  rotationId,
  frequency = 0,
  interval = 0,
  handleClose,
  updateRotation,
  deleteRotation,
  addRotation,
  setRotationChanged,
}) => {
  const { isAboutToReachHalfRotations, isAboutToReachMaxRotations } =
    useContext(PaywallContext);
  const { openHalfwayPaywallRotationsDialog, openMaxRotationsReachedDialog } =
    useContext(PaywallDialoguesContext) as IPaywallDialoguesContext;
  const {
    settings: { notification_time_followups },
    userData: { utcoffset },
  } = useContext(AuthContext);

  const dateChangedManually = useRef(false);

  const today = dateToInputDateFormat(new Date());
  const nextIterationPossibleDates = {
    weekly: dateToInputDateFormat(new Date().setDate(new Date().getDate() + 7)),
    biweekly: dateToInputDateFormat(
      new Date().setDate(new Date().getDate() + 14)
    ),
    monthly: dateToInputDateFormat(
      new Date().setMonth(new Date().getMonth() + 1)
    ),
    trimonthly: dateToInputDateFormat(
      new Date().setMonth(new Date().getMonth() + 3)
    ),
    biannual: dateToInputDateFormat(
      new Date().setMonth(new Date().getMonth() + 6)
    ),
    yearly: dateToInputDateFormat(
      new Date().setFullYear(new Date().getFullYear() + 1)
    ),
    none: '',
  };

  const [rotationFrequency, setRotationFrequency] = useState<number>(frequency);
  const [rotationInterval, setRotationInterval] = useState<number>(interval);
  const selectedRotation =
    `${rotationFrequency},${rotationInterval}` as TRotationTargetsValues;
  const rotationLabel = getRotationLabelByTargetValue(selectedRotation);
  const [rotationFrequencyLabel, setRotationFrequencyLabel] =
    useState<TRotationTargets>(rotationLabel);
  const [selectedStartDate, setSelectedStartDate] = useState<string>(
    nextIterationPossibleDates[rotationLabel]
  );

  const originalRotationFrequency = useRef<number>(frequency);
  const originalRotationInterval = useRef<number>(interval);

  // Effect when changing dates to a rotation somewhere else
  // Commented for now
  // useEffect(() => {
  //   setRotationFrequency(frequency);
  //   setRotationInterval(interval);
  // }, [frequency, interval]);

  // Effect to set a rotation as "changed" to show dirty prompt
  useEffect(() => {
    const rotationChanged =
      rotationFrequency !== originalRotationFrequency.current ||
      rotationInterval !== originalRotationInterval.current;

    setRotationChanged(rotationChanged);
  }, [rotationFrequency, rotationInterval, setRotationChanged]);

  const handleDateClick = (rotationTarget: TRotationTargetsValues) => {
    const frequencyLabel = getRotationLabelByTargetValue(rotationTarget);

    setRotationFrequency(Number(rotationTarget.slice(0, 1)));
    setRotationInterval(Number(rotationTarget.slice(-1)));
    setRotationFrequencyLabel(frequencyLabel);

    if (frequencyLabel === 'none') {
      dateChangedManually.current = false;
      setSelectedStartDate(nextIterationPossibleDates[frequencyLabel]);
      return;
    }

    if (!dateChangedManually.current)
      setSelectedStartDate(nextIterationPossibleDates[frequencyLabel]);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dateChangedManually.current = true;
    setSelectedStartDate(e.target.value || today);
  };

  const handleSaveClick = () => {
    const noFrequencySelected = rotationFrequencyLabel === 'none';

    // Saving without selecting a frequency for a new rotation, just close the dialog
    if (!rotationId && noFrequencySelected) {
      handleClose(true);
      return;
    }

    // Delete rotation
    if (
      rotationId &&
      noFrequencySelected &&
      deleteRotation instanceof Function
    ) {
      deleteRotation();
      handleClose(true);
      return;
    }

    const notificationTime = notification_time_followups ?? '09:00';

    const startDate = Math.floor(
      new Date(
        `${
          dateChangedManually ? selectedStartDate : today
        }T${notificationTime}:00${utcoffset}`
      ).getTime() / 1000
    );
    const nextIterationDate = Math.floor(
      new Date(
        `${
          dateChangedManually
            ? selectedStartDate
            : nextIterationPossibleDates[rotationFrequencyLabel]
        }T${notificationTime}:00${utcoffset}`
      ).getTime() / 1000
    );
    const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

    const baseRotation = {
      frequency: rotationFrequency,
      interval: rotationInterval,
      startDate,
      nextIterationDate,
    } as Rotation;

    // Update or Add a new Rotation
    updateRotation
      ? updateRotation({
          ...baseRotation,
          updatedAt: serverTimestamp,
          updatedBy: PLATFORM_IDENTIFIER,
        })
      : addRotation({
          ...baseRotation,
          contactId,
          createdAt: serverTimestamp,
          createdBy: PLATFORM_IDENTIFIER,
        })!.then(() => {
          if (isAboutToReachHalfRotations) {
            // With the addition of this rotation, halfway is reached
            openHalfwayPaywallRotationsDialog();
          }

          if (isAboutToReachMaxRotations) {
            // With the addition of this rotation, max is reached
            openMaxRotationsReachedDialog();
          }
        });
    handleClose(true);
  };

  return (
    <div>
      <div className="pt-4 my-4 border-t border-steel">
        <p className="pb-4">How often do you want to stay in touch?</p>
        <div className="grid grid-cols-2">
          <DateButton
            targetDate={ROTATION_TARGETS.weekly}
            selectedDate={selectedRotation}
            label="Every 1 week"
            onDateClick={() => handleDateClick(ROTATION_TARGETS.weekly)}
            testid="rotationDialog.editMode"
          />
          <DateButton
            targetDate={ROTATION_TARGETS.trimonthly}
            selectedDate={selectedRotation}
            label="Every 3 months"
            onDateClick={() => handleDateClick(ROTATION_TARGETS.trimonthly)}
            testid="rotationDialog.editMode"
          />
          <DateButton
            targetDate={ROTATION_TARGETS.biweekly}
            selectedDate={selectedRotation}
            label="Every 2 weeks"
            onDateClick={() => handleDateClick(ROTATION_TARGETS.biweekly)}
            testid="rotationDialog.editMode"
          />
          <DateButton
            targetDate={ROTATION_TARGETS.biannual}
            selectedDate={selectedRotation}
            label="Every 6 months"
            onDateClick={() => handleDateClick(ROTATION_TARGETS.biannual)}
            testid="rotationDialog.editMode"
          />
          <DateButton
            targetDate={ROTATION_TARGETS.monthly}
            selectedDate={selectedRotation}
            label="Every 1 month"
            onDateClick={() => handleDateClick(ROTATION_TARGETS.monthly)}
            testid="rotationDialog.editMode"
          />
          <DateButton
            targetDate={ROTATION_TARGETS.yearly}
            selectedDate={selectedRotation}
            label="Every 12 months"
            onDateClick={() => handleDateClick(ROTATION_TARGETS.yearly)}
            testid="rotationDialog.editMode"
          />
          <div className="pt-4">
            <DateButton
              targetDate={ROTATION_TARGETS.none}
              selectedDate={selectedRotation}
              label="Don't stay in touch"
              isDateRemove
              onDateClick={() => handleDateClick(ROTATION_TARGETS.none)}
              testid="rotationDialog.editMode"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center py-4">
        <strong>Start Rotation on:</strong>
        <input
          type="date"
          className="rounded border border-coolGrey h-10 text-slate ml-2 px-2 w-44 hover:border-slate hover:cursor-pointer focus:border-2 focus:border-fadeOrange focus:outline-none focus:px-[7px]"
          min={today}
          value={selectedStartDate}
          onChange={handleDateChange}
          data-testid="rotationDialog.editMode.dateInput"
        />
      </div>
      <div className="pt-4 mt-4 border-t border-steel flex items-center justify-center space-x-4">
        <CMTextButton
          icon={MdCancel}
          color="secondary"
          onClick={() => handleClose(false)}
          data-testid="rotationDialog.editMode.cancelButton"
        >
          Cancel
        </CMTextButton>
        <CMButton
          color="secondary"
          onClick={handleSaveClick}
          data-testid="rotationDialog.editMode.saveButton"
        >
          Save
        </CMButton>
      </div>
    </div>
  );
};

const RotationDialog = () => {
  const { userId } = useContext(AuthContext);
  const { rotationDialogData, rotationDialogOpen, closeRotationDialog } =
    useContext(SharedDialoguesContext);

  const { rotationId, contactId } = rotationDialogData;

  const { data: dataContact } = useContact(userId, contactId);
  // Using `useContactRotation` instead of `useRotationActions` so we get cached
  // data already read in contact-follow-ups.component
  const { data: dataContactRotation, add: addRotation } = useContactRotation(
    userId,
    contactId
  );
  const { deleteRotation, updateRotation } = useRotationActions(
    rotationId,
    contactId
  );

  const [rotationChanged, setRotationChanged] = useState<boolean>(false);

  const handleClose = (afterSave?: boolean) => {
    if (!afterSave && rotationChanged) {
      if (window.confirm('The changes will be lost.')) {
        setRotationChanged(false);
        closeRotationDialog();
      }
      return;
    }

    closeRotationDialog();
  };

  if (!dataContact || !dataContactRotation) return null;

  const contact = new Contact(dataContact);

  return (
    <CMDialog
      width="sm"
      renderContent={
        <div>
          <h2 className="text-2xl font-bold text-center pb-6">Rotation</h2>
          <div className="flex items-center">
            <ContactAvatar
              contactId={contactId}
              onAvatarClick={() => handleClose(false)}
              contactDisplayStatus={contact.displayStatus}
              contactName={contact.displayName}
            />
          </div>
          <EditRotation
            contactId={contactId}
            rotationId={rotationId}
            frequency={dataContactRotation?.[0]?.frequency}
            interval={dataContactRotation?.[0]?.interval}
            handleClose={handleClose}
            updateRotation={rotationId ? updateRotation : null}
            deleteRotation={rotationId ? deleteRotation : null}
            addRotation={addRotation}
            setRotationChanged={setRotationChanged}
          />
        </div>
      }
      open={rotationDialogOpen}
      handleClose={() => handleClose(false)}
    />
  );
};

export default RotationDialog;
