import React, { memo, useContext, useState } from 'react';
import { MdPerson, MdPersonOff, MdStar, MdStarBorder } from 'react-icons/md';
import { Link } from 'react-router-dom';

import CMAvatar from '../../components/cm-avatar/cm-avatar.component';

import { AuthContext } from '../../context/auth/auth.context';

import { useContact } from '../../firebase/firebase.utils';

import { getContactDisplayName } from '../../utils/list.utils';

const ContactRowActions = memo(({ testid, displayStatus, contactId }) => {
  const { userId } = useContext(AuthContext);
  const { update } = useContact(userId, contactId);

  const handleUpdateContactDisplayStatus = displayStatus => {
    update({
      displayStatus,
      updatedTimestamp: Math.floor(Date.now() / 1000),
    });
  };

  const actionButtonClassNames =
    'hover:bg-opacity-10 transition-all font-bold text-sm h-[74px] w-[74px] flex flex-col items-center justify-center';

  const starButton = (
    <button
      className={`${actionButtonClassNames} text-softPurple hover:bg-softPurple`}
      onClick={() => handleUpdateContactDisplayStatus('Starred')}
      data-testid={`${testid}.starButton`}
    >
      <MdStar size="21px" />
      <span>Star</span>
    </button>
  );
  const unStarButton = (
    <button
      className={`${actionButtonClassNames} text-softPurple hover:bg-softPurple`}
      onClick={() => handleUpdateContactDisplayStatus('Active')}
      data-testid={`${testid}.unstarButton`}
    >
      <MdStarBorder size="21px" />
      <span>Un-star</span>
    </button>
  );
  const hideButton = (
    <button
      className={`${actionButtonClassNames} text-coolGrey hover:bg-coolGrey`}
      onClick={() => handleUpdateContactDisplayStatus('Hidden')}
      data-testid={`${testid}.hideButton`}
    >
      <MdPersonOff size="21px" />
      <span>Hide</span>
    </button>
  );
  const unhideButton = (
    <button
      className={`${actionButtonClassNames} text-coolGrey hover:bg-coolGrey`}
      onClick={() => handleUpdateContactDisplayStatus('Active')}
      data-testid={`${testid}.unhideButton`}
    >
      <MdPerson size="21px" />
      <span>Un-hide</span>
    </button>
  );

  return (
    <ul className="flex">
      {displayStatus === 'Starred' ? (
        <>
          <li>{unStarButton}</li>
          <li>{hideButton}</li>
        </>
      ) : displayStatus === 'Hidden' ? (
        <>
          <li>{starButton}</li>
          <li>{unhideButton}</li>
        </>
      ) : (
        <>
          <li>{starButton}</li>
          <li>{hideButton}</li>
        </>
      )}
    </ul>
  );
});

const ContactRow = memo(
  ({
    testid,
    contactId,
    fullName,
    company,
    note = '',
    notesCount = 0,
    email,
    phoneNumber,
    displayStatus,
  }) => {
    const rowClassNames = 'h-12 md:h-[74px] flex items-center';
    const rowClassNamesMobile = 'hidden md:flex';

    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
      setIsHovering(true);
    };

    const totalNotesCount = note?.length ? notesCount + 1 : notesCount;

    return (
      <div
        className="bg-white relative block transition-all overflow-hidden group md:px-8 border border-white md:hover:bg-paleGreen md:hover:border-paleTeal md:hover:pr-[148px]"
        onMouseOver={handleMouseOver}
        data-testid={testid}
      >
        {/* Contact Row */}
        <Link
          className="md:grid grid-cols-8"
          to={{
            pathname: `/contacts/${contactId}`,
            state: {
              contactFullName: getContactDisplayName(
                fullName,
                company,
                email,
                phoneNumber
              ),
              contactNativeNote: note,
            },
          }}
        >
          <div
            className={`${rowClassNames} col-span-3`}
            data-testid={`${testid}.nameAndAvatar`}
          >
            <CMAvatar
              size="sm"
              displayStatus={displayStatus}
              isCompany={!fullName.length}
            />
            <div className="truncate pl-3">
              {getContactDisplayName(fullName, company, email, phoneNumber)}
            </div>
          </div>
          <div
            className={`${rowClassNames} ${rowClassNamesMobile}`}
            data-testid={`${testid}.noteCount`}
          >
            <span className="truncate">
              {totalNotesCount} {totalNotesCount === 1 ? 'Note' : 'Notes'}
            </span>
          </div>
          <div
            className={`${rowClassNames} ${rowClassNamesMobile} col-span-2`}
            data-testid={`${testid}.email`}
          >
            <span className="truncate">{email || '-'}</span>
          </div>
          <div
            className={`${rowClassNames} ${rowClassNamesMobile} col-span-2`}
            data-testid={`${testid}.phoneNummber`}
          >
            <span className="truncate">{phoneNumber || '-'}</span>
          </div>
        </Link>

        {/* Row Actions */}
        <div className="absolute transition-all right-[-148px] top-0 bg-white md:group-hover:right-0">
          {/* We only want to render the component on hover to avoid unnecessary renders */}
          {isHovering ? (
            <ContactRowActions
              displayStatus={displayStatus}
              contactId={contactId}
              testid={`${testid}.actions`}
            />
          ) : null}
        </div>
      </div>
    );
  }
);

export default ContactRow;
