import React, { useContext } from 'react';
import { MdWarning } from 'react-icons/md';
import { Link } from 'react-router-dom';

import Breadcrumb from '../../components/breadcrumb/breadcrumb.component';
import ChangePassword from '../../components/change-password/change-password.component';
import CMButton from '../../components/cm-button/cm-button.component';
import CMTextButton from '../../components/cm-text-button/cm-text-button.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import AccountDetails from './account-details.component';
import ExportYourData from './export-your-data.component';

import useActiveSubscriptionData from '../../hooks/useActiveSubscriptionData';
import useFeaturesSwitch from '../../hooks/useFeaturesSwitch';

import { AuthContext } from '../../context/auth/auth.context';
import {
  ISnackbarContext,
  SnackbarContext,
} from '../../context/snackbar/snackbar.context';

import { formatDate } from '../../utils/dates.utils';

const Account: React.FC<{}> = (): JSX.Element => {
  const { members_portal_migration } = useFeaturesSwitch();

  const { showSnackbar } = useContext<ISnackbarContext>(SnackbarContext);
  const {
    userData: { id, role, state, until },
    authToken,
    updateUserData,
    isPremium,
  } = useContext(AuthContext);

  const isPermanent: boolean = state === 'Permanent';
  const isSuspended: boolean = state === 'Suspended';
  const isNotRenewing: boolean = state === 'NotRenewing';

  const { loadingSubscription, activeSubscriptionData } =
    useActiveSubscriptionData();

  const handleSubmitPassword = () => (password: string) =>
    updateUserData({ user: { password }, id })
      .then(() => {
        showSnackbar({
          severity: 'success',
          message: 'Password reset succesful.',
        });
      })
      .catch(() => {
        showSnackbar({
          severity: 'error',
          message: 'Something wrong happened saving your password.',
        });
      });

  if (loadingSubscription)
    return <WebAppLayoutLoading darkBackground hiddenSidebar />;

  return (
    <WebAppLayout darkBackground hiddenSidebar digitalBussinessCard>
      <Breadcrumb color="light" label="App" to="/" />
      <h1 className="text-2xl md:text-5xl font-bold">Your Account</h1>

      <AccountDetails />

      {/* Password */}
      <div className="mt-8 md:mt-16 bg-white text-slate rounded-lg p-8">
        <h3 className="text-3xl font-bold pb-8">Password</h3>
        <div className="md:grid grid-cols-2 gap-8 space-y-8 md:space-y-0">
          <ChangePassword
            passwordLabel="New Password"
            dialogTitle="Update your password"
            showHelper
            renderButton={handleOnClick => (
              <CMButton
                data-testid="yourAccount.changePassword.changePasswordButton"
                onClick={handleOnClick}
                color="tertiary"
              >
                Change your password
              </CMButton>
            )}
            submitMethod={handleSubmitPassword()}
            testid="yourAccount.changePassword"
            validateCurrentPassword
          />
        </div>
      </div>

      {/* Subscription */}
      <div className="mt-8 md:mt-16 bg-white text-slate rounded-lg p-8">
        <h3 className="text-3xl font-bold pb-8">Your Subscription</h3>

        {activeSubscriptionData ? (
          <div className="md:grid grid-cols-2 gap-8 space-y-4 md:space-y-0 md:text-xl">
            <div>
              <strong className="uppercase">Plan:</strong>
              <br />
              {activeSubscriptionData.user_role}
            </div>
            <div>
              <strong className="uppercase">Billing period:</strong>
              <br />
              {activeSubscriptionData.periodicity}
            </div>
            <div>
              <strong className="uppercase">Payment method:</strong>
              <br />
              {activeSubscriptionData.subscription_gateway === 'Stripe'
                ? `Card ending in: ${activeSubscriptionData.last4}`
                : 'Apple Pay'}
            </div>
            <div>
              <strong className="uppercase">Next due:</strong>
              <br />
              {formatDate(new Date(`${until}T00:00:00`), false)}
            </div>
            {isSuspended ? (
              <div>
                <strong className="uppercase">Status:</strong>
                <br />
                {state}
              </div>
            ) : null}
          </div>
        ) : (
          <div className="md:grid grid-cols-3 gap-8 space-y-8 md:space-y-0 text-xl">
            <div>
              <strong className="uppercase">Plan:</strong>
              <br />
              {role}
            </div>
            <div>
              <strong className="uppercase">State:</strong>
              <br />
              {isPremium ? state : '-'}
            </div>
            {isPermanent ? null : (
              <div>
                <strong className="uppercase">Until:</strong>
                <br />
                {isPremium
                  ? formatDate(new Date(`${until}T00:00:00`), false)
                  : '-'}
              </div>
            )}
          </div>
        )}

        <div className="md:grid grid-cols-2 gap-8 pt-8 space-y-8 md:space-y-0">
          {isPermanent ? null : members_portal_migration ? (
            isPremium || isSuspended ? (
              activeSubscriptionData?.subscription_gateway === 'Apple' ||
              isNotRenewing ? (
                <div className="col-span-2 bg-ivory border-2 border-sahara p-4 rounded flex items-center">
                  <MdWarning className="text-sahara" size="30px" />
                  <span className="text-lg pl-4">
                    {isNotRenewing
                      ? 'You have cancelled your subscription.'
                      : 'You are subscribed with your Apple account.'}
                    <br />
                    {isNotRenewing
                      ? `You will have a Basic account starting on ${formatDate(
                          new Date(`${until}T00:00:00`),
                          false
                        )}`
                      : 'Tap on Settings ⚙️ in your mobile app to manage your subscription.'}
                  </span>
                </div>
              ) : (
                <div>
                  <CMButton
                    component={Link}
                    fullWidth
                    color="secondary"
                    to="subscription"
                  >
                    Manage your subscription
                  </CMButton>
                </div>
              )
            ) : (
              <>
                <div>
                  <CMButton
                    component={Link}
                    fullWidth
                    color="secondary"
                    to="/become-pro"
                  >
                    Upgrade to PRO
                  </CMButton>
                </div>
                <div>
                  <CMTextButton
                    component={Link}
                    to="/select-your-plan"
                    color="secondary"
                  >
                    Compare plans
                  </CMTextButton>
                </div>
              </>
            )
          ) : (
            <div>
              <CMButton
                fullWidth
                color="secondary"
                component="a"
                href={`${process.env.REACT_APP_MEMBERS_PORTAL_URL}/manage-account/${authToken}`}
              >
                {!isPremium ? 'Compare plans' : 'Manage your subscription'}
              </CMButton>
            </div>
          )}
        </div>
      </div>
      <ExportYourData />
    </WebAppLayout>
  );
};

export default Account;
