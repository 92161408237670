import React, { memo, useContext, useRef, useState } from 'react';
import { MdCheckCircle } from 'react-icons/md';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import CMButton from '../../../components/cm-button/cm-button.component';
import CMDialog from '../../../components/cm-dialog/cm-dialog.component';
import CMTextButton from '../../../components/cm-text-button/cm-text-button.component';

import { AuthContext } from '../../../context/auth/auth.context';
import { SnackbarContext } from '../../../context/snackbar/snackbar.context';

import { exportData } from '../../../services/users/users.service';

const AdminExportData = memo(({ fullName, userId, email, testid }) => {
  const { authToken } = useContext(AuthContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const formRef = useRef(null);

  const [emailAddress, setEmailAddress] = useState(email);
  const [savingUpdates, setSavingUpdates] = useState(false);
  const [saveRequestError, setSaveRequestError] = useState('');
  const [openExportDataDialogOpen, setOpenExportDataDialogOpen] =
    useState(false);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  const openExportDataDialog = () => {
    setEmailAddress(email);
    setOpenExportDataDialogOpen(true);
  };

  const handleRequestError = message => {
    showSnackbar({
      severity: 'error',
      message: 'Something wrong happened.',
    });

    if (message) {
      setSaveRequestError(message);
    }
  };

  const handleDataExportSubmit = () => {
    if (!emailAddress) return;

    setSavingUpdates(true);
    exportData(userId, emailAddress, authToken)
      .then(response => {
        if (response.status === 'N') {
          handleRequestError(response.output);
          return;
        }

        setOpenExportDataDialogOpen(false);
        setConfirmationDialogOpen(true);
      })
      .catch(handleRequestError)
      .finally(() => {
        setSavingUpdates(false);
      });
  };

  return (
    <>
      <CMTextButton
        data-testid={`${testid}.exportDataButton`}
        onClick={openExportDataDialog}
      >
        Export Data
      </CMTextButton>
      {/* Export data dialog */}
      <CMDialog
        width="xs"
        open={openExportDataDialogOpen}
        handleClose={() => setOpenExportDataDialogOpen(false)}
        title="Export data"
        renderContent={
          <ValidatorForm
            noValidate
            ref={formRef}
            onSubmit={handleDataExportSubmit}
          >
            <div className="space-y-6 mt-4">
              <p>Enter the email you want to send the export data to:</p>
              <TextValidator
                label="EMAIL"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                id="email"
                type="email"
                onChange={e => setEmailAddress(e.target.value)}
                value={emailAddress}
                required
                validators={['required', 'isEmail']}
                errorMessages={['This field is required', 'Not a valid email']}
              />
            </div>
            {saveRequestError && (
              <p className="mt-4 text-error font-bold">{saveRequestError}</p>
            )}
            <div className="space-y-4 md:space-y-0 md:flex md:space-x-4 mt-8">
              <CMButton
                data-testid="admin.exportData.cancelButton"
                fullWidth
                type="reset"
                onClick={() => {
                  setOpenExportDataDialogOpen(false);
                }}
              >
                Cancel
              </CMButton>
              <CMButton
                data-testid="admin.exportData.saveButton"
                loading={savingUpdates}
                type="submit"
                fullWidth
              >
                Send
              </CMButton>
            </div>
          </ValidatorForm>
        }
      />
      {/* Confirmation dialog */}
      <CMDialog
        width="sm"
        open={confirmationDialogOpen}
        handleClose={() => setConfirmationDialogOpen(false)}
        title="Export data"
        renderContent={
          <>
            <div className="pt-8 space-x-4 flex items-center">
              <MdCheckCircle size="45px" />
              <div>
                <div>
                  A copy of <strong>{fullName}’s</strong> data has been sent to{' '}
                  <strong>{emailAddress}</strong>.
                </div>
                <div className="py-4">
                  Remember: the process can take from 10 seconds to 5 minutes
                </div>
              </div>
            </div>
            <div className="text-right">
              <CMButton onClick={() => setConfirmationDialogOpen(false)}>
                Close
              </CMButton>
            </div>
          </>
        }
      />
    </>
  );
});

export default AdminExportData;
