import { CircularProgress } from '@mui/material';
import React from 'react';

export type TCMButtonProps = {
  children: string;
  color?: 'primary' | 'secondary' | 'tertiary';
  outline?: boolean;
  borderless?: boolean;
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
  fullWidth?: boolean;
  component?: string | React.ElementType;
  /** When `loading={true}`, `fullWidth={true}` is recommended to avoid spinner overlap */
  loading?: boolean;
  [x: string]: any;
};

const colorToClass = {
  primary: 'fadeOrange',
  secondary: 'softPurple',
  tertiary: 'blueGrey',
  disabled: 'coolGrey',
};

const sizeToClass = {
  sm: 'h-8',
  md: 'h-12',
  lg: 'h-16',
};

const sizeToFontSize = {
  sm: 'text-base',
  md: 'text-base',
  lg: 'text-xl',
};

const CMButton = ({
  children,
  color = 'primary',
  outline = false,
  borderless = false,
  size = 'md',
  disabled = false,
  fullWidth = false,
  component = 'button',
  loading = false,
  ...props
}: TCMButtonProps) => {
  const colorClass = !disabled ? colorToClass[color] : colorToClass['disabled'];
  const Component = component;

  const base =
    'relative focus:outline-none inline-flex items-center justify-center rounded-full px-6 uppercase font-bold transition whitespace-nowrap';
  const width = fullWidth && 'w-full';
  const border = !borderless && 'border-2';
  const bgColor = outline || borderless ? 'bg-white' : `bg-${colorClass}`;
  const borderColor = `border-${colorClass}`;
  const textColor = outline || borderless ? `text-${colorClass}` : 'text-white';
  const height = sizeToClass[size];
  const fontSize = sizeToFontSize[size];
  const cursor = disabled ? 'cursor-default' : 'cursor-pointer';
  const hover =
    (outline && !disabled) || (borderless && !disabled)
      ? `hover:bg-${colorClass} hover:bg-opacity-10`
      : !disabled
      ? 'hover:bg-opacity-80'
      : null;
  const focus =
    (outline && !disabled) || (borderless && !disabled)
      ? `focus:bg-${colorClass} focus:bg-opacity-10`
      : !disabled
      ? 'focus:bg-opacity-80'
      : null;

  return (
    <Component
      disabled={disabled}
      className={`${base} ${width} ${bgColor} ${border} ${borderColor} ${textColor} ${height} ${fontSize} ${cursor} ${hover} ${focus}`}
      {...props}
    >
      {loading ? (
        <CircularProgress
          size={30}
          color="inherit"
          className="absolute right-2"
        />
      ) : null}
      <span>{children}</span>
    </Component>
  );
};

export default CMButton;
