import React, { useContext } from 'react';

import CMButton from '../cm-button/cm-button.component';
import ContactAvatar from '../contact-avatar/contact-avatar.component';

import { AuthContext } from '../../context/auth/auth.context';

import Contact from '../../models/contact.model';

import { useContact } from '../../firebase/firebase.utils';

type IProps = {
  contactId: string;
  disableActions?: boolean;
  title: string;
  textareaPlaceholder: string;
  renderContent: React.ReactNode;
  noteBody: string;
  closeDialog: () => void;
  handleTouch: () => void;
  setNoteBody: React.Dispatch<React.SetStateAction<string>>;
};

const DateCompleteDialogContent: React.FC<IProps> = ({
  contactId,
  disableActions,
  title,
  textareaPlaceholder,
  renderContent,
  noteBody,
  closeDialog,
  handleTouch,
  setNoteBody,
}) => {
  const { userId } = useContext(AuthContext);

  const { data: dataContact } = useContact(userId, contactId);

  const onAvatarClick = (goToContactDetails: () => void) => {
    closeDialog();
    goToContactDetails();
  };

  const handleNoteBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setNoteBody(e.target.value);

  if (!dataContact) return null;

  const contact = new Contact(dataContact);

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold text-center">{title}</h2>
      <div className="flex items-center">
        <ContactAvatar
          onAvatarClick={onAvatarClick}
          contactId={contact?.id}
          contactDisplayStatus={contact?.displayStatus}
          contactName={contact?.displayName}
        />
      </div>
      <div className="border-t border-silver pt-6 space-y-6">
        <h2 className="text-xl font-bold text-left">
          Do you have anything to remember?
        </h2>
        {renderContent}
        <h2 className="text-xl font-bold text-left">
          Would you like to capture some details?
        </h2>
        <textarea
          autoFocus
          name="note"
          id="note"
          rows={3}
          placeholder={textareaPlaceholder}
          className="rounded border border-steel p-2 w-full"
          value={noteBody}
          onChange={handleNoteBodyChange}
        ></textarea>
      </div>
      <div className="border-b border-silver pb-4">
        <h2 className="text-xl font-bold text-left mb-6">Or was it simply a</h2>

        <CMButton outline onClick={handleTouch} disabled={disableActions}>
          Touch
        </CMButton>
      </div>
    </div>
  );
};

export default DateCompleteDialogContent;
