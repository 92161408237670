import React, { memo, useContext, useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import CMButton from '../../../components/cm-button/cm-button.component';
import CMDialog from '../../../components/cm-dialog/cm-dialog.component';
import CMTextButton from '../../../components/cm-text-button/cm-text-button.component';

import { AuthContext } from '../../../context/auth/auth.context';
import { SnackbarContext } from '../../../context/snackbar/snackbar.context';

import { updateUserDetails } from '../../../services/users/users.service';

// Creates an object containing only props that changed (`null` when empty)
const createPayload = (formData, initialValues) => {
  const payload = {};

  Object.keys(formData).forEach(label => {
    if (formData[label] !== initialValues[label]) {
      payload[label] = formData[label] === '' ? null : formData[label];
    }
  });

  return payload;
};

const AdminEditDetails = memo(({ userData, testid, onSuccess }) => {
  const { authToken } = useContext(AuthContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const formRef = useRef(null);

  const initialValues = {
    firstname: userData.firstname || '',
    lastname: userData.lastname || '',
    referrallink: userData.referrallink || '',
    email: userData.email || '',
    phonenumber: userData.phonenumber || '',
  };

  const [formData, setFormData] = useState(initialValues);
  const [savingUpdates, setSavingUpdates] = useState(false);
  const [saveRequestError, setSaveRequestError] = useState('');
  const [openEditDetailsDialogOpen, setOpenEditDetailsDialogOpen] =
    useState(false);

  const openEditDetailsDialog = () => {
    setOpenEditDetailsDialogOpen(true);
    setFormData(initialValues);
  };

  const handleChange = (event, key) => {
    setFormData({
      ...formData,
      [key]: event.target.value,
    });
  };

  const handleRequestError = message => {
    showSnackbar({
      severity: 'error',
      message: 'Something wrong happened.',
    });

    if (message) {
      setSaveRequestError(message);
    }
  };

  const handleUpdateDetailsSubmit = () => {
    if (!formData.firstname || !formData.email) return;

    const payload = createPayload(formData, initialValues);

    if (Object.keys(payload).length === 0) {
      // If nothing changed just close the modal
      setOpenEditDetailsDialogOpen(false);
      return;
    }

    setSavingUpdates(true);
    updateUserDetails(userData.userId, payload, authToken)
      .then(response => {
        if (response.status === 'N') {
          handleRequestError(response.output);
          return;
        }

        showSnackbar({
          severity: 'success',
          message: 'User info update succesful.',
        });

        onSuccess(formData);
        setOpenEditDetailsDialogOpen(false);
      })
      .catch(handleRequestError)
      .finally(() => {
        setSavingUpdates(false);
      });
  };

  return (
    <>
      <CMTextButton
        data-testid={`${testid}.editDetailsButton`}
        onClick={openEditDetailsDialog}
      >
        Edit Details
      </CMTextButton>
      <CMDialog
        width="sm"
        title="Edit details"
        renderContent={
          <ValidatorForm
            noValidate
            ref={formRef}
            onSubmit={handleUpdateDetailsSubmit}
          >
            <div className="pt-4 space-y-4 md:space-y-0 md:grid grid-cols-2 gap-4">
              <TextValidator
                label="FIRST NAME"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                id="firstname"
                type="text"
                onChange={e => handleChange(e, 'firstname')}
                value={formData.firstname}
                required
                validators={['required']}
                errorMessages={['This field is required']}
              />
              <TextValidator
                label="LAST NAME"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                id="lastname"
                type="text"
                onChange={e => handleChange(e, 'lastname')}
                value={formData.lastname}
              />
              <TextValidator
                label="REFERRAL LINK"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                id="referrallink"
                fullWidth
                type="text"
                onChange={e => handleChange(e, 'referrallink')}
                value={formData.referrallink}
              />
              <TextValidator
                label="MY PHONE"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                id="phonenumber"
                fullWidth
                type="text"
                onChange={e => handleChange(e, 'phonenumber')}
                value={formData.phonenumber}
              />
              <TextValidator
                label="MY EMAIL"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                id="email"
                type="email"
                onChange={e => handleChange(e, 'email')}
                value={formData.email}
                required
                validators={['required', 'isEmail']}
                errorMessages={['This field is required', 'Not a valid email']}
              />
            </div>
            {saveRequestError && (
              <p className="mt-4 text-error font-bold">{saveRequestError}</p>
            )}
            <div className="space-y-4 md:space-y-0 md:flex md:space-x-4 mt-8">
              <CMButton
                data-testid="admin.updateDetails.cancelButton"
                fullWidth
                type="reset"
                onClick={() => {
                  setOpenEditDetailsDialogOpen(false);
                }}
              >
                Cancel
              </CMButton>
              <CMButton
                data-testid="admin.updateDetails.saveButton"
                loading={savingUpdates}
                type="submit"
                fullWidth
              >
                Save
              </CMButton>
            </div>
          </ValidatorForm>
        }
        open={openEditDetailsDialogOpen}
        handleClose={() => setOpenEditDetailsDialogOpen(false)}
      />
    </>
  );
});

export default AdminEditDetails;
