import React, { memo, useContext, useEffect, useState } from 'react';

import Growler, {
  GrowlerTypes,
  IGrowlerProps,
} from '../growler/growler.component';

import useActiveSubscriptionData from '../../hooks/useActiveSubscriptionData';

import { AuthContext } from '../../context/auth/auth.context';

const SubscriptionNotifications: React.FC<{}> = memo(() => {
  const { userData } = useContext(AuthContext);

  const { loadingSubscription, activeSubscriptionData } =
    useActiveSubscriptionData();

  const [notification, setNotification] = useState<IGrowlerProps | null>(null);

  useEffect(() => {
    if (!userData) return;

    const isOverdue: boolean = userData.state === 'Overdue';
    const isSuspended: boolean = userData.state === 'Suspended';

    if (!isOverdue && !isSuspended) return;

    const lastNotificationShown = localStorage.getItem(
      `lastStateNotificationDismiss`
    );
    const currentTime: number = new Date().getTime();
    const last24Hours: number = currentTime - 3600000 * 4;
    const wasNotificationDismissed: boolean =
      !!lastNotificationShown && last24Hours < Number(lastNotificationShown);

    if (wasNotificationDismissed) return;

    setNotification({
      action: {
        label: 'Update Card',
        to: `/manage-account${
          activeSubscriptionData?.subscription_gateway === 'Stripe'
            ? '/subscription/update-credit-card'
            : ''
        }`,
      },
      type: isSuspended ? GrowlerTypes.Danger : GrowlerTypes.Info,
      title: isSuspended
        ? 'We were unable to charge your credit card on file and so your Contact Mapping Pro access is currently suspended.'
        : 'There has been a problem with your credit card.',
      content: isSuspended
        ? 'Update your card details right away to restore access to your full list of Notes and Follow-Ups and other exclusive Pro benefits.'
        : 'Your subscription will be canceled unless you update the card linked to your Contact Mapping subscription.',
      handleDismiss: () => {
        setNotification(null);

        localStorage.setItem(
          `lastStateNotificationDismiss`,
          new Date().getTime().toString()
        );
      },
    });

    return () => setNotification(null);
  }, [activeSubscriptionData, userData]);

  if (loadingSubscription) {
    return null;
  }

  return notification && <Growler {...notification} />;
});

export default SubscriptionNotifications;
