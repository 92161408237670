import { Menu, MenuItem } from '@mui/material';
import React, { memo, useState } from 'react';
import { HiDotsVertical } from 'react-icons/hi';

import { contactInfoSocialMedia } from '../../utils/networks';

const CMContactSocialProfiles: React.FC<{
  socialProfiles: any[];
  loading?: boolean;
  visibleIcons?: number;
  size?: 'sm' | 'md';
  fullWidth?: boolean;
  useGrid?: boolean;
  testid?: string;
}> = memo(
  ({
    loading = false,
    socialProfiles = [],
    size = 'md',
    fullWidth,
    visibleIcons = contactInfoSocialMedia.length,
    useGrid = false,
    testid = '',
  }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = event => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const baseClassName =
      'inline-flex rounded-full items-center justify-center transition text-white';
    const sizeClassName = size === 'sm' ? 'h-8 w-8' : 'h-12 w-12';

    const visibleIconsActive =
      Number.isInteger(visibleIcons) &&
      visibleIcons < contactInfoSocialMedia.length;

    // `visibleIcons < contactInfoSocialMedia.length` above and `visibleIcons - 1`
    // below accounts for treating the "Show more button" as another icon

    const iconProfiles = visibleIconsActive
      ? contactInfoSocialMedia.slice(0, visibleIcons - 1)
      : contactInfoSocialMedia;
    const menuProfiles = visibleIconsActive
      ? contactInfoSocialMedia.slice(
          visibleIcons - 1,
          contactInfoSocialMedia.length
        )
      : [];

    return (
      <div
        className={
          useGrid
            ? `grid gap-4 ${
                size === 'sm'
                  ? 'grid-cols-[repeat(5,_minmax(0,_32px))]'
                  : 'grid-cols-[repeat(5,_minmax(0,_48px))]'
              }`
            : `flex ${fullWidth ? 'justify-between' : 'space-x-2 flex-wrap'}`
        }
        data-testid={`${testid}.socialProfiles.${
          loading ? 'loading' : 'container'
        }`}
      >
        {iconProfiles.map(({ name, icon, data }, index) => {
          const { networkUrl } = data(socialProfiles);

          if (loading) {
            return (
              <div
                key={`network-${index}`}
                className={`${baseClassName} ${sizeClassName} bg-coolGrey animate-pulse`}
              ></div>
            );
          }

          if (!networkUrl) {
            return (
              <div
                key={`network-${index}`}
                className={`${baseClassName} ${sizeClassName} bg-coolGrey`}
                data-testid={`${testid}.socialProfiles.${name}Icon`}
              >
                <div className={size === 'sm' ? 'scale-75' : ''}>{icon}</div>
              </div>
            );
          }

          return (
            <a
              key={`network-${index}`}
              target="_blank"
              href={networkUrl}
              className={`${baseClassName} ${sizeClassName} bg-slate hover:opacity-95`}
              rel="noreferrer"
              data-testid={`${testid}.socialProfiles.${name}IconButton`}
            >
              <div className={size === 'sm' ? 'scale-75' : ''}>{icon}</div>
            </a>
          );
        })}

        {menuProfiles.length ? (
          loading ? (
            <div
              className={`${baseClassName} ${sizeClassName} bg-coolGrey animate-pulse`}
            ></div>
          ) : (
            <>
              {/* Show more button */}
              <button
                className={`${baseClassName} ${sizeClassName} bg-slate`}
                data-testid={`${testid}.socialProfiles.showMoreButton`}
                aria-controls={open ? 'social-profiles-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <div className={size === 'sm' ? 'scale-75' : ''}>
                  <HiDotsVertical size="24px" />
                </div>
              </button>
              <Menu
                id="social-profiles-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'social-profiles-menu-button',
                }}
                PaperProps={{
                  sx: {
                    mt: 1.5, // adds margin between button and menu
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                {menuProfiles.map(({ name, icon, data }, index) => {
                  const { networkUrl } = data(socialProfiles);
                  return (
                    <MenuItem
                      key={`networkMenuItem-${index}`}
                      disabled={!networkUrl}
                    >
                      <a
                        target="_blank"
                        href={networkUrl}
                        rel="noreferrer"
                        data-testid={`${testid}.socialProfiles.showMoreMenu.${name}MenuItem`}
                        className="py-1 flex"
                      >
                        <span className="w-32">{name}</span>
                        {icon}
                      </a>
                    </MenuItem>
                  );
                })}
              </Menu>
            </>
          )
        ) : null}
      </div>
    );
  }
);

export default CMContactSocialProfiles;
