import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { updateProfile } from '../../services/auth/auth.service';

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [data, setData] = useState({
    userData: null,
    authToken: JSON.parse(localStorage.getItem('authToken')),
    settings: null,
  });

  const navigate = useNavigate();

  const performCommonSignInTasks = (userData, authToken, settings) => {
    setData(prevState => ({
      userData,
      authToken,
      settings: settings || prevState.settings,
    }));
    localStorage.setItem('authToken', JSON.stringify(authToken));
  };

  const handleAuthTokenError = () => {
    localStorage.removeItem('authToken');
    setAuthToken(null);
  };

  const updateUserData = userData =>
    updateProfile(userData, data.authToken).then(userData => {
      setData(prevState => ({ ...prevState, userData }));
    });

  const setAuthToken = authToken =>
    setData(prevState => ({ ...prevState, authToken }));

  const signOut = () => {
    setData({ userData: null, authToken: null, settings: null });
    localStorage.removeItem('authToken');
    navigate('/signin');
  };

  return (
    <AuthContext.Provider
      value={{
        userData: data.userData,
        userId: data.userData?.id,
        isPremium: data.userData?.role === 'Premium',
        authToken: data.authToken,
        settings: data.settings,
        performCommonSignInTasks,
        handleAuthTokenError,
        updateUserData,
        setAuthToken,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
