import { Popover } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { BsCheck2 } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import { Link } from 'react-router-dom';

import CMButton from '../../components/cm-button/cm-button.component';
import CMToggle from '../../components/cm-toggle/cm-toggle.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import { SnackbarContext } from '../../context/snackbar/snackbar.context';

import { getPlans } from '../../services/plans/plans.service';

const PlanSelector = () => {
  const { showSnackbar } = useContext(SnackbarContext);

  const [loadingPlans, setLoadingPlans] = useState(false);
  const [proPlanPrice, setProPlanPrice] = useState(null);
  const [billPeriod, setBillPeriod] = useState('annually');
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [openPopover, setOpenPopover] = useState({});
  const [annualPrice, setAnnualPrice] = useState(null);
  const [monthlyPrice, setMonthlyPrice] = useState(null);

  const CheckIcon = <BsCheck2 size="28px" className="text-fadeOrange m-auto" />;
  const CloseIcon = <IoMdClose size="28px" className="m-auto" />;

  const planDetails = [
    {
      label: 'Apps (Mobile and web)',
      isTitle: true,
    },
    {
      label: 'Follow-Ups and Reminders',
      basicPlanValue: 'Limited',
      proPlanValue: CheckIcon,
    },
    {
      label: 'Rotations of Remember',
      basicPlanValue: 'Limited',
      proPlanValue: CheckIcon,
    },
    {
      label: 'Notes',
      basicPlanValue: 'Limited',
      proPlanValue: CheckIcon,
    },
    {
      label: 'Social media integration',
      basicPlanValue: CheckIcon,
      proPlanValue: CheckIcon,
    },
    {
      label: 'Stats',
      basicPlanValue: 'Basic',
      proPlanValue: 'Full',
    },
    {
      label: 'Onboarding',
      isTitle: true,
    },
    {
      label: 'Group onboarding',
      basicPlanValue: CheckIcon,
      proPlanValue: CheckIcon,
    },
    {
      label: 'Personalized onboarding',
      basicPlanValue: CloseIcon,
      proPlanValue: CheckIcon,
    },
    {
      label: 'Community',
      isTitle: true,
    },
    {
      label: 'Open community',
      basicPlanValue: CheckIcon,
      proPlanValue: CheckIcon,
    },
    {
      label: 'Pro community',
      basicPlanValue: CloseIcon,
      proPlanValue: CheckIcon,
    },
    {
      label: 'Training and events',
      isTitle: true,
    },
    {
      label: 'Office Hours - Live Q&A',
      basicPlanValue: CheckIcon,
      proPlanValue: CheckIcon,
    },
    {
      label: 'Weekly Business Trainings',
      basicPlanValue: CloseIcon,
      proPlanValue: CheckIcon,
    },
    {
      label: 'Daily Business Accelerator',
      basicPlanValue: CheckIcon,
      proPlanValue: CheckIcon,
    },
    {
      label: 'Digital Business Card',
      isTitle: true,
    },
    {
      label: 'Your own personal page',
      basicPlanValue: CheckIcon,
      proPlanValue: CheckIcon,
    },
    {
      label: 'Customizable URL',
      basicPlanValue: CloseIcon,
      proPlanValue: CheckIcon,
    },
    {
      label: 'Extras: introduce yourself',
      basicPlanValue: CloseIcon,
      proPlanValue: CheckIcon,
    },
    {
      label: 'Integrations',
      isTitle: true,
    },
    {
      label: 'Chrome Extension',
      basicPlanValue: CloseIcon,
      proPlanValue: CheckIcon,
    },
  ];

  useEffect(() => {
    setLoadingPlans(true);
    getPlans()
      .then(response => {
        const annualPlan = response?.find(plan => plan.interval === 'year');
        const monthlyPlan = response?.find(plan => plan.interval === 'month');

        if (!annualPlan || !monthlyPlan) return;

        const planPriceAnnually = annualPlan.amount / 100;

        setAnnualPrice(planPriceAnnually);
        setMonthlyPrice(monthlyPlan.amount / 100);
        setProPlanPrice(Math.trunc((planPriceAnnually / 12) * 100) / 100);
      })
      .catch(() => {
        showSnackbar({
          severity: 'error',
          message: 'Something went wrong.',
        });
      })
      .finally(() => setLoadingPlans(false));
  }, [showSnackbar]);

  useEffect(() => {
    const selectedPeriodPrice =
      billPeriod === 'annually' ? annualPrice / 12 : monthlyPrice;
    setProPlanPrice(Math.trunc(selectedPeriodPrice * 100) / 100);
  }, [billPeriod, setProPlanPrice, annualPrice, monthlyPrice]);

  if (loadingPlans)
    return <WebAppLayoutLoading darkBackground hiddenSidebar hiddenHeader />;

  return (
    <WebAppLayout
      darkBackground
      digitalBussinessCard
      hiddenHeader
      hiddenSidebar
    >
      <div className="mt-8 md:mt-16 bg-white text-slate rounded-lg p-8">
        <h3 className="text-3xl font-bold pb-8 text-center">
          Select your plan
        </h3>

        <div className="grid grid-cols-3 space-x-2">
          <div className="flex bg-purple-200 flex-col pb-4 col-span-3 md:col-span-1">
            <div className="grow"></div>
            <div>
              <span>Billed: </span>
              <CMToggle
                onToggleChange={val => setBillPeriod(val)}
                selected={billPeriod}
                options={[
                  {
                    value: 'annually',
                    label: 'Annually',
                  },
                  {
                    value: 'monthly',
                    label: 'Monthly',
                  },
                ]}
              />
            </div>
          </div>
          <div className="text-center space-y-4 p-2 md:p-4 flex flex-col break-all col-start-2">
            <h5>Basic</h5>
            <p className="grow">
              <span className="font-bold text-2xl">$0</span>USD
            </p>
            <CMButton color="tertiary" component={Link} to="/welcome">
              Select
            </CMButton>
          </div>
          <div className="bg-gray-100 text-center space-y-4 p-2 md:p-4 flex flex-col break-all">
            <h5>Pro</h5>
            <p className="grow">
              <span className="font-bold text-2xl">${proPlanPrice}</span>USD{' '}
              <br />
              /month
            </p>
            <CMButton component={Link} to="/billing-period-payment">
              Select
            </CMButton>
          </div>
        </div>

        {planDetails.map((feature, index) => {
          const { isTitle, label, popoverInfo, basicPlanValue, proPlanValue } =
            feature;

          if (isTitle) {
            return (
              <div
                key={index}
                className="bg-slate w-full text-white uppercase font-bold p-2"
              >
                {label}
              </div>
            );
          }

          return (
            <div key={index} className="flex pl-2">
              <div
                className={`w-1/3 py-6 ${
                  popoverInfo && 'underline cursor-pointer'
                }`}
                onClick={e => {
                  setPopoverAnchorEl(e.currentTarget);
                  setOpenPopover({ [index]: true });
                }}
              >
                {label}
              </div>
              <div className="w-1/3 py-6 text-center">{basicPlanValue}</div>
              <div className="w-1/3 py-6 pr-2 text-center bg-gray-100 font-bold">
                {proPlanValue}
              </div>
              {popoverInfo && (
                <Popover
                  open={!!openPopover[index]}
                  anchorEl={popoverAnchorEl}
                  onClose={() => {
                    setPopoverAnchorEl(null);
                    setOpenPopover({ [index]: false });
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  {popoverInfo}
                </Popover>
              )}
            </div>
          );
        })}
      </div>
    </WebAppLayout>
  );
};

export default PlanSelector;
