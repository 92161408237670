import React, { useContext } from 'react';
import { MdInfoOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';

import CMButton from '../cm-button/cm-button.component';

import useFeaturesSwitch from '../../hooks/useFeaturesSwitch';

import { AuthContext } from '../../context/auth/auth.context';
import { PaywallContext } from '../../context/paywall/paywall.context';
import { RemoteConfigContext } from '../../context/remote-config/remote-config.context';

const PaywallToast = ({ notesOnly = false }) => {
  const { members_portal_migration } = useFeaturesSwitch();

  const { authToken, userData } = useContext(AuthContext);
  const {
    configValues: { paywall_max_followups, paywall_max_notes },
  } = useContext(RemoteConfigContext);
  const { hasReachedMaxNotes, hasReachedMaxFollowUps } =
    useContext(PaywallContext);

  if (hasReachedMaxNotes && !hasReachedMaxFollowUps && notesOnly)
    return (
      <div className="flex bg-paleGreen border border-paleTeal rounded mb-16 p-4">
        <MdInfoOutline size="35px" />
        <div className="pl-4 text-lg">
          You reached {paywall_max_notes} notes, your older notes will be
          archived.
          <br />
          Upgrade to access old notes.
        </div>
      </div>
    );

  if (hasReachedMaxFollowUps)
    return (
      <div className="bg-darkSlate bg-opacity-90 rounded-lg p-4 flex items-center mb-16 flex-col space-y-4 md:flex-row md:space-y-0">
        <div className="text-white flex-shrink-0 flex-grow">
          <p className="text-2xl font-bold pb-4">
            You reached free account limit
          </p>
          <p>You can have {paywall_max_followups} outstanding follow-ups</p>
        </div>
        {hasReachedMaxNotes ? (
          <div className="flex bg-paleGreen border border-paleTeal rounded max-w-sm p-4 mr-4">
            <MdInfoOutline size="35px" />
            <div className="pl-4 text-sm">
              You reached {paywall_max_notes} notes, your older notes will be
              archived.
              <br />
              Upgrade to access old notes.
            </div>
          </div>
        ) : null}
        {members_portal_migration ? (
          <CMButton
            component={Link}
            to={
              userData.state === 'Suspended'
                ? '/manage-account/subscription/update-credit-card'
                : '/select-your-plan'
            }
          >
            Go Pro
          </CMButton>
        ) : (
          <CMButton
            component="a"
            href={`${process.env.REACT_APP_MEMBERS_PORTAL_URL}/manage-account/${authToken}`}
          >
            Go Pro
          </CMButton>
        )}
      </div>
    );

  return null;
};

export default PaywallToast;
