import { Tab, Tabs } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { memo, useContext, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import PaywallToast from '../../components/paywall-toast/paywall-toast.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import ContactDetailsViewEdit from './contact-details-view-edit.component';
import ContactFollowUps from './contact-follow-ups.component';
import ContactInfo from './contact-info.component';
import ContactNotes from './contact-notes.component';

import { AuthContext } from '../../context/auth/auth.context';

import { useContact } from '../../firebase/firebase.utils';

const TabPanel = ({ children, value, index, ...props }) => {
  return (
    <div
      className="bg-white"
      role="tabpanel"
      hidden={value !== index}
      {...props}
    >
      {value === index && children}
    </div>
  );
};

const ContactTabs = memo(({ nativeNote, activeTab, setActiveTab }) => (
  <>
    <div className="border-b border-silver bg-white">
      <Tabs
        value={activeTab}
        onChange={(e, tabIndex) => setActiveTab(tabIndex)}
        variant="fullWidth"
        aria-label="contact tabs"
      >
        <Tab label="Notes" data-testid="contactDetails.tabs.notes" />
        <Tab label="Follow-ups" data-testid="contactDetails.tabs.followUps" />
        <Tab label="Details" data-testid="contactDetails.tabs.details" />
      </Tabs>
    </div>
    <TabPanel value={activeTab} index={0}>
      <ContactNotes nativeNote={nativeNote} />
    </TabPanel>
    <TabPanel value={activeTab} index={1}>
      <ContactFollowUps />
    </TabPanel>
    <TabPanel value={activeTab} index={2}>
      <ContactDetailsViewEdit />
    </TabPanel>
  </>
));

const ContactDetails = memo(() => {
  const { userId } = useContext(AuthContext);
  const { contactId } = useParams();

  const { state } = useLocation();
  const matchesMd = useMediaQuery(theme => theme.breakpoints.up('md'));

  const [activeTab, setActiveTab] = useState(0);

  // When navigating through the web app, users will get to this route through
  // the /contacts page. In that case there will always be `state` (check `Link`
  // in src/pages/contacts/contact-row.component.jsx). That state is in charge
  // of populating the name in the `<h1>` and we now for a fact that the contact
  // exists.
  // If `state` is `undefined` it means the user got to this route on it's own,
  // For example: by copy/pasting the URL from somewhere else. If this is the
  // case we'll check against Firestore that the contact exists and bring it's
  // data (`dataContact`), in case it doesn't exist we'll just redirect to /404
  const { data: dataContact } = useContact(
    !Object.keys(state || {}).length ? userId : null,
    contactId
  );

  if (!state && !dataContact)
    return <WebAppLayoutLoading testid="contactDetailsPage" />;
  if (!state && !dataContact.exists) return <Navigate replace to="/404" />;

  return (
    <WebAppLayout>
      <PaywallToast />
      <h1 className="hidden md:block text-2xl md:text-5xl font-bold">
        {state?.contactFullName ??
          (dataContact.fullName || dataContact.company)}
      </h1>
      <div className="md:mt-16 lg:flex lg:space-x-8">
        {/* Info Card */}
        <div className="lg:w-96 lg:shrink-0">
          <ContactInfo setActiveTab={setActiveTab} />
        </div>
        {/* Tabs (removed for mobile) */}
        {matchesMd ? (
          <div className="hidden md:block flex-1 pt-8 lg:pt-0">
            <ContactTabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              nativeNote={state?.contactNativeNote || dataContact?.note || ''}
            />
          </div>
        ) : null}
      </div>
    </WebAppLayout>
  );
});

export default ContactDetails;
