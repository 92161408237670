import _uniqBy from 'lodash/uniqBy';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import FullCalendar, {
  EventApi,
  EventContentArg,
  ViewMountArg,
} from '@fullcalendar/react';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import rrulePlugin from '@fullcalendar/rrule';
import timeGridPlugin from '@fullcalendar/timegrid';

import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import CalendarEventDetails from './calendar-event-details.component';
import CalendarEventsSummary from './calendar-events-summary.component';

import useFeaturesSwitch from '../../hooks/useFeaturesSwitch';

import { AuthContext } from '../../context/auth/auth.context';
import { PaywallContext } from '../../context/paywall/paywall.context';
import { SharedDialoguesContext } from '../../context/shared-dialogues/shared-dialogues.context';

import SpecialDate from '../../models/special-dates.model';

import {
  useFollowUps,
  useRotations,
  useSpecialDates,
} from '../../firebase/firebase.utils';

import { EventTypes } from '../../utils/enums';

import './calendar.css';
import {
  CalendarViews,
  ICalendarEvent,
  getCalendarEvents,
} from './calendar.utils';

const CalendarPage: React.FC<{}> = (): JSX.Element => {
  const { userId } = useContext(AuthContext);
  const {
    openDailyEventsDialog,
    openFollowUpDialog,
    openSpecialDateViewDialog,
    openRotationViewDialog,
  } = useContext(SharedDialoguesContext);
  const {
    latestFollowUpsIDs,
    hasReachedMaxFollowUps,
    latestRotationsIDs,
    hasReachedMaxRotations,
  } = useContext(PaywallContext);

  const { data: dataFollowUps } = useFollowUps(userId);
  const { data: dataSpecialDates } = useSpecialDates(userId, false);
  const { data: dataRotations } = useRotations(userId);

  const { calendar_view } = useFeaturesSwitch();

  const [summaryEvents, setSummaryEvents] = useState<ICalendarEvent[]>([]);
  const [detailedEvents, setDetailedEvents] = useState<ICalendarEvent[]>([]);
  const [currentCalendarView, setCurrentCalendarView] = useState<CalendarViews>(
    CalendarViews.MONTH
  );

  useEffect(() => {
    if (!dataFollowUps || !dataSpecialDates) return;

    const availableFollowUps = hasReachedMaxFollowUps
      ? dataFollowUps.filter(({ id }) => latestFollowUpsIDs.includes(id))
      : dataFollowUps;

    const followUpsEvents = getCalendarEvents(
      availableFollowUps,
      EventTypes.FollowUp
    );

    const specialDatesEvents: ICalendarEvent[] = getCalendarEvents(
      dataSpecialDates.map(val => new SpecialDate(val)),
      EventTypes.SpecialDate
    );

    const availableRotations = hasReachedMaxRotations
      ? dataRotations?.filter(({ id }) => latestRotationsIDs.includes(id))
      : dataRotations;

    const rotationsEvents: ICalendarEvent[] = getCalendarEvents(
      availableRotations,
      EventTypes.Rotation
    );

    setSummaryEvents([
      ...followUpsEvents,
      ...specialDatesEvents,
      ...rotationsEvents,
    ]);
    setDetailedEvents([
      ...getDetailedEvents(followUpsEvents),
      ...getDetailedEvents(specialDatesEvents),
      ...getDetailedEvents(rotationsEvents),
    ]);
  }, [
    dataFollowUps,
    dataSpecialDates,
    dataRotations,
    hasReachedMaxFollowUps,
    latestFollowUpsIDs,
    hasReachedMaxRotations,
    latestRotationsIDs,
  ]);
  const getDetailedEvents = (
    calendarEvents: ICalendarEvent[]
  ): ICalendarEvent[] => {
    const detailedEvents: ICalendarEvent[] = [];

    calendarEvents.forEach((events: ICalendarEvent) => {
      events.detailedEvents?.forEach(e => detailedEvents.push(e.calendarEvent));
    });

    return _uniqBy(detailedEvents, 'id');
  };
  const handleEventClick = (e: EventApi) => {
    const isMonthView =
      e._context.calendarApi.view.type === CalendarViews.MONTH;

    const {
      eventType: eventsType,
      detailedEvents,
      eventData,
    } = e.extendedProps;

    if (isMonthView) {
      openDailyEventsDialog({
        date: e.start!.toLocaleString('en-us', {
          month: 'long',
          year: 'numeric',
          day: 'numeric',
        }),
        detailedEvents,
        eventsType,
      });

      return;
    }

    if (eventsType === EventTypes.FollowUp) {
      openFollowUpDialog({
        followUpId: eventData.id,
        contactId: eventData.contactId,
      });
      return;
    }

    if (eventsType === EventTypes.SpecialDate) {
      openSpecialDateViewDialog({
        specialDateId: eventData.id,
        contactId: eventData.contactId,
      });
      return;
    }

    openRotationViewDialog({
      rotationId: eventData.id,
      contactId: eventData.contactId,
    });
  };

  const renderEventContent = (e: EventContentArg): JSX.Element => {
    const viewType: string = e.view.type;
    const showSummary: boolean = viewType === CalendarViews.MONTH;

    return (
      <div
        onClick={() => handleEventClick(e.event)}
        className="space-x-2 flex items-center pl-2 cursor-pointer"
      >
        {showSummary ? (
          <CalendarEventsSummary
            title={e.event.title}
            eventType={e.event.extendedProps?.eventType}
            detailedEvents={e.event.extendedProps?.detailedEvents.length}
          />
        ) : (
          <CalendarEventDetails
            title={e.event.title}
            eventType={e.event.extendedProps?.eventType}
            contactId={e.event.extendedProps?.eventData?.contactId}
          />
        )}
      </div>
    );
  };

  if (!calendar_view) return <Navigate replace to="/" />;

  if (!summaryEvents) return <WebAppLayoutLoading />;

  return (
    <WebAppLayout fullWidth>
      <div className="m-10">
        <FullCalendar
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            rrulePlugin,
          ]}
          headerToolbar={{
            left: 'title prev,next',
            right: 'dayGridMonth,timeGridWeek,timeGridDay',
          }}
          height="90vh"
          initialView={CalendarViews.MONTH}
          selectMirror={true}
          dayMaxEvents={true}
          eventContent={renderEventContent}
          events={
            currentCalendarView === CalendarViews.MONTH
              ? summaryEvents
              : detailedEvents
          }
          viewDidMount={(e: ViewMountArg) =>
            setCurrentCalendarView(e.view.type as CalendarViews)
          }
        />
      </div>
    </WebAppLayout>
  );
};

export default CalendarPage;
