import { Tab, Tabs, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import CMButton from '../../components/cm-button/cm-button.component';
import FollowUpsList from '../../components/follow-ups-list/follow-ups-list.component';
import NoResults from '../../components/no-results/no-results.component';
import PaywallToast from '../../components/paywall-toast/paywall-toast.component';
import SecondarySearch from '../../components/secondary-search/secondary-search.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import { AuthContext } from '../../context/auth/auth.context';
import { SharedDialoguesContext } from '../../context/shared-dialogues/shared-dialogues.context';

import { useFollowUps } from '../../firebase/firebase.utils';

import { filterItemsByDate } from '../../utils/list.utils';

const FollowUps = () => {
  const { userId } = useContext(AuthContext);
  const { openFollowUpDialog } = useContext(SharedDialoguesContext);
  const matchesMd = useMediaQuery(theme => theme.breakpoints.up('md'));
  const displayStatusParam = new URLSearchParams(useLocation().search).get(
    'displayStatus'
  );

  const [selectedDisplayStatus, setSelectedDisplayStatus] = useState(
    displayStatusParam || 'All'
  );
  const [searchTerm, setSearchTerm] = useState('');

  const { data: dataFollowUps } = useFollowUps(userId);

  const handleTabChange = (event, newValue) => {
    setSelectedDisplayStatus(newValue);
  };

  useEffect(() => {
    setSelectedDisplayStatus('All');
  }, [searchTerm]);

  if (!dataFollowUps) return <WebAppLayoutLoading testid="followUpsPage" />;

  // Empty state
  if (dataFollowUps.length === 0)
    return (
      <WebAppLayout>
        <div className="flex items-center justify-between mb-8 md:mb-16">
          <h1 className="text-2xl md:text-5xl font-bold">Follow-ups</h1>
        </div>
        <div
          className="md:py-24 md:px-48 bg-white rounded-lg"
          data-testid="followUps.emptyState"
        >
          <div className="md:text-2xl font-bold">
            “The Fortune is in the Follow Up.”
          </div>
          <div className="md:text-xl py-8">
            Even when there is not a clear next step, you should still be
            setting a Follow Up for each interaction. Why? This is how you will
            make sure you have a structure in place that ensures that you are
            checking in with your network at some interval and keeping the
            relationship strong.
          </div>
          <div className="hidden md:block">
            <CMButton
              color="secondary"
              size="lg"
              onClick={() => openFollowUpDialog()}
              data-testid="followUps.newFollowUpButton"
            >
              New Follow-up
            </CMButton>
          </div>
        </div>
      </WebAppLayout>
    );

  const {
    todayItems: todayItemsForSorting,
    recentItems: recentFollowUps,
    upcomingItems: upcomingItemsForSorting,
  } = filterItemsByDate(dataFollowUps, 'followupTimestamp');

  const todayTimeBasedFollowUps = todayItemsForSorting
    .filter(({ timeBased }) => timeBased) // Time Based Follow Ups are sorted in ascending order
    .sort((a, b) => a.followupTimestamp - b.followupTimestamp);
  const todayNotTimeBased = todayItemsForSorting
    .filter(({ timeBased }) => !timeBased) // Today Follow Ups sorted by contact name
    .sort((a, b) => {
      return a.contactName
        ?.toLowerCase()
        .localeCompare(b.contactName?.toLowerCase());
    });
  const todayFollowUps = [...todayTimeBasedFollowUps, ...todayNotTimeBased];
  // Recent Follow Ups are sorted in descending order, no sort needed here;
  // Upcoming Follow Ups are sorted in ascending order
  const upcomingFollowUps = upcomingItemsForSorting.sort(
    (a, b) => a.followupTimestamp - b.followupTimestamp
  );

  const hasSearchFieldMatch = (body = '', contactName = '') => {
    const search = searchTerm.toLocaleLowerCase().trim();
    const searchFieldMatches =
      body.toLowerCase().includes(search) ||
      contactName?.toLowerCase().includes(search);
    return search === '' || searchFieldMatches;
  };

  const filterBySearchTerm = list =>
    list.filter(({ body, contactName }) =>
      hasSearchFieldMatch(body, contactName)
    );

  const todayFollowUpsFilteredBySearchTerm = filterBySearchTerm(todayFollowUps);
  const recentFollowUpsFilteredBySearchTerm =
    filterBySearchTerm(recentFollowUps);
  const upcomingFollowUpsFilteredBySearchTerm =
    filterBySearchTerm(upcomingFollowUps);

  const noResultsFound = () =>
    !todayFollowUpsFilteredBySearchTerm.length &&
    !recentFollowUpsFilteredBySearchTerm.length &&
    !upcomingFollowUpsFilteredBySearchTerm.length;

  const getFilterResultsText = () => {
    if (selectedDisplayStatus === 'Upcoming')
      return 'Upcoming follow-ups containing';

    if (selectedDisplayStatus === 'Today')
      return 'Follow-ups for Today containing';

    if (selectedDisplayStatus === 'Recent')
      return 'Follow-ups Recent for Action containing';

    return 'Follow-ups containing';
  };

  // If we are filtering follow-ups by a determined group and that list reaches
  // `length === 0`, we need to send users back to seeing 'All' follow-ups
  if (
    (todayFollowUpsFilteredBySearchTerm.length === 0 &&
      selectedDisplayStatus === 'Today') ||
    (recentFollowUpsFilteredBySearchTerm.length === 0 &&
      selectedDisplayStatus === 'Recent') ||
    (upcomingFollowUpsFilteredBySearchTerm.length === 0 &&
      selectedDisplayStatus === 'Upcoming')
  )
    setSelectedDisplayStatus('All');

  return (
    <WebAppLayout>
      <PaywallToast />
      <div className="flex items-center justify-between mb-8 md:mb-16">
        <h1 className="text-2xl md:text-5xl font-bold">Follow-ups</h1>
        <div className="hidden md:block">
          <CMButton
            color="secondary"
            onClick={() => openFollowUpDialog()}
            data-testid="followUps.newFollowUpButton"
          >
            New Follow-up
          </CMButton>
        </div>
      </div>

      <div className="flex max-w-4xl">
        {matchesMd ? (
          <div className="max-w-4xl flex-grow">
            {/* Tabs */}
            <div className="bg-white rounded md:shadow-md md:px-8 w-full md:w-auto">
              <Tabs
                value={selectedDisplayStatus}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                variant="fullWidth"
                data-testid="followUps.tabs"
              >
                <Tab label="All" value="All" data-testid="contacts.tabs.all" />
                <Tab
                  disabled={!todayFollowUpsFilteredBySearchTerm.length}
                  label={`Today (${todayFollowUpsFilteredBySearchTerm.length})`}
                  value="Today"
                  data-testid="contacts.tabs.today"
                />
                <Tab
                  disabled={!recentFollowUpsFilteredBySearchTerm.length}
                  label={`Recent (${recentFollowUpsFilteredBySearchTerm.length})`}
                  value="Recent"
                  data-testid="contacts.tabs.recent"
                />
                <Tab
                  disabled={!upcomingFollowUpsFilteredBySearchTerm.length}
                  label={`Upcoming (${upcomingFollowUpsFilteredBySearchTerm.length})`}
                  value="Upcoming"
                  data-testid="contacts.tabs.upcoming"
                />
              </Tabs>
            </div>
          </div>
        ) : null}

        {/* Search */}
        <div className="ml-8 hidden md:block flex-grow">
          <SecondarySearch
            searchTerm={searchTerm}
            handleOnChange={setSearchTerm}
            placeholder="Search follow ups"
            testid="followUps"
          />
        </div>
      </div>

      {searchTerm.length ? (
        <div
          className="text-black text-xl pt-8"
          data-testid="followups.searchPhrase"
        >
          {getFilterResultsText()} "
          <strong className="text-fadeOrange">{searchTerm.trim()}</strong>"
        </div>
      ) : null}

      <div className="max-w-4xl pt-8">
        {noResultsFound() ? (
          <NoResults testid="followUps.emptyResults" />
        ) : ['Today', 'All'].includes(selectedDisplayStatus) &&
          todayFollowUpsFilteredBySearchTerm.length ? (
          <div className="pb-8">
            <h2 className="text-2xl font-bold pb-2">
              Today ({todayFollowUpsFilteredBySearchTerm.length})
            </h2>
            <FollowUpsList
              list={todayFollowUpsFilteredBySearchTerm}
              searchTerm={searchTerm}
              filter={selectedDisplayStatus}
              hidePaywallItems
              testid="followUps.todayListRow"
            />
          </div>
        ) : null}

        {['Recent', 'All'].includes(selectedDisplayStatus) &&
        recentFollowUpsFilteredBySearchTerm.length ? (
          <div className="pb-8">
            <h2 className="text-2xl font-bold pb-2">
              Recent ({recentFollowUpsFilteredBySearchTerm.length})
            </h2>
            <FollowUpsList
              list={recentFollowUpsFilteredBySearchTerm}
              searchTerm={searchTerm}
              filter={selectedDisplayStatus}
              hidePaywallItems
              testid="followUps.recentListRow"
            />
          </div>
        ) : null}

        {['Upcoming', 'All'].includes(selectedDisplayStatus) &&
        upcomingFollowUpsFilteredBySearchTerm.length ? (
          <div className="pb-8">
            <h2 className="text-2xl font-bold pb-2">
              Upcoming ({upcomingFollowUpsFilteredBySearchTerm.length})
            </h2>
            <FollowUpsList
              list={upcomingFollowUpsFilteredBySearchTerm}
              searchTerm={searchTerm}
              filter={selectedDisplayStatus}
              hidePaywallItems
              testid="followUps.upcomingListRow"
            />
          </div>
        ) : null}
      </div>
    </WebAppLayout>
  );
};

export default FollowUps;
