import React from 'react';
import { MdArrowBack } from 'react-icons/md';
import { Link } from 'react-router-dom';

import CMTextButton from '../cm-text-button/cm-text-button.component';

type IProps = {
  color?: 'light' | 'none';
  to?: string | number;
  label: string;
};

const Breadcrumb = ({ color = 'none', to = -1, label }: IProps) => {
  return (
    <CMTextButton color={color} icon={MdArrowBack} component={Link} to={to}>
      {label}
    </CMTextButton>
  );
};

export default Breadcrumb;
