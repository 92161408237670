import React from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { IoMdClose, IoMdWarning } from 'react-icons/io';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import CMTextButton from '../cm-text-button/cm-text-button.component';

export enum GrowlerTypes {
  Info = 'info',
  Danger = 'danger',
}

export type IGrowlerProps = {
  action: { label: string; to: string };
  title: string;
  type: GrowlerTypes;
  content: string;
  handleDismiss: () => void;
};

const Growler: React.FC<IGrowlerProps> = ({
  title,
  type,
  content,
  action,
  handleDismiss,
}): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();
  const typeDanger: boolean = type === GrowlerTypes.Danger;

  return (
    <div
      className={`flex p-6 justify-between border-b-2 ${
        typeDanger ? 'bg-palePurle border-b-errorStrong' : 'border-b-slate'
      }`}
    >
      <div className="flex space-x-6">
        {typeDanger ? (
          <div>
            <IoMdWarning className="text-errorStrong" size="30px" />
          </div>
        ) : (
          <div>
            <BiInfoCircle size="30px" />
          </div>
        )}

        <div>
          <p className="font-bold text-xl">{title}</p>
          <p>{content}</p>
          <CMTextButton
            color="primary"
            iconColor="text-blueGrey"
            onClick={() => {
              navigate(action.to);
              handleDismiss();
            }}
          >
            {action.label}
          </CMTextButton>
        </div>
      </div>
      <IoMdClose className="cursor-pointer" onClick={handleDismiss} />
    </div>
  );
};

export default Growler;
