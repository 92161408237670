import {
  Alert,
  AlertColor,
  Snackbar,
  SnackbarCloseReason,
} from '@mui/material';
import React, { createContext, useCallback, useState } from 'react';

export type ISnackbarProps = {
  message: string;
  severity: AlertColor;
};

export interface ISnackbarContext {
  showSnackbar({ message, severity }: ISnackbarProps): void;
}

export const SnackbarContext = createContext<ISnackbarContext>({
  showSnackbar() {},
});

export const SnackbarContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(null);

  const handleClose = (
    event: Event | React.SyntheticEvent<Element, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const showSnackbar = useCallback(({ message, severity }: ISnackbarProps) => {
    setMessage({
      message,
      severity,
    });
    setOpen(true);
  }, []);

  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar,
      }}
    >
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={message?.severity}
          sx={{ width: '100%' }}
        >
          {message?.message}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};
