import React, { useContext } from 'react';
import { Link, Navigate } from 'react-router-dom';

import CMButton from '../../components/cm-button/cm-button.component';
import ContactCard from '../../components/contact-card/contact-card.component';
import InspirationProfilesList from '../../components/inspiration-profiles-list/inspiration-profiles-list.component';
import ShareProfileContent, {
  ShareProfileFooter,
} from '../../components/share-profile-content/share-profile-content.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import { AuthContext } from '../../context/auth/auth.context';

const ShareYourProfile = () => {
  const { userData } = useContext(AuthContext);

  const originUrl = window.location.origin;

  // If a user doesn't have a public profile yet, just send them to /edit-profile
  if (userData?.dbc_enabled !== 'Y')
    return <Navigate replace to="/edit-profile" />;

  return (
    <WebAppLayout digitalBussinessCard>
      <div className="px-4 md:px-0">
        <div className="text-center pt-9 pb-4 md:pt-16 md:pb-10">
          <h2 className="text-4xl md:text-5xl font-bold">Share your profile</h2>
        </div>
        <div className="grid md:grid-cols-2 gap-8 pb-8">
          <ContactCard
            fullName={`${userData.firstname} ${userData.lastname}`}
            img={userData.image}
            subtitle={`${userData.title} ${
              userData.organization ? `@ ${userData.organization}` : ''
            }`}
            profileUrl={`/hi/${userData.profileurl}`}
          />
          <div className="md:flex items-center">
            <div className="md:w-56 space-y-5">
              <CMButton
                component={Link}
                to="/edit-profile"
                color="secondary"
                outline
                fullWidth
                data-testid="shareYourProfile.editProfileButton"
              >
                Edit profile
              </CMButton>
              <CMButton
                component={Link}
                to="/pick-url"
                color="secondary"
                outline
                fullWidth
                data-testid="shareYourProfile.editYourUrlButton"
              >
                Edit your URL
              </CMButton>
            </div>
          </div>
        </div>
        <div className="bg-slate rounded-md">
          <div className="bg-white border border-slate rounded-md p-4">
            <ShareProfileContent
              url={`${originUrl}/hi/${userData.profileurl}`}
              profileUrl={userData.profileurl}
            />
          </div>
          <div className="pb-6 px-2 md:px-6">
            <ShareProfileFooter />
          </div>
        </div>
        <div className="py-16">
          <h4 className="text-4xl font-bold pb-4">Some inspiration for you</h4>
          <InspirationProfilesList />
        </div>
      </div>
    </WebAppLayout>
  );
};

export default ShareYourProfile;
