import CircularProgress from '@mui/material/CircularProgress';
import _partition from 'lodash/partition';
import React, { memo, useContext } from 'react';
import { BiCalendarEvent } from 'react-icons/bi';
import { MdCheck, MdEdit } from 'react-icons/md';
import { useParams } from 'react-router-dom';

import CMButton from '../../components/cm-button/cm-button.component';
import CMTextButton from '../../components/cm-text-button/cm-text-button.component';
import HTMLText from '../../components/html-text/html-text.component';
import PaywallHiddenItemsMessage from '../../components/paywall-hidden-items-message/paywall-hidden-items-message.component';
import ReadMore from '../../components/read-more/read-more.component';

import RotationToText from './rotation-to-text.component';

import useFeaturesSwitch from '../../hooks/useFeaturesSwitch';

import { AuthContext } from '../../context/auth/auth.context';
import { PaywallContext } from '../../context/paywall/paywall.context';
import { SharedDialoguesContext } from '../../context/shared-dialogues/shared-dialogues.context';

import {
  useContactFollowUps,
  useContactRotation,
} from '../../firebase/firebase.utils';

import { formatDate } from '../../utils/dates.utils';

const RotationSection = memo(
  ({
    openRotationDialog,
    rotationId,
    rotationFrequency = 0,
    rotationInterval = 0,
    rotationNextIterationDate = 0,
    contactId,
  }) => {
    const handleAddEditRotation = () => {
      openRotationDialog({
        rotationId,
        contactId,
      });
    };

    return (
      <section className="border-b border-silver">
        <div className="flex items-center justify-between p-4">
          <h1>Rotation of Remember</h1>
          {rotationId ? (
            <CMButton
              color="secondary"
              outline
              onClick={handleAddEditRotation}
              data-testid="contactDetails.contactFollowUps.EditRotationButton"
            >
              Edit
            </CMButton>
          ) : (
            <CMButton
              color="secondary"
              onClick={handleAddEditRotation}
              data-testid="contactDetails.contactFollowUps.addRotationButton"
            >
              Add a rotation
            </CMButton>
          )}
        </div>
        <div className="p-4 flex items-center space-x-2">
          <BiCalendarEvent size="24px" />
          {rotationId ? (
            <RotationToText
              rotationFrequency={rotationFrequency}
              rotationInterval={rotationInterval}
              rotationNextIterationDate={rotationNextIterationDate}
            />
          ) : (
            <p>You have no set up your rotation of remember frequency yet.</p>
          )}
        </div>
      </section>
    );
  }
);

const FollowUp = memo(
  ({ followupTimestamp, body, timeBased, onFollowUpActionsClick, testid }) => {
    return (
      <div className="p-4 flex bg-white">
        <div className="flex-grow pr-4">
          <div
            className="font-bold pb-3 uppercase"
            data-testid={`${testid}.date`}
          >
            {formatDate(
              new Date(Math.round(followupTimestamp * 1000)),
              timeBased
            )}
          </div>
          <ReadMore
            renderActionButton={
              <CMTextButton onClick={onFollowUpActionsClick} color="secondary">
                Open Follow-Up
              </CMTextButton>
            }
          >
            <HTMLText text={body} testid={`${testid}.body`} />
          </ReadMore>
        </div>
        <div className="p-4 w-48 shrink-0 flex flex-col items-start justify-center border-l border-dirtyWhite">
          <CMTextButton
            icon={MdEdit}
            color="secondary"
            onClick={() => onFollowUpActionsClick('edit')}
            data-testid={`${testid}.editButton`}
          >
            Edit
          </CMTextButton>
          <CMTextButton
            icon={MdCheck}
            color="secondary"
            onClick={() => onFollowUpActionsClick('done')}
            data-testid={`${testid}.deleteButton`}
          >
            Mark as Done
          </CMTextButton>
        </div>
      </div>
    );
  }
);

const ContactFollowUps = () => {
  const { userId } = useContext(AuthContext);
  const { openFollowUpDialog, openRotationDialog } = useContext(
    SharedDialoguesContext
  );
  const { latestFollowUpsIDs, hasReachedMaxFollowUps } =
    useContext(PaywallContext);
  const { rotation } = useFeaturesSwitch();

  const { contactId } = useParams();

  const { data: dataFollowUpsRecent } = useContactFollowUps(
    userId,
    contactId,
    true
  );
  const { data: dataFollowUpsUpcoming } = useContactFollowUps(
    userId,
    contactId,
    false
  );
  const { data: dataContactRotation } = useContactRotation(userId, contactId);

  const handleFollowUpActionsClick = (mode, followUpId) => {
    openFollowUpDialog({
      followUpId: followUpId,
      contactId,
      isEditing: mode === 'edit',
      isDone: mode === 'done',
      closeOnCancelEdit: mode === 'edit',
    });
  };

  const loading =
    !dataFollowUpsRecent || !dataFollowUpsUpcoming || !dataContactRotation;

  if (loading)
    return (
      <div className="text-center py-24">
        <CircularProgress />
      </div>
    );

  const [availableFollowUpsRecent, archivedFollowUpsRecent] =
    hasReachedMaxFollowUps
      ? _partition(dataFollowUpsRecent, ({ id }) =>
          latestFollowUpsIDs.includes(id)
        )
      : [dataFollowUpsRecent, []];

  const [availableFollowUpsUpcoming, archivedFollowUpsUpcoming] =
    hasReachedMaxFollowUps
      ? _partition(dataFollowUpsUpcoming, ({ id }) =>
          latestFollowUpsIDs.includes(id)
        )
      : [dataFollowUpsUpcoming, []];
  const archivedFollowUpsLength =
    archivedFollowUpsRecent.length + archivedFollowUpsUpcoming.length;

  // Empty State
  if ([...dataFollowUpsRecent, ...dataFollowUpsUpcoming].length === 0)
    return (
      <>
        {rotation ? (
          <RotationSection
            openRotationDialog={openRotationDialog}
            rotationId={dataContactRotation?.[0]?.id}
            rotationFrequency={dataContactRotation?.[0]?.frequency}
            rotationInterval={dataContactRotation?.[0]?.interval}
            rotationNextIterationDate={
              dataContactRotation?.[0]?.nextIterationDate
            }
            contactId={contactId}
          />
        ) : null}
        <div className="flex flex-col items-center content-center py-32">
          <BiCalendarEvent className="text-steel" size="40px" />
          <div className="text-xl py-8">
            You have no follow-ups with this contact
          </div>
          <CMButton
            color="secondary"
            onClick={() => handleFollowUpActionsClick('edit')}
            data-testid="contactDetails.contactFollowUps.addFirstFollowUpButton"
          >
            Add a follow-up
          </CMButton>
        </div>
      </>
    );

  return (
    <>
      {rotation ? (
        <RotationSection
          openRotationDialog={openRotationDialog}
          rotationId={dataContactRotation?.[0]?.id}
          rotationFrequency={dataContactRotation?.[0]?.frequency}
          rotationInterval={dataContactRotation?.[0]?.interval}
          rotationNextIterationDate={
            dataContactRotation?.[0]?.nextIterationDate
          }
          contactId={contactId}
        />
      ) : null}
      {availableFollowUpsRecent.length ? (
        <section
          className={`${
            dataFollowUpsUpcoming.length ? 'border-b border-silver' : ''
          }`}
        >
          <div className="flex items-center justify-between p-4">
            <h1>Recent ({availableFollowUpsRecent.length})</h1>
            <CMButton
              color="secondary"
              onClick={() => handleFollowUpActionsClick('edit')}
              data-testid="contactDetails.contactFollowUps.addFollowUpButton"
            >
              Add a follow-up
            </CMButton>
          </div>
          <ul>
            {availableFollowUpsRecent.map(
              ({ id, followupTimestamp, body, timeBased }, index) => (
                <li key={id} className="border-b border-silver last:border-b-0">
                  <FollowUp
                    followupTimestamp={followupTimestamp}
                    body={body}
                    timeBased={timeBased}
                    onFollowUpActionsClick={mode =>
                      handleFollowUpActionsClick(mode, id)
                    }
                    testid={`contactDetails.contactFollowUpsRecent.${index}`}
                  />
                </li>
              )
            )}
          </ul>
        </section>
      ) : null}
      {availableFollowUpsUpcoming.length ? (
        <section>
          <div className="flex items-center justify-between p-4">
            <h1>Upcoming ({availableFollowUpsUpcoming.length})</h1>
            {!dataFollowUpsRecent.length ? (
              <CMButton
                color="secondary"
                onClick={() => handleFollowUpActionsClick('edit')}
                data-testid="contactDetails.contactFollowUps.addFollowUpButton"
              >
                Add a follow-up
              </CMButton>
            ) : null}
          </div>
          <ul>
            {availableFollowUpsUpcoming.map(
              ({ id, followupTimestamp, body, timeBased }, index) => (
                <li key={id} className="border-b border-silver last:border-b-0">
                  <FollowUp
                    followupTimestamp={followupTimestamp}
                    body={body}
                    timeBased={timeBased}
                    onFollowUpActionsClick={mode =>
                      handleFollowUpActionsClick(mode, id)
                    }
                    testid={`contactDetails.contactFollowUpsUpcoming.${index}`}
                  />
                </li>
              )
            )}
          </ul>
        </section>
      ) : null}
      {archivedFollowUpsLength ? (
        <div className="bg-dirtyWhite pt-4">
          <PaywallHiddenItemsMessage
            outstandingItems={archivedFollowUpsLength}
          />
        </div>
      ) : null}
    </>
  );
};

export default ContactFollowUps;
