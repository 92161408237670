import React, { memo, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import SecondarySearch from '../../../components/secondary-search/secondary-search.component';
import WebAppLayout from '../../../components/web-app-layout/web-app-layout.component';
import AdminContactsList from '../../components/admin-contacts-list/admin-contacts-list.component';

import { AuthContext } from '../../../context/auth/auth.context';

import {
  getUserByIdForAdmin,
  getUsersForAdmin,
} from '../../../services/users/users.service';

const AdminUserManagement = memo(() => {
  const { authToken } = useContext(AuthContext);

  const [generalSearchTerm, setGeneralSearchTerm] = useState('');
  const [userIdSearchTerm, setUserIdSearchTerm] = useState('');
  const [loadingGeneralResults, setLoadingGeneralResults] = useState(false);
  const [loadingUserIdResult, setLoadingUserIdResult] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [generalSearchResults, setGeneralSearchResults] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const generalSearchTermParam = searchParams.get('generalSearchTerm');

    setGeneralSearchTerm(generalSearchTermParam || '');

    if (generalSearchTermParam) {
      setLoadingGeneralResults(true);

      getUsersForAdmin(0, generalSearchTermParam, authToken)
        .then(({ users }) => setGeneralSearchResults(users))
        .catch(() => setGeneralSearchResults())
        .finally(() => setLoadingGeneralResults(false));
    } else {
      setGeneralSearchResults();
    }
    // eslint-disable-next-line
  }, [searchParams]);

  const handleGeneralSearchButtonClick = () => {
    const searchTerm = generalSearchTerm.trim().replace(/  +/g, ' ');
    searchParams.delete('userIdSearchTerm');
    searchParams.set('generalSearchTerm', searchTerm);

    setSearchParams(searchParams);
  };

  const handleUserIdSearchButtonClick = () => {
    setSearchParams(searchParams);
    setLoadingUserIdResult(true);

    getUserByIdForAdmin(userIdSearchTerm, authToken)
      .then(data => navigate(`/admin/user-management/${data.id}`))
      .catch(() => {
        alert(`User ${userIdSearchTerm} not found`);
        setLoadingUserIdResult(false);
        setUserIdSearchTerm('');
      });
  };

  return (
    <WebAppLayout>
      <h1 className="text-2xl md:text-5xl font-bold">
        Admin | User Management
      </h1>
      <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-8 mt-8 md:mt-16 mb-8 items-center">
        <SecondarySearch
          searchTerm={generalSearchTerm}
          handleOnChange={setGeneralSearchTerm}
          handleClearValue={() => {
            searchParams.delete('generalSearchTerm');
            setSearchParams(searchParams);
          }}
          placeholder="Search by Name or Email"
          showButton
          handleOnButtonClick={handleGeneralSearchButtonClick}
          handleOnEnterKeyPress={handleGeneralSearchButtonClick}
          testid="admin.userManagement.generalSearch"
        />
        <SecondarySearch
          searchTerm={userIdSearchTerm}
          type="number"
          handleOnChange={setUserIdSearchTerm}
          placeholder="Search by User ID"
          showButton
          loading={loadingUserIdResult}
          handleOnButtonClick={handleUserIdSearchButtonClick}
          handleOnEnterKeyPress={handleUserIdSearchButtonClick}
          testid="admin.userManagement.userIdSearch"
        />
      </div>

      {generalSearchResults?.length ? (
        <div
          className="text-black text-xl pb-8"
          data-testid="admin.userManagement.narrowSearchPhrase"
        >
          There are <strong>{generalSearchResults?.length}</strong> results that
          match your search.{' '}
          {generalSearchResults.length > 100
            ? "We'll show you the first 100, please narrow your search."
            : null}
        </div>
      ) : null}

      {generalSearchTerm && generalSearchResults?.length === 0 ? (
        <div
          className="text-black text-xl pb-8"
          data-testid="admin.userManagement.noResultsPhrase"
        >
          There are no results that match your search.
        </div>
      ) : null}

      {generalSearchResults?.length || loadingGeneralResults ? (
        <AdminContactsList
          list={generalSearchResults}
          loading={loadingGeneralResults}
          testid="admin.userManagement.generalSearch"
        />
      ) : null}
    </WebAppLayout>
  );
});

export default AdminUserManagement;
