import { StepButton } from '@mui/material';
import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import Stepper from '@mui/material/Stepper';
import React from 'react';

import CMButton from '../cm-button/cm-button.component';

const CMStepper = ({ steps, activeStep, handleStepClick, handleNext }) =>
  steps && (
    <Stepper nonLinear activeStep={activeStep} orientation="vertical">
      {steps.map((step, index) => (
        <Step disabled={step.disabled} key={step.label}>
          <StepButton onClick={handleStepClick(index)}>{step.label}</StepButton>
          <StepContent>
            {step.content}
            <div className="text-right mt-6">
              <CMButton
                onClick={() => {
                  if (step.onNext instanceof Function) {
                    step.onNext();
                  }

                  if (!step.disabled) handleNext();
                }}
              >
                {index === steps.length - 1 ? 'Finish' : 'Next'}
              </CMButton>
            </div>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );

export default CMStepper;
