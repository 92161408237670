import { Alert } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useContext, useState } from 'react';
import { BsPersonFill } from 'react-icons/bs';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaMobileAlt,
  FaTwitter,
} from 'react-icons/fa';
import { IoMdHand } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';

import CMDialog from '../../cm-dialog/cm-dialog.component';
import CMStepper from '../../cm-stepper/cm-stepper.component';
import DuplicateContactDialog from '../../duplicate-contact-dialog/duplicate-contact-dialog.component';

import { AuthContext } from '../../../context/auth/auth.context';
import { SharedDialoguesContext } from '../../../context/shared-dialogues/shared-dialogues.context';

import {
  addNewContact,
  useContact,
  useContacts,
} from '../../../firebase/firebase.utils';

import { removeMultipleSpaces } from '../../../utils/list.utils';

import { ReactComponent as AlignableIcon } from '../../../assets/images/alignable-icon.svg';

const AddNewContactDialog = () => {
  const { userId } = useContext(AuthContext);

  const { openNoteDialog } = useContext(SharedDialoguesContext);
  const { addNewContactDialogOpen, closeAddNewContactDialog } = useContext(
    SharedDialoguesContext
  );

  const [saveFailed, setSaveFailed] = useState(false);
  const [sameNamedContacts, setSameNamedContacts] = useState([]);
  const [duplcatedContactId, setDuplicatedContactId] = useState();
  const [isContactDuplicate, setIsContactDuplicate] = useState(false);
  const [showRequiredNamesError, setShowRequiredNamesError] = useState(false);
  const [contactExistsInAddressBook, setContactExistsInAddressBook] =
    useState(false);
  const [duplicateContactDialogOpen, setDuplicateContactDialogOpen] =
    useState(false);
  const [duplicateConfirmationShown, setDuplicateConfirmationShown] =
    useState(false);
  const [duplicateContactInformation, setDuplicateContactInformation] =
    useState({});

  const { data: dataContacts } = useContacts(userId);
  const { update: updateContact } = useContact(userId, duplcatedContactId);

  const formInitialState = {
    firstname: '',
    lastname: '',
    highlights: '',
    phone: '',
    email: '',
    clubhouseId: '',
    alignableId: '',
    facebookId: '',
    twitterId: '',
    linkedinId: '',
    instagramId: '',
  };

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState(formInitialState);

  const handleChange = (value, field) => {
    setFormData(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const resetFormState = () => {
    setActiveStep(0);
    setFormData(formInitialState);
    setDuplicateConfirmationShown(false);
    setContactExistsInAddressBook(false);
    setIsContactDuplicate(false);
    setDuplicatedContactId(null);
    setDuplicateContactInformation({});
  };

  const goToNextStep = () =>
    setActiveStep(prevActiveStep => prevActiveStep + 1);

  const checkDuplicate = () => {
    const duplicates = dataContacts.filter(
      contact =>
        contact.fullName?.toLowerCase() ===
        `${formData.firstname.toLocaleLowerCase().trim()} ${formData.lastname
          .toLowerCase()
          .trim()}`
    );

    setSameNamedContacts(duplicates);

    if (duplicates.length > 0 && !duplicateConfirmationShown)
      setDuplicateContactDialogOpen(true);
    else goToNextStep();
  };

  const handleNext = () => {
    if (activeStep === 0) checkDuplicate();
    else if (activeStep === steps.length - 1) onSubmit();
    else goToNextStep();
  };

  const handleStepClick = step => () => {
    if (activeStep === 0) checkDuplicate();
    else setActiveStep(step);
  };

  const overwriteContactData = duplicateContact => {
    const instantMessageAddressesCM =
      duplicateContact.instantMessageAddressesCM;

    if (instantMessageAddressesCM?.length) {
      const alignable = instantMessageAddressesCM.find(
        val => val.service.toLowerCase() === 'alignable'
      );
      const facebook = instantMessageAddressesCM.find(
        val => val.service.toLowerCase() === 'facebook'
      );
      const twitter = instantMessageAddressesCM.find(
        val => val.service.toLowerCase() === 'twitter'
      );
      const linkedin = instantMessageAddressesCM.find(
        val => val.service.toLowerCase() === 'linkedin'
      );
      const instagram = instantMessageAddressesCM.find(
        val => val.service.toLowerCase() === 'instagram'
      );
      const clubhouse = instantMessageAddressesCM.find(
        val => val.service.toLowerCase() === 'clubhouse'
      );

      if (alignable?.username) handleChange(alignable.username, 'alignableId');
      if (facebook?.username) handleChange(facebook.username, 'facebookId');
      if (twitter?.username) handleChange(twitter.username, 'twitterId');
      if (linkedin?.username) handleChange(linkedin.username, 'linkedinId');
      if (instagram?.username) handleChange(instagram.username, 'instagramId');
      if (clubhouse?.username) handleChange(clubhouse.username, 'clubhouseId');
    }

    if (duplicateContact.email) handleChange(duplicateContact.email, 'email');
    if (duplicateContact.phone) handleChange(duplicateContact.phone, 'phone');
    if (duplicateContact.highlights)
      handleChange(duplicateContact.highlights, 'highlights');
  };

  const onSubmit = () => {
    const {
      firstname,
      lastname,
      email,
      highlights,
      phone,
      alignableId,
      facebookId,
      twitterId,
      instagramId,
      clubhouseId,
      linkedinId,
    } = formData;

    // TODO: refactor to get supported services from networks.js
    // More details in ticket https://dotsur.atlassian.net/browse/OPEXC-336
    const instantMessageAddressesCM = [
      {
        service: 'alignable',
        username: alignableId,
      },
      {
        service: 'facebook',
        username: facebookId,
      },
      { service: 'twitter', username: twitterId },
      {
        service: 'instagram',
        username: instagramId,
      },
      {
        service: 'clubhouse',
        username: clubhouseId,
      },
      {
        service: 'linkedin',
        username: linkedinId,
      },
    ];

    const givenName = removeMultipleSpaces(firstname);
    const familyName = removeMultipleSpaces(lastname);
    const payload = {
      givenName,
      familyName,
      fullName: `${givenName} ${familyName}`,
      instantMessageAddressesCM,
      updatedTimestamp: Math.floor(Date.now() / 1000),
      unloggedTimestamp: isContactDuplicate
        ? duplicateContactInformation.unloggedTimestamp
        : Math.floor(Date.now() / 1000),
      createdTimestamp: isContactDuplicate
        ? duplicateContactInformation.createdTimestamp
        : Math.floor(Date.now() / 1000),
      unloggedInteractionReason: isContactDuplicate
        ? duplicateContactInformation.unloggedInteractionReason
        : 'new_contact',
      source: isContactDuplicate
        ? duplicateContactInformation.source
        : 'web_app',
      highlights,
    };

    if (!contactExistsInAddressBook) {
      payload.emails = email
        ? [
            {
              email,
              label: 'Other',
            },
          ]
        : [];

      payload.phones = phone
        ? [
            {
              label: 'Other',
              number: phone,
            },
          ]
        : [];
    }

    for (let key in payload) {
      if (payload[key] === undefined) delete payload[key];
    }

    const operation = isContactDuplicate ? updateContact : addNewContact;
    operation(payload, isContactDuplicate ? undefined : userId)
      .then(contactId => {
        resetFormState();
        closeAddNewContactDialog();
        openNoteDialog({
          isEditing: true,
          closeOnCancelEdit: true,
          isAddMode: true,
          contactId: isContactDuplicate ? duplcatedContactId : contactId,
        });
      })
      .catch(() => setSaveFailed(true));
  };

  const steps = [
    {
      label: 'Basic information',
      disabled: !formData.firstname && !formData.lastname,
      onNext: () => {
        setShowRequiredNamesError(!formData.firstname && !formData.lastname);
      },
      content: (
        <>
          <div className="pt-2 md:pt-4 flex justify-between mb-6">
            <div className="pt-3">
              <BsPersonFill size="24px" className="m-auto text-slate" />
            </div>
            <TextField
              autoFocus
              label="FIRST NAME"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              id="firstname"
              className="w-60"
              type="text"
              onChange={e => handleChange(e.target.value, 'firstname')}
              value={formData.firstname}
            />
            <TextField
              label="LAST NAME"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              className="w-60"
              id="lastname"
              type="text"
              onChange={e => handleChange(e.target.value, 'lastname')}
              value={formData.lastname}
            />
          </div>
          {showRequiredNamesError && (
            <Alert severity="error">
              A first and/or last name is required to create a contact.
            </Alert>
          )}
        </>
      ),
    },
    {
      label: 'Highlight (Optional)',
      disabled: !formData.firstname && !formData.lastname,
      content: (
        <div>
          <TextField
            rows={4}
            multiline={true}
            id="highlights"
            name="highlights"
            placeholder="Where did you meet? Do they have kids? What else do you know about them?"
            value={formData.highlights}
            onChange={e => handleChange(e.target.value, 'highlights')}
            fullWidth
          />
        </div>
      ),
    },
    {
      label: 'Contact Details',
      disabled: !formData.firstname && !formData.lastname,
      content: (
        <>
          {!contactExistsInAddressBook ? (
            <ul className="grid md:grid-cols-2 gap-y-6 gap-x-4 mt-4">
              <li className="flex items-center space-x-4 col-start-1">
                <span>
                  <MdEmail size="24px" />
                </span>
                <TextField
                  label="EMAIL"
                  placeholder="annablack@email.com"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  id="email"
                  fullWidth
                  onChange={e => handleChange(e.target.value, 'email')}
                  value={formData.email}
                />
              </li>
              <li className="flex items-center space-x-4 col-start-1">
                <span>
                  <FaMobileAlt size="24px" />
                </span>
                <TextField
                  label="MOBILE"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  id="phone"
                  fullWidth
                  type="text"
                  onChange={e => handleChange(e.target.value, 'phone')}
                  value={formData.phone}
                />
              </li>
            </ul>
          ) : (
            <div>
              <p className="my-4">
                This contact's phone number and email address are managed from
                your phone's native address book.
              </p>
              <p>
                If you want to update this information, please do it from your
                phone.
              </p>
            </div>
          )}
        </>
      ),
    },
    {
      label: 'Social Media',
      disabled: !formData.firstname && !formData.lastname,
      content: (
        <ul className="grid md:grid-cols-2 gap-y-6 gap-x-4 mt-4">
          <li className="flex items-center space-x-4">
            <span>
              <AlignableIcon width="24px" height="24px" />
            </span>
            <TextField
              label="ALIGNABLE"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              placeholder="Alignable ID"
              fullWidth
              id="alignableId"
              onChange={e => handleChange(e.target.value, 'alignableId')}
              value={formData.alignableId}
            />
          </li>
          <li className="flex items-center space-x-4">
            <span>
              <FaFacebook size="24px" />
            </span>
            <TextField
              label="FACEBOOK"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              placeholder="Facebook ID"
              fullWidth
              id="facebookId"
              onChange={e => handleChange(e.target.value, 'facebookId')}
              value={formData.facebookId}
            />
          </li>
          <li className="flex items-center space-x-4">
            <span>
              <FaInstagram size="24px" />
            </span>
            <TextField
              label="INSTAGRAM"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              placeholder="Instagram ID"
              fullWidth
              id="instagramId"
              onChange={e => handleChange(e.target.value, 'instagramId')}
              value={formData.instagramId}
            />
          </li>
          <li className="flex items-center space-x-4">
            <span>
              <FaTwitter size="24px" />
            </span>
            <TextField
              label="TWITTER"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              placeholder="Twitter ID"
              fullWidth
              id="twitterId"
              onChange={e => handleChange(e.target.value, 'twitterId')}
              value={formData.twitterId}
            />
          </li>
          <li className="flex items-center space-x-4">
            <span>
              <FaLinkedinIn size="24px" />
            </span>
            <TextField
              label="LINKEDIN"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              placeholder="LinkedIn ID"
              fullWidth
              id="linkedinId"
              onChange={e => handleChange(e.target.value, 'linkedinId')}
              value={formData.linkedinId}
            />
          </li>
          <li className="flex items-center space-x-4">
            <span>
              <IoMdHand size="24px" />
            </span>
            <TextField
              label="CLUBHOUSE"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              placeholder="Clunhouse ID"
              fullWidth
              id="clubhouseId"
              onChange={e => handleChange(e.target.value, 'clubhouseId')}
              value={formData.clubhouseId}
            />
          </li>
        </ul>
      ),
    },
  ];

  return (
    <>
      <CMDialog
        width="sm"
        open={addNewContactDialogOpen}
        handleClose={() => {
          const hasPendingChanges =
            formData.firstname ||
            formData.lastname ||
            formData.highlights ||
            formData.phone ||
            formData.email ||
            formData.facebookId ||
            formData.instagramId ||
            formData.linkedinId ||
            formData.twitterId ||
            formData.clubhouseId;

          if (
            !hasPendingChanges ||
            window.confirm('The changes will be lost.')
          ) {
            closeAddNewContactDialog();
            resetFormState();
          }
        }}
        renderContent={
          <div className="my-9">
            <CMStepper
              activeStep={activeStep}
              handleStepClick={handleStepClick}
              handleNext={handleNext}
              steps={steps}
            />
            {saveFailed && (
              <div className="mt-6 font-bold">
                An error has ocurred. Sorry about that.
              </div>
            )}
          </div>
        }
      />
      <DuplicateContactDialog
        open={duplicateContactDialogOpen}
        onClose={() => {
          setDuplicateConfirmationShown(true);
          setDuplicateContactDialogOpen(false);
        }}
        onContactSelected={duplicateContact => {
          setIsContactDuplicate(!!duplicateContact);
          setDuplicatedContactId(duplicateContact?.id);
          setContactExistsInAddressBook(
            !!duplicateContact && duplicateContact.nativeId
          );

          if (duplicateContact) {
            overwriteContactData(duplicateContact);
            setDuplicateContactInformation({
              unloggedTimestamp: duplicateContact.unloggedTimestamp,
              createdTimestamp: duplicateContact.createdTimestamp,
              unloggedInteractionReason:
                duplicateContact.unloggedInteractionReason,
              source: duplicateContact.source,
            });
          }

          setDuplicateConfirmationShown(true);
          setDuplicateContactDialogOpen(false);

          goToNextStep();
        }}
        contacts={sameNamedContacts}
        fullName={`${formData.firstname} ${formData.lastname}`}
      />
    </>
  );
};

export default AddNewContactDialog;
