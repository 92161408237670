import React from 'react';
import { Link } from 'react-router-dom';

import CMButton from '../../components/cm-button/cm-button.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

const BecomeProPage = () => {
  return (
    <WebAppLayout darkBackground digitalBussinessCard hiddenSidebar>
      <div className="mt-8 md:mt-16 bg-white text-slate rounded-lg p-8">
        <div className="px-8">
          <h3 className="text-3xl font-bold">
            Become a Pro and Unlock Exclusive Benefits
          </h3>

          <p className="my-8">
            With Pro, you get access to the best that Contact Mapping has to
            offer:
          </p>

          <ul className="list-disc pl-8">
            <li>Unlimited Notes and Follow-Ups</li>
            <li>
              The Contact Mapping browser extension
              <ul className="list-disc pl-8">
                <li>Create contacts directly from a social media profile</li>
                <li>
                  One-click to “clip” your messenger conversations into your
                  Contact Mapping Notes
                </li>
              </ul>
            </li>
            <li>
              Exclusive training and coaching from top industry leaders to
              support your growth and habit building
            </li>
            <li>Access to a 1:1 onboarding session.</li>
          </ul>
        </div>

        <hr className="my-8" />

        <div className="flex justify-between">
          <CMButton component={Link} to={-1} outline={true}>
            Back
          </CMButton>
          <CMButton component={Link} to="/billing-period-payment">
            Next
          </CMButton>
        </div>
      </div>
    </WebAppLayout>
  );
};

export default BecomeProPage;
