import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router';

import NoResults from '../../components/no-results/no-results.component';
import PaginatedList from '../../components/paginated-list/paginated-list.component';
import PaywallToast from '../../components/paywall-toast/paywall-toast.component';
import SecondarySearch from '../../components/secondary-search/secondary-search.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import SpecialDateView from '../special-date-view/special-date-view.component';

import useFeaturesSwitch from '../../hooks/useFeaturesSwitch';

import { AuthContext } from '../../context/auth/auth.context';

import SpecialDate from '../../models/special-dates.model';

import { useContacts, useSpecialDates } from '../../firebase/firebase.utils';

import { SpecialDatesDisplayStatus } from '../../utils/enums';

const SpecialDates: React.FC = (): JSX.Element => {
  const { userId } = useContext(AuthContext);
  const { special_dates } = useFeaturesSwitch();

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

  const displayStatusParam = new URLSearchParams(useLocation().search).get(
    'displayStatus'
  ) as SpecialDatesDisplayStatus;

  const [selectedDisplayStatus, setSelectedDisplayStatus] =
    useState<SpecialDatesDisplayStatus>(
      displayStatusParam || SpecialDatesDisplayStatus.All
    );

  const [searchTerm, setSearchTerm] = useState<string>('');

  const { data: dataContacts } = useContacts(userId);
  const { data: dataSpecialDates } = useSpecialDates(userId);

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: SpecialDatesDisplayStatus
  ) => {
    setSelectedDisplayStatus(newValue);
  };

  useEffect(() => {
    setSelectedDisplayStatus(SpecialDatesDisplayStatus.All);
  }, [searchTerm]);

  if (!special_dates) return <Navigate replace to="/" />;

  if (!dataSpecialDates || !dataContacts) return <WebAppLayoutLoading />;

  if (!dataSpecialDates.length) return <Navigate replace to="/" />;

  const {
    todayItems: todayItemsForSorting,
    recentItems,
    upcomingItems,
  } = SpecialDate.getGroupedByTime(
    dataSpecialDates.map(val => new SpecialDate(val))
  );

  const todayItems = todayItemsForSorting.sort((a, b) => {
    const aContactName =
      dataContacts.find(({ id }) => a.contactId === id)?.fullName ?? '';
    const bContactName =
      dataContacts.find(({ id }) => b.contactId === id)?.fullName ?? '';

    return aContactName
      ?.toLowerCase()
      .localeCompare(bContactName?.toLowerCase());
  });

  const specialDateMatchesSearchFilter = ({ contactId, type }) => {
    const textFilter = searchTerm.toLowerCase().trim();
    const contactName = dataContacts.find(
      ({ id }) => contactId === id
    )?.fullName;

    return (
      contactName?.toLowerCase().includes(textFilter) ||
      type.includes(textFilter)
    );
  };

  const todaySpecialDatesFilteredBySearchTerm = todayItems.filter(
    specialDateMatchesSearchFilter
  );
  const recentSpecialDatesFilteredBySearchTerm = recentItems.filter(
    specialDateMatchesSearchFilter
  );
  const upcomingSpecialDatesFilteredBySearchTerm = upcomingItems.filter(
    specialDateMatchesSearchFilter
  );

  const specialDatesLists = [
    {
      displayStatus: [
        SpecialDatesDisplayStatus.Today,
        SpecialDatesDisplayStatus.All,
      ],
      label: SpecialDatesDisplayStatus.Today,
      list: todaySpecialDatesFilteredBySearchTerm,
      tabTestId: 'contacts.tabs.today',
      listTestId: 'specialdates.todayListRow',
    },
    {
      displayStatus: [
        SpecialDatesDisplayStatus.Recent,
        SpecialDatesDisplayStatus.All,
      ],
      label: SpecialDatesDisplayStatus.Recent,
      list: recentSpecialDatesFilteredBySearchTerm,
      tabTestId: 'contacts.tabs.recent',
      listTestId: 'specialdates.recentListRow',
    },
    {
      displayStatus: [
        SpecialDatesDisplayStatus.Upcoming,
        SpecialDatesDisplayStatus.All,
      ],
      label: SpecialDatesDisplayStatus.Upcoming,
      list: upcomingSpecialDatesFilteredBySearchTerm,
      tabTestId: 'contacts.tabs.upcoming',
      listTestId: 'specialdates.upcomingListRow',
    },
  ];

  const noResultsFound = () =>
    !todaySpecialDatesFilteredBySearchTerm.length &&
    !recentSpecialDatesFilteredBySearchTerm.length &&
    !upcomingSpecialDatesFilteredBySearchTerm.length;

  const getFilterResultsText = () => {
    if (selectedDisplayStatus === SpecialDatesDisplayStatus.Upcoming)
      return 'Upcoming Special Dates containing';

    if (selectedDisplayStatus === SpecialDatesDisplayStatus.Today)
      return 'Special Dates for Today containing';

    if (selectedDisplayStatus === SpecialDatesDisplayStatus.Recent)
      return 'Recent Special Dates containing';

    return 'Special Dates containing';
  };

  const isTodayListSelectedAndEmpty =
    todaySpecialDatesFilteredBySearchTerm.length === 0 &&
    selectedDisplayStatus === SpecialDatesDisplayStatus.Today;
  const isRecentListSelectedAndEmpty =
    recentSpecialDatesFilteredBySearchTerm.length === 0 &&
    selectedDisplayStatus === SpecialDatesDisplayStatus.Recent;
  const isUpcomingListSelectedAndEmpty =
    upcomingSpecialDatesFilteredBySearchTerm.length === 0 &&
    selectedDisplayStatus === SpecialDatesDisplayStatus.Upcoming;

  if (
    isTodayListSelectedAndEmpty ||
    isRecentListSelectedAndEmpty ||
    isUpcomingListSelectedAndEmpty
  )
    setSelectedDisplayStatus(SpecialDatesDisplayStatus.All);

  return (
    <WebAppLayout>
      <PaywallToast />
      <div className="flex items-center justify-between mb-8 md:mb-16">
        <h1 className="text-2xl md:text-5xl font-bold">Special dates</h1>
      </div>

      <div className="flex max-w-4xl">
        {matchesMd ? (
          <div className="max-w-4xl flex-grow">
            <div className="bg-white rounded md:shadow-md md:px-8 w-full md:w-auto">
              <Tabs
                value={selectedDisplayStatus}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                variant="fullWidth"
                data-testid="specialdates.tabs"
              >
                <Tab label="All" value="All" data-testid="contacts.tabs.all" />
                {specialDatesLists.map(({ label, list, tabTestId }) => (
                  <Tab
                    disabled={!list.length}
                    label={`${label} (${list.length})`}
                    value={label}
                    data-testid={tabTestId}
                    key={tabTestId}
                  />
                ))}
              </Tabs>
            </div>
          </div>
        ) : null}

        <div className="ml-8 hidden md:block flex-grow">
          <SecondarySearch
            searchTerm={searchTerm}
            handleOnChange={setSearchTerm}
            placeholder="Search special dates"
            testid="specialdates"
          />
        </div>
      </div>

      <>
        {searchTerm.length ? (
          <div
            className="text-black text-xl pt-8"
            data-testid="specialdates.searchPhrase"
          >
            {getFilterResultsText()} "
            <strong className="text-fadeOrange">{searchTerm.trim()}</strong>"
          </div>
        ) : null}
      </>

      <div className="max-w-4xl pt-8">
        {noResultsFound() ? (
          <NoResults testid="specialdates.emptyResults" />
        ) : null}

        {specialDatesLists.map(({ displayStatus, label, list, listTestId }) =>
          displayStatus.includes(selectedDisplayStatus) && list.length ? (
            <div className="pb-8" key={listTestId}>
              <h2 className="text-2xl font-bold pb-2">
                {label} ({list.length})
              </h2>
              <PaginatedList
                list={list}
                listSize={list.length}
                paginationColor="secondary"
                listContainerClassName="md:grid grid-cols-3 gap-4"
                searchTerm={searchTerm}
                filter={selectedDisplayStatus}
                testid={listTestId}
                renderItem={(date: SpecialDate) => (
                  <SpecialDateView key={date.id} specialDate={date} />
                )}
              />
            </div>
          ) : null
        )}
      </div>
    </WebAppLayout>
  );
};

export default SpecialDates;
