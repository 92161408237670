export const hours12 = [
  '12',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
];

export const minutes600s = ['00', '10', '20', '30', '40', '50'];

export const amPm = ['AM', 'PM'];

export const monthNames: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const getTimeFromDate = (date: Date): string =>
  new Date(date).toLocaleTimeString('en-us', {
    hour: '2-digit',
    hour12: false,
    minute: '2-digit',
    second: '2-digit',
  });

// Formats date, relative for Today and Yesterday
export const formatDate = (date: Date, withTime?: boolean) => {
  const today = new Date();
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  const time = withTime
    ? `@ ${date.toLocaleTimeString('en-us', {
        hour: '2-digit',
        minute: '2-digit',
      })}`
    : '';

  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return `Today ${time}`;
  }

  if (
    date.getDate() === yesterday.getDate() &&
    date.getMonth() === yesterday.getMonth() &&
    date.getFullYear() === yesterday.getFullYear()
  ) {
    return `Yesterday ${time}`;
  }

  return `${new Date(date).toLocaleDateString('en-us', {
    month: 'long',
    day: 'numeric',
    // Hide year if current
    year: date.getFullYear() === today.getFullYear() ? undefined : 'numeric',
  })} ${time}`;
};

// Formats date to YYYY-MM-DD (used in `input[type="date"]`)
// For months and dates it will add leading 0
export const dateToInputDateFormat = date => {
  const day = new Date(date).getDate();
  const fullDay = `${day}`.padStart(2, '0');
  const month = new Date(date).getMonth() + 1;
  const fullMonth = `${month}`.padStart(2, '0');
  const fullYear = new Date(date).getFullYear();

  return `${fullYear}-${fullMonth}-${fullDay}`;
};

// Returns time greeting string based on actual time
export const timeGreeting = () => {
  const date = new Date();
  const currentTime = date.getHours();

  if (currentTime >= 0 && currentTime <= 12) {
    return 'morning';
  }
  if (currentTime > 12 && currentTime <= 18) {
    return 'afternoon';
  }
  return 'evening';
};

// Returns minutes of a date in the nearest multiple of 10 ('00', '10', '20', '30', '40', '50')
// Example: 53 and 59 will return 50
export const getMinutes600s = (date: Date): string =>
  `${parseInt(`${date.getMinutes() / 10}`, 10) * 10}`.padStart(2, '0');

// Returns hours in 24 hour format
export const getHours24 = (hours: string, isAm: boolean): string => {
  if (isAm) {
    return hours === '12' ? '00' : hours;
  }

  return hours === '12' ? hours : `${Number(hours) + 12}`;
};

export const getTimezoneOffset = (): string => {
  const offset = new Date().getTimezoneOffset();
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;

  return `${offset < 0 ? '+' : '-'}${hours.toString().padStart(2, '0')}${minutes
    .toString()
    .padStart(2, '0')}`;
};

export const daysInMonthAsArray = (month: number): number[] => {
  const daysInMonth: number = new Date(
    new Date().getFullYear(),
    month,
    0
  ).getDate();
  return [...Array(daysInMonth).keys()];
};
