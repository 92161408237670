import firebase from 'firebase/app';

import { useContact, useNote } from '../firebase/firebase.utils';

// Hook with shared logic for note actions
const useNoteActions = (userId, noteId, contactId) => {
  const { data: dataContact, update: updateContact } = useContact(
    userId,
    contactId
  );

  const {
    data: dataNote,
    update: updateNote,
    deleteDocument: deleteNote,
  } = useNote(userId, noteId);

  // Uses `collectionRef.add` instead of `useNotes().add` to avoid loading all notes
  const collectionRef = firebase
    .firestore()
    .collection(`users/${userId}/notes`);
  const addNote = note => collectionRef.add(note);

  const handleDeleteNote = () => {
    const userConfirmedDeletion = window.confirm('The note will be deleted.');
    if (userConfirmedDeletion) {
      const nowUnix = Math.floor(Date.now() / 1000);

      deleteNote().then(() => {
        updateContact({
          notesCount: firebase.firestore.FieldValue.increment(-1),
          updatedTimestamp: nowUnix,
        });
      });
    }

    return userConfirmedDeletion;
  };

  const handleUpdateContact = () => {
    const nowUnix = Math.floor(Date.now() / 1000);
    const startOfTodayUnix = Math.floor(new Date().setHours(0, 0, 0, 0) / 1000);

    return updateContact({
      notesCount: firebase.firestore.FieldValue.increment(1),
      unloggedInteractionReason: '',
      unloggedTimestamp: nowUnix,
      updatedTimestamp: nowUnix,
      firstNoteTimestamp: dataContact.firstNoteTimestamp || startOfTodayUnix,
    });
  };

  const handleSaveNote = (body, contactId, mode, shoudUpdateContact) => {
    const nowUnix = Math.floor(Date.now() / 1000);

    // Update or Add a new Note
    return mode === 'update'
      ? updateNote({
          contactId: contactId || null,
          body,
          updatedTimestamp: nowUnix,
          status: contactId ? null : 'draft',
        }).then(() => {
          if (shoudUpdateContact) handleUpdateContact();
        })
      : addNote({
          contactId: contactId || null,
          body,
          createdTimestamp: nowUnix,
          updatedTimestamp: nowUnix,
          status: contactId ? null : 'draft',
        }).then(() => {
          if (contactId) handleUpdateContact();
        });
  };

  return {
    dataContact,
    dataNote,
    handleDeleteNote,
    handleSaveNote,
  };
};

export default useNoteActions;
