import React, { memo, useContext } from 'react';

import ChangePassword from '../../../components/change-password/change-password.component';
import CMTextButton from '../../../components/cm-text-button/cm-text-button.component';

import { AuthContext } from '../../../context/auth/auth.context';
import { SnackbarContext } from '../../../context/snackbar/snackbar.context';

import { updateUserDetails } from '../../../services/users/users.service';

const AdminResetPassword = memo(({ userId, testid }) => {
  const { authToken } = useContext(AuthContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const handleSubmit = () => password => {
    return updateUserDetails(userId, { password }, authToken)
      .then(() => {
        showSnackbar({
          severity: 'success',
          message: 'Password reset succesful.',
        });
      })
      .catch(() => {
        showSnackbar({
          severity: 'error',
          message: 'Something wrong happened.',
        });
      });
  };

  return (
    <ChangePassword
      dialogTitle="Reset Password"
      renderButton={handleOnClick => (
        <CMTextButton
          data-testid={`${testid}.resetPasswordButton`}
          onClick={handleOnClick}
        >
          Reset Password
        </CMTextButton>
      )}
      submitMethod={handleSubmit()}
      testid={testid}
    />
  );
});

export default AdminResetPassword;
