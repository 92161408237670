import React from 'react';
import { RRule } from 'rrule';

type IProps = {
  rotationFrequency: number;
  rotationInterval: number;
  rotationNextIterationDate: number;
};

const RotationToText: React.FC<IProps> = ({
  rotationFrequency,
  rotationInterval,
  rotationNextIterationDate,
}) => {
  const rruleRotation = new RRule({
    freq: rotationFrequency,
    interval: rotationInterval,
    dtstart: new Date(rotationNextIterationDate * 1000),
  });

  const nextIterationDateLabel = ` on ${new Date(
    rotationNextIterationDate * 1000
  ).toLocaleDateString('en-us', { weekday: 'long' })}`;

  return (
    <p className="first-letter:uppercase">{`${rruleRotation.toText()}${
      rotationFrequency === 2 ? nextIterationDateLabel : ''
    }`}</p>
  );
};

export default RotationToText;
