import React, { useContext, useState } from 'react';

import CMTextButton from '../../../components/cm-text-button/cm-text-button.component';

import { AuthContext } from '../../../context/auth/auth.context';
import { SnackbarContext } from '../../../context/snackbar/snackbar.context';

import { repeatPretrial } from '../../../services/users/users.service';

const AdminRepeatPretrial = ({ fullName, userId, testid, onSuccess }) => {
  const { authToken } = useContext(AuthContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const [loading, setLoading] = useState(false);

  const handleRepeatPretrial = () => {
    if (
      !window.confirm(
        `${fullName}'s subscription will be reset to PreTrial. Are you sure?`
      )
    )
      return;

    setLoading(true);
    repeatPretrial(userId, authToken)
      .then(() => {
        showSnackbar({
          severity: 'success',
          message: `State of ${fullName} changed to PreTrial.`,
        });
        onSuccess();
      })
      .catch(err => {
        const errorMessage = err?.response?.data?.args?.[0] ?? '';
        const formattedErrorMessage = errorMessage
          .replace('! check_args - ', '')
          .replace(/[{}]/g, '');
        showSnackbar({
          severity: 'error',
          message: formattedErrorMessage || 'Something wrong happened.',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CMTextButton
      data-testid={`${testid}.repeatPretrialButton`}
      onClick={handleRepeatPretrial}
      loading={loading}
      disabled={loading}
    >
      Repeat PreTrial
    </CMTextButton>
  );
};

export default AdminRepeatPretrial;
