import firebase from 'firebase/app';
import { useCallback, useContext } from 'react';
import { RRule } from 'rrule';

import { AuthContext } from '../context/auth/auth.context';
import {
  IPaywallDialoguesContext,
  PaywallDialoguesContext,
} from '../context/paywall/paywall-dialogues.context';
import { PaywallContext } from '../context/paywall/paywall.context';
import { SharedDialoguesContext } from '../context/shared-dialogues/shared-dialogues.context';

import Rotation from '../models/rotation.model';

import { useRotation } from '../firebase/firebase.utils';

import { PLATFORM_IDENTIFIER } from '../utils/constants';

// Hook with shared logic for note rotations
const useRotationActions = (rotationId: string, contactId: string) => {
  const {
    userId,
    settings: { notification_time_followups },
  } = useContext(AuthContext);
  const { openNextRotationDialog } = useContext(SharedDialoguesContext);
  const { hasOneOutstandingRotation } = useContext(PaywallContext);
  const { openDismissRotationDialog } = useContext(
    PaywallDialoguesContext
  ) as IPaywallDialoguesContext;

  const {
    data: dataRotation,
    update: updateRotation,
    deleteDocument: deleteRotation,
  } = useRotation(userId, rotationId);

  const handleSkipRotation = useCallback(() => {
    const { nextIterationDate, frequency, interval } = dataRotation!;

    const [notificationHours, notificationMinutes] = notification_time_followups
      ? notification_time_followups.split(':')
      : '09:00'.split(':');
    const todayAtNotificationTime = Math.floor(
      new Date().setHours(
        parseInt(notificationHours, 10),
        parseInt(notificationMinutes, 10),
        0,
        0
      ) / 1000
    );
    const rruleRotation = new RRule({
      freq: frequency,
      interval: interval,
      dtstart: new Date(nextIterationDate * 1000),
    });
    const dontShowNextRotationDialog = JSON.parse(
      localStorage.getItem('dontShowNextRotationDialog') || 'false'
    );

    if (hasOneOutstandingRotation) {
      return deleteRotation()?.then(() => {
        openDismissRotationDialog();
      });
    }

    // If `nextIterationDate` is after today at the same time notifications are sent,
    // we wan't to set the next date from that one instead of the current date.
    // There are 2 cases for this:
    // 1. The rotation that is being skipped is in a future date.
    // 2. The rotation being skipped is in the dashboard, it shows as due today, but
    //    the hours and minutes are between now and the end of today. Example: the
    //    rotation is due today at 10:00, but it's 9:30 now
    const newIterationDate =
      nextIterationDate >= todayAtNotificationTime
        ? Math.round(
            rruleRotation.after(new Date(nextIterationDate * 1000)).getTime() /
              1000
          )
        : Math.round(rruleRotation.after(new Date()).getTime() / 1000);

    return updateRotation({
      nextIterationDate: newIterationDate,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedBy: PLATFORM_IDENTIFIER,
    } as Rotation)?.then(() => {
      if (!dontShowNextRotationDialog) {
        openNextRotationDialog({ rotationId, contactId });
      }
    });
  }, [
    contactId,
    dataRotation,
    deleteRotation,
    hasOneOutstandingRotation,
    notification_time_followups,
    openNextRotationDialog,
    rotationId,
    updateRotation,
    openDismissRotationDialog,
  ]);

  return {
    dataRotation,
    deleteRotation,
    updateRotation,
    handleSkipRotation,
  };
};

export default useRotationActions;
