import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router';

import NoResults from '../../components/no-results/no-results.component';
import PaywallToast from '../../components/paywall-toast/paywall-toast.component';
import RotationsList from '../../components/rotations-list/rotations-list.component';
import SecondarySearch from '../../components/secondary-search/secondary-search.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import useFeaturesSwitch from '../../hooks/useFeaturesSwitch';

import { AuthContext } from '../../context/auth/auth.context';

import Rotation from '../../models/rotation.model';

import { useContacts, useRotations } from '../../firebase/firebase.utils';

import { RotationsDisplayStatus } from '../../utils/enums';
import { filterItemsByDate } from '../../utils/list.utils';

const Rotations = () => {
  const { userId } = useContext(AuthContext);
  const { rotation } = useFeaturesSwitch();

  const theme = useTheme();
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

  const displayStatusParam = new URLSearchParams(useLocation().search).get(
    'displayStatus'
  ) as RotationsDisplayStatus;

  const [selectedDisplayStatus, setSelectedDisplayStatus] =
    useState<RotationsDisplayStatus>(
      displayStatusParam || RotationsDisplayStatus.All
    );

  const [searchTerm, setSearchTerm] = useState<string>('');

  const { data: dataContacts } = useContacts(userId);
  const { data: dataRotations } = useRotations(userId);

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: RotationsDisplayStatus
  ) => {
    setSelectedDisplayStatus(newValue);
  };

  useEffect(() => {
    setSelectedDisplayStatus(RotationsDisplayStatus.All);
  }, [searchTerm]);

  // FS Off
  if (!rotation) return <Navigate replace to="/" />;

  if (!dataRotations || !dataContacts) return <WebAppLayoutLoading />;

  if (!dataRotations.length) return <Navigate replace to="/" />;

  const {
    todayItems: todayItemsForSorting,
    recentItems,
    upcomingItems: upcomingItemsForSorting,
  } = filterItemsByDate<Rotation>(dataRotations, 'nextIterationDate');

  // Today Rotations sorted by contact name
  const todayItems = todayItemsForSorting.sort((a, b) => {
    const aContactName =
      dataContacts.find(({ id }) => a.contactId === id)?.fullName ?? '';
    const bContactName =
      dataContacts.find(({ id }) => b.contactId === id)?.fullName ?? '';

    return aContactName
      ?.toLowerCase()
      .localeCompare(bContactName?.toLowerCase());
  });
  // Recent Rotations are sorted in descending order, no sort needed
  // Upcoming Rotations are sorted in ascending order
  const upcomingItems = upcomingItemsForSorting.sort(
    (a, b) => a.nextIterationDate - b.nextIterationDate
  );

  const rotationMatchesSearchFilter = ({ contactId }) => {
    const textFilter = searchTerm.toLowerCase().trim();
    const contactName = dataContacts.find(
      ({ id }) => contactId === id
    )?.fullName;

    return contactName?.toLowerCase().includes(textFilter);
  };

  const todayRotationsFilteredBySearchTerm = todayItems.filter(
    rotationMatchesSearchFilter
  );
  const recentRotationsFilteredBySearchTerm = recentItems.filter(
    rotationMatchesSearchFilter
  );
  const upcomingRotationsFilteredBySearchTerm = upcomingItems.filter(
    rotationMatchesSearchFilter
  );

  const rotationsLists = [
    {
      displayStatus: [RotationsDisplayStatus.Today, RotationsDisplayStatus.All],
      label: RotationsDisplayStatus.Today,
      list: todayRotationsFilteredBySearchTerm,
      tabTestId: 'contacts.tabs.today',
      listTestId: 'rotations.todayListRow',
    },
    {
      displayStatus: [
        RotationsDisplayStatus.Recent,
        RotationsDisplayStatus.All,
      ],
      label: RotationsDisplayStatus.Recent,
      list: recentRotationsFilteredBySearchTerm,
      tabTestId: 'contacts.tabs.recent',
      listTestId: 'rotations.recent',
    },
    {
      displayStatus: [
        RotationsDisplayStatus.Upcoming,
        RotationsDisplayStatus.All,
      ],
      label: RotationsDisplayStatus.Upcoming,
      list: upcomingRotationsFilteredBySearchTerm,
      tabTestId: 'contacts.tabs.upcoming',
      listTestId: 'rotations.upcomingListRow',
    },
  ];

  const noResultsFound = () =>
    !todayRotationsFilteredBySearchTerm.length &&
    !recentRotationsFilteredBySearchTerm.length &&
    !upcomingRotationsFilteredBySearchTerm.length;

  const getFilterResultsText = () => {
    if (selectedDisplayStatus === RotationsDisplayStatus.Upcoming)
      return 'Upcoming rotations containing';

    if (selectedDisplayStatus === RotationsDisplayStatus.Today)
      return 'Rotations for Today containing';

    if (selectedDisplayStatus === RotationsDisplayStatus.Recent)
      return 'Recent rotations containing';

    return 'Rotations containing';
  };

  // If we are filtering rotations by a determined group and that list reaches
  // `length === 0`, we need to send users back to seeing RotationsDisplayStatus.All rotations
  const isTodayListSelectedAndEmpty =
    todayRotationsFilteredBySearchTerm.length === 0 &&
    selectedDisplayStatus === RotationsDisplayStatus.Today;
  const isRecentListSelectedAndEmpty =
    recentRotationsFilteredBySearchTerm.length === 0 &&
    selectedDisplayStatus === RotationsDisplayStatus.Recent;
  const isUpcomingListSelectedAndEmpty =
    upcomingRotationsFilteredBySearchTerm.length === 0 &&
    selectedDisplayStatus === RotationsDisplayStatus.Upcoming;

  if (
    isTodayListSelectedAndEmpty ||
    isRecentListSelectedAndEmpty ||
    isUpcomingListSelectedAndEmpty
  )
    setSelectedDisplayStatus(RotationsDisplayStatus.All);

  return (
    <WebAppLayout>
      <PaywallToast />
      <div className="flex items-center justify-between mb-8 md:mb-16">
        <h1 className="text-2xl md:text-5xl font-bold">Rotation of Remember</h1>
      </div>

      <div className="flex max-w-4xl">
        {matchesMd ? (
          <div className="max-w-4xl flex-grow">
            {/* Tabs */}
            <div className="bg-white rounded md:shadow-md md:px-8 w-full md:w-auto">
              <Tabs
                value={selectedDisplayStatus}
                onChange={handleTabChange}
                textColor="primary"
                indicatorColor="primary"
                variant="fullWidth"
                data-testid="rotations.tabs"
              >
                <Tab label="All" value="All" data-testid="contacts.tabs.all" />
                {rotationsLists.map(({ label, list, tabTestId }) => (
                  <Tab
                    disabled={!list.length}
                    label={`${label} (${list.length})`}
                    value={label}
                    data-testid={tabTestId}
                    key={tabTestId}
                  />
                ))}
              </Tabs>
            </div>
          </div>
        ) : null}

        {/* Search */}
        <div className="ml-8 hidden md:block flex-grow">
          <SecondarySearch
            searchTerm={searchTerm}
            handleOnChange={setSearchTerm}
            placeholder="Search rotations"
            testid="rotations"
          />
        </div>
      </div>

      <>
        {searchTerm.length ? (
          <div
            className="text-black text-xl pt-8"
            data-testid="rotations.searchPhrase"
          >
            {getFilterResultsText()} "
            <strong className="text-fadeOrange">{searchTerm.trim()}</strong>"
          </div>
        ) : null}
      </>

      <div className="max-w-4xl pt-8">
        {noResultsFound() ? (
          <NoResults testid="rotations.emptyResults" />
        ) : null}

        {rotationsLists.map(({ displayStatus, label, list, listTestId }) =>
          displayStatus.includes(selectedDisplayStatus) && list.length ? (
            <div className="pb-8" key={listTestId}>
              <h2 className="text-2xl font-bold pb-2">
                {label} ({list.length})
              </h2>
              <RotationsList
                list={list}
                searchTerm={searchTerm}
                filter={selectedDisplayStatus}
                hidePaywallItems
                testid={listTestId}
              />
            </div>
          ) : null
        )}
      </div>
    </WebAppLayout>
  );
};

export default Rotations;
