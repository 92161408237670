import firebase from 'firebase/app';

export default class Rotation {
  readonly id: string;
  contactId: string;
  frequency: number;
  interval: number;
  startDate: number;
  nextIterationDate: number;
  createdAt?: firebase.firestore.FieldValue;
  updatedAt?: firebase.firestore.FieldValue;
  createdBy?: string;
  updatedBy?: string;

  constructor(data: Object) {
    Object.assign(this, data);
  }
}
