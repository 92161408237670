import React, { memo } from 'react';

// Actual uses of maxLines are 5 and 7, if newer ones are added,
// remember to also add them to tailwind.config.jsx
const ReadMore = memo(({ children, renderActionButton, maxLines = 7 }) => (
  <div>
    <div className={`line-clamp-${maxLines}`}>{children}</div>
    {renderActionButton}
  </div>
));

export default ReadMore;
