import React, { useContext, useEffect, useState } from 'react';

import CMAvatar from '../../cm-avatar/cm-avatar.component';
import CMDialog from '../../cm-dialog/cm-dialog.component';
import PaginatedList from '../../paginated-list/paginated-list.component';
import SecondarySearch from '../../secondary-search/secondary-search.component';

import { AuthContext } from '../../../context/auth/auth.context';
import { SharedDialoguesContext } from '../../../context/shared-dialogues/shared-dialogues.context';

import { useContacts } from '../../../firebase/firebase.utils';

import {
  getContactDisplayName,
  sortAndFilterContactList,
} from '../../../utils/list.utils';

const SelectContactDialog = () => {
  const { userId } = useContext(AuthContext);
  const { selectContactDialogOpen, closeSelectContactDialog } = useContext(
    SharedDialoguesContext
  );

  const [searchTerm, setSearchTerm] = useState('');

  // Only load data if modal is open
  const { data: dataContacts } = useContacts(
    selectContactDialogOpen ? userId : null
  );

  useEffect(() => {
    setSearchTerm('');
  }, [selectContactDialogOpen]);

  if (!dataContacts) return null;

  const list = sortAndFilterContactList(dataContacts, searchTerm);

  return (
    <CMDialog
      width="xs"
      renderContent={
        <div className="mt-10">
          <div className="pb-4 mb-2 -mx-6 px-6 border-b border-silver">
            <SecondarySearch
              searchTerm={searchTerm}
              handleOnChange={setSearchTerm}
              placeholder="Search contacts"
              testid="selectContactDialog"
            />
          </div>
          {/*
            `h-[608px]` in combination with `listContainerClassName="h-[560px]"`
            is used to mantain a fixed height of the Dialog regardless of the
            amount of items being shown (or empty list)
          */}
          <div className="h-[608px]">
            {list.length === 0 ? (
              <div
                className="py-14 px-8 text-center text-2xl font-bold"
                data-testid="contacts.emptyResults"
              >
                No contacts match your filter and/or search term.
              </div>
            ) : (
              <PaginatedList
                shouldSetURLParam={false}
                list={list}
                renderItem={(
                  { id, fullName, company, displayStatus, phones, emails },
                  testid,
                  page,
                  index
                ) => (
                  <div
                    key={id}
                    className="flex items-center p-2 hover:cursor-pointer hover:bg-paleTeal hover:bg-opacity-10 transition-colors rounded-full"
                    onClick={() => closeSelectContactDialog(id)}
                    data-testid={`${testid}.paginatedList.page${page}.item${index}`}
                  >
                    <CMAvatar
                      size="sm"
                      displayStatus={displayStatus}
                      isCompany={!fullName.length}
                    />
                    <div className="truncate pl-3">
                      {getContactDisplayName(
                        fullName,
                        company,
                        phones[0]?.number,
                        emails[0]?.email
                      )}
                    </div>
                  </div>
                )}
                listSize={10}
                paginationColor="primary"
                paginationSize="medium"
                listContainerClassName="h-[560px]"
                searchTerm={searchTerm}
                testid="selectContactDialog"
              />
            )}
          </div>
        </div>
      }
      open={selectContactDialogOpen}
      handleClose={() => closeSelectContactDialog()}
    />
  );
};

export default SelectContactDialog;
