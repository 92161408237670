import { Tab, Tabs, useMediaQuery } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import CMAvatar from '../../components/cm-avatar/cm-avatar.component';
import CMButton from '../../components/cm-button/cm-button.component';
import PaginatedList from '../../components/paginated-list/paginated-list.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import { AuthContext } from '../../context/auth/auth.context';
import { SharedDialoguesContext } from '../../context/shared-dialogues/shared-dialogues.context';

import { useContactsUnlogged } from '../../firebase/firebase.utils';

const startOfTodayUnix = Math.floor(new Date().setHours(0, 0, 0, 0) / 1000);
const startOfYesterdayUnix = Math.floor(
  new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0) /
    1000
);

const UnloggedInteractions = () => {
  const { userId } = useContext(AuthContext);
  const { openNoteDialog } = useContext(SharedDialoguesContext);
  const matchesMd = useMediaQuery(theme => theme.breakpoints.up('md'));
  const navigate = useNavigate();

  const [selectedFilter, setSelectedFilter] = useState('All');

  const renderContactItem = (
    { id, unloggedInteractionReason, displayStatus, fullName, company },
    testid,
    page,
    index
  ) => (
    <div
      key={id}
      className="bg-white border border-silver rounded-md max-w-4xl py-4 px-8 mt-2 flex"
      data-testid={`${testid}.paginatedList.page${page}.item${index}`}
    >
      <div className="flex-grow">
        <div
          className="uppercase font-bold pb-2"
          data-testid={`${testid}.paginatedList.page${page}.item${index}.reason`}
        >
          {unloggedInteractionReason === 'new_contact'
            ? 'New Contact'
            : 'Completed Follow-up'}
        </div>
        <Link className="inline-flex items-center" to={`/contacts/${id}`}>
          {unloggedInteractionReason === 'new_contact' ? (
            <CMAvatar
              size="sm"
              displayStatus={displayStatus}
              isCompany={!fullName.length}
            />
          ) : (
            <CMAvatar size="sm" displayStatus="Starred" isCalendarEvent />
          )}
          <div
            className="font-bold pl-4"
            data-testid={`${testid}.paginatedList.page${page}.item${index}.name`}
          >
            {fullName || company}
          </div>
        </Link>
      </div>
      <div className="pl-4 border-l py-6 border-silver hidden md:block">
        <CMButton
          onClick={() => handleOpenNoteDialog(id)}
          outline={unloggedInteractionReason !== 'new_contact'}
          data-testid={`${testid}.paginatedList.page${page}.item${index}.addANoteButton`}
        >
          Add a note
        </CMButton>
      </div>
    </div>
  );

  const { data: dataContacts } = useContactsUnlogged(userId, 'LAST_MONTH');

  const handleOpenNoteDialog = contactId =>
    openNoteDialog({
      contactId,
      isEditing: true,
      closeOnCancelEdit: true,
      isAddMode: true,
    });

  const handleTabChange = (event, newValue) => {
    setSelectedFilter(newValue);
  };

  if (!dataContacts) return <WebAppLayoutLoading />;

  // Empty state
  // If there's nothing to show, send users back to the /dashboard
  if (dataContacts.length === 0) navigate('/dashboard', { replace: true });

  const newContacts = dataContacts.filter(
    ({ unloggedInteractionReason }) =>
      unloggedInteractionReason === 'new_contact'
  );
  const followUpCompleteContacts = dataContacts.filter(
    ({ unloggedInteractionReason }) =>
      unloggedInteractionReason === 'followup_complete'
  );

  const showTabs = newContacts.length && followUpCompleteContacts.length;

  const contactsToFilter =
    selectedFilter === 'All'
      ? dataContacts
      : selectedFilter === 'New'
      ? newContacts
      : followUpCompleteContacts;

  // If filtered items array is empty, reset filter to All
  if (
    (newContacts.length === 0 && selectedFilter === 'New') ||
    (followUpCompleteContacts.length === 0 && selectedFilter === 'Completed')
  )
    setSelectedFilter('All');

  const todayContacts = contactsToFilter.filter(
    ({ unloggedTimestamp }) => unloggedTimestamp >= startOfTodayUnix
  );
  const yesterdayContacts = contactsToFilter.filter(
    ({ unloggedTimestamp }) =>
      unloggedTimestamp < startOfTodayUnix &&
      unloggedTimestamp > startOfYesterdayUnix
  );
  const thisMonthContacts = contactsToFilter.filter(({ unloggedTimestamp }) => {
    return unloggedTimestamp <= startOfYesterdayUnix;
  });

  return (
    <WebAppLayout>
      <div className="flex items-center justify-between mb-8 md:mb-16">
        <h1 className="text-2xl md:text-5xl font-bold">
          Unlogged Interactions
        </h1>
      </div>
      {/* Only show Tabs if there are more than 2 filters avialble (removed for mobile) */}
      {showTabs && matchesMd ? (
        // Tabs
        <div className="max-w-4xl bg-white rounded md:shadow-md md:px-8 w-full md:w-auto">
          <Tabs
            value={selectedFilter}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            variant="fullWidth"
            data-testid="unloggedInteractions.tabs"
          >
            <Tab
              label="All"
              value="All"
              data-testid="unloggedInteractions.tabs.all"
            />
            <Tab
              label="New Contacts"
              value="New"
              data-testid="unloggedInteractions.tabs.newContacts"
            />
            <Tab
              label="Completed Follow-ups"
              value="Completed"
              data-testid="unloggedInteractions.tabs.completedFollowUps"
            />
          </Tabs>
        </div>
      ) : null}
      {todayContacts.length ? (
        <div className="pt-16 max-w-4xl">
          <h2 className="text-2xl font-bold pb-2">
            Today ({todayContacts.length})
          </h2>
          <PaginatedList
            shouldSetURLParam={false}
            list={todayContacts}
            renderItem={renderContactItem}
            paginationColor="primary"
            filter={selectedFilter}
            testid={`unloggedInteractions.${selectedFilter.toLocaleLowerCase()}.todayList`}
          />
        </div>
      ) : null}
      {yesterdayContacts.length ? (
        <div className="pt-16 max-w-4xl">
          <h2 className="text-2xl font-bold pb-2">
            Yesterday ({yesterdayContacts.length})
          </h2>
          <PaginatedList
            shouldSetURLParam={false}
            list={yesterdayContacts}
            renderItem={renderContactItem}
            paginationColor="primary"
            filter={selectedFilter}
            testid={`unloggedInteractions.${selectedFilter.toLocaleLowerCase()}.yesterdayList`}
          />
        </div>
      ) : null}
      {thisMonthContacts.length ? (
        <div className="pt-16 max-w-4xl">
          <h2 className="text-2xl font-bold pb-2">
            This month ({thisMonthContacts.length})
          </h2>
          <PaginatedList
            shouldSetURLParam={false}
            list={thisMonthContacts}
            renderItem={renderContactItem}
            paginationColor="primary"
            filter={selectedFilter}
            testid={`unloggedInteractions.${selectedFilter.toLocaleLowerCase()}.thisMonthList`}
          />
        </div>
      ) : null}
    </WebAppLayout>
  );
};

export default UnloggedInteractions;
