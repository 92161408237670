import { CircularProgress } from '@mui/material';
import React, { memo } from 'react';
import { BiCalendarEvent } from 'react-icons/bi';
import { MdOutlineStickyNote2, MdPerson, MdWork } from 'react-icons/md';

const sizesToTW = {
  sm: '10', // 40px
  md: '16', // 64px
  lg: '20', // 80px
};

const avatarSizes = {
  sm: '21px',
  md: '32px',
  lg: '32px',
};

type IProps = {
  img?: string;
  size?: string;
  initials?: string;
  displayStatus?: 'Starred' | 'Active' | 'Hidden';
  isCompany?: boolean;
  isDraftNote?: boolean;
  isCalendarEvent?: boolean;
};

const CMAvatar: React.FC<IProps> = memo(
  ({
    img,
    size = 'lg',
    initials,
    displayStatus,
    isCompany,
    isDraftNote,
    isCalendarEvent,
  }): JSX.Element => {
    const sizeTW = sizesToTW[size];

    return (
      <div
        className={`overflow-hidden w-${sizeTW} h-${sizeTW} items-center justify-center flex border-2 rounded-full bg-white relative shrink-0 ${
          displayStatus === 'Starred' ? 'border-softPurple' : 'border-paleTeal'
        }`}
      >
        {img ? (
          <>
            <img
              src={img}
              className={`w-full h-full absolute z-10 overflow-hidden rounded-full`}
              alt=""
            />
            {/* Loading indicator that will be hidden once image is loaded */}
            <CircularProgress size={avatarSizes[size]} />
          </>
        ) : // If img is not present we can either display initials, <MdWork>
        // (for companies), <MdOutlineStickyNote2> (for draft notes) or <MdPerson>
        // For now the background color will be `bg-paleTeal` but in the future if
        // we extend this usage we might pick a random color from our palette
        initials ? (
          <div className="w-full h-full text-white bg-paleTeal font-medium flex items-center justify-center">
            <span>{initials}</span>
          </div>
        ) : isCompany ? (
          <MdWork
            size={avatarSizes[size]}
            className={`${
              displayStatus === 'Starred' ? 'text-softPurple' : 'text-paleTeal'
            }`}
          />
        ) : isDraftNote ? (
          <MdOutlineStickyNote2
            size={avatarSizes[size]}
            className="text-paleTeal"
          />
        ) : isCalendarEvent ? (
          <BiCalendarEvent
            size={avatarSizes[size]}
            className="text-softPurple"
          />
        ) : (
          <MdPerson
            size={avatarSizes[size]}
            className={`${
              displayStatus === 'Starred' ? 'text-softPurple' : 'text-paleTeal'
            }`}
          />
        )}
      </div>
    );
  }
);

export default CMAvatar;
