import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import CMButton from '../cm-button/cm-button.component';

import useFeaturesSwitch from '../../hooks/useFeaturesSwitch';

import { AuthContext } from '../../context/auth/auth.context';
import { RemoteConfigContext } from '../../context/remote-config/remote-config.context';

import { getGoPremiumRoute } from '../../utils/helpers';

type IProps = {
  outstandingItems: number;
  forNotes?: boolean;
  forRotations?: boolean;
};

const PaywallHiddenItemsMessage: React.FC<IProps> = ({
  outstandingItems,
  forNotes,
  forRotations,
}) => {
  const { members_portal_migration } = useFeaturesSwitch();

  const { authToken, userData } = useContext(AuthContext);
  const {
    configValues: {
      paywall_max_followups,
      paywall_max_notes,
      paywall_max_rotations,
    },
  } = useContext(RemoteConfigContext);

  const title = forNotes
    ? `+ ${outstandingItems} archived Notes`
    : forRotations
    ? `+ ${outstandingItems} archived open Rotations`
    : `+ ${outstandingItems} archived open Follow-ups`;
  const subtitle = forNotes
    ? `You reached ${paywall_max_notes} notes, your olders notes are archived.`
    : forRotations
    ? `For each Rotation you complete, the closest date from the archived will be unlocked (to have ${paywall_max_rotations}).`
    : `For each Follow-up you complete, the closest date from the archived will be unlocked (to have ${paywall_max_followups}).`;

  return (
    <div className="py-4">
      <div className="font-bold text-2xl">{title}</div>
      <div className="text-xl py-3">{subtitle}</div>
      {members_portal_migration ? (
        <CMButton component={Link} to={getGoPremiumRoute(userData.state)}>
          Go Pro
        </CMButton>
      ) : (
        <CMButton
          component="a"
          href={`${process.env.REACT_APP_MEMBERS_PORTAL_URL}/manage-account/${authToken}`}
        >
          Go Pro
        </CMButton>
      )}
    </div>
  );
};

export default PaywallHiddenItemsMessage;
