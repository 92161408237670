import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import CMButton from '../../components/cm-button/cm-button.component';
import ProfileProgressHeader from '../../components/profile-progress-header/profile-progress-header.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import { AuthContext } from '../../context/auth/auth.context';

import { ProfileContent } from '../public-profile/public-profile.page';

const PreviewProfile = () => {
  const { userData } = useContext(AuthContext);

  const renderActionButtons = () => {
    return (
      <div className="bg-slate">
        <div className="max-w-3xl mx-auto md:flex md:space-x-11 py-7 space-y-6 md:space-y-0 px-6 md:px-0">
          {userData.dbc_enabled !== 'Y' ? (
            <CMButton
              component={Link}
              color="secondary"
              fullWidth
              to="/pick-url"
              data-testid="previewProfile.pickYourUrlButton"
            >
              Pick your URL
            </CMButton>
          ) : (
            <CMButton
              component={Link}
              color="secondary"
              fullWidth
              to="/my-profile"
              data-testid="previewProfile.publishProfileButton"
            >
              Publish
            </CMButton>
          )}
          <CMButton
            component={Link}
            color="tertiary"
            fullWidth
            to="/edit-profile"
            data-testid="previewProfile.keepEditingButton"
          >
            Keep editing
          </CMButton>
        </div>
      </div>
    );
  };

  return (
    <WebAppLayout fullWidth>
      {userData.dbc_enabled !== 'Y' ? (
        <div className="bg-slate text-white">
          <ProfileProgressHeader
            title="Preview your card"
            subtitle="Your digital business card won’t be public until you claim your url"
            percentage="50"
          />
        </div>
      ) : null}
      {renderActionButtons()}
      <div className="max-w-3xl mx-auto py-12 px-4 md:px-0">
        <ProfileContent isPreview={true} pp={userData} />
      </div>
      {renderActionButtons()}
    </WebAppLayout>
  );
};

export default PreviewProfile;
