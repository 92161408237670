import LinkIcon from '@mui/icons-material/Link';
import React from 'react';
import ReactGA from 'react-ga';
import { FaFacebook, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

import CMButton from '../cm-button/cm-button.component';

const iconClasses =
  'inline-flex rounded-full h-16 w-16 items-center justify-center shadow-md bg-slate hover:opacity-95';

const writeText = url => {
  navigator.clipboard
    .writeText(url)
    .then(() => alert('URL Copied to clipboard!'));
};

export const ShareProfileFooter = () => (
  <p className="text-white text-center pt-6 text-sm">
    <strong>REMEMBER</strong>: Your digital business card "tags" anyone who
    opens it to your affiliate ID. If you want to become an affiliate,{' '}
    <a href="mailto:affiliates@contactmapping.com?subject=I’d Like to Become an Affiliate&body=I have a Digital Business Card, and I would like to get a Contact Mapping Affiliate link. Thanks!">
      <strong className="underline text-blueGrey">let us know here</strong>
    </a>
  </p>
);

const ShareProfileContent = ({ url, profileUrl }) => (
  <div>
    <section className="border-b border-silver pt-4 pb-8 text-center">
      <h2 className="text-xl font-bold">Copy and send out your link:</h2>
      <p className="text-slate text-base py-6 break-all">
        <LinkIcon className="text-fadeOrange mr-2" />
        {url}
      </p>
      <CMButton onClick={() => writeText(url)}>Copy URL</CMButton>
    </section>
    <section className="pt-4 pb-8 text-center">
      <h2 className="text-xl font-bold pb-6">
        Share your digital card with the world:
      </h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 max-w-max mx-auto">
        <FacebookShareButton
          url={url}
          quote="I just created a brand new digital business card with all my updated info using Contact Mapping! You can add or update my details on your phone in two taps. Check out how cool this is and then create your own digital card for free!"
          beforeOnClick={() =>
            ReactGA.event({
              category: 'digital business card',
              action: 'Facebook Share',
              label: profileUrl,
            })
          }
        >
          <div className={iconClasses}>
            <FaFacebook color="white" size="24px" />
          </div>
        </FacebookShareButton>
        <TwitterShareButton
          url={url}
          title="I just created a brand new digital business card with all my updated info using @contactmapping! You can add or update my details on your phone in two taps. Check out how cool this is and then create your own digital card for free!"
          beforeOnClick={() =>
            ReactGA.event({
              category: 'digital business card',
              action: 'Twitter Share',
              label: profileUrl,
            })
          }
        >
          <div className={iconClasses}>
            <FaTwitter color="white" size="24px" />
          </div>
        </TwitterShareButton>
        <LinkedinShareButton
          url={url}
          beforeOnClick={() =>
            ReactGA.event({
              category: 'digital business card',
              action: 'LinkedIn Share',
              label: profileUrl,
            })
          }
        >
          <div className={iconClasses}>
            <FaLinkedinIn color="white" size="24px" />
          </div>
        </LinkedinShareButton>
        <EmailShareButton
          url={url}
          subject="My new digital business card"
          body="I just created a brand new digital business card with all my updated info using Contact Mapping! You can add or update my details on your phone in two taps. Check out how cool this is and then create your own digital card for free!"
          beforeOnClick={() =>
            ReactGA.event({
              category: 'digital business card',
              action: 'Email Share',
              label: profileUrl,
            })
          }
        >
          <div className={iconClasses}>
            <MdEmail color="white" size="24px" />
          </div>
        </EmailShareButton>
      </div>
    </section>
  </div>
);

export default ShareProfileContent;
