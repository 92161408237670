import { AxiosError } from 'axios';
import React, { useContext, useState } from 'react';
import { BiCalendarEvent } from 'react-icons/bi';
import { MdPerson, MdStickyNote2 } from 'react-icons/md';

import { CMPasswordInput } from '../../components/change-password/change-password.component';
import CMButton from '../../components/cm-button/cm-button.component';
import CMDialog from '../../components/cm-dialog/cm-dialog.component';

import { AuthContext } from '../../context/auth/auth.context';
import {
  ISnackbarContext,
  SnackbarContext,
} from '../../context/snackbar/snackbar.context';

import { authRequest } from '../../services/auth/auth.service';
import { exportData } from '../../services/users/users.service';

const ExportYourData = () => {
  const {
    userData: { email, id },
  } = useContext(AuthContext);
  const { showSnackbar } = useContext<ISnackbarContext>(SnackbarContext);

  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleShowSnackbar = (message: string) => {
    showSnackbar({
      severity: 'error',
      message: message,
    });
  };

  const handleSubmit = () => {
    if (!password.length) {
      setError('This field is required');
      return;
    }

    if (error.length) {
      setError('');
    }

    setLoading(true);
    authRequest({ email, password })
      .then(({ auth_token }) => exportData(id, email, auth_token, true))
      .then(response => {
        if (response.status === 'N') {
          handleShowSnackbar('Unable to export your data, please try again.');
        }

        setDialogOpen(true);
      })
      .catch((err: AxiosError) => {
        const message =
          err?.response?.status === 401
            ? "There's something wrong with your credentials."
            : 'Unable to export your data, please try again.';

        handleShowSnackbar(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="mt-8 md:mt-16 bg-white text-slate rounded-lg p-8">
      <h3 className="text-3xl font-bold pb-8">Export your data</h3>
      <p>
        An email will be sent to <strong>{email}</strong> with three CSV files
        attached, containing:
      </p>
      <div className="space-y-3 py-3">
        <div className="flex items-center">
          <MdPerson size="24px" />
          <span className="pl-2">Contacts</span>
        </div>
        <div className="flex items-center">
          <BiCalendarEvent size="24px" />
          <span className="pl-2">Follow-ups</span>
        </div>
        <div className="flex items-center">
          <MdStickyNote2 size="24px" />
          <span className="pl-2">Notes</span>
        </div>
      </div>
      <p className="font-bold pt-6 pb-4">
        Please enter your password to generate these files
      </p>
      <div className="grid md:grid-cols-2">
        <CMPasswordInput
          label="PASSWORD"
          id="password"
          error={!!error.length}
          helperText={error}
          value={password}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
          testid="yourAccount.exportYourData.passwordInput"
        />
        <div className="md:col-start-1 pt-8">
          <CMButton
            fullWidth
            type="submit"
            loading={loading}
            disabled={loading}
            onClick={handleSubmit}
          >
            Generate file
          </CMButton>
        </div>
      </div>
      <CMDialog
        width="sm"
        renderContent={
          <div className="text-center space-y-4">
            <div className="text-xl font-bold">Your data is on its way</div>
            <div>
              A copy of your data will be emailed to <br />
              <span className="font-bold">{email}</span> when it is ready.
            </div>
            <div className="text-sm">
              Depending on the file size, the process may take up to 10 minutes.
            </div>
            <CMButton onClick={() => setDialogOpen(false)}>Close</CMButton>
          </div>
        }
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
      />
    </div>
  );
};

export default ExportYourData;
