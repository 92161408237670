import MoreVertIcon from '@mui/icons-material/MoreVert';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useContext, useState } from 'react';
import { BiCalendarEvent } from 'react-icons/bi';
import { FaMobileAlt, FaPhoneAlt } from 'react-icons/fa';
import {
  MdEmail,
  MdPerson,
  MdPersonOff,
  MdStar,
  MdStarBorder,
} from 'react-icons/md';
import { useParams } from 'react-router-dom';

import CMAvatar from '../../components/cm-avatar/cm-avatar.component';
import CMContactSocialProfiles from '../../components/cm-contact-social-profiles/cm-contact-social-profiles.component';
import CMTextButton from '../../components/cm-text-button/cm-text-button.component';
import HTMLText from '../../components/html-text/html-text.component';

import RotationToText from './rotation-to-text.component';

import useFeaturesSwitch from '../../hooks/useFeaturesSwitch';

import { AuthContext } from '../../context/auth/auth.context';
import { SharedDialoguesContext } from '../../context/shared-dialogues/shared-dialogues.context';

import { useContact, useContactRotation } from '../../firebase/firebase.utils';

import { unifyInstantMessageAddresses } from '../../utils/instant-message.utils';
import { getContactDisplayName } from '../../utils/list.utils';

const ContactDetailsActions = ({ displayStatus, updateContact }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateContactDisplayStatus = displayStatus => {
    updateContact({
      displayStatus,
      updatedTimestamp: Math.floor(Date.now() / 1000),
    });
    handleClose();
  };

  const starMenuItem = (
    <MenuItem
      onClick={() => handleUpdateContactDisplayStatus('Starred')}
      data-testid="contactDetails.contactInfo.actionsMenu.starButton"
    >
      <ListItemIcon>
        <MdStar size="21px" />
      </ListItemIcon>
      <ListItemText>Star</ListItemText>
    </MenuItem>
  );
  const unStarMenuItem = (
    <MenuItem
      onClick={() => handleUpdateContactDisplayStatus('Active')}
      data-testid="contactDetails.contactInfo.actionsMenu.unstarButton"
    >
      <ListItemIcon>
        <MdStarBorder size="21px" />
      </ListItemIcon>
      <ListItemText>Un-star</ListItemText>
    </MenuItem>
  );
  const hideMenuItem = (
    <MenuItem
      onClick={() => handleUpdateContactDisplayStatus('Hidden')}
      data-testid="contactDetails.contactInfo.actionsMenu.hideButton"
    >
      <ListItemIcon>
        <MdPersonOff size="21px" />
      </ListItemIcon>
      <ListItemText>Hide</ListItemText>
    </MenuItem>
  );
  const unHideMenuItem = (
    <MenuItem
      onClick={() => handleUpdateContactDisplayStatus('Active')}
      data-testid="contactDetails.contactInfo.actionsMenu.unhideButton"
    >
      <ListItemIcon>
        <MdPerson size="21px" />
      </ListItemIcon>
      <ListItemText>Un-hide</ListItemText>
    </MenuItem>
  );

  return (
    <>
      {/* Actions Icon */}
      <IconButton
        onClick={handleClick}
        data-testid="contactDetails.contactInfo.actionsMenuToggle"
      >
        <MoreVertIcon />
      </IconButton>
      {/* Actions Menu */}
      <Menu
        id="contact-actions-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
      >
        {displayStatus === 'Starred' ? (
          <div>
            {unStarMenuItem}
            {hideMenuItem}
          </div>
        ) : displayStatus === 'Hidden' ? (
          <div>
            {starMenuItem}
            {unHideMenuItem}
          </div>
        ) : (
          <div>
            {starMenuItem}
            {hideMenuItem}
          </div>
        )}
      </Menu>
    </>
  );
};

const ContactDetailsHighlights = ({ highlights }) => {
  const [completeTextVisible, setCompleteTextVisible] = useState(false);

  const htmlHighlight = (
    <HTMLText
      testid="contactDetails.contactInfo.longHighlightsText"
      text={highlights}
      htmlElement="span"
      removeLinks
    />
  );

  if (highlights.replace(/(\r\n|\r|\n)/g, '').trim().length <= 180)
    return <div>{htmlHighlight}</div>;

  const shortHtmlHighlight = (
    <HTMLText
      testid="contactDetails.contactInfo.shortHighlightsText"
      text={highlights}
      size={180}
      htmlElement="span"
      removeLinks
    />
  );

  return (
    <div>
      {!completeTextVisible ? (
        <p>
          {shortHtmlHighlight}{' '}
          <button
            className="text-fadeOrange font-bold uppercase underline"
            onClick={() => setCompleteTextVisible(true)}
            data-testid="contactDetails.contactInfo.seeMoreHighlightsButton"
          >
            See More
          </button>
        </p>
      ) : (
        <p>
          {htmlHighlight}{' '}
          <button
            className="text-fadeOrange font-bold uppercase underline"
            onClick={() => setCompleteTextVisible(false)}
            data-testid="contactDetails.contactInfo.hideHighlightsButton"
          >
            Hide
          </button>
        </p>
      )}
    </div>
  );
};

const ContactInfo = ({ setActiveTab }) => {
  const { userId } = useContext(AuthContext);
  const { contactId } = useParams();
  const { rotation } = useFeaturesSwitch();

  const { openRotationDialog } = useContext(SharedDialoguesContext);

  const { data: dataContact, update } = useContact(userId, contactId);
  const { data: dataContactRotation } = useContactRotation(userId, contactId);

  if (!dataContact || !dataContactRotation)
    return (
      <div className="bg-white text-center py-24">
        <CircularProgress />
      </div>
    );

  return (
    <section
      className="bg-white sticky -top-[1px]"
      data-testid="contactDetails.contactInfo"
    >
      <div className="flex justify-end p-4 items-center space-x-2">
        {dataContact.displayStatus === 'Starred' ? (
          <MdStar
            size="24px"
            className="text-softPurple"
            data-testid="contactDetails.contactInfo.starredIcon"
          />
        ) : dataContact.displayStatus === 'Hidden' ? (
          <MdPersonOff
            size="24px"
            className="text-slate"
            data-testid="contactDetails.contactInfo.hiddenIcon"
          />
        ) : (
          <MdPerson
            size="24px"
            className="text-slate"
            data-testid="contactDetails.contactInfo.activeIcon"
          />
        )}
        <ContactDetailsActions
          displayStatus={dataContact.displayStatus}
          updateContact={update}
        />
      </div>
      <div className="py-4 md:p-4 border-b border-silver">
        <div className="flex items-center space-x-4">
          <CMAvatar
            size="md"
            displayStatus={dataContact.displayStatus}
            isCompany={!dataContact.fullName.length}
          />
          <div>
            <div className="text-xl font-bold">
              {getContactDisplayName(
                dataContact?.fullName,
                dataContact?.company,
                dataContact?.phones[0]?.number,
                dataContact?.emails[0]?.email
              )}
            </div>
            {dataContact.company ? <div>{dataContact.company}</div> : null}
          </div>
        </div>
      </div>
      <div className="py-4 md:p-4">
        {/* Highlight */}
        <div className="mb-8">
          <div className="font-bold uppercase mb-4">Highlights</div>
          <div>
            <ContactDetailsHighlights
              highlights={
                dataContact.highlights ||
                'Where did you meet? Do they have kids? What else do you know about them?'
              }
            />
          </div>
          {!dataContact.highlights && (
            <CMTextButton onClick={() => setActiveTab(2)}>
              Create highlights
            </CMTextButton>
          )}
        </div>
        {/* Rotation */}
        {rotation ? (
          <div className="mb-8">
            <div className="font-bold uppercase">Rotation of Remember</div>
            {!dataContactRotation.length ? (
              <CMTextButton
                onClick={() =>
                  openRotationDialog({ rotationId: null, contactId })
                }
              >
                Set a Rotation
              </CMTextButton>
            ) : (
              <div className="flex items-center space-x-2 pt-2">
                <BiCalendarEvent size="24px" />
                <RotationToText
                  rotationFrequency={dataContactRotation[0].frequency}
                  rotationInterval={dataContactRotation[0].interval}
                  rotationNextIterationDate={
                    dataContactRotation[0].nextIterationDate
                  }
                />
              </div>
            )}
          </div>
        ) : null}

        <div className="font-bold uppercase mb-4">Social Media</div>
        <CMContactSocialProfiles
          socialProfiles={unifyInstantMessageAddresses(
            dataContact.instantMessageAddresses,
            dataContact.instantMessageAddressesCM
          )}
          useGrid
          testid="contactDetails.contactInfo"
        />
        {dataContact.phones.length || dataContact.emails.length ? (
          <>
            <div className="font-bold uppercase mt-8 mb-4">Contact Details</div>
            <ul className="space-y-4">
              {dataContact.phones.map(({ label, number }, index) => (
                <li
                  key={label + number}
                  className="flex items-center space-x-4"
                >
                  {label.toLowerCase() === 'mobile' ? (
                    <FaMobileAlt className="text-steel" size="24px" />
                  ) : (
                    <FaPhoneAlt className="text-steel" size="24px" />
                  )}
                  <div>
                    <div className="text-steel">{label.toUpperCase()}</div>
                    <a
                      href={`${
                        label.toLowerCase() === 'mobile' &&
                        navigator?.userAgent?.toLowerCase().indexOf('mac') > -1
                          ? 'sms'
                          : 'tel'
                      }:${number}`}
                      data-testid={`contactDetails.contactInfo.phones.${index}.number`}
                    >
                      {number}
                    </a>
                  </div>
                </li>
              ))}
              {dataContact.emails.map(({ label, email }, index) => (
                <li key={label + email} className="flex items-center space-x-4">
                  <MdEmail className="text-steel" size="24px" />
                  <div>
                    <div className="text-steel">{label.toUpperCase()}</div>
                    <a
                      href={`mailto:${email}`}
                      data-testid={`contactDetails.contactInfo.emails.${index}.email`}
                    >
                      {email}
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </div>
    </section>
  );
};

export default ContactInfo;
