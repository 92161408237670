import React, { memo } from 'react';

import ContactRow from '../contact-row/contact-row.component';
import PaginatedList from '../paginated-list/paginated-list.component';

const renderContactItem = (
  { id, fullName, company, note, notesCount, emails, phones, displayStatus },
  testid,
  page,
  index
) => (
  <ContactRow
    key={id}
    contactId={id}
    fullName={fullName}
    company={company}
    note={note}
    notesCount={notesCount}
    email={emails?.[0]?.email}
    phoneNumber={phones?.[0]?.number}
    displayStatus={displayStatus}
    testid={`${testid}.paginatedList.page${page}.item${index}`}
  />
);

const ContactsList = memo(
  ({ list = [], listSize = 25, searchTerm, filter, testid }) => {
    return (
      <>
        {/* Grid header */}
        <div className="bg-white hidden md:grid grid-cols-8 uppercase font-bold py-4 px-8 border-b border-silver">
          <div className="col-span-3">Name</div>
          <div>Notes</div>
          <div className="col-span-2">Email</div>
          <div className="col-span-2">Phone Number</div>
          <div></div>
        </div>
        {/* Grid Row */}
        {/*
          If we need async data loading we can use <SkeletonLoader> while data loads.
          Check code from commit 1e5ba0700dfc1768a6e505c4c644dda0acea68c5
          https://github.com/contactmapping/cmweb/blob/1e5ba0700dfc1768a6e505c4c644dda0acea68c5/src/pages/contacts/contacts.page.jsx
          for a working example of how different loading happened. This was removed
          so that we load the entire list at one to know the size of the contact list.
          Keeping this piece of code for future reference
        */}
        {/*
          {!dataContacts && initialDataLoaded ? (
            // Skeleton loader
            <SkeletonLoader />
          ) : (
        */}
        <PaginatedList
          list={list}
          listSize={listSize}
          renderItem={renderContactItem}
          shouldSetURLParam={true}
          paginationColor="primary"
          searchTerm={searchTerm}
          filter={filter}
          testid={`${testid}.listRow`}
        />
      </>
    );
  }
);

export default ContactsList;
