import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AuthContext } from '../context/auth/auth.context';

import { tryTokenRequest } from '../services/auth/auth.service';

const useTryToken = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    authToken: contextAuthToken,
    userData,
    performCommonSignInTasks,
    handleAuthTokenError,
  } = useContext(AuthContext);

  const urlAuthToken = searchParams.get('auth');
  const authToken = urlAuthToken || contextAuthToken;

  const [tryTokenError, setTryTokenError] = useState(false);
  const [isPerformingTryToken, setIsPerformingTryToken] = useState(
    authToken && !userData
  );

  const internalIsPerformingTryToken = useRef(false);

  const tryToken = useCallback(() => {
    setIsPerformingTryToken(true);
    setTryTokenError(false);
    internalIsPerformingTryToken.current = true;

    tryTokenRequest(authToken)
      .then(({ userData, authToken, settings }) => {
        if (urlAuthToken) {
          // Delete `auth` param from URL
          searchParams.delete('auth');
          setSearchParams(searchParams);
        }

        performCommonSignInTasks(userData, authToken, settings);
      })
      .catch(error => {
        if (error.response?.status === 401) {
          // If a token was already stored in `localStorage` and throws a 401
          // not authorized error, let's remove it
          handleAuthTokenError();
        }

        setTryTokenError(true);
      })
      .finally(() => {
        internalIsPerformingTryToken.current = false;
        setIsPerformingTryToken(false);
      });
  }, [
    authToken,
    performCommonSignInTasks,
    handleAuthTokenError,
    searchParams,
    setSearchParams,
    urlAuthToken,
  ]);

  useEffect(() => {
    if (userData || internalIsPerformingTryToken.current) return;

    if (authToken) tryToken();
  }, [userData, authToken, tryToken]);

  return {
    isPerformingTryToken,
    tryToken,
    tryTokenError,
  };
};

export default useTryToken;
