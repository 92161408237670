import { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

const useURLParams = (defaultFilter: string) => {
  const filterParam = new URLSearchParams(useLocation().search).get('filter');

  const searchTermParam = new URLSearchParams(useLocation().search).get(
    'generalSearchTerm'
  );

  const [selectedFilter, setSelectedFilter] = useState(
    filterParam || defaultFilter
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchTermParam || '');

  useEffect(() => {
    searchParams.set('filter', selectedFilter);
    setSearchParams(searchParams);
  }, [selectedFilter, searchParams, setSearchParams]);

  useEffect(() => {
    const filter = searchTerm.trim().replace(/  +/g, ' ');

    if (filter) {
      searchParams.set('generalSearchTerm', filter);
    } else {
      searchParams.delete('generalSearchTerm');
    }

    setSearchParams(searchParams);
    setSearchTerm(searchTerm);
  }, [searchTerm, searchParams, setSearchParams, setSearchTerm]);

  const handleDisplayStatusChange = (newValue: string) => {
    setSelectedFilter(newValue);
  };

  return {
    selectedFilter,
    searchTerm,
    searchParams,
    handleDisplayStatusChange,
    setSearchTerm,
    setSearchParams,
    setSelectedFilter,
  };
};

export default useURLParams;
