import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ChangePasswordForm } from '../../components/change-password/change-password.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import {
  ISnackbarContext,
  SnackbarContext,
} from '../../context/snackbar/snackbar.context';

import { setNewUserPassword } from '../../services/users/users.service';

import { parseJwt } from '../../utils/token.utils';

import logo from '../../assets/images/logo-dark.png';

const ResetPassword = () => {
  const { showSnackbar } = useContext<ISnackbarContext>(SnackbarContext);
  const navigate = useNavigate();
  const { token } = useParams();

  const handleSubmitNewPassword = (password: string) => {
    const data = parseJwt(token);
    const nowUnix = Math.floor(Date.now() / 1000);

    const tokenDataInvalidOrExpired = !data || data?.exp < nowUnix;

    if (tokenDataInvalidOrExpired) {
      showSnackbar({
        severity: 'error',
        message: 'Your reset password link expired.',
      });

      return Promise.resolve();
    }

    return setNewUserPassword(data.user_id, password, token)
      .then(() => {
        showSnackbar({
          severity: 'success',
          message: 'Your password was changed succesfully.',
        });
        navigate('/signin');
      })
      .catch(() => {
        showSnackbar({
          severity: 'error',
          message: 'Something wrong happened.',
        });
      });
  };

  return (
    <WebAppLayout hiddenHeader fullWidth>
      <div className="bg-slate bg-login bg-blend-multiply bg-no-repeat bg-center w-full h-screen items-center flex">
        <div className="h-full w-full flex justify-center items-center mx-8">
          <div className="bg-white shadow-lg rounded-lg px-12 pt-12 pb-8 m-auto md:w-3/5 lg:w-1/3 space-y-8">
            <img
              src={logo}
              alt="Contact Mapping"
              className="w-[180px] mx-auto"
            />
            <div className="text-center">
              <h1 className="text-3xl font-bold">Let's set up your password</h1>
            </div>
            <ChangePasswordForm
              passwordLabel="New Password"
              showHelper
              submitMethod={handleSubmitNewPassword}
              testid="resetPassword"
            />
          </div>
        </div>
      </div>
    </WebAppLayout>
  );
};

export default ResetPassword;
