import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * https://reactrouter.com/web/guides/scroll-restoration/scroll-to-top
 * This component will check for path change and will scroll to top to avoid
 * scroll persist on route change
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
