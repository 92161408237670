import { useMediaQuery, useTheme } from '@mui/material';
import React, { memo } from 'react';

import CMAvatar from '../cm-avatar/cm-avatar.component';

type IProps = {
  date: string;
  contactDisplayStatus?: 'Starred' | 'Active' | 'Hidden';
  contactDisplayName: string;
  mainContent: React.ReactNode;
  onItemClick: () => void;
  actionButtons?: React.ReactNode;
  testid: string;
};

const SmallItemCard: React.FC<IProps> = memo(
  ({
    date,
    contactDisplayStatus,
    contactDisplayName,
    mainContent,
    onItemClick,
    actionButtons,
    testid,
  }) => {
    const theme = useTheme();
    const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

    return (
      <div
        className={`border-b md:border border-silver py-5 md:p-5 rounded bg-white flex flex-col ${
          onItemClick instanceof Function ? 'cursor-pointer' : ''
        }`}
        data-testid={testid}
        onClick={
          matchesMd && onItemClick instanceof Function
            ? onItemClick
            : () => null
        }
      >
        {date ? (
          <div className="text-right uppercase font-bold pb-2">{date}</div>
        ) : null}
        <div className="flex items-center">
          <CMAvatar size="sm" displayStatus={contactDisplayStatus} />
          <div className="pl-2 font-medium">{contactDisplayName}</div>
        </div>
        <div className="pt-2 grow">{mainContent}</div>
        {matchesMd && actionButtons ? (
          <div className="pt-2 mt-2 border-t border-t-silver">
            {actionButtons}
          </div>
        ) : null}
      </div>
    );
  }
);

export default SmallItemCard;
