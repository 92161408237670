import React, { useRef, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';

import CMButton from '../../components/cm-button/cm-button.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import { requestPasswordReset } from '../../services/auth/auth.service';

import logo from '../../assets/images/logo-dark.png';

const ForgotPassword = () => {
  const formRef = useRef(null);

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [resetRequested, setResetRequested] = useState(false);

  const setRequestSuccessful = () => {
    setResetRequested(true);
    setError(false);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setLoading(true);

    requestPasswordReset(email)
      .then(setRequestSuccessful)
      .catch(err => {
        if (err?.response?.status === 401) {
          setRequestSuccessful();
        } else {
          setError(true);
          setResetRequested(false);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <WebAppLayout hiddenHeader fullWidth>
      <div className="bg-slate bg-login bg-blend-multiply bg-no-repeat bg-center w-full h-screen items-center flex">
        <ValidatorForm
          className="h-full w-full flex justify-center items-center mx-8"
          noValidate
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <div className="bg-white shadow-lg rounded-lg px-12 pt-12 pb-8 m-auto md:w-3/5 lg:w-1/3 space-y-8">
            <img
              src={logo}
              alt="Contact Mapping"
              className="w-[180px] mx-auto"
            />
            {resetRequested ? (
              <>
                <div data-testid="forgotPassword.confirmationText">
                  If your email address exists in our database, you will receive
                  an email with the instructions to reset your password.
                </div>
                <div>Please remember to check your spam folder.</div>
                <CMButton
                  component={Link}
                  fullWidth
                  id="login"
                  outline={true}
                  to="/signin"
                  data-testid="forgotPassword.backToLogin"
                >
                  Go back to login
                </CMButton>
              </>
            ) : (
              <>
                <p data-testid="forgotPassword.pleaseEnterEmailText">
                  Please, enter the email address you used to register so we can
                  start the reset process.
                </p>
                <TextValidator
                  label="Email"
                  className="w-full"
                  variant="outlined"
                  id="email"
                  onChange={event => setEmail(event.target.value)}
                  value={email}
                  required
                  validators={['required', 'isEmail']}
                  errorMessages={[
                    'This field is required',
                    'Not a valid email',
                  ]}
                  InputLabelProps={{ style: { fontSize: '1rem' } }}
                  inputProps={{
                    style: { fontSize: '1rem' },
                    'data-testid': 'forgotPassword.emailInput',
                  }}
                />

                <div className="space-y-6">
                  {error && (
                    <div
                      className="text-error font-bold text-xs text-center"
                      data-testid="forgotPassword.errorText.incorrectEmail"
                    >
                      There's been an error, please try again.
                    </div>
                  )}
                  <CMButton
                    disabled={loading}
                    loading={loading}
                    id="submit"
                    type="submit"
                    fullWidth
                    data-testid="forgotPassword.submit"
                  >
                    Submit
                  </CMButton>
                </div>
              </>
            )}
          </div>
        </ValidatorForm>
      </div>
    </WebAppLayout>
  );
};

export default ForgotPassword;
