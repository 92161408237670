import { useMediaQuery } from '@mui/material';
import React, { memo, useContext } from 'react';
import { MdCheck, MdEdit } from 'react-icons/md';

import CMAvatar from '../../components/cm-avatar/cm-avatar.component';
import CMContactSocialProfiles from '../../components/cm-contact-social-profiles/cm-contact-social-profiles.component';
import CMTextButton from '../../components/cm-text-button/cm-text-button.component';
import HTMLText from '../../components/html-text/html-text.component';

import ReadMore from '../read-more/read-more.component';

import { AuthContext } from '../../context/auth/auth.context';
import { SharedDialoguesContext } from '../../context/shared-dialogues/shared-dialogues.context';

import { useContact } from '../../firebase/firebase.utils';

import { formatDate } from '../../utils/dates.utils';
import { unifyInstantMessageAddresses } from '../../utils/instant-message.utils';
import { getContactDisplayName } from '../../utils/list.utils';

const FollowUpItem = memo(
  ({ id, contactId, followupTimestamp, body, timeBased, testid }) => {
    const { userId } = useContext(AuthContext);
    const { openFollowUpDialog } = useContext(SharedDialoguesContext);

    const matchesMd = useMediaQuery(theme => theme.breakpoints.up('md'));

    const { data: dataContact } = useContact(userId, contactId);

    const socialProfiles = unifyInstantMessageAddresses(
      dataContact?.instantMessageAddresses,
      dataContact?.instantMessageAddressesCM
    );

    const handleItemClick = mode => {
      openFollowUpDialog({
        followUpId: id,
        contactId: contactId,
        isEditing: mode === 'edit',
        isDone: mode === 'done',
        closeOnCancelEdit: mode === 'edit',
      });
    };

    return (
      <div
        className="rounded border border-silver bg-white p-8 mb-2 flex"
        data-testid={testid}
      >
        <div
          className="flex-grow md:pr-8"
          data-testid={`${testid}.viewDetailsButton`}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <CMAvatar size="sm" displayStatus={dataContact?.displayStatus} />{' '}
              <div className="font-bold pl-4">
                {getContactDisplayName(
                  dataContact?.fullName,
                  dataContact?.company,
                  dataContact?.phones[0]?.number,
                  dataContact?.emails[0]?.email
                )}
              </div>
            </div>
            <div className="text-sm font-bold">
              {formatDate(
                new Date(Math.round(followupTimestamp * 1000)),
                timeBased
              )}
            </div>
          </div>
          <div className="pt-4">
            <CMContactSocialProfiles
              loading={!dataContact}
              socialProfiles={socialProfiles}
              size="sm"
              testid={`${testid}.socialProfiles`}
            />
          </div>
          {body ? (
            <div className="pt-4">
              <ReadMore
                renderActionButton={
                  // onClick function is not available for mobile
                  matchesMd ? (
                    <CMTextButton onClick={handleItemClick} color="secondary">
                      Open Follow-Up
                    </CMTextButton>
                  ) : null
                }
              >
                <HTMLText testid={`${testid}.body`} text={body} />
              </ReadMore>
            </div>
          ) : null}
        </div>
        <div className="p-4 w-48 shrink-0 hidden md:flex flex-col items-start justify-center border-l border-silver">
          <CMTextButton
            icon={MdEdit}
            color="secondary"
            onClick={() => handleItemClick('edit')}
            data-testid={`${testid}.editButton`}
          >
            Edit
          </CMTextButton>
          <CMTextButton
            icon={MdCheck}
            color="secondary"
            onClick={() => handleItemClick('done')}
            data-testid={`${testid}.completeButton`}
          >
            Mark as Done
          </CMTextButton>
        </div>
      </div>
    );
  }
);

export default FollowUpItem;
