import _uniqBy from 'lodash/uniqBy';

import { contactDetailsSocialMedia } from './networks';

/**
 * This method unifies IM Addresses with a base array. It's intended for both
 * presentation and to have a reference array when saving to Firestore, since
 * we always save the entire IM adddresses array.
 * Please make sure this method is the same all over our apps.
 */
export const unifyInstantMessageAddresses = (
  originalArray: object[] = [],
  prioritaryArray: object[] = []
) => {
  const supportedServices: string[] = contactDetailsSocialMedia.map(e =>
    e.name.toLowerCase()
  );

  const arrayNormalizr = imArray =>
    imArray
      .filter(im => {
        return (
          // Filter non empty social profiles
          im.username !== '' &&
          supportedServices.includes(im.service?.trim().toLowerCase())
        );
      })
      .map(im => ({
        ...im,
        service: im.service.trim().toLowerCase(),
      }));

  const baseArray = supportedServices.map(service => ({
    service,
    username: '',
  }));

  // Create an array removing duplicated objects based on the service name from
  // top to bottom
  const finalArray = _uniqBy(
    [
      ...arrayNormalizr(prioritaryArray),
      ...arrayNormalizr(originalArray),
      ...baseArray,
    ],
    'service'
  );

  return finalArray;
};
