import firebase from 'firebase/app';
import 'firebase/remote-config';
import React, { createContext, useEffect, useState } from 'react';

export const RemoteConfigContext = createContext();

// This is the Remote Config Context file. We'll save everything from remote
// config here, every time a new config is added we need to do the same thing
// here, to avoid all the boilerplate code in each component that needs the data
export const RemoteConfigContextProvider = ({ children }) => {
  const remoteConfig = firebase.remoteConfig();
  remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

  const [configValues, setConfigValues] = useState({});
  const [featuresSwitch, setFeaturesSwitch] = useState({});

  useEffect(() => {
    remoteConfig.defaultConfig = {
      bio_help_url: '',
      privacy_policy_url: '',
      paywall_max_followups: 10,
      paywall_max_notes: 5,
      paywall_max_rotations: 10,
      paywall_max_special_dates: 10,
      play_store_url:
        'https://play.google.com/store/apps/details?id=com.contactmapping.android',
      start_training_calendly_url:
        'https://calendly.com/elizabeth-l/live-onboarding?month=2022-05',
      training_events_url: 'https://community.contactmapping.com/events',
      community_url: 'https://community.contactmapping.com/landing/plans/62847',
      chrome_extension_url:
        'https://chrome.google.com/webstore/detail/contact-mapping-extension/nodcipflaegppagnfchjbfibolclhiol?hl=en-GB',
      apple_store_url:
        'https://itunes.apple.com/us/app/contact-mapping/id1337928740?ls=1&mt=8',
      web_features_switch: {
        paywall: false,
        web_app_free: false,
        members_portal_migration: false,
        time_based_follow_ups: false,
        special_dates: false,
        calendar_view: false,
        rotation: false,
      },
      welcome_video:
        'https://player.vimeo.com/video/759200242?h=7100fba473&badge=0&autopause=0&player_id=0&app_id=58479/embed',
      demo_video:
        'https://player.vimeo.com/video/751777213?h=4fc924a3f9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      calendly_welcome_basic_url:
        'https://calendly.com/contact-mapping/business-strategy',
      calendly_welcome_pro_url:
        'https://calendly.com/contact-mapping/meet-and-great',
      community_calendar_url:
        'https://calendly.com/contact-mapping/daily-launch',
      proven_path_payment_terms: '',
      proven_path_pricekey: '',
    };
    remoteConfig
      .fetchAndActivate()
      .then(() => {
        return remoteConfig.getAll();
      })
      .then(response => {
        const configValues = {
          bio_help_url: response.bio_help_url?.asString(),
          privacy_policy_url: response.privacy_policy_url?.asString(),
          paywall_max_followups: response.paywall_max_followups?.asNumber(),
          paywall_max_notes: response.paywall_max_notes?.asNumber(),
          paywall_max_rotations: response.paywall_max_rotations?.asNumber(),
          paywall_max_special_dates:
            response.paywall_max_special_dates?.asNumber(),
          play_store_url: response.play_store_url?.asString(),
          training_events_url: response.training_events_url?.asString(),
          community_url: response.community_url?.asString(),
          chrome_extension_url: response.chrome_extension_url?.asString(),
          apple_store_url: response.apple_store_url?.asString(),
          start_training_calendly_url:
            response.start_training_calendly_url?.asString(),
          welcome_video: response.welcome_video?.asString(),
          demo_video: response.demo_video?.asString(),
          calendly_welcome_basic_url:
            response.calendly_welcome_basic_url?.asString(),
          calendly_welcome_pro_url:
            response.calendly_welcome_pro_url?.asString(),
          community_calendar_url: response.community_calendar_url?.asString(),
          proven_path_payment_terms:
            response.proven_path_payment_terms?.asString(),
          proven_path_pricekey: response.proven_path_pricekey?.asString(),
        };

        // We'll split feature switches into it's own piece of state
        const featuresSwitch = JSON.parse(
          response.web_features_switch?.asString() ?? {}
          // FS Values:
          // paywall
          // web_app_free
          // members_portal_migration
          // time_based_follow_ups
          // special_dates
          // calendar_view
          // rotation
        );

        setConfigValues(configValues);
        setFeaturesSwitch(featuresSwitch);
      });
  }, [remoteConfig]);

  return (
    <RemoteConfigContext.Provider value={{ configValues, featuresSwitch }}>
      {children}
    </RemoteConfigContext.Provider>
  );
};
