import CircularProgress from '@mui/material/CircularProgress';
import _partition from 'lodash/partition';
import React, { memo, useContext } from 'react';
import { FaRegStickyNote } from 'react-icons/fa';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useParams } from 'react-router-dom';

import CMButton from '../../components/cm-button/cm-button.component';
import CMTextButton from '../../components/cm-text-button/cm-text-button.component';
import HTMLText from '../../components/html-text/html-text.component';
import PaywallHiddenItemsMessage from '../../components/paywall-hidden-items-message/paywall-hidden-items-message.component';
import ReadMore from '../../components/read-more/read-more.component';

import useNoteActions from '../../hooks/useNoteActions';

import { AuthContext } from '../../context/auth/auth.context';
import { PaywallContext } from '../../context/paywall/paywall.context';
import { SharedDialoguesContext } from '../../context/shared-dialogues/shared-dialogues.context';

import { useContactNotes } from '../../firebase/firebase.utils';

import { formatDate } from '../../utils/dates.utils';

const Note = memo(
  ({
    createdTimestamp,
    body,
    userId,
    noteId,
    contactId,
    onNoteActionsClick,
    testid,
  }) => {
    const { handleDeleteNote } = useNoteActions(userId, noteId, contactId);

    return (
      <div className="p-4 flex bg-white">
        <div className="flex-grow pr-4">
          <div
            className="font-bold pb-3 uppercase"
            data-testid={`${testid}.date`}
          >
            {formatDate(new Date(Math.round(createdTimestamp * 1000)))}
          </div>
          <ReadMore
            renderActionButton={
              <CMTextButton
                onClick={onNoteActionsClick}
                data-testid={`${testid}.openButton`}
              >
                Open Note
              </CMTextButton>
            }
          >
            <HTMLText text={body} testid={`${testid}.body`} />
          </ReadMore>
        </div>
        <div className="p-4 w-36 shrink-0 flex flex-col items-start justify-center border-l border-silver">
          <CMTextButton
            icon={MdEdit}
            onClick={() => onNoteActionsClick('edit')}
            data-testid={`${testid}.editButton`}
          >
            Edit
          </CMTextButton>
          <CMTextButton
            icon={MdDelete}
            onClick={handleDeleteNote}
            data-testid={`${testid}.deleteButton`}
          >
            Delete
          </CMTextButton>
        </div>
      </div>
    );
  }
);

const ContactNotes = memo(({ nativeNote }) => {
  const { userId } = useContext(AuthContext);
  const { openNoteDialog } = useContext(SharedDialoguesContext);
  const { latestNotesIDs, hasReachedMaxNotes } = useContext(PaywallContext);

  const { contactId } = useParams();

  const { data: dataNotes } = useContactNotes(userId, contactId);

  const handleNoteActionsClick = (mode, noteId) => {
    openNoteDialog({
      noteId,
      contactId: contactId,
      isEditing: mode === 'edit',
      closeOnCancelEdit: mode === 'edit',
    });
  };

  if (!dataNotes)
    return (
      <div className="text-center py-24">
        <CircularProgress />
      </div>
    );

  const [availableNotes, archivedNotes] = hasReachedMaxNotes
    ? _partition(dataNotes, ({ id }) => latestNotesIDs.includes(id))
    : [dataNotes, []];

  // Empty State
  if (nativeNote.length === 0 && dataNotes.length === 0)
    return (
      <div className="flex flex-col items-center content-center py-32">
        <FaRegStickyNote className="text-steel" size="40px" />
        <div className="text-xl py-8">You have no notes for this contact</div>
        <CMButton
          onClick={() => handleNoteActionsClick('edit')}
          data-testid="contactDetails.contactNotes.addFirstNoteButton"
        >
          Add your first note
        </CMButton>
      </div>
    );

  return (
    <section>
      <div className="flex items-center justify-between p-4">
        <h1>
          Notes ({nativeNote.length ? dataNotes.length + 1 : dataNotes.length})
        </h1>
        <CMButton
          onClick={() => handleNoteActionsClick('edit')}
          data-testid="contactDetails.contactNotes.addNoteButton"
        >
          Add a Note
        </CMButton>
      </div>
      <ul>
        {/* Contacts can have a native note (`note` property in Firestore). */}
        {nativeNote.length ? (
          <li className="p-4 border-b border-silver last:border-b-0">
            <div
              className="font-bold pb-3 uppercase"
              data-testid="contactDetails.contactNotes.nativeNote.date"
            >
              From address book
            </div>
            <div data-testid="contactDetails.contactNotes.nativeNote.body">
              {nativeNote}
            </div>
          </li>
        ) : null}
        {/* All other notes (Actionable notes from CM) */}
        {availableNotes.length
          ? availableNotes.map(({ id, createdTimestamp, body }, index) => (
              <li key={id} className="border-b border-silver last:border-b-0">
                <Note
                  createdTimestamp={createdTimestamp}
                  body={body}
                  userId={userId}
                  noteId={id}
                  contactId={contactId}
                  onNoteActionsClick={mode => handleNoteActionsClick(mode, id)}
                  testid={`contactDetails.contactNotes.${index}`}
                />
              </li>
            ))
          : null}
      </ul>
      {archivedNotes.length ? (
        <div className="bg-dirtyWhite pt-4">
          <PaywallHiddenItemsMessage
            outstandingItems={archivedNotes.length}
            forNotes
          />
        </div>
      ) : null}
    </section>
  );
});

export default ContactNotes;
