import ReactGA from 'react-ga';
import { BiCalendarAlt } from 'react-icons/bi';
import { FaMobileAlt, FaPhoneAlt } from 'react-icons/fa';
import { MdHome, MdWork } from 'react-icons/md';
import { VscGlobe } from 'react-icons/vsc';

const formatPhoneNumber = phoneNumberString => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return cleaned;
};

export const contactDetails = [
  {
    label: 'Mobile',
    icon: <FaMobileAlt className="text-slate m-auto" size="24px" />,
    content: pp => {
      if (pp.phones && pp.phones.length >= 1)
        return (
          <a
            href={`tel://${pp.phones[0].number}`}
            className="text-fadeOrange font-rasa text-xl"
            onClick={() =>
              ReactGA.event({
                category: 'digital business card',
                action: 'Mobile Phone',
                label: pp.profileUrl,
              })
            }
          >
            {formatPhoneNumber(pp.phones[0].number)}
          </a>
        );

      return null;
    },
  },
  {
    label: 'Phone',
    icon: <FaPhoneAlt className="text-slate m-auto" size="24px" />,
    content: pp => {
      const phone = pp.phones?.find(
        phone => phone.label === 'Home' && phone.number
      );

      if (phone === undefined) return null;

      return (
        <a
          href={`tel://${phone.number}`}
          className="text-fadeOrange font-rasa text-xl"
        >
          {formatPhoneNumber(phone.number)}
        </a>
      );
    },
  },
  {
    label: 'Work',
    icon: <MdWork className="text-slate m-auto" size="24px" />,
    content: pp => {
      if (pp.title)
        return (
          <div className="font-rasa text-lg">{`${pp.title} ${
            pp.organization ? ` at ${pp.organization}` : ``
          }`}</div>
        );

      return null;
    },
  },
  {
    label: 'Website',
    icon: <VscGlobe className="text-slate m-auto" size="24px" />,
    content: pp => {
      if (pp.website)
        return (
          <a
            href={`https://${pp.website}`}
            target="_blank"
            rel="noreferrer"
            className="text-fadeOrange font-rasa text-lg"
          >
            {`https://${pp.website}`}
          </a>
        );
      return null;
    },
  },
  {
    label: 'Calendly',
    icon: <BiCalendarAlt size="24px" className="text-slate m-auto" />,
    content: pp => {
      if (pp.calendly)
        return (
          <a
            href={`https://calendly.com/${pp.calendly}`}
            target="_blank"
            rel="noreferrer"
            className="text-fadeOrange font-rasa text-lg"
          >
            {`https://calendly.com/${pp.calendly}`}
          </a>
        );
      return null;
    },
  },
  {
    label: 'Home',
    icon: <MdHome className="text-slate m-auto" size="24px" />,
    content: pp => {
      const addressString = Object.values(pp.address ?? {})
        .filter(i => i)
        .join(', ');
      if (addressString.length)
        return <div className="font-rasa text-lg">{addressString}</div>;

      return null;
    },
  },
];
