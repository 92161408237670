import React, { useContext, useState } from 'react';
import { IoMdCalendar } from 'react-icons/io';
import { MdCake } from 'react-icons/md';

import CMButton from '../../cm-button/cm-button.component';
import CMDialog from '../../cm-dialog/cm-dialog.component';
import CMTextButton from '../../cm-text-button/cm-text-button.component';
import DateCompleteDialogContent from '../../date-complete-dialog-content/date-complete-dialog-content.component';

import useSpecialDatesActions from '../../../hooks/useSpecialDatesActions';

import { SharedDialoguesContext } from '../../../context/shared-dialogues/shared-dialogues.context';

import SpecialDate from '../../../models/special-dates.model';

const SpecialDateCompleteDialog: React.FC = () => {
  const {
    specialDateCompleteDialogOpen,
    closeSpecialDateCompleteDialog,
    closeSpecialDateViewDialog,
    specialDateDialogData,
  } = useContext(SharedDialoguesContext);

  const { contactId, specialDateId } = specialDateDialogData;

  const [noteBody, setNoteBody] = useState<string>('');

  const { dataSpecialDate, handleDismissSpecialDate } = useSpecialDatesActions(
    specialDateId,
    contactId
  );

  if (!dataSpecialDate) return null;

  const specialDate = new SpecialDate(dataSpecialDate);

  const onSpecialDateDismiss = (shouldAddCustomNote: boolean): void => {
    handleDismissSpecialDate(
      shouldAddCustomNote ? noteBody : `${specialDate.typeLabel} touch`
    ).then(() => {
      closeDialog();
      closeSpecialDateViewDialog();
    });
  };

  const closeDialog = () => {
    setNoteBody('');
    closeSpecialDateCompleteDialog();
  };

  return (
    <CMDialog
      width="sm"
      open={specialDateCompleteDialogOpen}
      handleClose={closeDialog}
      renderBottomActions={
        <div className="flex justify-between w-full">
          <div className="flex">
            <CMTextButton onClick={closeDialog}>Not now</CMTextButton>
          </div>
          <div className="flex">
            <CMButton
              disabled={!noteBody || specialDate.isOutsideOfCurrentRange}
              onClick={() => onSpecialDateDismiss(true)}
            >
              Save note
            </CMButton>
          </div>
        </div>
      }
      renderContent={
        <DateCompleteDialogContent
          contactId={contactId}
          title="Special Date"
          disableActions={specialDate.isOutsideOfCurrentRange}
          textareaPlaceholder="What would you like to remember from this interaction?"
          closeDialog={closeDialog}
          handleTouch={() => onSpecialDateDismiss(false)}
          renderContent={
            <div className="flex items-center">
              {specialDate.isBirthday ? (
                <MdCake size="24px" />
              ) : (
                <IoMdCalendar size="24px" />
              )}{' '}
              <span className="pl-2">{specialDate.dateAsString}</span>
            </div>
          }
          noteBody={noteBody}
          setNoteBody={setNoteBody}
        />
      }
    />
  );
};

export default SpecialDateCompleteDialog;
