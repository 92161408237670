export default class Contact {
  id: any;
  createdTimestamp: number;
  displayStatus: 'Starred' | 'Active' | 'Hidden';
  emails: any[];
  company: string;
  familyName: string;
  fullName: string;
  givenName: string;
  instantMessageAddresses: object[];
  instantMessageAddressesCM: object[];
  phones: any[];
  source: string;
  unloggedInteractionReason: string;
  unloggedTimestamp: number;
  updatedTimestamp: number;

  constructor(data: Object) {
    Object.assign(this, data);
  }

  get displayName(): string {
    return (
      this.fullName ||
      this.company ||
      this.phones?.[0]?.number ||
      this.emails?.[0]?.email ||
      ''
    );
  }
}
