import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import CMButton from '../../cm-button/cm-button.component';
import CMDialog from '../../cm-dialog/cm-dialog.component';
import CMTextButton from '../../cm-text-button/cm-text-button.component';

import useFeaturesSwitch from '../../../hooks/useFeaturesSwitch';

import { AuthContext } from '../../../context/auth/auth.context';
import {
  IPaywallDialoguesContext,
  PaywallDialoguesContext,
} from '../../../context/paywall/paywall-dialogues.context';
import { RemoteConfigContext } from '../../../context/remote-config/remote-config.context';

import { getGoPremiumRoute } from '../../../utils/helpers';

const MainPaywallDialog: React.FC = () => {
  const navigate = useNavigate();
  const { members_portal_migration } = useFeaturesSwitch();

  const { authToken, userData } = useContext(AuthContext);
  const {
    configValues: { paywall_max_followups, paywall_max_rotations },
  } = useContext(RemoteConfigContext);
  const {
    mainDialogOpenName,
    newFollowUpLimitDialogOpen,
    newRotationLimitDialogOpen,
    halfwayPaywallFollowUpsDialogOpen,
    halfwayPaywallRotationsDialogOpen,
    maxFollowUpsReachedDialogOpen,
    maxRotationsReachedDialogOpen,
    dismissRotationDialogOpen,
    closeNewFollowUpLimitDialog,
    closeNewRotationLimitDialog,
    closeHalfwayPaywallFollowUpsDialog,
    closeHalfwayPaywallRotationsDialog,
    closeMaxFollowUpsReachedDialog,
    closeMaxRotationsReachedDialog,
    closeDismissRotationDialog,
  } = useContext(PaywallDialoguesContext) as IPaywallDialoguesContext;

  if (mainDialogOpenName === '') return null;

  const data = {
    newFollowUpLimitDialogOpen: {
      title: 'Great job mapping!',
      subtitle: `Heads up, this is normally when we would have you set your next follow-up, but you have reached your free account limit of ${paywall_max_followups} outstanding follow-ups.`,
      headsUp:
        'Let’s set up your Pro account now so this connection doesn’t fallthrough the cracks!',
      handleClose: closeNewFollowUpLimitDialog,
    },
    newRotationLimitDialogOpen: {
      title: 'Great job mapping!',
      subtitle: `Heads up, this is normally when we would have you set your next rotation, but you have reached your free account limit of ${paywall_max_rotations} outstanding rotations.`,
      headsUp:
        'Let’s set up your Pro account now so this connection doesn’t fallthrough the cracks!',
      handleClose: closeNewRotationLimitDialog,
    },
    halfwayPaywallFollowUpsDialogOpen: {
      title: 'Way to go!',
      subtitle: `You already have ${Math.round(
        paywall_max_followups / 2
      )} follow-ups on the horizon. Everyone loves to be remembered!`,
      headsUp: `Quick heads up, you have ${paywall_max_followups} total follow-ups in your free version. Don’t let this stop you in the future. Let’s upgrade now!`,
      handleClose: closeHalfwayPaywallFollowUpsDialog,
    },
    halfwayPaywallRotationsDialogOpen: {
      title: 'Way to go!',
      subtitle: `You already have ${Math.round(
        paywall_max_rotations / 2
      )} rotations on the horizon. Everyone loves to be remembered!`,
      headsUp: `Quick heads up, you have ${paywall_max_rotations} total rotations in your free version. Don’t let this stop you in the future. Let’s upgrade now!`,
      handleClose: closeHalfwayPaywallRotationsDialog,
    },
    maxFollowUpsReachedDialogOpen: {
      title: 'You are amazing at remembering!',
      subtitle: `You have ${paywall_max_followups} follow-ups queued to connect and improve your relationships.`,
      headsUp:
        'That’s our free account limit, to keep up the good work, get coaching and have access to many other amazing features and content go premium.',
      handleClose: closeMaxFollowUpsReachedDialog,
    },
    maxRotationsReachedDialogOpen: {
      title: 'You are amazing at remembering!',
      subtitle: `You have ${paywall_max_rotations} rotations queued to connect and improve your relationships.`,
      headsUp:
        'That’s our free account limit, to keep up the good work, get coaching and have access to many other amazing features and content go premium.',
      handleClose: closeMaxRotationsReachedDialog,
    },
    dismissRotationDialogOpen: {
      title: 'You are amazing at remembering!',
      subtitle: `You currently have more than ${paywall_max_rotations} active Rotations. As a Basic plan user, you are allowed up to ${paywall_max_rotations} active Rotations of Remember at any given time. The next recurrence of this Rotation will not be created. If you would like to keep this Rotation active and unlock unlimited Rotations, please upgrade to Pro.`,
      headsUp: '',
      handleClose: closeDismissRotationDialog,
    },
  };

  const title = data[mainDialogOpenName].title;
  const subtitle = data[mainDialogOpenName].subtitle;
  const headsUp = data[mainDialogOpenName].headsUp;
  const handleClose = data[mainDialogOpenName].handleClose;

  const handleGoPro = () => {
    if (!members_portal_migration) {
      window.location.href = `${process.env.REACT_APP_MEMBERS_PORTAL_URL}/manage-account/${authToken}`;
      return;
    }

    handleClose();
    navigate(getGoPremiumRoute(userData.state));
  };

  return (
    <CMDialog
      width="sm"
      renderContent={
        <div>
          <h2 className="text-4xl font-bold pb-6">{title}</h2>
          <p className="text-xl">{subtitle}</p>
          {headsUp.length ? <p className="py-8">{headsUp}</p> : null}
          <div className="flex justify-between">
            <CMTextButton onClick={handleClose}>Dismiss</CMTextButton>
            <CMButton onClick={handleGoPro}>Go Pro</CMButton>
          </div>
        </div>
      }
      open={
        newFollowUpLimitDialogOpen ||
        newRotationLimitDialogOpen ||
        halfwayPaywallFollowUpsDialogOpen ||
        halfwayPaywallRotationsDialogOpen ||
        maxFollowUpsReachedDialogOpen ||
        maxRotationsReachedDialogOpen ||
        dismissRotationDialogOpen
      }
      handleClose={handleClose}
    />
  );
};

export default MainPaywallDialog;
