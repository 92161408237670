import CloseIcon from '@mui/icons-material/Close';
import { Breakpoint } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import React from 'react';

type IProps = DialogProps & {
  handleClose?: () => void;
  open: boolean;
  title?: string;
  renderContent: React.ReactNode;
  renderBottomActions?: React.ReactNode;
  width?: Breakpoint;
  contentProps?: DialogContentProps;
  renderFooterContent?: React.ReactNode;
};

const CMDialog = ({
  handleClose,
  open,
  title = '',
  renderContent,
  renderBottomActions,
  width = 'sm',
  contentProps,
  renderFooterContent,
  ...props
}: IProps) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={width}
      fullWidth={true}
      scroll="body"
      {...props}
    >
      {/* Title */}
      {title.length ? (
        <DialogTitle
          id="customized-dialog-title"
          classes={{
            root: 'border-b text-xl text-slate font-medium uppercase',
          }}
        >
          <div className={handleClose ? 'pr-4' : ''}>{title}</div>
        </DialogTitle>
      ) : null}

      {/* Content */}
      <DialogContent {...contentProps}>
        {handleClose ? (
          // Close Button needs to be inside `<DialogContent>` so that it doesn't
          // mess with how CSS is handled inside `<Dialog>` in cases where there
          // is no `title`
          <div className="absolute top-2 right-2">
            <IconButton aria-label="close" onClick={handleClose} size="large">
              <CloseIcon />
            </IconButton>
          </div>
        ) : null}
        {renderContent}
      </DialogContent>

      {/* Bottom Actions */}
      {renderBottomActions ? (
        <div className="flex justify-center p-6 pt-0">
          {renderBottomActions}
        </div>
      ) : null}

      {/* Extra footer content */}
      {renderFooterContent}
    </Dialog>
  );
};

export default CMDialog;
