import React, { memo, useContext } from 'react';

import RotationToText from '../../pages/contact-details/rotation-to-text.component';

import SmallItemCard from '../small-item-card/small-item-card.component';

import { AuthContext } from '../../context/auth/auth.context';
import { SharedDialoguesContext } from '../../context/shared-dialogues/shared-dialogues.context';

import Contact from '../../models/contact.model';
import Rotation from '../../models/rotation.model';

import { useContact } from '../../firebase/firebase.utils';

import { formatDate } from '../../utils/dates.utils';

type IProps = Pick<
  Rotation,
  'nextIterationDate' | 'frequency' | 'interval' | 'contactId'
> & {
  testid: string;
  rotationId: string;
};

const RotationItem: React.FC<IProps> = memo(
  ({
    nextIterationDate,
    frequency,
    interval,
    testid,
    rotationId,
    contactId,
  }) => {
    const { userId } = useContext(AuthContext);
    const { openRotationViewDialog } = useContext(SharedDialoguesContext);

    const { data: dataContact } = useContact(userId, contactId);

    const handleItemClick = () =>
      openRotationViewDialog({
        rotationId,
        contactId,
      });

    const contact = new Contact(dataContact || {});

    return (
      <SmallItemCard
        date={formatDate(new Date(Math.round(nextIterationDate * 1000)))}
        contactDisplayStatus={contact.displayStatus}
        contactDisplayName={contact.displayName}
        mainContent={
          <RotationToText
            rotationFrequency={frequency}
            rotationInterval={interval}
            rotationNextIterationDate={nextIterationDate}
          />
        }
        onItemClick={handleItemClick}
        testid={testid}
      />
    );
  }
);

export default RotationItem;
