import {
  FaFacebook,
  FaFacebookMessenger,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from 'react-icons/fa';
import { IoMdHand } from 'react-icons/io';
import { MdEmail } from 'react-icons/md';

import { ReactComponent as AlignableIcon } from '../assets/images/alignable-icon.svg';

const facebook = {
  name: 'Facebook',
  icon: <FaFacebook size="24px" />,
  data: socialProfiles => {
    const search = socialProfiles.find(
      network =>
        network.service?.toLowerCase() === 'facebook' &&
        network.username?.length
    );

    return {
      networkUrl:
        search?.username && `https://www.facebook.com/${search.username}`,
      networkUsername: search?.username,
    };
  },
};

const messenger = {
  name: 'Messenger',
  icon: <FaFacebookMessenger size="24px" />,
  data: socialProfiles => {
    const search = socialProfiles.find(
      network =>
        network.service?.toLowerCase() === 'facebook' &&
        network.username?.length
    );

    return {
      networkUrl: search?.username && `https://m.me/${search.username}`,
      networkUsername: search?.username,
    };
  },
};

const instagram = {
  name: 'Instagram',
  icon: <FaInstagram size="24px" />,
  data: socialProfiles => {
    const search = socialProfiles.find(
      network =>
        network.service?.toLowerCase() === 'instagram' &&
        network.username?.length
    );

    return {
      networkUrl:
        search?.username && `https://www.instagram.com/${search.username}`,
      networkUsername: search?.username,
    };
  },
};

const linkedin = {
  name: 'LinkedIn',
  icon: <FaLinkedinIn size="24px" />,
  data: socialProfiles => {
    const search = socialProfiles.find(
      network =>
        network.service?.toLowerCase() === 'linkedin' &&
        network.username?.length
    );

    return {
      networkUrl:
        search?.username && `https://www.linkedin.com/in/${search.username}`,
      networkUsername: search?.username,
    };
  },
};

const twitter = {
  name: 'Twitter',
  icon: <FaTwitter size="24px" />,
  data: socialProfiles => {
    const search = socialProfiles.find(
      network =>
        network.service?.toLowerCase() === 'twitter' && network.username?.length
    );

    return {
      networkUrl:
        search?.username && `https://www.twitter.com/${search.username}`,
      networkUsername: search?.username,
    };
  },
};

const clubhouse = {
  name: 'Clubhouse',
  icon: <IoMdHand size="24px" />,
  data: socialProfiles => {
    const search = socialProfiles.find(
      network =>
        network.service?.toLowerCase() === 'clubhouse' &&
        network.username?.length
    );

    return {
      networkUrl:
        search?.username && `https://www.clubhouse.com/@${search.username}`,
      networkUsername: search?.username,
    };
  },
};

const alignable = {
  name: 'Alignable',
  icon: <AlignableIcon width="24px" height="24px" />,
  data: socialProfiles => {
    const search = socialProfiles.find(
      network =>
        network.service?.toLowerCase() === 'alignable' &&
        network.username?.length
    );

    return {
      networkUrl:
        search?.username && `https://www.alignable.com/${search.username}`,
      networkUsername: search?.username,
    };
  },
};

const email = {
  name: 'Email',
  icon: <MdEmail size="24px" />,
  data: socialProfiles => {
    const search = socialProfiles.find(network => 'email' in network);

    return {
      networkUrl: search?.email && `mailto:${search.email}`,
    };
  },
};

export const profileNetworks = [
  facebook,
  messenger,
  linkedin,
  instagram,
  twitter,
  clubhouse,
  email,
];

export const contactInfoSocialMedia = [
  facebook,
  messenger,
  linkedin,
  instagram,
  twitter,
  clubhouse,
  alignable,
];

export const contactDetailsSocialMedia = [
  facebook,
  linkedin,
  instagram,
  twitter,
  clubhouse,
  alignable,
];
