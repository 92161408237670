import { useContext } from 'react';

import { RemoteConfigContext } from '../context/remote-config/remote-config.context';

const useFeaturesSwitch = () => {
  // FS Values:
  // paywall
  // web_app_free
  // members_portal_migration
  // time_based_follow_ups
  // special_dates
  // calendar_view
  // rotation
  const {
    featuresSwitch: {
      web_app_free,
      paywall,
      members_portal_migration,
      time_based_follow_ups,
      special_dates,
      calendar_view,
      rotation,
    },
  } = useContext(RemoteConfigContext);

  return {
    web_app_free,
    paywall,
    members_portal_migration,
    time_based_follow_ups,
    special_dates,
    calendar_view,
    rotation,
  };
};

export default useFeaturesSwitch;
