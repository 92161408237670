import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import CMButton from '../../components/cm-button/cm-button.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import { AuthContext } from '../../context/auth/auth.context';
import { SnackbarContext } from '../../context/snackbar/snackbar.context';

import { acceptLatestTerms } from '../../services/users/users.service';

import logo from '../../assets/images/logo-dark.png';

const AcceptTerms = () => {
  const { userId, authToken } = useContext(AuthContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const [loading, setLoading] = useState(false);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [acceptedTermsOk, setAcceptedTermsOk] = useState(false);

  const handleAcceptTerms = () => {
    setLoading(true);
    acceptLatestTerms(userId, authToken)
      .then(() => {
        setAcceptedTermsOk(true);
      })
      .catch(() => {
        showSnackbar({
          severity: 'error',
          message: 'There was an error, please try again',
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <WebAppLayout hiddenHeader hiddenSidebar fullWidth>
      <div className="bg-slate bg-login bg-blend-multiply bg-no-repeat bg-center w-full h-screen items-center flex">
        <div className="bg-white shadow-lg rounded-lg px-12 pt-12 pb-8 m-auto md:w-3/5 lg:w-1/3 space-y-8">
          <img src={logo} alt="Contact Mapping" className="w-[180px] mx-auto" />
          <div className="space-y-6 text-center">
            <h1 className="text-3xl font-bold">
              Latest Privacy policy and terms and Conditions
            </h1>

            {acceptedTermsOk ? (
              <>
                <div>Your changes were saved correctly</div>
                <CMButton
                  component={Link}
                  to="/"
                  data-testid="acceptTerms.goToHomeButton"
                  fullWidth
                >
                  Continue to App
                </CMButton>
              </>
            ) : (
              <>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={e => setAcceptedTerms(e.target.checked)}
                      />
                    }
                    label={
                      <div className="text-left">
                        By checking here, I affirm that I am bound by and agree
                        to comply with the{' '}
                        <a
                          className="text-fadeOrange underline "
                          href="https://www.contactmapping.com/tos"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Terms of Use
                        </a>{' '}
                        and{' '}
                        <a
                          className="text-fadeOrange underline "
                          href="https://www.contactmapping.com/privacy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </div>
                    }
                  />
                </FormGroup>

                <CMButton
                  disabled={loading || !acceptedTerms}
                  loading={loading}
                  fullWidth
                  id="accept"
                  onClick={handleAcceptTerms}
                  data-testid="acceptTerms.acceptButton"
                >
                  Next
                </CMButton>
              </>
            )}
          </div>
        </div>
      </div>
    </WebAppLayout>
  );
};

export default AcceptTerms;
