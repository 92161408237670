import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#e9965e', // fadeOrange
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#976fb1', // softPurple
      contrastText: '#ffffff',
    },
    text: {
      primary: '#475a6b', // slate
    },
  },
  typography: {
    // fontSize: '1rem',
  },
  breakpoints: {
    // Change breakpoints to match tailwind
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },
});

export default theme;
