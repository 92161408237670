import React, { memo } from 'react';

import PaginatedList from '../../../components/paginated-list/paginated-list.component';
import SkeletonLoader from '../../../components/skeleton-loader/skeleton-loader.component';

import AdminContactRow from '../admin-contact-row/admin-contact-row.component';

const renderContactItem = (
  { id, firstname, lastname, email, state, until, image },
  testid,
  page,
  index
) => (
  <AdminContactRow
    key={id}
    firstname={firstname}
    lastname={lastname}
    id={id}
    email={email}
    state={state}
    until={until}
    imageUrl={image}
    testid={`${testid}.paginatedList.page${page}.item${index}`}
  />
);

const AdminContactsList = memo(
  ({ list = [], listSize = 10, loading, testid }) => {
    return (
      <>
        {/* Grid header */}
        <div className="bg-white hidden md:grid grid-cols-8 uppercase font-bold py-4 px-8 border-b border-silver">
          <div className="col-span-3">Name</div>
          <div className="col-span-1">User ID</div>
          <div className="col-span-2">Email</div>
          <div className="col-span-1">Membership</div>
          <div className="col-span-1">Renewal</div>
          <div></div>
        </div>
        {/* Grid Row */}

        {loading ? (
          // Skeleton loader
          <div className="bg-white">
            <SkeletonLoader />
          </div>
        ) : (
          <PaginatedList
            list={list}
            shouldSetURLParam={true}
            listSize={listSize}
            renderItem={renderContactItem}
            paginationColor="primary"
            testid={`${testid}.listRow`}
          />
        )}
      </>
    );
  }
);

export default AdminContactsList;
