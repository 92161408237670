import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import Breadcrumb from '../../components/breadcrumb/breadcrumb.component';
import CMButton from '../../components/cm-button/cm-button.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import useActiveSubscriptionData from '../../hooks/useActiveSubscriptionData';

import { AuthContext } from '../../context/auth/auth.context';
import {
  ISnackbarContext,
  SnackbarContext,
} from '../../context/snackbar/snackbar.context';

import { cancelSubscription } from '../../services/auth/auth.service';

const CancelSubscription = () => {
  const navigate = useNavigate();

  const { showSnackbar } = useContext<ISnackbarContext>(SnackbarContext);

  const { authToken, performCommonSignInTasks, userData } =
    useContext(AuthContext);

  const { loadingSubscription, activeSubscriptionData } =
    useActiveSubscriptionData();

  const [loading, setLoading] = useState<boolean>(false);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);

  const onDowngradeConfirm = () => {
    setLoading(true);

    cancelSubscription(activeSubscriptionData.subscription_id, authToken)
      .then(() => {
        showSnackbar({
          severity: 'success',
          message: 'Downgrade to basic successful',
        });
        performCommonSignInTasks({ ...userData, role: 'Free' }, authToken);
        navigate('/manage-account');
      })
      .catch(() => {
        showSnackbar({
          severity: 'error',
          message: 'Something went wrong.',
        });
      })
      .finally(() => setLoading(false));
  };

  if (loadingSubscription)
    return <WebAppLayoutLoading darkBackground hiddenSidebar />;

  if (!activeSubscriptionData) return <Navigate replace to="/manage-account" />;

  return (
    <WebAppLayout darkBackground hiddenSidebar digitalBussinessCard>
      <Breadcrumb
        color="light"
        label="Manage your subscription"
        to="/manage-account/subscription"
      />
      <h1 className="text-2xl md:text-5xl font-bold">Downgrade to basic</h1>

      <div className="mt-8 md:mt-16 bg-white text-slate rounded-lg p-8 space-y-6">
        <h3 className="text-3xl font-bold">
          We are sorry to hear you want to go Basic
        </h3>

        <p>I understand that:</p>

        <ul className="list-disc pl-4 mb-6">
          <li>
            I will only have 10 <strong>Follow-ups</strong> at any given time
          </li>
          <li>
            I will only have 25 Notes that I can view and modify, the rest will
            be archived.
          </li>
          <li>I won't be able to use the Chrome Extension anymore</li>
          <li>I won't be able to attend to pro trainings and events</li>
          <li>
            I won't be able to keep my Digital Business Card add-ons (but keep
            my URL)
          </li>
        </ul>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox onChange={e => setAcceptedTerms(e.target.checked)} />
            }
            label="I confirm that I read and understand all the above points."
          />
        </FormGroup>

        <hr />

        <div className="flex justify-between">
          <CMButton
            component={Link}
            to={-1}
            outline={true}
            loading={loading}
            color="secondary"
          >
            Cancel
          </CMButton>
          <CMButton
            onClick={onDowngradeConfirm}
            disabled={!acceptedTerms || loading}
            loading={loading}
          >
            Go basic
          </CMButton>
        </div>
      </div>
    </WebAppLayout>
  );
};

export default CancelSubscription;
