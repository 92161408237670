import _uniq from 'lodash/uniq';
import { RRule } from 'rrule';

import FollowUp from '../../models/followup.model';
import Rotation from '../../models/rotation.model';
import SpecialDate from '../../models/special-dates.model';

import {
  dateToInputDateFormat,
  getTimeFromDate,
} from '../../utils/dates.utils';
import { EventTypes } from '../../utils/enums';

export enum CalendarViews {
  MONTH = 'dayGridMonth',
  WEEK = 'timeGridWeek',
  DAY = 'timeGridDay',
}

export type ICalendarEvent = {
  id: string;
  title: string;
  start?: string;
  eventType: EventTypes;
  detailedEvents?: any[];
  rrule?: object;
};

const getFollowUpsByDate = (dates: FollowUp[]) =>
  dates.reduce((dateA: FollowUp, dateB: FollowUp) => {
    const formattedDate = dateToInputDateFormat(
      new Date(Math.round(dateB.followupTimestamp * 1000))
    );
    dateA[formattedDate] = dateA[formattedDate] || [];
    dateA[formattedDate].push({
      ...dateB,
      calendarEvent: {
        id: dateB.id,
        eventType: EventTypes.FollowUp,
        eventData: dateB,
        title: `${dateB.contactName} Follow up`,
        start: `${formattedDate}${
          dateB.timeBased
            ? `T${getTimeFromDate(
                new Date(Math.round(dateB.followupTimestamp * 1000))
              )}`
            : ''
        }`,
      },
    });
    return dateA;
  }, Object.create(null));

const getRotatiosByDate = (dates: Rotation[]) => {
  const occurrencesInAYear: string[] = [];
  const rotationsByOccurrences = {};
  const today = new Date();
  const oneYearFromNow = new Date(
    today.getFullYear() + 1,
    today.getMonth(),
    today.getDate()
  );
  const rotations = dates.map(date => {
    const rotation = {
      ...date,
      calendarEvent: {
        rrule: {
          freq: date.frequency,
          interval: date.interval,
          dtstart: new Date(Math.round(date.nextIterationDate * 1000)),
          until: oneYearFromNow,
        },
        id: date.id,
        eventType: EventTypes.Rotation,
        eventData: date,
        title: 'Rotation',
      },
    };

    const rrule = new RRule(rotation.calendarEvent.rrule);

    const occurrencesDates = rrule!
      .all()
      .map(occurrence => dateToInputDateFormat(occurrence));

    occurrencesInAYear.push(...occurrencesDates);

    return rotation;
  });

  // occurrencesInAYear may have repeated dates/occurrences since it stores
  // the occurrences of all rotations within a year.
  // For example, a bi-weekly rotation for Mondays and a monthly rotation for Mondays as well will have repeated dates
  // Therefore we need to pick only one of these repeated dates
  _uniq(occurrencesInAYear).forEach((occurrence: string) => {
    const rotationsInOccurrence = rotations.filter(rotation => {
      const rrule = new RRule(rotation.calendarEvent.rrule);
      return rrule
        .all()
        .some((date: Date) => dateToInputDateFormat(date) === occurrence);
    });

    rotationsByOccurrences[occurrence] = rotationsInOccurrence;
  });

  return rotationsByOccurrences;
};

const getSpecialDatesByDate = (dates: SpecialDate[]) =>
  dates.reduce((dateA: SpecialDate, dateB: SpecialDate) => {
    const formattedDate = dateToInputDateFormat(dateB.asCurrentYearDate);
    dateA[formattedDate] = dateA[formattedDate] || [];
    dateA[formattedDate].push({
      ...dateB,
      calendarEvent: {
        title: dateB.typeLabel,
        eventType: EventTypes.SpecialDate,
        eventData: dateB,
        contactId: dateB.contactId,
        rrule: {
          dtstart: `${formattedDate}`,
          freq: RRule.YEARLY,
          interval: 1,
        },
      },
    });
    return dateA;
  }, Object.create(null));

export const getCalendarEvents = (dates, eventType: EventTypes) => {
  const isSpecialDate: boolean = eventType === EventTypes.SpecialDate;
  const isFollowUp: boolean = eventType === EventTypes.FollowUp;
  const eventsByDate = isSpecialDate
    ? getSpecialDatesByDate(dates)
    : isFollowUp
    ? getFollowUpsByDate(dates)
    : getRotatiosByDate(dates);

  const datesForCalendar: string[] = Object.keys(eventsByDate);
  return datesForCalendar.map((eventDate: string) => {
    const detailedEvents = eventsByDate[eventDate];
    const data: ICalendarEvent = {
      id: `${eventType}-${eventDate}`,
      eventType,
      detailedEvents,
      title: isSpecialDate
        ? 'Special Dates'
        : isFollowUp
        ? 'Follow Ups'
        : 'Rotations',
    };

    if (isSpecialDate) {
      data.rrule = {
        dtstart: eventDate,
        freq: RRule.YEARLY,
        interval: 1,
      };
    } else {
      data.start = eventDate;
    }

    return data;
  });
};
