import FileSaver from 'file-saver';
import React, { useState } from 'react';
import ReactGA from 'react-ga';
import vCardsJS from 'vcards-js';

import CMButton from '../cm-button/cm-button.component';

// const socialProfileServiceToUrl = {
//   Facebook: 'https://www.facebook.com/',
//   Twitter: 'https://www.twitter.com/',
//   LinkedIn: 'https://www.linkedin.com/in/',
//   Instagram: 'https://www.instagram.com/',
//   ClubHouse: 'https://www.facebook.com/@',
// };

const phoneLabelToVCardLabel = {
  Mobile: 'cellPhone',
  Home: 'homePhone',
};

const getBase64FromUrl = async url => {
  const data = await fetch(url);
  const blob = await data.blob();

  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

const saveFile = (info, image) => {
  const vCard = vCardsJS();

  // Set vCard data
  vCard.firstName = info.firstname;
  vCard.lastName = info.lastname;
  vCard.organization = info.organization;
  vCard.photo.embedFromString(image, 'image/png');
  vCard.workEmail = info.emails?.[0]?.email;
  vCard.title = info.title;
  vCard.workUrl = info.website;
  vCard.homeAddress.city = info.address?.city;
  vCard.homeAddress.stateProvince = info.address?.state;
  vCard.homeAddress.countryRegion = info.address?.country;
  vCard.note = info.about;

  // socialProfiles don't work properly depending on the user's device and
  // operative system, we won't add them for now
  // vCard.socialUrls['calendly'] = info.calendly && `https://calendly.com/${info.calendly}`;
  // info.socialProfiles.length &&
  //   info.socialProfiles.forEach(({ service, username }) => {
  //     vCard.socialUrls[
  //       service
  //     ] = `${socialProfileServiceToUrl[service]}${username}`;
  //   });

  info.phones.length &&
    info.phones.forEach(({ label, number }) => {
      vCard[phoneLabelToVCardLabel[label]] = number;
    });

  // Get vCard as formatted string
  const vCardString = vCard.getFormattedString();

  const file = new Blob([vCardString], {
    type: 'text/vcard',
  });

  // Show alert for Chrome iOS to use Safari instead
  if (navigator.userAgent.match(/AppleWebKit.*CriOS/)) {
    alert('Please use Safari to Download Contact Info');
    return;
  }

  ReactGA.event({
    category: 'digital business card',
    action: 'vcf download',
    label: info.profileurl,
  });

  FileSaver.saveAs(file, `${vCard.firstName}${vCard.lastName}.vcf`);
};

const DownloadContactInfo = ({ testid, info, onAfterDownload }) => {
  const [isLoadingVCard, setIsLoadingVCard] = useState(false);

  const handleOnClick = () => {
    // For images located in Firebase Storage we'll create a base64 string of
    // them and embed it in the vCard. vCardJS has a `embedFromFile` method that
    // should work but uses `readFileSync`, which is not available on the browser
    if (info.image?.slice(0, 4) === 'http') {
      // Loading indicator is only needed here while the image is converted to base64
      setIsLoadingVCard(true);
      getBase64FromUrl(info.image).then(imageData => {
        const image = imageData.split('data:image/jpeg;base64,');
        setIsLoadingVCard(false);
        saveFile(info, image);

        if (onAfterDownload instanceof Function) onAfterDownload();
      });
      return;
    }

    // For legacy users that tested the beta the case might be that the image
    // is itself a base64 string, we might remove this in the future. (08/31/2021)
    if (info.image?.slice(0, 4) === 'data') {
      const image = info.image.split('data:image/png;base64,');
      saveFile(info, image);
      if (onAfterDownload instanceof Function) onAfterDownload();
      return;
    }

    saveFile(info);
  };

  return (
    <CMButton
      fullWidth
      color="secondary"
      onClick={handleOnClick}
      disabled={isLoadingVCard}
      loading={isLoadingVCard}
      data-testid={`${testid}.downloadContactInfoButton`}
    >
      Download contact info
    </CMButton>
  );
};

export default DownloadContactInfo;
