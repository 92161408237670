export const sortAndFilterContactList = (dataContacts, searchTerm = '') => {
  const letterStarters: any[] = [];
  const specialCharactersStarters: any[] = [];
  const phoneNumbers: any[] = [];

  for (let i = 0, N = dataContacts.length; i < N; i++) {
    const contact = dataContacts[i];
    contact.fullName = removeMultipleSpaces(contact.fullName);
    const textField =
      contact.fullName || contact.company || contact.emails?.[0]?.email;

    // Separate contacts depending on the first character,
    // so if it starts with a special character or it's a phone number it gets to the bottom of the list
    // If the contact does not have a name, company, email or number; we don't show it
    if (textField) {
      const startsWithLetter = textField[0].match(/^([a-zA-Z]|[à-ú]|[À-Ú])+$/i);
      contact.sortableField = textField;
      (startsWithLetter ? letterStarters : specialCharactersStarters).push(
        contact
      );
    } else if (contact.phones?.[0]?.number) {
      contact.sortableField = contact.phones[0].number;
      phoneNumbers.push(contact);
    }
  }

  return [
    ...letterStarters.sort((a, b) => {
      return a.sortableField
        .toLowerCase()
        .localeCompare(b.sortableField.toLowerCase());
    }),
    ...specialCharactersStarters.sort((a, b) =>
      a.sortableField.toLowerCase().localeCompare(b.sortableField.toLowerCase())
    ),
    ...phoneNumbers.sort((a, b) =>
      a.sortableField.toLowerCase().localeCompare(b.sortableField.toLowerCase())
    ),
  ].filter(contact => {
    if (searchTerm === '') {
      return true;
    }

    // If search term is present filter by `fullName`, or by `company`
    const matchesDisplayName = contact.fullName.length
      ? contact.fullName.toLowerCase().includes(searchTerm.toLowerCase().trim())
      : contact.company.toLowerCase().includes(searchTerm.toLowerCase().trim());

    const matchesEmail = contact.emails?.some(e =>
      e.email.toLowerCase().includes(searchTerm.toLowerCase().trim())
    );

    const matchesHighlight = contact.highlights
      ?.toLowerCase()
      .includes(searchTerm.toLowerCase().trim());

    return matchesDisplayName || matchesEmail || matchesHighlight;
  });
};

export const getContactDisplayName = (fullName, company, email, phoneNumber) =>
  fullName || company || email || phoneNumber || '';

export const filterItemsByDate = <T>(
  items: T[],
  sortAttribute: string
): { [key: string]: T[] } => {
  const startOfTodayUnix = Math.floor(new Date().setHours(0, 0, 0, 0) / 1000);
  const endOfTodayUnix = Math.floor(
    new Date().setHours(23, 59, 59, 999) / 1000
  );

  const todayItems = items.filter(
    item =>
      item[sortAttribute] >= startOfTodayUnix &&
      item[sortAttribute] <= endOfTodayUnix
  );
  const recentItems = items.filter(
    item => item[sortAttribute] < startOfTodayUnix
  );
  const upcomingItems = items.filter(
    item => item[sortAttribute] > endOfTodayUnix
  );

  return { todayItems, recentItems, upcomingItems };
};

export const removeMultipleSpaces = (text: string): string =>
  text.trim().replace(/\s+/g, ' ');
