import firebase from 'firebase/app';
import _partition from 'lodash/partition';

import { SpecialDatesTypes } from '../utils/enums';

export default class SpecialDate {
  readonly id: string;
  contactId: string;
  type: SpecialDatesTypes;
  shortDate: string;
  createdAt?: firebase.firestore.FieldValue;
  updatedAt?: firebase.firestore.FieldValue;
  createdBy?: string;
  updatedBy?: string;
  month: string;
  day: string;
  dismissedAt: firebase.firestore.Timestamp | null;

  constructor(data: Object) {
    Object.assign(this, data);

    this.month = this.shortDate ? this.shortDate.split('-')[0] : '';
    this.day = this.shortDate ? this.shortDate.split('-')[1] : '';
  }

  static getDatesByType = (dates: SpecialDate[]) => ({
    anniversary: dates?.find((d: SpecialDate) => !d.isBirthday),
    birthday: dates?.find((d: SpecialDate) => d.isBirthday),
  });

  static createDate = (type: SpecialDatesTypes, contactId: string) =>
    new SpecialDate({
      shortDate: '',
      type,
      contactId,
      id: null,
      day: undefined,
      month: undefined,
      // `dismissedAt` set to 1970-01-01 as default instead of `null`
      dismissedAt: firebase.firestore.Timestamp.fromDate(new Date(0)),
    });

  static getDaysDifference = (
    fromDate: Date,
    toDate: Date = new Date()
  ): number => {
    const differenceInTime: number = toDate.getTime() - fromDate.getTime();
    return Math.floor(differenceInTime / (1000 * 3600 * 24));
  };

  static orderDates = (dateA: SpecialDate, dateB: SpecialDate): number =>
    dateA.asCurrentYearDate!.getTime() - dateB.asCurrentYearDate!.getTime();

  // Returns an array of strings representing dates from 2 days ago to 7 days in the future
  static getSpecialDatesRange = (): string[] => {
    const dates: string[] = [];

    for (let i = -2; i < 8; i++) {
      const month: string = `${
        new Date(new Date().setDate(new Date().getDate() + i)).getMonth() + 1
      }`.padStart(2, '0');
      const date: string = `${new Date(
        new Date().setDate(new Date().getDate() + i)
      ).getDate()}`.padStart(2, '0');

      dates.push(`${month}-${date}`);
    }

    return dates;
  };

  get isOutsideOfCurrentRange(): boolean {
    return !SpecialDate.getSpecialDatesRange().includes(this.shortDate);
  }

  get isBirthday(): boolean {
    return this.type === SpecialDatesTypes.Birthday;
  }

  get dateAsString(): string {
    if (!this.shortDate) {
      return '';
    }

    return this.asCurrentYearDate === null
      ? ''
      : this.asCurrentYearDate.toLocaleString('en-us', {
          month: 'long',
          day: 'numeric',
        });
  }

  get typeLabel(): string {
    return this.isBirthday ? 'Birthday' : 'Anniversary';
  }

  get asCurrentYearDate(): Date | null {
    if (!this.shortDate) return null;

    const [month, date] = this.shortDate.split('-');
    return new Date(new Date().getFullYear(), Number(month) - 1, Number(date));
  }

  get dateLabel(): string {
    const today: Date = new Date();
    const date: Date = this.asCurrentYearDate!;
    const differenceInDays: number = SpecialDate.getDaysDifference(date);
    const isPastDate = today > date;
    const isRecentDate = isPastDate && differenceInDays <= 2;
    if (differenceInDays === 0) {
      return 'Today';
    }

    if (isRecentDate) {
      return `${differenceInDays} day${differenceInDays > 1 ? 's' : ''} ago`;
    }

    return differenceInDays === 1 ? 'Tomorrow' : this.dateAsString;
  }

  static getGroupedByTime = (
    dates: SpecialDate[] = []
  ): {
    todayItems: SpecialDate[];
    recentItems: SpecialDate[];
    upcomingItems: SpecialDate[];
  } => {
    const todayItems: SpecialDate[] = [];
    const recentItems: SpecialDate[] = [];
    const upcomingItems: SpecialDate[] = [];
    const today: Date = new Date();

    for (let i: number = 0, N: number = dates.length; i < N; i++) {
      const specialDate: SpecialDate = dates[i];
      const daysDifference = SpecialDate.getDaysDifference(
        specialDate.asCurrentYearDate!,
        today
      );
      const isRecentDate = daysDifference > 0 && daysDifference <= 2;

      if (daysDifference === 0) todayItems.push(specialDate);
      else if (isRecentDate) recentItems.push(specialDate);
      else upcomingItems.push(specialDate);
    }

    const currentMonth = `${today.getMonth() + 1}`.padStart(2, '0');
    const currentDay = `${today.getDate()}`.padStart(2, '0');
    const currentShortDate = `${currentMonth}-${currentDay}`;
    const [nextCurrentYearDates, nextYearDates] = _partition(
      upcomingItems.sort((a, b) => SpecialDate.orderDates(a, b)),
      ({ shortDate }) => shortDate > currentShortDate
    );

    return {
      todayItems,
      recentItems: recentItems.sort((a, b) => SpecialDate.orderDates(a, b)),
      upcomingItems: [...nextCurrentYearDates, ...nextYearDates],
    };
  };
}
