import React from 'react';

const CMToggle = ({
  options = [],
  orientation = 'horizontal',
  selected,
  onToggleChange,
}) => (
  <div
    className={`${
      orientation === 'horizontal' ? 'rounded-full' : 'rounded-[28px] flex-col'
    } p-1 bg-gray-200 inline-flex space-x-1`}
  >
    {options.map(({ value, label }, index) => (
      <div
        key={index}
        className={`rounded-full inline-flex items-center justify-center px-4 py-2 cursor-pointer ${
          selected === value ? 'bg-white' : ''
        }`}
        onClick={() => onToggleChange(value)}
      >
        {label}
      </div>
    ))}
  </div>
);

export default CMToggle;
