import { TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FaMobileAlt, FaPhoneAlt } from 'react-icons/fa';
import { MdCancel, MdEdit, MdEmail } from 'react-icons/md';
import { useParams } from 'react-router-dom';

import CMButton from '../../components/cm-button/cm-button.component';
import CMTextButton from '../../components/cm-text-button/cm-text-button.component';
import HTMLText from '../../components/html-text/html-text.component';
import SpecialDatesBuilder from '../../components/special-dates-builder/special-dates-builder.component';

import useFeaturesSwitch from '../../hooks/useFeaturesSwitch';

import { AuthContext } from '../../context/auth/auth.context';

import {
  useContact,
  useContactSpecialDates,
} from '../../firebase/firebase.utils';

import { unifyInstantMessageAddresses } from '../../utils/instant-message.utils';
import { contactDetailsSocialMedia } from '../../utils/networks';

const Highlights = ({ highlights = '', updateContact }) => {
  const emptyHighlights = !Boolean(highlights);

  const [isEditing, setIsEditing] = useState(emptyHighlights);
  const [value, setValue] = useState(highlights || '');

  useEffect(() => {
    // If highlights prop changes, re set it
    setValue(highlights);
  }, [highlights]);

  const toggleEdit = () => setIsEditing(prevState => !prevState);

  const handleChange = e => setValue(e.target.value);

  const handleCancel = () => {
    setValue(highlights || '');
    toggleEdit();
  };

  const handleSubmit = e => {
    e.preventDefault();
    updateContact({
      highlights: value.trim(),
      updatedTimestamp: Math.floor(Date.now() / 1000),
    });

    if (value.length) {
      toggleEdit();
    }
  };

  if (isEditing)
    return (
      <form onSubmit={handleSubmit}>
        <div className="flex justify-between pb-6">
          <h2 className="text-xl font-bold">Highlights</h2>
          {emptyHighlights ? (
            <CMTextButton
              icon={MdEdit}
              data-testid="contactDetails.contactDetailsViewEdit.editHighlightsHeaderSaveButton"
              type="submit"
            >
              Save
            </CMTextButton>
          ) : null}
        </div>
        <TextField
          autoFocus
          multiline
          placeholder="Where did you met? Do they have kids? What else do you know about them?"
          value={value}
          onChange={handleChange}
          fullWidth
          inputProps={{
            'data-testid':
              'contactDetails.contactDetailsViewEdit.editHighlightsTextarea',
          }}
        />
        {!emptyHighlights ? (
          <div className="pt-4 flex items-center space-x-4 justify-center">
            <CMTextButton
              icon={MdCancel}
              onClick={handleCancel}
              data-testid="contactDetails.contactDetailsViewEdit.editHighlightsCancelButton"
            >
              Cancel
            </CMTextButton>
            <CMButton
              type="submit"
              data-testid="contactDetails.contactDetailsViewEdit.editHighlightsSaveButton"
            >
              Save Highlight
            </CMButton>
          </div>
        ) : null}
      </form>
    );

  return (
    <>
      <div className="flex justify-between pb-6">
        <h2 className="text-xl font-bold">Highlights</h2>
        <CMTextButton
          icon={MdEdit}
          data-testid="contactDetails.contactDetailsViewEdit.editHighlightsButton"
          onClick={toggleEdit}
        >
          Edit
        </CMTextButton>
      </div>
      <HTMLText
        testid="contactDetails.contactDetailsViewEdit.highlightsText"
        text={highlights}
      />
    </>
  );
};

const SocialMedia = ({ socialProfiles, updateContact }) => {
  // Note to devs: On this component you'll find several occurrences of checks
  // for `messageId` to add it to social media objects or not. The reason for
  // this is that not all social media objects currently have this property.
  // It would be wise in the future to unify how all social media objects are
  // handled and just add an empty `messageId` where not needed to avoid all
  // this checks.

  const [isEditing, setIsEditing] = useState(false);
  const [fields, setFields] = useState([]);
  const [formState, setFormState] = useState([]);

  const setFieldsFromSocialProfiles = useCallback(
    () =>
      setFields(
        contactDetailsSocialMedia.map(({ name, icon, editDisabled }) => {
          const profile = socialProfiles.find(
            network => network.service?.toLowerCase() === name.toLowerCase()
          );

          const obj = {
            name,
            icon,
            service: name.toLowerCase(),
            username: profile?.username || '',
            editDisabled,
          };

          // messageId is not present for all Social Media
          if (profile?.messageId?.length) obj.messageId = profile.messageId;

          return obj;
        })
      ),
    [socialProfiles]
  );

  useEffect(() => {
    setFieldsFromSocialProfiles();
  }, [setFieldsFromSocialProfiles]);

  // Update formState on initial render and every time fields change
  // For example: in other device, that will update `socialProfiles`
  useEffect(() => {
    setFormState(
      fields.reduce((prev, { service, username, messageId }) => {
        const obj = { username };

        return { ...prev, [service]: obj };
      }, {})
    );
  }, [fields]);

  const handleCancel = () => {
    setFieldsFromSocialProfiles();
    toggleEdit();
  };

  const toggleEdit = () => setIsEditing(prevState => !prevState);

  const handleChange = e => {
    setFormState(prevState => {
      const obj = { username: e.target.value };

      return {
        ...prevState,
        [e.target.name]: obj,
      };
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    const stateToInstantMessageAddresses = [
      // Convert state to CM instant message address format [{service, username}]
      ...Object.keys(formState).map(key => {
        const obj = {
          service: key,
          username: formState[key].username,
        };

        const prevSocialProfile = socialProfiles.find(
          network => network.service?.toLowerCase() === key.toLowerCase()
        );

        // messageId is not present for all Social Media but we should only keep it if:
        // - It's present
        // - The `username` is same as it was before (it hasn't changed),
        //   otherwise we should remove it (`messageId`)
        // - The `username` is not empty, otherwise username has been removed and
        //   we should remove `messageId` as well
        if (
          prevSocialProfile.messageId &&
          formState[key].username === prevSocialProfile.username &&
          formState[key].username !== ''
        )
          obj.messageId = prevSocialProfile.messageId;

        return obj;
      }),
    ];

    updateContact({
      instantMessageAddressesCM: stateToInstantMessageAddresses,
      updatedTimestamp: Math.floor(Date.now() / 1000),
    });

    toggleEdit();
  };

  if (isEditing)
    return (
      <form onSubmit={handleSubmit}>
        <h2 className="text-xl font-bold">Social Media</h2>
        <ul className="grid grid-cols-2 gap-y-6 gap-x-4 mt-8">
          {fields.map(({ name, icon, service, editDisabled }) => (
            <li className="flex items-center space-x-4" key={service}>
              <span className={editDisabled ? 'text-coolGrey' : ''}>
                {icon}
              </span>
              <TextField
                label={service.toUpperCase()}
                name={service}
                value={formState[service].username}
                placeholder={`${name} ID`}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={handleChange}
                inputProps={{
                  'data-testid': `contactDetails.contactDetailsViewEdit.editSocialMediaInput.${name.toLowerCase()}`,
                }}
                disabled={editDisabled}
              />
            </li>
          ))}
        </ul>
        {/*
          `flex-row-reverse space-x-reverse` class names so that Enter key
          simulates Update instead of Cancel mantaining original design
        */}
        <div className="pt-4 flex items-center space-x-4 justify-center flex-row-reverse space-x-reverse">
          <CMButton
            type="submit"
            data-testid="contactDetails.contactDetailsViewEdit.editSocialMediaUpdateButton"
          >
            Update
          </CMButton>
          <CMTextButton
            icon={MdCancel}
            onClick={handleCancel}
            data-testid="contactDetails.contactDetailsViewEdit.editSocialMediaCancelButton"
          >
            Cancel
          </CMTextButton>
        </div>
      </form>
    );

  return (
    <>
      <div className="flex justify-between pb-6">
        <h2 className="text-xl font-bold">Social Media</h2>
        <CMTextButton
          icon={MdEdit}
          onClick={toggleEdit}
          data-testid="contactDetails.contactDetailsViewEdit.editSocialMediaEditButton"
        >
          Edit
        </CMTextButton>
      </div>
      <ul className="grid grid-cols-2 gap-4">
        {contactDetailsSocialMedia.map(({ name, icon, data }, index) => {
          const { networkUrl, networkUsername } = data(socialProfiles);
          return (
            <li
              key={`network-${index}`}
              className="flex items-center space-x-4"
            >
              <div className={`${networkUrl ? '' : 'text-coolGrey'}`}>
                {icon}
              </div>
              <div>
                <div className="font-bold">{name.toUpperCase()}</div>
                {networkUsername ? (
                  <a
                    target="_blank"
                    href={networkUrl}
                    rel="noreferrer"
                    data-testid={`contactDetails.contactDetailsViewEdit.socialMediaUserName.${name.toLowerCase()}`}
                  >
                    {networkUsername}
                  </a>
                ) : (
                  <div
                    data-testid={`contactDetails.contactDetailsViewEdit.socialMediaUserName.${name.toLowerCase()}`}
                  >
                    Not set
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

const ContactDetailsViewEdit = () => {
  const { userId } = useContext(AuthContext);
  const { contactId } = useParams();
  const { data: dataContact, update } = useContact(userId, contactId);
  const { special_dates } = useFeaturesSwitch();
  const { data: dataSpecialDates } = useContactSpecialDates(
    contactId,
    // Putting this ternary here to avoid reading from Firestore
    // until `special_dates` is on
    special_dates ? userId : null
  );

  if (!dataContact || (special_dates && !dataSpecialDates))
    return (
      <div className="bg-white text-center py-24">
        <CircularProgress />
      </div>
    );

  return (
    <section>
      {/* Highlights */}
      <div className="p-4 border-b border-silver">
        <Highlights
          highlights={dataContact.highlights}
          updateContact={update}
        />
      </div>
      {special_dates ? (
        <div className="p-4 border-b border-silver">
          <SpecialDatesBuilder dates={dataSpecialDates} />
        </div>
      ) : null}
      {/* Contact Details */}
      {dataContact.phones.length || dataContact.emails.length ? (
        <div className="p-4 border-b border-silver">
          <h2 className="text-xl font-bold pb-6">Contact Details</h2>
          <div className="grid grid-cols-2 gap-4">
            {dataContact.phones.length ? (
              <ul className="space-y-4">
                {dataContact.phones.map(({ label, number }, index) => (
                  <li
                    key={label + number}
                    className="flex items-center space-x-4"
                  >
                    {label.toLowerCase() === 'mobile' ? (
                      <FaMobileAlt size="24px" />
                    ) : (
                      <FaPhoneAlt size="24px" />
                    )}
                    <div>
                      <div className="font-bold">{label.toUpperCase()}</div>
                      <a
                        href={`tel:${number}`}
                        data-testid={`contactDetails.contactDetailsViewEdit.phones.${index}.number`}
                      >
                        {number}
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            ) : null}
            {dataContact.emails.length ? (
              <ul className="space-y-4">
                {dataContact.emails.map(({ label, email }, index) => (
                  <li
                    key={label + email}
                    className="flex items-center space-x-4"
                  >
                    <MdEmail size="24px" />
                    <div>
                      <div className="font-bold">{label.toUpperCase()}</div>
                      <a
                        href={`mailto:${email}`}
                        data-testid={`contactDetails.contactDetailsViewEdit.emails.${index}.email`}
                      >
                        {email}
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        </div>
      ) : null}
      {/* Social Media */}
      <div className="p-4">
        <SocialMedia
          socialProfiles={unifyInstantMessageAddresses(
            dataContact.instantMessageAddresses,
            dataContact.instantMessageAddressesCM
          )}
          updateContact={update}
        />
      </div>
    </section>
  );
};

export default ContactDetailsViewEdit;
