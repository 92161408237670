import React, { memo } from 'react';

import ContactsList from '../../components/contacts-list/contacts-list.component';

import { sortAndFilterContactList } from '../../utils/list.utils';

const ContactsPageList = memo(
  ({ dataContacts, searchTerm, selectedDisplayStatus }) => {
    const activeContacts = dataContacts.filter(
      ({ displayStatus }) => displayStatus !== 'Hidden'
    );
    const starredContacts = activeContacts.filter(
      ({ displayStatus }) => displayStatus === 'Starred'
    );
    const hiddenContacts = dataContacts.filter(
      ({ displayStatus }) => displayStatus === 'Hidden'
    );
    const recentContacts = dataContacts.filter(
      ({ createdTimestamp }) =>
        createdTimestamp >=
        Math.floor(new Date().setDate(new Date().getDate() - 7) / 1000)
    );

    const filteredContacts = {
      Starred: starredContacts,
      Active: activeContacts,
      Hidden: hiddenContacts,
      Recent: recentContacts,
    };

    const list = sortAndFilterContactList(
      filteredContacts[selectedDisplayStatus],
      searchTerm
    );

    if (list.length === 0)
      return (
        <div
          className="bg-white py-14 px-8 text-center text-2xl font-bold"
          data-testid="contacts.emptyResults"
        >
          No contacts match your filter and/or search term.
        </div>
      );

    return (
      <ContactsList
        list={list}
        searchTerm={searchTerm}
        filter={selectedDisplayStatus}
        testid={`contacts.${selectedDisplayStatus.toLowerCase()}`}
      />
    );
  }
);

export default ContactsPageList;
