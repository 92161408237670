import React, { useContext, useState } from 'react';

import CMTextButton from '../../../components/cm-text-button/cm-text-button.component';

import { AuthContext } from '../../../context/auth/auth.context';
import { SnackbarContext } from '../../../context/snackbar/snackbar.context';

import { unlockDevice } from '../../../services/users/users.service';

const AdminUnlockDevice = ({ fullName, userId, testid, onSuccess }) => {
  const { authToken } = useContext(AuthContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const [loading, setLoading] = useState(false);

  const handleMakePermanent = () => {
    if (
      !window.confirm(
        `You are about to unlock ${fullName} - ID: ${userId}. Use this function only if:
        - The customer reported a locked screen with the “Multiple devices detected” message
        - AND they are NOT using the app from a second device
        - AND they tried uninstalling the app, reinstalling and logging in again but the result is always the locked screen

        The customer should try to login immediately after this unlock process.`
      )
    )
      return;

    setLoading(true);
    unlockDevice(userId, authToken)
      .then(() => {
        showSnackbar({
          severity: 'success',
          message: `User ${fullName} unblocked.`,
        });
        setLoading(false);
        onSuccess();
      })
      .catch(err => {
        const errorMessage = err?.response?.data?.args?.[0] ?? '';
        const formattedErrorMessage = errorMessage
          .replace('! check_args - ', '')
          .replace(/[{}]/g, '');
        showSnackbar({
          severity: 'error',
          message: formattedErrorMessage || 'Something wrong happened.',
        });
        setLoading(false);
      });
  };

  return (
    <CMTextButton
      data-testid={`${testid}.unlockDeviceButton`}
      onClick={handleMakePermanent}
      loading={loading}
      disabled={loading}
    >
      Unlock Device
    </CMTextButton>
  );
};

export default AdminUnlockDevice;
