import React from 'react';

import CalendarEvent from './calendar-event.component';

import { EventTypes } from '../../utils/enums';

const CalendarEventsSummary: React.FC<{
  title: string;
  eventType: EventTypes;
  detailedEvents: number;
}> = ({ title, eventType, detailedEvents }): JSX.Element => (
  <CalendarEvent
    itemColor={
      eventType === EventTypes.FollowUp
        ? 'secondary'
        : eventType === EventTypes.SpecialDate
        ? 'tertiary'
        : 'primary'
    }
    content={
      <>
        {title} <strong>({detailedEvents})</strong>
      </>
    }
  />
);

export default CalendarEventsSummary;
