import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useContext } from 'react';

import CMButton from '../../components/cm-button/cm-button.component';
import PaywallToast from '../../components/paywall-toast/paywall-toast.component';
import SecondarySearch from '../../components/secondary-search/secondary-search.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import ContactsPageList from './contacts-page-list.component';

import useURLParams from '../../hooks/useURLParams';

import { AuthContext } from '../../context/auth/auth.context';
import { SharedDialoguesContext } from '../../context/shared-dialogues/shared-dialogues.context';

import { useContacts } from '../../firebase/firebase.utils';

import appStore from '../../assets/images/app-store.png';
import googlePlay from '../../assets/images/google-play.png';
import phoneApp from '../../assets/images/phone-app.png';

const Contacts = () => {
  const { openAddNewContactDialog } = useContext(SharedDialoguesContext);
  const { userId } = useContext(AuthContext);

  const { data: dataContacts } = useContacts(userId);
  const {
    searchTerm,
    selectedFilter,
    handleDisplayStatusChange,
    setSearchTerm,
  } = useURLParams('Active');

  if (!dataContacts) return <WebAppLayoutLoading />;

  // Empty state, no contacts
  if (dataContacts.length === 0) {
    return (
      <WebAppLayout>
        <h1 className="text-2xl md:text-5xl font-bold">Contacts</h1>

        <div
          className="mt-16 py-16 px-8 rounded-md border border-silver bg-white text-center"
          data-testid="contacts.emptyState"
        >
          <div className="text-3xl font-medium">
            It feels lonely with nobody around
          </div>
          <div className="text-xl mt-6">
            Once you download the app and import your Address book, it will be
            plenty of people here
          </div>
          <img src={phoneApp} alt="" className="mx-auto mt-10" />
          <div className="inline-flex space-x-3 mx-auto mt-8">
            <a
              href="https://play.google.com/store/apps/details?id=com.contactmapping.android"
              target="_blank"
              rel="noreferrer"
              data-testid="contacts.emptyState.googlePlayLink"
            >
              <img src={googlePlay} alt="" />
            </a>
            <a
              href="https://apps.apple.com/us/app/contact-mapping/id1337928740"
              target="_blank"
              rel="noreferrer"
              data-testid="contacts.emptyState.appleAppStoreLink"
            >
              <img src={appStore} alt="" />
            </a>
          </div>
        </div>
      </WebAppLayout>
    );
  }

  return (
    <WebAppLayout>
      <PaywallToast />
      <div className="flex items-center justify-between mb-8 md:mb-16">
        <h1 className="text-2xl md:text-5xl font-bold">Contacts</h1>
        <div className="hidden md:block">
          <CMButton
            color="tertiary"
            onClick={openAddNewContactDialog}
            data-testid="notes.newNoteButton"
          >
            New Contact
          </CMButton>
        </div>
      </div>

      <div className="flex mt-8 md:mt-16">
        <div className="bg-white rounded md:shadow-md shrink-0 md:px-8 w-full md:w-auto">
          <Tabs
            value={selectedFilter}
            onChange={(e, val) => handleDisplayStatusChange(val)}
            textColor="primary"
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            data-testid="contacts.tabs"
          >
            <Tab
              label="Starred"
              value="Starred"
              data-testid="contacts.tabs.starred"
            />
            <Tab
              label="Active"
              value="Active"
              data-testid="contacts.tabs.active"
            />
            <Tab
              label="Hidden"
              value="Hidden"
              data-testid="contacts.tabs.hidden"
            />
            <Tab
              label="Recently Added"
              value="Recent"
              data-testid="contacts.tabs.recent"
            />
          </Tabs>
        </div>
        <div className="ml-8 flex-grow hidden md:block">
          <SecondarySearch
            searchTerm={searchTerm}
            handleOnChange={setSearchTerm}
            placeholder="Search contacts"
            testid="contacts"
          />
        </div>
      </div>

      {searchTerm.length ? (
        <div
          className="text-black text-xl pt-8"
          data-testid="contacts.searchPhrase"
        >
          Contacts with: "
          <strong className="text-fadeOrange">{searchTerm.trim()}</strong>"
        </div>
      ) : null}

      <div className="mt-8">
        <ContactsPageList
          dataContacts={dataContacts}
          searchTerm={searchTerm}
          selectedDisplayStatus={selectedFilter}
        />
      </div>
    </WebAppLayout>
  );
};

export default Contacts;
