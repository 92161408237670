import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MdLock } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import CMButton from '../../components/cm-button/cm-button.component';
import ProfileProgressHeader from '../../components/profile-progress-header/profile-progress-header.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import { AuthContext } from '../../context/auth/auth.context';

const PickUrl = () => {
  const { userData, isPremium, updateUserData } = useContext(AuthContext);

  const navigate = useNavigate();

  const [url, setUrl] = useState('');
  const [error, setError] = useState('');
  const [saving, setSaving] = useState(false);

  const profileUrlRef = useRef(null);

  const baseURL = `${window.location.origin}/hi/`;

  useEffect(() => {
    const { firstname, lastname, profileurl, id } = userData;
    // If profileUrl has value it means that it was previously setup and it
    // also exists in profileUrls's collection. Otherwise we'll create one
    // based on firstname, lastname and id
    setUrl(
      profileurl ||
        `${firstname
          .trim()
          .toLowerCase()
          // Replace special chars with '-'
          .replace(/[^A-Z0-9]/gi, '-')}-${
          lastname
            ?.trim()
            .toLowerCase()
            // Replace special chars with '-'
            .replace(/[^A-Z0-9]/gi, '-') ?? ''
        }-${id}`
          // Replace duplicated '-' with just 1
          .replace(/(-)\1{1,}/g, '-')
    );
    // Save profileUrl value ref for later. We will use it in case the user
    // is editing the URL and we need to remove the current one
    profileUrlRef.current = profileurl;
  }, [userData]);

  useEffect(() => {
    setError('');
    if (url.length === 0) setError('URL is required');
  }, [url]);

  const handleSave = () => {
    setSaving(true);
    if (!isPremium && profileUrlRef.current === url) {
      // Since basic users cannot change their URL for now, if it has already been
      // set up and it's the same as it was before, just redirect to their profile

      navigate('/my-profile');
      return;
    }

    updateUserData({
      profileurl: url,
      id: userData.id,
      dbc_enabled: 'Y',
    })
      .then(() => {
        setSaving(false);
        navigate('/my-profile');
      })
      .catch(err => {
        const errMsg = err.response?.data?.profileurl[0];
        setSaving(false);
        setError(`${url} ${errMsg}`);
      });
  };

  const handleInputChange = value => {
    error.length > 0 && setError('');
    value.length === 0 || value.match('^[A-Za-z0-9-]+$')
      ? setUrl(value)
      : setError('Only letters, numbers and - are supported');
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleSave();
  };

  return (
    <WebAppLayout digitalBussinessCard>
      {userData.dbc_enabled !== 'Y' ? (
        <div className="bg-white md:bg-transparent">
          <ProfileProgressHeader
            title="Pick your URL"
            subtitle="Once you claim your URL, your digital business card will be public and you can start sharing it with everyone."
            percentage="75"
          >
            {!isPremium ? (
              <div className="absolute top-16 mt-2 right-20 bg-silver text-steel rounded-md py-1 px-2 flex items-center">
                <MdLock className="mr-1" />
                PRO
              </div>
            ) : null}
          </ProfileProgressHeader>
        </div>
      ) : null}

      <div className="mb-16 md:mt-4">
        <div className="bg-white md:shadow-lg md:rounded-lg text-slate">
          {/*  Title  */}
          <div className="p-6">
            <h1>Your Card</h1>
          </div>
          {isPremium ? (
            // Section PRO
            <div className="p-4 md:p-8 border-t">
              <form onSubmit={handleSubmit}>
                {/* Desktop */}
                <div className="w-5/6 mx-auto hidden md:block">
                  <TextField
                    label="PICK YOUR URL"
                    variant="outlined"
                    className="w-full"
                    id="url"
                    type="text"
                    value={url}
                    required
                    helperText={error}
                    error={!!error.length}
                    onChange={event => handleInputChange(event.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          position="start"
                          disableTypography
                          className="text-steel"
                          style={{ marginRight: 0 }}
                        >
                          {baseURL}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {/* Mobile */}
                <div className="md:hidden">
                  <FormControl error={!!error.length} size="small">
                    <div className="flex items-center space-x-1">
                      <div className="font-bold">
                        www.contactmapping.com/hi/
                      </div>
                      <OutlinedInput
                        id="url-mobile"
                        onChange={event =>
                          handleInputChange(event.target.value)
                        }
                        type="text"
                        value={url}
                        required
                        error={!!error.length}
                      />
                    </div>
                    {!!error.length ? (
                      <FormHelperText id="url-mobile-error-text">
                        {error}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </div>
                <div className="w-80 mx-auto py-12">
                  <CMButton
                    color="tertiary"
                    fullWidth
                    type="submit"
                    loading={saving}
                    disabled={saving}
                  >
                    Claim my URL
                  </CMButton>
                </div>
              </form>
              <div className="py-8">
                <h4 className="font-bold text-lg">Can I change my url?</h4>
                <p>
                  Yes, you can change it as many times as you want while the url
                  that you pick is not used by another user.
                </p>
              </div>
              <div className="py-8">
                <h4 className="font-bold text-lg">
                  What happens if I change my URL?
                </h4>
                <p>
                  You will need to inform your contacts and update it everywhere
                  that you are using it (we cannot do that on our end).
                </p>
              </div>
            </div>
          ) : (
            // Section Basic
            <div className="p-4 md:p-8 border-t">
              <div className="text-center text-xl">
                <p className="break-all">
                  <span className="text-steel">{baseURL}</span>
                  {url}
                </p>
              </div>
              <div className="w-80 mx-auto py-12">
                <CMButton
                  color="tertiary"
                  fullWidth
                  onClick={handleSave}
                  loading={saving}
                  disabled={saving}
                >
                  Publish
                </CMButton>
              </div>
              <div className="py-8">
                <h4 className="font-bold text-lg">Can I change my url?</h4>
                <p>
                  Yes, if you are a PRO user, you can change it as many times as
                  you want while the url that you pick is not used by another
                  user.
                </p>
              </div>
              <div className="py-8">
                <h4 className="font-bold text-lg">
                  What happens if I change my URL?
                </h4>
                <p>
                  You will need to inform your contacts and update it everywhere
                  that you are using it (we cannot do that on our end).
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </WebAppLayout>
  );
};

export default PickUrl;
