import { FuegoProvider } from '@nandorojo/swr-firestore';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import React from 'react';

import { firebaseConfigProd, firebaseConfigStaging } from './firebase.config';
import Fuego from './fuego';

const firebaseConfig =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? firebaseConfigProd
    : firebaseConfigStaging;

const fuego = new Fuego(firebaseConfig);

const SWRFirestore = ({ children }) => {
  return <FuegoProvider fuego={fuego}>{children}</FuegoProvider>;
};

export default SWRFirestore;
