import _partition from 'lodash/partition';
import React, { memo, useContext } from 'react';

import PaginatedList from '../../components/paginated-list/paginated-list.component';
import PaywallHiddenItemsMessage from '../../components/paywall-hidden-items-message/paywall-hidden-items-message.component';
import RotationItem from '../../components/rotation-item/rotation-item.component';

import { PaywallContext } from '../../context/paywall/paywall.context';

import Rotation from '../../models/rotation.model';

const renderRotationItem = (
  { id, contactId, nextIterationDate, frequency, interval }: Rotation,
  testid: string,
  page: number,
  index: number
) => (
  <RotationItem
    key={id}
    nextIterationDate={nextIterationDate}
    frequency={frequency}
    interval={interval}
    rotationId={id}
    contactId={contactId}
    testid={`${testid}.paginatedList.page${page}.item${index}`}
  />
);

type IRotationsListProps = {
  list: Rotation[];
  listSize?: number;
  searchTerm: string;
  filter: string;
  hidePaywallItems: boolean;
  testid: string;
};

const RotationsList: React.FC<IRotationsListProps> = memo(
  ({ list, listSize = 25, searchTerm, filter, hidePaywallItems, testid }) => {
    const { latestRotationsIDs, hasReachedMaxRotations } =
      useContext(PaywallContext);

    const [availableRotations, archivedRotations] =
      hidePaywallItems && hasReachedMaxRotations
        ? _partition(list, ({ id }) => latestRotationsIDs.includes(id))
        : [list, []];

    return (
      <PaginatedList
        list={availableRotations}
        listSize={listSize}
        paginationColor="secondary"
        renderItem={renderRotationItem}
        listContainerClassName="md:grid grid-cols-3 gap-4"
        searchTerm={searchTerm}
        filter={filter}
        showHiddenItemsMessage={hidePaywallItems && archivedRotations.length}
        hiddenItemsMessage={
          <PaywallHiddenItemsMessage
            outstandingItems={archivedRotations.length}
            forRotations
          />
        }
        testid={testid}
      />
    );
  }
);

export default RotationsList;
