import { BiCalendarAlt } from 'react-icons/bi';
import { BsPersonFill } from 'react-icons/bs';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaMobileAlt,
  FaPhoneAlt,
  FaTwitter,
} from 'react-icons/fa';
import { IoMdHand } from 'react-icons/io';
import { MdEmail, MdHome, MdWork } from 'react-icons/md';
import { VscGlobe } from 'react-icons/vsc';

export const formFormat = [
  {
    section: 'Contact Details',
    fields: [
      {
        label: 'First Name',
        name: 'firstname',
        icon: <BsPersonFill size="24px" className="m-auto text-slate" />,
        public: true,
        half: true,
        inline: true,
        required: true,
        validators: ['required'],
        errorMessages: ['This field is required'],
      },
      {
        label: 'Last Name',
        name: 'lastname',
        icon: null,
        public: true,
        half: true,
        inline: true,
        required: true,
        validators: ['required'],
        errorMessages: ['This field is required'],
      },
      {
        label: 'Email',
        name: 'emails.label.Work',
        icon: <MdEmail size="24px" className="m-auto text-slate" />,
        public: true,
        half: true,
        required: true,
        validators: ['required', 'isEmail'],
        errorMessages: ['This field is required', 'Not a valid email'],
      },
      {
        label: 'Mobile',
        name: 'phones.label.Mobile',
        half: true,
        icon: <FaMobileAlt size="24px" className="m-auto text-slate" />,
        public: true,
        required: true,
        validators: ['required'],
        errorMessages: ['This field is required'],
      },
      {
        label: 'Phone',
        name: 'phones.label.Home',
        half: true,
        icon: <FaPhoneAlt size="24px" className="m-auto text-slate" />,
        public: true,
      },
      {
        label: 'Calendly URL',
        name: 'calendly',
        icon: <BiCalendarAlt size="24px" className="m-auto text-slate" />,
        public: true,
        startAdornment: 'https://calendly.com/',
      },
    ],
  },
  {
    section: 'Social Profiles',
    fields: [
      {
        label: 'Facebook',
        name: 'socialprofiles.service.Facebook',
        icon: <FaFacebook size="24px" className="m-auto text-slate" />,
        public: true,
        // half: true,
        startAdornment: 'https://www.facebook.com/',
      },
      {
        label: 'Twitter',
        name: 'socialprofiles.service.Twitter',
        icon: <FaTwitter size="24px" className="m-auto text-slate" />,
        public: true,
        // half: true,
        startAdornment: 'https://www.twitter.com/',
      },
      {
        label: 'LinkedIn',
        name: 'socialprofiles.service.LinkedIn',
        icon: <FaLinkedinIn size="24px" className="m-auto text-slate" />,
        public: true,
        // half: true,
        startAdornment: 'https://www.linkedin.com/in/',
      },
      {
        label: 'Instagram',
        name: 'socialprofiles.service.Instagram',
        icon: <FaInstagram size="24px" className="m-auto text-slate" />,
        public: true,
        // half: true,
        startAdornment: 'https://www.instagram.com/',
      },
      {
        label: 'Clubhouse',
        name: 'socialprofiles.service.ClubHouse',
        icon: <IoMdHand size="24px" className="m-auto text-slate" />,
        public: true,
        // half: true,
        startAdornment: 'https://www.clubhouse.com/@',
      },
    ],
  },
  {
    section: 'Work Details',
    fields: [
      {
        label: 'Headline / Job Title',
        name: 'title',
        icon: <MdWork size="24px" className="m-auto text-slate" />,
        public: true,
        half: true,
        inline: true,
        required: true,
        validators: ['required'],
        errorMessages: ['This field is required'],
      },
      {
        label: 'Company',
        name: 'organization',
        icon: null,
        public: true,
        half: true,
        inline: true,
      },
      {
        label: 'Website',
        name: 'website',
        icon: <VscGlobe size="24px" className="m-auto text-slate" />,
        public: true,
        startAdornment: 'https://',
      },
    ],
  },
  {
    section: 'Location Details',
    fields: [
      {
        label: 'City',
        name: 'address.city',
        icon: <MdHome size="24px" className="m-auto text-slate" />,
        public: true,
        half: true,
        inline: true,
      },
      {
        label: 'State',
        name: 'address.state',
        icon: null,
        public: true,
        half: true,
        inline: true,
      },
      {
        label: 'Country',
        name: 'address.country',
        icon: null,
        public: true,
        half: true,
      },
    ],
  },
  {
    section: 'About Me',
    fields: [
      {
        label: 'Tell us about yourself',
        name: 'about',
        icon: null,
        public: true,
        multiline: 5,
        placeholder:
          '👋  what do you do? what brought you there? what are your passions?',
      },
    ],
  },
];
