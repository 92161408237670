import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { IoMdCalendar } from 'react-icons/io';

import RotationToText from '../../../pages/contact-details/rotation-to-text.component';

import CMButton from '../../cm-button/cm-button.component';
import CMDialog from '../../cm-dialog/cm-dialog.component';
import CMTextButton from '../../cm-text-button/cm-text-button.component';

import { AuthContext } from '../../../context/auth/auth.context';
import { SharedDialoguesContext } from '../../../context/shared-dialogues/shared-dialogues.context';

import Contact from '../../../models/contact.model';

import { useContact, useRotation } from '../../../firebase/firebase.utils';

const NextRotationDialog: React.FC = () => {
  const { userId } = useContext(AuthContext);
  const {
    nextRotationDialogOpen,
    closeNextRotationDialog,
    rotationViewDialogData,
    openRotationDialog,
  } = useContext(SharedDialoguesContext);

  const { contactId, rotationId } = rotationViewDialogData;

  const { data: dataContact } = useContact(userId, contactId);
  const { data: dataRotation } = useRotation(userId, rotationId);

  useEffect(() => {
    // if rotation is deleted while this dialog is open, close it
    if (!dataRotation?.exists) {
      closeNextRotationDialog();
    }
  }, [dataRotation?.exists, closeNextRotationDialog]);

  // `!dataRotation.exists` prevents errors when rotation is deleted
  if (!dataContact || !dataRotation || !dataRotation.exists) return null;

  const { fullName } = new Contact(dataContact);
  const { frequency, interval, nextIterationDate } = dataRotation;

  const displayDate = new Date(nextIterationDate * 1000).toLocaleDateString(
    'en-us',
    {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    }
  );

  const handleDontShowAgain = (checked: boolean) =>
    localStorage.setItem(`dontShowNextRotationDialog`, checked.toString());

  return (
    <CMDialog
      width="sm"
      renderBottomActions={
        <div className="flex justify-between w-full">
          <CMTextButton
            onClick={() => openRotationDialog(rotationViewDialogData)}
          >
            Edit frequency
          </CMTextButton>
          <CMButton onClick={closeNextRotationDialog}>Close</CMButton>
        </div>
      }
      renderContent={
        <div className="space-y-4 border-b border-silver">
          <h2 className="text-2xl font-bold text-center py-5">
            Next rotation with {fullName}
          </h2>
          <div className="py-12 space-y-12 flex flex-col items-center">
            <IoMdCalendar size="30px" />
            <div className="text-center">
              <div className="text-xl font-bold">{displayDate}</div>
              <div>
                <RotationToText
                  rotationFrequency={frequency}
                  rotationInterval={interval}
                  rotationNextIterationDate={nextIterationDate}
                />
              </div>
            </div>
          </div>
          <FormControlLabel
            control={
              <Checkbox onChange={e => handleDontShowAgain(e.target.checked)} />
            }
            label="Don't show this again"
          />
        </div>
      }
      open={nextRotationDialogOpen}
      handleClose={closeNextRotationDialog}
    />
  );
};

export default NextRotationDialog;
