import { Tab, Tabs, useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';

import CMButton from '../../components/cm-button/cm-button.component';
import NoResults from '../../components/no-results/no-results.component';
import NotesList from '../../components/notes-list/notes-list.component';
import PaywallToast from '../../components/paywall-toast/paywall-toast.component';
import SecondarySearch from '../../components/secondary-search/secondary-search.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import useURLParams from '../../hooks/useURLParams';

import { AuthContext } from '../../context/auth/auth.context';
import { SharedDialoguesContext } from '../../context/shared-dialogues/shared-dialogues.context';

import { useContacts } from '../../firebase/firebase.utils';
import { useNotes } from '../../firebase/firebase.utils';

const Notes = () => {
  const { userId } = useContext(AuthContext);
  const { openNoteDialog } = useContext(SharedDialoguesContext);

  const {
    searchTerm,
    selectedFilter,
    handleDisplayStatusChange,
    setSearchTerm,
    setSelectedFilter,
  } = useURLParams('All');

  const matchesMd = useMediaQuery(theme => theme.breakpoints.up('md'));

  const { data: dataContacts } = useContacts(userId);
  const { data: dataNotes } = useNotes(userId);

  const handleOpenNoteDialog = () =>
    openNoteDialog({
      isEditing: true,
      closeOnCancelEdit: true,
      isAddMode: true,
    });

  if (!dataNotes || !dataContacts)
    return <WebAppLayoutLoading testid="notesPage" />;

  const draftNotesNoSearch = dataNotes.filter(
    ({ contactId }) => contactId === null
  );

  const noteMatchesSearchFilter = ({ body, contactId }) => {
    const textFilter = searchTerm.toLowerCase().trim();
    const contactName = dataContacts.find(
      ({ id }) => contactId === id
    )?.fullName;

    // If search term is present filter by `body` or contact name
    return (
      body.toLowerCase().includes(textFilter) ||
      contactName?.toLowerCase().includes(textFilter)
    );
  };

  const draftNotes = draftNotesNoSearch.filter(noteMatchesSearchFilter);

  const notesList = dataNotes.filter(noteMatchesSearchFilter);

  // Empty state
  if (dataNotes.length === 0)
    return (
      <WebAppLayout>
        <div className="flex items-center justify-between mb-8 md:mb-16">
          <h1 className="text-2xl md:text-5xl font-bold">Notes</h1>
        </div>
        <div
          className="md:py-24 md:px-48 bg-white rounded-lg"
          data-testid="notes.emptyState"
        >
          <div className="md:text-2xl font-bold">
            “What You Love the Most About Me Is How Much I Remember About You.”
          </div>
          <div className="md:text-xl py-8">
            Remembering more of what colleagues, friends, and acquaintances
            share with you is about to become your secret weapon.
          </div>
          <div className="hidden md:block">
            <CMButton
              size="lg"
              onClick={handleOpenNoteDialog}
              data-testid="notes.newNoteButton"
            >
              New note
            </CMButton>
          </div>
        </div>
      </WebAppLayout>
    );

  // If all Draft notes are assigned, reset filter to All
  if (draftNotes.length === 0 && selectedFilter !== 'All')
    setSelectedFilter('All');

  return (
    <WebAppLayout>
      <PaywallToast notesOnly />
      <div className="flex items-center justify-between mb-8 md:mb-16">
        <h1 className="text-2xl md:text-5xl font-bold">Notes</h1>
        <div className="hidden md:block">
          <CMButton
            onClick={handleOpenNoteDialog}
            data-testid="notes.newNoteButton"
          >
            New Note
          </CMButton>
        </div>
      </div>

      <div className="flex max-w-4xl">
        {matchesMd ? (
          <div className="max-w-4xl flex-grow">
            <div className="bg-white rounded md:shadow-md md:px-8 w-full md:w-auto">
              <Tabs
                value={selectedFilter}
                onChange={(e, val) => handleDisplayStatusChange(val)}
                textColor="primary"
                indicatorColor="primary"
                variant="fullWidth"
                data-testid="notes.tabs"
              >
                <Tab label="All" value="All" data-testid="notes.tabs.all" />
                <Tab
                  disabled={!draftNotesNoSearch.length}
                  label={`Drafts (${draftNotesNoSearch.length})`}
                  value="Drafts"
                  data-testid="notes.tabs.drafts"
                />
              </Tabs>
            </div>
          </div>
        ) : null}
        <div className="ml-8 hidden md:block flex-grow">
          <SecondarySearch
            searchTerm={searchTerm}
            handleOnChange={setSearchTerm}
            placeholder="Search notes"
            testid="notes"
          />
        </div>
      </div>

      {searchTerm.length ? (
        <div
          className="text-black text-xl pt-8"
          data-testid="notes.searchPhrase"
        >
          Notes containing: "
          <strong className="text-fadeOrange">{searchTerm.trim()}</strong>"
        </div>
      ) : null}

      <div className="max-w-4xl pt-8">
        {selectedFilter === 'All' && dataNotes.length ? (
          <div className="pb-8">
            <h2 className="text-2xl font-bold pb-2">
              {draftNotes.length ? `Notes and drafts ` : 'Notes '}
              {`(${notesList.length})`}
            </h2>
            {notesList.length ? (
              <NotesList
                list={notesList}
                searchTerm={searchTerm}
                filter={selectedFilter}
                testid="notes.allNotesListRow"
                hidePaywallItems
              />
            ) : (
              <NoResults testid="notes.emptyResults" />
            )}
          </div>
        ) : null}
        {selectedFilter === 'Drafts' ? (
          <div className="pb-8">
            <h2 className="text-2xl font-bold pb-2">
              Drafts ({draftNotes.length})
            </h2>
            {draftNotes.length ? (
              <NotesList
                list={draftNotes}
                searchTerm={searchTerm}
                filter={selectedFilter}
                testid="notes.draftNotesListRow"
                hidePaywallItems
              />
            ) : (
              <NoResults testid="notes.emptyResults" />
            )}
          </div>
        ) : null}
      </div>
    </WebAppLayout>
  );
};

export default Notes;
