import React, { useContext } from 'react';

import CMDialog from '../../cm-dialog/cm-dialog.component';

import { AuthContext } from '../../../context/auth/auth.context';
import { SharedDialoguesContext } from '../../../context/shared-dialogues/shared-dialogues.context';

import Contact from '../../../models/contact.model';
import Rotation from '../../../models/rotation.model';
import SpecialDate from '../../../models/special-dates.model';

import { useContact } from '../../../firebase/firebase.utils';

import { EventTypes } from '../../../utils/enums';

const EventItem: React.FC<{ event: SpecialDate | Rotation }> = ({
  event,
}): JSX.Element => {
  const { userId } = useContext(AuthContext);
  const { data: dataContact } = useContact(userId, event.contactId);
  const contact = new Contact(dataContact!);

  return (
    <strong>
      {contact.displayName}'s{' '}
      {event instanceof SpecialDate ? event.typeLabel : 'Rotation'}
    </strong>
  );
};

const DailyEventsDialog: React.FC<{}> = () => {
  const {
    dailyEventsDialogOpen,
    dailyEventsDialogData,
    closeDailyEventsDialog,
    openFollowUpDialog,
    openSpecialDateViewDialog,
    openRotationViewDialog,
  } = useContext(SharedDialoguesContext);

  const { eventsType, detailedEvents, date } = dailyEventsDialogData;
  const isFollowUp: boolean = eventsType === EventTypes.FollowUp;
  const isSpecialDate: boolean = eventsType === EventTypes.SpecialDate;
  const events = isFollowUp
    ? detailedEvents.sort(
        (dateA, dateB) => dateA.followupTimestamp - dateB.followupTimestamp
      )
    : detailedEvents;

  const handleEventClick = eventData => {
    closeDailyEventsDialog();

    if (isFollowUp) {
      openFollowUpDialog({
        followUpId: eventData.id,
        contactId: eventData.contactId,
        isEditing: false,
        isDone: false,
        closeOnCancelEdit: false,
      });
      return;
    }

    if (isSpecialDate) {
      openSpecialDateViewDialog({
        specialDateId: eventData.id,
        contactId: eventData.contactId,
      });
      return;
    }

    openRotationViewDialog({
      rotationId: eventData.id,
      contactId: eventData.contactId,
    });
  };

  return (
    <CMDialog
      width="sm"
      handleClose={closeDailyEventsDialog}
      title={date}
      open={dailyEventsDialogOpen}
      onClose={closeDailyEventsDialog}
      renderContent={
        <div className="mt-4">
          <h1 className="text-xl font-bold">
            {isFollowUp
              ? 'Follow-Ups'
              : isSpecialDate
              ? 'Special Dates'
              : 'Rotations'}
          </h1>
          <ul className="my-4">
            {events?.map((e, index) => {
              const time =
                isFollowUp && e.timeBased
                  ? new Date(Math.round(e.followupTimestamp * 1000))
                  : null;

              return (
                <li
                  key={index}
                  className="space-x-2 flex items-center py-4 border-b cursor-pointer"
                  onClick={() => handleEventClick(e)}
                >
                  <div
                    className={`rounded-full ${
                      isFollowUp
                        ? 'bg-softPurple'
                        : isSpecialDate
                        ? 'bg-blueGrey'
                        : 'bg-fadeOrange'
                    } h-2 w-2`}
                  ></div>
                  {isFollowUp ? (
                    <strong>
                      {time &&
                        `${new Date(time).toLocaleTimeString('en-us', {
                          hour: '2-digit',
                          minute: '2-digit',
                        })}-`}{' '}
                      {e.contactName}
                    </strong>
                  ) : (
                    <EventItem
                      event={
                        isSpecialDate ? new SpecialDate(e) : new Rotation(e)
                      }
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      }
    />
  );
};

export default DailyEventsDialog;
