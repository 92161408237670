export enum BillPeriods {
  Month = 'month',
  Year = 'year',
  Onetime = 'onetime',
}

export enum SpecialDatesTypes {
  Birthday = 'birthday',
  Anniversary = 'anniversary',
}

export enum EventTypes {
  FollowUp = 'followup',
  SpecialDate = 'specialdate',
  Rotation = 'rotation',
}

export enum RotationsDisplayStatus {
  All = 'All',
  Today = 'Today',
  Upcoming = 'Upcoming',
  Recent = 'Recent',
}

export enum SpecialDatesDisplayStatus {
  All = 'All',
  Today = 'Today',
  Upcoming = 'Upcoming',
  Recent = 'Recent',
}
