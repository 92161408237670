import React, { memo } from 'react';

import CMAvatar from '../cm-avatar/cm-avatar.component';
import CMButton from '../cm-button/cm-button.component';
import CMDialog from '../cm-dialog/cm-dialog.component';
import PaginatedList from '../paginated-list/paginated-list.component';

const DuplicateContactDialog = memo(
  ({ contacts, fullName, open, onContactSelected, onClose }) => (
    <CMDialog
      width="sm"
      title="Possible Duplicate Alert"
      open={open}
      handleClose={onClose}
      renderContent={
        <div className="mt-6">
          <p className="mb-6">
            We found <strong>{contacts?.length}</strong> contacts in your
            address book that are similar to <strong>{fullName}</strong>.
          </p>
          <p className="mb-6">
            If your new contact is in the list, please select it and we will
            update that one instead of creating a duplicated contact.
          </p>
          <PaginatedList
            list={contacts}
            shouldSetURLParam={false}
            renderItem={(
              {
                id,
                fullName,
                company,
                emails,
                phones,
                displayStatus,
                nativeId,
                instantMessageAddressesCM,
                highlights,
                unloggedTimestamp,
                createdTimestamp,
                unloggedInteractionReason,
                source,
              },
              testid,
              page,
              index
            ) => (
              <div
                key={id}
                className="md:grid grid-cols-3 md:hover:bg-paleGreen md:hover:border-paleTeal md:hover:cursor-pointer rounded-full p-2"
                testid={`${testid}.paginatedList.page${page}.item${index}`}
                onClick={() =>
                  onContactSelected({
                    id,
                    nativeId,
                    instantMessageAddressesCM,
                    highlights,
                    email: emails?.[0]?.email,
                    phone: phones?.[0]?.number,
                    unloggedTimestamp,
                    createdTimestamp,
                    unloggedInteractionReason,
                    source,
                  })
                }
              >
                <div className="flex items-center h-9">
                  <CMAvatar
                    size="sm"
                    displayStatus={displayStatus}
                    isCompany={!fullName.length}
                  />
                  <div className="truncate pl-3">{fullName || company}</div>
                </div>
                {emails?.[0]?.email && (
                  <div className="items-center hidden md:flex">
                    <span className="truncate">{emails[0].email || '-'}</span>
                  </div>
                )}
                {phones?.[0]?.number && (
                  <div className="items-center hidden md:flex">
                    <span className="truncate">{phones[0].number}</span>
                  </div>
                )}
              </div>
            )}
            testid="duplicateContactDialog"
          />
          <p className="font-bold my-6 text-lg">
            My new contact is not in that list
          </p>
          <CMButton fullWidth={true} onClick={() => onContactSelected(null)}>
            Confirm Is A New Contact
          </CMButton>
        </div>
      }
    />
  )
);

export default DuplicateContactDialog;
