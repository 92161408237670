// Based on https://github.com/nandorojo/swr-firestore/issues/59
// This is a custom Fuego class to support firebase V8. Once the swr-firestore
// is updated to support it we won't need this
import firebase from 'firebase/app';

export default class Fuego {
  constructor(config) {
    this.db = !firebase.apps.length
      ? firebase.initializeApp(config).firestore()
      : firebase.app().firestore();
    this.auth = firebase.auth;
    this.storage = firebase.storage;
    this.analytics = firebase.analytics;
  }
}
