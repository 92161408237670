import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../context/auth/auth.context';

import { getMyActiveSubscription } from '../services/auth/auth.service';

import ISubscription from '../types/subscription.type';

const useActiveSubscriptionData = () => {
  const { userId, authToken, isPremium, userData } = useContext(AuthContext);

  const [loadingSubscription, setLoadingSubscription] = useState<boolean>(true);
  const [activeSubscriptionData, setActiveSubscriptionData] =
    useState<ISubscription | null>(null);

  useEffect(() => {
    if (!isPremium && userData?.state !== 'Suspended') {
      setLoadingSubscription(false);
      return;
    }

    getMyActiveSubscription(userId, authToken)
      .then(data => setActiveSubscriptionData(data))
      .finally(() => setLoadingSubscription(false));
  }, [isPremium, userId, authToken, userData]);

  return {
    loadingSubscription,
    activeSubscriptionData,
  };
};

export default useActiveSubscriptionData;
