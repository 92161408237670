import _partition from 'lodash/partition';
import React, { memo, useContext } from 'react';

import NoteItem from '../note-item/note-item.component';
import PaginatedList from '../paginated-list/paginated-list.component';
import PaywallHiddenItemsMessage from '../paywall-hidden-items-message/paywall-hidden-items-message.component';

import { PaywallContext } from '../../context/paywall/paywall.context';

const renderNoteItem = (
  { id, contactId, createdTimestamp, body },
  testid,
  page,
  index
) => (
  <NoteItem
    key={id}
    id={id}
    contactId={contactId}
    createdTimestamp={createdTimestamp}
    body={body}
    testid={`${testid}.paginatedList.page${page}.item${index}`}
  />
);

const NotesList = memo(
  ({ list, listSize = 25, searchTerm, filter, hidePaywallItems, testid }) => {
    const { latestNotesIDs, hasReachedMaxNotes } = useContext(PaywallContext);

    const [availableNotes, archivedNotes] =
      hidePaywallItems && hasReachedMaxNotes
        ? _partition(list, ({ id }) => latestNotesIDs.includes(id))
        : [list, []];

    return (
      <PaginatedList
        list={availableNotes}
        shouldSetURLParam={true}
        listSize={listSize}
        paginationColor="primary"
        renderItem={renderNoteItem}
        searchTerm={searchTerm}
        filter={filter}
        showHiddenItemsMessage={hidePaywallItems && archivedNotes.length}
        hiddenItemsMessage={
          <PaywallHiddenItemsMessage
            outstandingItems={archivedNotes.length}
            forNotes
          />
        }
        testid={testid}
      />
    );
  }
);

export default NotesList;
