import { TextField } from '@mui/material';
import React, { memo, useContext, useState } from 'react';

import CMButton from '../../../components/cm-button/cm-button.component';
import CMDialog from '../../../components/cm-dialog/cm-dialog.component';
import CMTextButton from '../../../components/cm-text-button/cm-text-button.component';

import { AuthContext } from '../../../context/auth/auth.context';
import { SnackbarContext } from '../../../context/snackbar/snackbar.context';

import { extendTrial } from '../../../services/users/users.service';

import { dateToInputDateFormat } from '../../../utils/dates.utils';

const AdminExtendTrial = memo(({ userId, until, testid, onSuccess }) => {
  const { authToken } = useContext(AuthContext);
  const { showSnackbar } = useContext(SnackbarContext);

  const initialValues = {
    newDate: until,
    newDateHelperText: '',
    newDateError: false,
  };

  const [extendTrialDialogOpen, setExtendTrialDialogOpen] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [savingNewDate, setSavingNewDate] = useState(false);

  const minDate =
    until &&
    dateToInputDateFormat(
      new Date(`${until}T00:00:00`).setDate(
        new Date(`${until}T00:00:00`).getDate() + 1
      )
    );

  const openExtendTrialDialog = () => {
    setExtendTrialDialogOpen(true);
    // Reset values
    setValues(initialValues);
  };

  const closeExtendTrialDialog = () => {
    setExtendTrialDialogOpen(false);
  };

  const handleChange = event => {
    setValues({
      ...values,
      newDate: event.target.value,
    });
  };

  const handleChangeDateSubmit = () => {
    const invalidDate = new Date(values.newDate) < new Date(minDate);

    setValues(values => ({
      ...values,
      newDateHelperText: invalidDate
        ? `New date can't be before original date (${until})`
        : '',
      newDateError: invalidDate,
    }));

    if (invalidDate) return;

    setSavingNewDate(true);
    extendTrial(userId, values.newDate, authToken)
      .then(() => {
        showSnackbar({
          severity: 'success',
          message: 'Trial extended succesfully.',
        });
        closeExtendTrialDialog();
        onSuccess(values.newDate);
      })
      .catch(err => {
        const errorMessage = err?.response?.data?.args?.[0] ?? '';
        const formattedErrorMessage = errorMessage
          .replace('! check_args - ', '')
          .replace(/[{}]/g, '');
        showSnackbar({
          severity: 'error',
          message: formattedErrorMessage || 'Something wrong happened.',
        });
      })
      .finally(() => {
        setSavingNewDate(false);
      });
  };

  return (
    <>
      <CMTextButton
        data-testid={`${testid}.extendTrialButton`}
        onClick={openExtendTrialDialog}
      >
        Extend Trial
      </CMTextButton>
      <CMDialog
        width="xs"
        title="Extend Trial"
        renderContent={
          <div className="pt-4 space-y-4">
            <TextField
              label="New Date"
              className="w-full"
              variant="outlined"
              id="newdate"
              error={values.newDateError}
              helperText={values.newDateHelperText}
              type="date"
              value={values.newDate}
              onChange={e => handleChange(e)}
              InputLabelProps={{
                style: { fontSize: '1rem' },
                shrink: true,
              }}
              inputProps={{
                style: { fontSize: '1rem' },
                'data-testid': 'admin.extendTrial.dateInput',
                min: minDate,
              }}
            />
          </div>
        }
        renderBottomActions={
          <div className="flex flex-col w-full">
            <div className="space-y-4 md:space-y-0 md:flex md:space-x-4 w-full">
              <CMButton
                data-testid="admin.extendTrial.cancelButton"
                onClick={closeExtendTrialDialog}
                fullWidth
              >
                Cancel
              </CMButton>
              <CMButton
                data-testid="admin.extendTrial.saveButton"
                disabled={!values.newDate.length || savingNewDate}
                loading={savingNewDate}
                onClick={handleChangeDateSubmit}
                fullWidth
              >
                Save
              </CMButton>
            </div>
          </div>
        }
        open={extendTrialDialogOpen}
        handleClose={closeExtendTrialDialog}
      />
    </>
  );
});

export default AdminExtendTrial;
