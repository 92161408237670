import {
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Popover,
  useMediaQuery,
} from '@mui/material';
import React, { memo, useContext, useState } from 'react';
import { BiCalendarAlt } from 'react-icons/bi';
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdMenu,
  MdPerson,
  MdStar,
} from 'react-icons/md';
import { RiStickyNoteLine } from 'react-icons/ri';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import CMAvatar from '../cm-avatar/cm-avatar.component';
import CMButton from '../cm-button/cm-button.component';
import CMTextButton from '../cm-text-button/cm-text-button.component';
import SecondarySearch from '../secondary-search/secondary-search.component';

import useDebounce from '../../hooks/useDebounce';
import useFeaturesSwitch from '../../hooks/useFeaturesSwitch';

import { AuthContext } from '../../context/auth/auth.context';

import {
  useContacts,
  useFollowUps,
  useNotes,
} from '../../firebase/firebase.utils';

import { getGoPremiumRoute } from '../../utils/helpers';
import {
  getContactDisplayName,
  sortAndFilterContactList,
} from '../../utils/list.utils';

import iso from '../../assets/images/iso-dark.png';
import logo from '../../assets/images/logo-dark.png';

const HeaderMenu = memo(({ debouncedSearchTerm, handleItemClick }) => {
  const { userId } = useContext(AuthContext);

  const { data: dataContacts } = useContacts(
    debouncedSearchTerm.length ? userId : null
  );
  const { data: dataNotes } = useNotes(
    debouncedSearchTerm.length ? userId : null
  );
  const { data: dataFollowUps } = useFollowUps(
    debouncedSearchTerm.length ? userId : null
  );

  const loading = !dataContacts || !dataNotes || !dataFollowUps;

  if (loading)
    return (
      <div className="w-[486px] p-16 text-center">
        <CircularProgress
          size="32px"
          data-testid="header.searchResults.loadingIndicator"
        />
      </div>
    );

  const filteredContacts = sortAndFilterContactList(
    dataContacts,
    debouncedSearchTerm
  );

  const filteredNotes = dataNotes.filter(({ body }) =>
    // If search term is present filter by `body`
    body.toLowerCase().includes(debouncedSearchTerm.toLowerCase().trim())
  );

  const filteredFollowUps = dataFollowUps.filter(({ body, contactName }) => {
    // If search term is present filter by `body` or `contactaName`
    const search = debouncedSearchTerm.toLocaleLowerCase().trim();
    return (
      body.toLowerCase().includes(search) ||
      contactName?.toLowerCase().includes(search)
    );
  });

  if (
    !filteredContacts.length &&
    !filteredNotes.length &&
    !filteredFollowUps.length
  ) {
    return (
      <div className="p-5 w-[486px]">
        <strong
          className="text-xl"
          data-testid="header.searchResults.emptyResultsPhrase"
        >
          No results with "
          <span className="text-fadeOrange">{debouncedSearchTerm}</span>"
        </strong>
      </div>
    );
  }

  return (
    <div className="p-5 w-[486px]">
      {filteredContacts.length ? (
        <div>
          <strong className="text-xl">
            Contacts ({filteredContacts.length})
          </strong>
          <ul className="border-b border-coolGrey mb-5">
            {filteredContacts
              .slice(0, 5)
              .map(
                (
                  { fullName, company, displayStatus, id, email, phoneNumber },
                  index
                ) => {
                  return (
                    <li key={id}>
                      <Link
                        onClick={handleItemClick}
                        to={`/contacts/${id}`}
                        data-testid={`header.searchResults.contactsList.item${index}`}
                      >
                        <div className="flex items-center py-2">
                          <div className="w-8">
                            {displayStatus === 'Starred' ? (
                              <MdStar className="text-softPurple" size="24px" />
                            ) : null}
                          </div>
                          <CMAvatar
                            size="sm"
                            displayStatus={displayStatus}
                            isCompany={!fullName.length}
                          />
                          <div className="truncate pl-3">
                            {getContactDisplayName(
                              fullName,
                              company,
                              email,
                              phoneNumber
                            )}
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                }
              )}
          </ul>
        </div>
      ) : null}
      <div className="border-b border-coolGrey mb-5 pb-5">
        <strong
          className="text-xl"
          data-testid="header.searchResults.allResultsPhrase"
        >
          All results including "
          <span className="text-fadeOrange">{debouncedSearchTerm}</span>"
        </strong>
        <div className="flex flex-col items-start space-y-2 pt-5">
          <CMTextButton
            component={Link}
            icon={MdPerson}
            color="none"
            iconColor="text-blueGrey"
            to={`/search?query=${encodeURIComponent(
              debouncedSearchTerm
            )}&type=Contacts`}
            data-testid="header.searchResults.contactResultsButton"
          >
            {`${filteredContacts.length} Contacts`}
          </CMTextButton>
          <CMTextButton
            component={Link}
            icon={RiStickyNoteLine}
            color="none"
            iconColor="text-fadeOrange"
            to={`/search?query=${encodeURIComponent(
              debouncedSearchTerm
            )}&type=Notes`}
            data-testid="header.searchResults.notesResultsButton"
          >
            {`${filteredNotes.length} Notes`}
          </CMTextButton>
          <CMTextButton
            component={Link}
            icon={BiCalendarAlt}
            color="none"
            iconColor="text-softPurple"
            to={`/search?query=${encodeURIComponent(
              debouncedSearchTerm
            )}&type=FollowUps`}
            data-testid="header.searchResults.followupsResultsButton"
          >
            {`${filteredFollowUps.length} Follow-ups`}
          </CMTextButton>
        </div>
      </div>
      Press ENTER to go to results page
    </div>
  );
});

const HeaderSearch = memo(() => {
  const navigate = useNavigate();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleClose = () => {
    setMenuAnchorEl(null);
    setSearchTerm('');
  };

  const goToSearchPage = () =>
    navigate(`/search?query=${encodeURIComponent(searchTerm)}`);

  return (
    <div className="w-72 hidden lg:block pl-4">
      <SecondarySearch
        searchTerm={searchTerm}
        handleOnChange={setSearchTerm}
        handleAdditionalOnChange={setMenuAnchorEl}
        handleOnEnterKeyPress={goToSearchPage}
        placeholder="Search Contact Mapping"
        testid="header"
      />
      <Popover
        id="search-menu"
        anchorEl={menuAnchorEl}
        disableAutoFocus
        open={Boolean(searchTerm)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <HeaderMenu
          handleItemClick={handleClose}
          debouncedSearchTerm={debouncedSearchTerm}
        />
      </Popover>
    </div>
  );
});

const WebAppHeader = ({ toggleMenu }) => {
  const { userData, isPremium, authToken, signOut } = useContext(AuthContext);
  const { web_app_free, members_portal_migration } = useFeaturesSwitch();

  const location = useLocation();
  const matchesLg = useMediaQuery(theme => theme.breakpoints.up('lg'));

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Users might be using the web app without having completed their public
  // profile. That's why we need to fallback `data` to `userData`.
  const firstName = userData?.firstname || '';
  const lastName = userData?.lastname || '';

  return (
    <header className="sticky z-50 -top-[1px] lg:static h-14 lg:h-16">
      <div className="flex justify-between items-center w-full h-14 lg:h-16 pl-4 lg:px-8 bg-white border-b border-silver">
        <Link
          to="/dashboard"
          className="shrink-0"
          data-testid="header.homeButton"
        >
          <div className="flex align-center">
            <img
              src={logo}
              alt="Contact Mapping"
              className="w-[180px] hidden lg:block"
            />
            <img
              src={iso}
              alt="Contact Mapping"
              className="w-[28px] block lg:hidden"
            />
            <div className="border-l border-slate pl-4 ml-4 flex items-center text-xl">
              app
            </div>
          </div>
        </Link>
        {/* Mobile */}
        <div className="block lg:hidden">
          {!userData ? (
            <div className="pr-4">
              <CMButton
                size="sm"
                component={Link}
                to="/signin"
                state={{ from: location }}
                data-testid="header.signInButton"
              >
                Sign in
              </CMButton>
            </div>
          ) : (
            <button
              className="h-10 w-10 border-l border-silver"
              onClick={toggleMenu}
              data-testid="header.mobileMenuToggleButton"
            >
              <MdMenu size="24px" className="text-blueGrey mx-auto" />
            </button>
          )}
        </div>
        {/* Desktop */}
        <div className="hidden lg:flex lg:items-center pl-4 overflow-hidden ">
          {!userData ? (
            <CMButton
              size="sm"
              component={Link}
              to="/signin"
              state={{ from: location }}
              data-testid="header.signInButton"
            >
              Sign in
            </CMButton>
          ) : null}
          {/*
            Not using userData?.role cause we need to know for sure there is
            userData (the user is logged in)
          */}
          {userData && !isPremium ? (
            members_portal_migration ? (
              <CMButton
                size="sm"
                data-testid="header.goPremiumButton"
                component={Link}
                to={getGoPremiumRoute(userData.state)}
              >
                Go Pro
              </CMButton>
            ) : (
              <CMButton
                size="sm"
                data-testid="header.goPremiumButton"
                component="a"
                href={`${process.env.REACT_APP_MEMBERS_PORTAL_URL}/manage-account/${authToken}`}
              >
                Go Pro
              </CMButton>
            )
          ) : null}
          {/* Search Bar & User Menu only for logged in users */}
          {userData ? (
            <>
              {/* Only show search bar for Premium users on `lg` and routes other than /search */}
              {(web_app_free || isPremium) && // This can be safely removed when `web_app_free` is not needed anymore
              location.pathname !== '/search' &&
              matchesLg ? (
                <HeaderSearch />
              ) : null}
              {/* Avatar */}
              <button
                onClick={handleClick}
                aria-controls="user-menu"
                aria-haspopup="true"
                className="flex items-center pl-4 overflow-hidden"
                data-testid="header.desktopMenuToggleButton"
              >
                <CMAvatar
                  size="sm"
                  img={userData?.image}
                  initials={`${firstName?.charAt(0)}${lastName?.charAt(0)}`}
                />
                <span className="text-xl pl-4 truncate">
                  {`${firstName} ${lastName}`}
                </span>
                <span className="pl-4 flex">
                  {Boolean(anchorEl) ? (
                    <MdKeyboardArrowUp size="21px" />
                  ) : (
                    <MdKeyboardArrowDown size="21px" />
                  )}
                </span>
              </button>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                elevation={1}
              >
                <MenuItem
                  component={Link}
                  to="/my-profile"
                  data-testid="header.desktopMenu.dbcButton"
                >
                  Digital Business Card
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/manage-account"
                  data-testid="header.desktopMenu.myProfileButton"
                >
                  Your Account
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={signOut}
                  data-testid="header.desktopMenu.logoutButton"
                >
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : null}
        </div>
      </div>
    </header>
  );
};

export default WebAppHeader;
