import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import BillingPeriodSelector from '../../components/billing-period-selector/billing-period-selector.component';
import CardDetailsElements from '../../components/card-details/card-details.component';
import CMButton from '../../components/cm-button/cm-button.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import { getCoupon, getPlans } from '../../services/plans/plans.service';

import Coupon from '../../models/coupon.model';
import Plan from '../../models/plan.model';

const BillingPeriodPayment = () => {
  const navigate = useNavigate();

  const [currentSelectedPlan, setCurrentSelectedPlan] = useState<Plan | null>(
    null
  );

  const [searchParams] = useSearchParams();
  const [couponCode, setCouponCode] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const [couponError, setCouponError] = useState<boolean>(false);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [showPaymentStep, setShowPaymentStep] = useState<boolean>(false);

  const getCouponByCode = useCallback(
    (couponParam = null) => {
      const code = couponCode || couponParam;
      if (code) {
        setLoading(true);
        setCouponError(false);
        getCoupon(code)
          .then((response: Coupon) => {
            setCoupon(response.valid ? response : null);
            setCouponError(!response.valid);
          })
          .catch(() => {
            setCouponError(true);
            setCoupon(null);
          })
          .finally(() => setLoading(false));
      }
    },
    [couponCode]
  );

  useEffect(() => {
    getPlans().then((response: Plan[]) => setPlans(response));
  }, []);

  useEffect(() => {
    const couponParam: string | null = searchParams.get('coupon');

    if (couponParam) {
      setCouponCode(couponParam);
      getCouponByCode(couponParam);
    }
  }, [searchParams, getCouponByCode]);

  if (!plans?.length)
    return <WebAppLayoutLoading darkBackground hiddenSidebar />;

  return (
    <WebAppLayout darkBackground digitalBussinessCard hiddenSidebar>
      {!showPaymentStep ? (
        <div className="mt-8 md:mt-16 bg-white text-slate rounded-lg p-8">
          <h3 className="text-3xl font-bold">Select billing period:</h3>

          <p className="my-8">Please select your billing period.</p>

          <div className="my-10">
            <BillingPeriodSelector
              plans={plans}
              coupon={coupon}
              currentSelectedPlan={currentSelectedPlan}
              onPlanChange={setCurrentSelectedPlan}
            />
          </div>

          <h3 className="font-bold my-4">Do you have a promo code?</h3>
          <div className="flex">
            <TextField
              label="PROMO CODE"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              id="promoCode"
              type="text"
              value={couponCode}
              onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === 'Enter') getCouponByCode();
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCouponCode(e.target.value)
              }
            />
            <div className="ml-4">
              <CMButton
                loading={loading}
                disabled={loading}
                onClick={() => getCouponByCode()}
              >
                Apply code
              </CMButton>
            </div>
          </div>
          {couponError && (
            <p className="text-error font-bold text-xs mt-2">
              The coupon does not exist or is not valid.
            </p>
          )}

          <hr className="my-8" />

          <div className="flex justify-between">
            <CMButton onClick={() => navigate(-1)} outline={true}>
              Back
            </CMButton>
            <CMButton
              disabled={currentSelectedPlan === null}
              onClick={() => setShowPaymentStep(true)}
            >
              Next
            </CMButton>
          </div>
        </div>
      ) : (
        <CardDetailsElements
          currentSelectedPlan={currentSelectedPlan}
          coupon={coupon}
          onCancel={() => setShowPaymentStep(false)}
          cancelButtonText="Back"
          cancelButtonProps={{
            outline: true,
          }}
          submitButtonText="Confirm Subscription"
          submitRedirectTo="/welcome"
        />
      )}
    </WebAppLayout>
  );
};

export default BillingPeriodPayment;
