import React from 'react';
import { Link, Navigate } from 'react-router-dom';

import Breadcrumb from '../../components/breadcrumb/breadcrumb.component';
import CMButton from '../../components/cm-button/cm-button.component';
import CMTextButton from '../../components/cm-text-button/cm-text-button.component';
import WebAppLayoutLoading from '../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import useActiveSubscriptionData from '../../hooks/useActiveSubscriptionData';

import { formatDate } from '../../utils/dates.utils';

const Subscription = () => {
  const { loadingSubscription, activeSubscriptionData } =
    useActiveSubscriptionData();

  const isSuspended: boolean =
    activeSubscriptionData?.user_state === 'Suspended';

  if (loadingSubscription)
    return <WebAppLayoutLoading darkBackground hiddenSidebar />;

  // If there's no active subscription, send users back to /account
  // Example: Free user typing /account/subscription on the address bar
  if (!activeSubscriptionData) return <Navigate replace to="/manage-account" />;

  return (
    <WebAppLayout darkBackground hiddenSidebar digitalBussinessCard>
      <Breadcrumb color="light" label="Your Account" to="/manage-account" />
      <h1 className="text-2xl md:text-5xl font-bold">
        Manage your subscription
      </h1>

      <div className="mt-8 md:mt-16 bg-white text-slate rounded-lg p-8">
        <h3 className="text-3xl font-bold pb-8">Your Subscription</h3>
        <div className="md:grid grid-cols-2 gap-8 space-y-4 md:space-y-0 md:text-xl">
          <div>
            <strong className="uppercase">
              {isSuspended ? 'Status' : 'Plan'}
            </strong>
            <br />
            {isSuspended
              ? activeSubscriptionData.user_state
              : activeSubscriptionData.user_role}
          </div>
          <div className="text-right">
            {isSuspended ? null : (
              <CMTextButton
                component={Link}
                to="cancel-subscription"
                color="secondary"
              >
                Downgrade to basic
              </CMTextButton>
            )}
          </div>
          <div>
            <strong className="uppercase">Billing period:</strong>
            <br />
            {activeSubscriptionData.periodicity}
          </div>
          <div className="text-right">
            {isSuspended ? null : (
              <CMButton
                component="a"
                color="secondary"
                href="mailto:support@contactmapping.com?subject=Change my billing period&body=Hi, I would like to update my Contact Mapping subscription billing period."
              >
                Change billing period
              </CMButton>
            )}
          </div>
          <div className="md:col-span-2">
            <strong className="uppercase">Next due:</strong>
            <br />
            {formatDate(
              new Date(`${activeSubscriptionData.user_until}T00:00:00`),
              false
            )}
          </div>
          <div>
            <strong className="uppercase">Payment method:</strong>
            <br />
            Card ending in: {activeSubscriptionData.last4}
          </div>
          <div className="text-right">
            <CMButton
              component={Link}
              color="secondary"
              to="update-credit-card"
            >
              Update credit card details
            </CMButton>
          </div>
        </div>
      </div>
    </WebAppLayout>
  );
};

export default Subscription;
