import React, { useContext, useState } from 'react';
import { IoMdCalendar } from 'react-icons/io';

import CMButton from '../../cm-button/cm-button.component';
import CMDialog from '../../cm-dialog/cm-dialog.component';
import CMTextButton from '../../cm-text-button/cm-text-button.component';
import DateCompleteDialogContent from '../../date-complete-dialog-content/date-complete-dialog-content.component';

import useNoteActions from '../../../hooks/useNoteActions';
import useRotationActions from '../../../hooks/useRotationActions';

import { AuthContext } from '../../../context/auth/auth.context';
import { SharedDialoguesContext } from '../../../context/shared-dialogues/shared-dialogues.context';

const RotationCompleteDialog: React.FC = () => {
  const { userId } = useContext(AuthContext);
  const {
    rotationCompleteDialogOpen,
    closeRotationCompleteDialog,
    closeRotationViewDialog,
    rotationViewDialogData,
  } = useContext(SharedDialoguesContext);

  const { contactId, rotationId } = rotationViewDialogData;

  const [noteBody, setNoteBody] = useState<string>('');

  const { dataRotation, handleSkipRotation } = useRotationActions(
    rotationId,
    contactId
  );
  const { handleSaveNote } = useNoteActions(userId, undefined, contactId);

  const displayDate = new Date().toLocaleDateString('en-us', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  });

  const handleCompleteRotation = (shouldAddCustomNote: boolean): void => {
    handleSaveNote(
      shouldAddCustomNote ? noteBody : 'Touch',
      contactId,
      'add',
      true
    )
      .then(() => handleSkipRotation())
      .then(() => {
        closeDialog();
        closeRotationViewDialog();
      });
  };

  const closeDialog = () => {
    setNoteBody('');
    closeRotationCompleteDialog();
  };

  if (!dataRotation) return null;

  return (
    <CMDialog
      width="sm"
      open={rotationCompleteDialogOpen}
      handleClose={closeDialog}
      renderBottomActions={
        <div className="flex justify-between w-full">
          <div className="flex">
            <CMTextButton onClick={closeDialog}>Not now</CMTextButton>
          </div>
          <div className="flex">
            <CMButton
              disabled={!noteBody}
              onClick={() => handleCompleteRotation(true)}
            >
              Save note
            </CMButton>
          </div>
        </div>
      }
      renderContent={
        <DateCompleteDialogContent
          contactId={contactId}
          title="Complete Rotation"
          textareaPlaceholder="Remembering more of what colleagues, friends, and acquaintances share with you is about to become your secret weapon."
          closeDialog={closeDialog}
          handleTouch={() => handleCompleteRotation(false)}
          renderContent={
            <div className="flex items-center">
              <IoMdCalendar size="24px" />
              <div className="pl-2">{displayDate}</div>
            </div>
          }
          noteBody={noteBody}
          setNoteBody={setNoteBody}
        />
      }
    />
  );
};

export default RotationCompleteDialog;
