import React from 'react';

type IProps = {
  renderContent: React.ReactNode;
  renderActions: React.ReactNode;
};

const DashboardEmptyState: React.FC<IProps> = ({
  renderContent,
  renderActions,
}) => {
  return (
    <div className="text-steel">
      <div className="text-2xl font-bold pb-8">{renderContent}</div>
      <div className="hidden md:block w-80">{renderActions}</div>
    </div>
  );
};

export default DashboardEmptyState;
