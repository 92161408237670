import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import useFeaturesSwitch from '../../hooks/useFeaturesSwitch';

import {
  useFollowUps,
  useNotes,
  useRotations,
} from '../../firebase/firebase.utils';

import { AuthContext } from '../auth/auth.context';
import { RemoteConfigContext } from '../remote-config/remote-config.context';

export const PaywallContext = createContext();

const initialState = {
  // Notes
  latestNotesIDs: [],
  hasReachedMaxNotes: false,
  // Follow ups
  latestFollowUpsIDs: [],
  isAboutToReachHalfFollowUps: false,
  isAboutToReachMaxFollowUps: false,
  hasReachedMaxFollowUps: false,
  // Rotation
  latestRotationsIDs: [],
  isAboutToReachHalfRotations: false,
  isAboutToReachMaxRotations: false,
  hasReachedMaxRotations: false,
  hasOneOutstandingRotation: false,
};

const actions = {
  UPDATE_LATEST_NOTES_IDS: 'UPDATE_LATEST_NOTES_IDS',
  UPDATE_LATEST_FOLLOWUPS_IDS: 'UPDATE_LATEST_FOLLOWUPS_IDS',
  UPDATE_LATEST_ROTATIONS_IDS: 'UPDATE_LATEST_ROTATIONS_IDS',
  RESET_PAYWALL: 'RESET_PAYWALL',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.UPDATE_LATEST_NOTES_IDS:
      return {
        ...state,
        latestNotesIDs: action.data.notesIds,
        hasReachedMaxNotes:
          action.data.notesIds.length === action.data.configMaxNotes,
      };

    case actions.UPDATE_LATEST_FOLLOWUPS_IDS:
      return {
        ...state,
        latestFollowUpsIDs: action.data.followUpsIds,
        isAboutToReachHalfFollowUps:
          action.data.followUpsIds.length ===
          Math.round(action.data.configMaxFollowUps / 2 - 1),
        isAboutToReachMaxFollowUps:
          action.data.followUpsIds.length ===
          action.data.configMaxFollowUps - 1,
        hasReachedMaxFollowUps:
          action.data.followUpsIds.length === action.data.configMaxFollowUps,
      };

    case actions.UPDATE_LATEST_ROTATIONS_IDS:
      const hasOneOutstandingRotation =
        action.data.rotationsIds.length === action.data.configMaxRotations + 1;
      const rotationIds = hasOneOutstandingRotation
        ? action.data.rotationsIds.slice(0, -1)
        : action.data.rotationsIds;

      return {
        ...state,
        latestRotationsIDs: rotationIds,
        isAboutToReachHalfRotations:
          rotationIds.length ===
          Math.round(action.data.configMaxRotations / 2 - 1),
        isAboutToReachMaxRotations:
          rotationIds.length === action.data.configMaxRotations - 1,
        hasReachedMaxRotations:
          rotationIds.length === action.data.configMaxRotations,
        hasOneOutstandingRotation,
      };

    case actions.RESET_PAYWALL:
      // if a user goes premium
      return initialState;

    default:
      return state;
  }
};

export const PaywallContextProvider = ({ children }) => {
  const {
    configValues: {
      paywall_max_followups,
      paywall_max_notes,
      paywall_max_rotations,
    },
  } = useContext(RemoteConfigContext);
  const { isPremium, userId } = useContext(AuthContext);

  const { paywall } = useFeaturesSwitch();

  const [state, dispatch] = useReducer(reducer, initialState);

  const updateLatestNotesIDs = useCallback(data => {
    dispatch({
      type: actions.UPDATE_LATEST_NOTES_IDS,
      data,
    });
  }, []);

  const updateLatestFollowUpsIDs = useCallback(data => {
    dispatch({ type: actions.UPDATE_LATEST_FOLLOWUPS_IDS, data });
  }, []);

  const updateLatestRotationsIDs = useCallback(data => {
    dispatch({ type: actions.UPDATE_LATEST_ROTATIONS_IDS, data });
  }, []);

  const resetPaywall = useCallback(() => {
    dispatch({ type: actions.RESET_PAYWALL });
  }, []);

  const { data: dataFollowUps } = useFollowUps(
    paywall && !isPremium && userId,
    paywall_max_followups,
    'asc'
  );
  const { data: dataNotes } = useNotes(
    paywall && !isPremium && userId,
    paywall_max_notes
  );
  const { data: dataRotations } = useRotations(
    paywall && !isPremium && userId,
    paywall_max_rotations + 1, // getting +1 to check if `hasOneOutstandingRotation`
    'asc'
  );

  useEffect(() => {
    if (paywall && !isPremium && dataFollowUps && dataNotes && dataRotations) {
      const followUpsIds = dataFollowUps.map(({ id }) => id);
      const notesIds = dataNotes.map(({ id }) => id);
      const rotationsIds = dataRotations.map(({ id }) => id);

      updateLatestFollowUpsIDs({
        followUpsIds,
        configMaxFollowUps: paywall_max_followups,
      });
      updateLatestNotesIDs({ notesIds, configMaxNotes: paywall_max_notes });
      updateLatestRotationsIDs({
        rotationsIds,
        configMaxRotations: paywall_max_rotations,
      });
    }
  }, [
    paywall,
    isPremium,
    dataFollowUps,
    dataNotes,
    dataRotations,
    paywall_max_followups,
    paywall_max_notes,
    paywall_max_rotations,
    updateLatestFollowUpsIDs,
    updateLatestNotesIDs,
    updateLatestRotationsIDs,
  ]);

  const value = useMemo(
    () => ({
      // State
      // Notes
      latestNotesIDs: state.latestNotesIDs,
      hasReachedMaxNotes: state.hasReachedMaxNotes,
      // Follow ups
      latestFollowUpsIDs: state.latestFollowUpsIDs,
      isAboutToReachHalfFollowUps: state.isAboutToReachHalfFollowUps,
      isAboutToReachMaxFollowUps: state.isAboutToReachMaxFollowUps,
      hasReachedMaxFollowUps: state.hasReachedMaxFollowUps,
      // Rotation
      latestRotationsIDs: state.latestRotationsIDs,
      isAboutToReachHalfRotations: state.isAboutToReachHalfRotations,
      isAboutToReachMaxRotations: state.isAboutToReachMaxRotations,
      hasReachedMaxRotations: state.hasReachedMaxRotations,
      hasOneOutstandingRotation: state.hasOneOutstandingRotation,
      // Actions
      updateLatestNotesIDs,
      updateLatestFollowUpsIDs,
      updateLatestRotationsIDs,
      resetPaywall,
    }),
    [
      state.latestNotesIDs,
      state.hasReachedMaxNotes,
      state.latestFollowUpsIDs,
      state.isAboutToReachHalfFollowUps,
      state.isAboutToReachMaxFollowUps,
      state.hasReachedMaxFollowUps,
      state.latestRotationsIDs,
      state.isAboutToReachHalfRotations,
      state.isAboutToReachMaxRotations,
      state.hasReachedMaxRotations,
      state.hasOneOutstandingRotation,
      updateLatestNotesIDs,
      updateLatestFollowUpsIDs,
      updateLatestRotationsIDs,
      resetPaywall,
    ]
  );

  return (
    <PaywallContext.Provider value={value}>{children}</PaywallContext.Provider>
  );
};
