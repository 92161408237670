import axios from 'axios';

import Coupon from '../../models/coupon.model';
import Plan from '../../models/plan.model';

import { API_URL } from '../../utils/constants';

export const getPlans = (): Promise<Plan[]> =>
  axios
    .get(`${API_URL}/products`)
    .then(response =>
      response?.data
        ?.map((plan: Plan) => new Plan(plan))
        .filter(
          (plan: Plan) =>
            plan.gateway === 'Stripe' && plan.interval !== 'onetime'
        )
    );

export const getCoupon = (coupon: string): Promise<Coupon> =>
  axios
    .get(`${API_URL}/stripecoupons/${coupon}`)
    .then(response => new Coupon(response?.data));

export const setupPaymentIntent = (
  amount: number,
  authToken: string
): Promise<string> =>
  axios
    .post(
      `${API_URL}/stripesetupintents`,
      { amount },
      {
        headers: { Authorization: authToken },
      }
    )
    .then(response => response?.data?.input?.client_secret);

export const createSubscription = (
  params: any,
  authToken: string
): Promise<any> =>
  axios.post(`${API_URL}/subscriptions`, params, {
    headers: { Authorization: authToken },
  });

export const updateSubscription = (
  params: any,
  authToken: string,
  subscriptionId: string
): Promise<any> =>
  axios.patch(`${API_URL}/subscriptions/${subscriptionId}`, params, {
    headers: { Authorization: authToken },
  });
