import { useMediaQuery } from '@mui/material';
import React, { memo, useContext } from 'react';
import { BiCalendarEvent } from 'react-icons/bi';
import { MdDelete, MdEdit, MdPersonAdd } from 'react-icons/md';

import CMAvatar from '../../components/cm-avatar/cm-avatar.component';
import CMContactSocialProfiles from '../../components/cm-contact-social-profiles/cm-contact-social-profiles.component';
import CMTextButton from '../../components/cm-text-button/cm-text-button.component';
import HTMLText from '../../components/html-text/html-text.component';

import ReadMore from '../read-more/read-more.component';

import useNoteActions from '../../hooks/useNoteActions';

import { AuthContext } from '../../context/auth/auth.context';
import { PaywallContext } from '../../context/paywall/paywall.context';
import { SharedDialoguesContext } from '../../context/shared-dialogues/shared-dialogues.context';

import { formatDate } from '../../utils/dates.utils';
import { unifyInstantMessageAddresses } from '../../utils/instant-message.utils';
import { getContactDisplayName } from '../../utils/list.utils';

const NoteItem = memo(({ id, contactId, createdTimestamp, body, testid }) => {
  const { userId } = useContext(AuthContext);
  const { openNoteDialog, openFollowUpDialog, openSelectContactDialog } =
    useContext(SharedDialoguesContext);
  const { latestNotesIDs, hasReachedMaxNotes } = useContext(PaywallContext);

  const matchesMd = useMediaQuery(theme => theme.breakpoints.up('md'));

  const { dataContact, handleDeleteNote } = useNoteActions(
    userId,
    id,
    contactId
  );

  const paywallDisabled = hasReachedMaxNotes && !latestNotesIDs?.includes(id);

  const handleFollowUp = () => {
    openFollowUpDialog({
      contactId,
      isEditing: true,
      closeOnCancelEdit: true,
    });
  };

  const handleItemClick = mode =>
    openNoteDialog({
      noteId: id,
      contactId: contactId,
      isEditing: mode === 'edit',
      closeOnCancelEdit: mode === 'edit',
    });

  return (
    <div
      className={`rounded border p-8 mb-2 flex border-silver ${
        paywallDisabled ? 'text-coolGrey bg-dirtyWhite' : 'bg-white'
      }`}
      data-testid={testid}
    >
      <div className="flex-grow md:pr-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <CMAvatar
              size="sm"
              isDraftNote={!contactId}
              displayStatus={dataContact?.displayStatus}
            />{' '}
            <div className="font-bold pl-4">
              {!contactId
                ? 'Draft'
                : !dataContact
                ? 'Loading...'
                : getContactDisplayName(
                    dataContact?.fullName,
                    dataContact?.company,
                    dataContact?.phones[0]?.number,
                    dataContact?.emails[0]?.email
                  ) || ''}
            </div>
          </div>
          <div className="text-sm font-bold">
            {formatDate(new Date(Math.round(createdTimestamp * 1000)))}
          </div>
        </div>
        {contactId ? (
          <div className="pt-4">
            <CMContactSocialProfiles
              loading={!dataContact}
              socialProfiles={unifyInstantMessageAddresses(
                dataContact?.instantMessageAddresses,
                dataContact?.instantMessageAddressesCM
              )}
              size="sm"
              testid={`${testid}.socialProfiles`}
            />
          </div>
        ) : null}
        <div className="pt-4">
          <ReadMore
            renderActionButton={
              matchesMd ? (
                <CMTextButton
                  onClick={handleItemClick}
                  data-testid={`${testid}.openNoteButton`}
                  disabled={paywallDisabled}
                >
                  Open Note
                </CMTextButton>
              ) : null
            }
          >
            <HTMLText testid={`${testid}.body`} text={body} />
          </ReadMore>
        </div>
      </div>
      <div className="p-4 w-48 shrink-0 hidden md:flex flex-col items-start justify-center border-l border-silver">
        {!contactId ? (
          <CMTextButton
            icon={MdPersonAdd}
            onClick={() => openSelectContactDialog(id)}
            data-testid={`${testid}.assignButton`}
            disabled={paywallDisabled}
          >
            Assign
          </CMTextButton>
        ) : (
          <CMTextButton
            icon={BiCalendarEvent}
            onClick={handleFollowUp}
            data-testid={`${testid}.followUpButton`}
            disabled={paywallDisabled}
          >
            Follow-up
          </CMTextButton>
        )}
        <CMTextButton
          icon={MdEdit}
          onClick={() => handleItemClick('edit')}
          data-testid={`${testid}.editButton`}
          disabled={paywallDisabled}
        >
          Edit
        </CMTextButton>
        <CMTextButton
          icon={MdDelete}
          onClick={handleDeleteNote}
          data-testid={`${testid}.deleteButton`}
          disabled={paywallDisabled}
        >
          Delete
        </CMTextButton>
      </div>
    </div>
  );
});

export default NoteItem;
