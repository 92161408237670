import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';

// import { MdEdit } from 'react-icons/md';
import CMAvatar from '../../../components/cm-avatar/cm-avatar.component';

// const AdminContactRowActions = memo(({ testid, id }) => {
//   const handleOnEditClick = () => {};

//   const actionButtonClassNames =
//     'hover:bg-opacity-10 transition-all font-bold text-sm h-[74px] w-[74px] flex flex-col items-center justify-center';

//   return (
//     <button
//       className={`${actionButtonClassNames} text-softPurple hover:bg-softPurple`}
//       onClick={handleOnEditClick}
//       data-testid={`${testid}.editButton`}
//     >
//       <MdEdit size="21px" />
//       <span>Edit</span>
//     </button>
//   );
// });

const rowClassNames = 'h-12 md:h-[74px] flex items-center';
const rowClassNamesMobile = 'hidden md:flex';

const AdminContactRow = memo(
  ({
    firstname,
    lastname,
    id,
    email,
    state, // Membership
    until, // Renewal
    imageUrl,
    testid,
  }) => {
    const navigate = useNavigate();

    // There's a lof of commented code in this file. It will be uncommented once
    // we add the edit details page

    // const [isHovering, setIsHovering] = useState(false);

    // const handleMouseOver = () => {
    //   setIsHovering(true);
    // };

    return (
      <div
        // className to add when adding actions again: 'md:hover:pr-[74px]'
        className="bg-white relative block transition-all overflow-hidden group md:px-8 border border-white md:hover:bg-paleGreen md:hover:border-paleTeal"
        // onMouseOver={handleMouseOver}
        data-testid={testid}
      >
        {/* Contact Row */}
        <div
          className="md:hover:cursor-pointer md:grid grid-cols-8"
          onClick={() => {
            navigate(`/admin/user-management/${id}`);
          }}
        >
          <div
            className={`${rowClassNames} col-span-3`}
            data-testid={`${testid}.name`}
          >
            <CMAvatar size="sm" img={imageUrl} />
            <div className="truncate pl-3">{`${firstname} ${
              lastname ?? ''
            }`}</div>
          </div>
          <div
            className={`${rowClassNames} ${rowClassNamesMobile} col-span-1`}
            data-testid={`${testid}.id`}
          >
            <span className="truncate">{id}</span>
          </div>
          <div
            className={`${rowClassNames} ${rowClassNamesMobile} col-span-2`}
            data-testid={`${testid}.email`}
          >
            <span className="truncate">{email}</span>
          </div>
          <div
            className={`${rowClassNames} ${rowClassNamesMobile} col-span-1`}
            data-testid={`${testid}.membership`}
          >
            <span className="truncate">{state}</span>
          </div>
          <div
            className={`${rowClassNames} ${rowClassNamesMobile} col-span-1`}
            data-testid={`${testid}.renewal`}
          >
            <span className="truncate">{until}</span>
          </div>
        </div>

        {/* Row Actions */}
        {/* <div className="absolute transition-all right-[-74px] top-0 bg-white md:group-hover:right-0"> */}
        {/* We only want to render the component on hover to avoid unnecessary renders */}
        {/* {isHovering ? (
            <AdminContactRowActions id={id} testid={`${testid}.actions`} />
          ) : null}
        </div> */}
      </div>
    );
  }
);

export default AdminContactRow;
