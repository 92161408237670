import firebase from 'firebase/app';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { BiCalendarEvent } from 'react-icons/bi';
import { MdCancel, MdDelete, MdEdit, MdPersonAdd } from 'react-icons/md';

import CMButton from '../../cm-button/cm-button.component';
import CMContactSocialProfiles from '../../cm-contact-social-profiles/cm-contact-social-profiles.component';
import CMDialog from '../../cm-dialog/cm-dialog.component';
import CMTextButton from '../../cm-text-button/cm-text-button.component';
import ContactAvatar from '../../contact-avatar/contact-avatar.component';
import HTMLText from '../../html-text/html-text.component';

import useKeySave from '../../../hooks/useKeySave';
import useNoteActions from '../../../hooks/useNoteActions';

import { AuthContext } from '../../../context/auth/auth.context';
import { PaywallDialoguesContext } from '../../../context/paywall/paywall-dialogues.context';
import { PaywallContext } from '../../../context/paywall/paywall.context';
import { SharedDialoguesContext } from '../../../context/shared-dialogues/shared-dialogues.context';

import { useContact } from '../../../firebase/firebase.utils';

import { formatDate } from '../../../utils/dates.utils';
import { unifyInstantMessageAddresses } from '../../../utils/instant-message.utils';
import { getContactDisplayName } from '../../../utils/list.utils';

const AvatarAndName = ({
  contactDisplayStatus,
  contactName,
  isDraftNote,
  onAssign,
  contactId,
  onAvatarClick,
}) => (
  <div className="flex items-center justify-between">
    <ContactAvatar
      contactDisplayStatus={contactDisplayStatus}
      contactName={contactName}
      isDraftNote={isDraftNote}
      contactId={contactId}
      onAvatarClick={onAvatarClick}
    />
    {onAssign ? (
      <CMTextButton
        icon={MdPersonAdd}
        onClick={onAssign}
        data-testid="noteDialog.assignButton"
      >
        {isDraftNote ? 'Assign' : 'Change Contact'}
      </CMTextButton>
    ) : null}
  </div>
);

const ViewNote = ({
  createdTimestamp,
  body = '', // Default empty string to avoid error when deleting notes
  isDraftNote,
  onFollowUp,
  onEdit,
  onDelete,
  onAssign,
  socialProfiles,
}) => (
  <>
    <div className="uppercase font-bold py-3">
      {formatDate(new Date(Math.round(createdTimestamp * 1000)))}
    </div>
    <HTMLText testid="noteDialog.viewMode.body" text={body} />
    <div className="py-6 flex space-x-8 justify-center">
      {/* Follow-up */}
      {!isDraftNote ? (
        <div
          className="flex flex-col items-center w-16 hover:cursor-pointer group"
          onClick={onFollowUp}
          data-testid="noteDialog.viewMode.followUpButton"
        >
          <div className="rounded-full border-2 border-fadeOrange w-14 h-14 flex items-center justify-center mb-4 bg-white group-hover:bg-fadeOrange group-hover:bg-opacity-10 transition-all">
            <BiCalendarEvent size="32px" className="text-fadeOrange" />
          </div>
          <div className="underline text-sm text-fadeOrange font-bold">
            Follow-up
          </div>
        </div>
      ) : (
        <div
          className="flex flex-col items-center w-16 hover:cursor-pointer group"
          onClick={onAssign}
          data-testid="noteDialog.viewMode.assignButton"
        >
          <div className="rounded-full border-2 border-fadeOrange w-14 h-14 flex items-center justify-center mb-4 bg-white group-hover:bg-fadeOrange group-hover:bg-opacity-10 transition-all">
            <MdPersonAdd size="32px" className="text-fadeOrange" />
          </div>
          <div className="underline text-sm text-fadeOrange font-bold">
            Assign
          </div>
        </div>
      )}
      {/* Edit */}
      <div
        className="flex flex-col items-center w-16 hover:cursor-pointer group"
        onClick={onEdit}
        data-testid="noteDialog.viewMode.editButton"
      >
        <div className="rounded-full border-2 border-fadeOrange w-14 h-14 flex items-center justify-center mb-4 bg-white group-hover:bg-fadeOrange group-hover:bg-opacity-10 transition-all">
          <MdEdit size="32px" className="text-fadeOrange" />
        </div>
        <div className="underline text-sm text-fadeOrange font-bold">Edit</div>
      </div>
      {/* Delete */}
      <div
        className="flex flex-col items-center w-16 hover:cursor-pointer group"
        onClick={onDelete}
        data-testid="noteDialog.viewMode.deleteButton"
      >
        <div className="rounded-full border-2 border-fadeOrange w-14 h-14 flex items-center justify-center mb-4 bg-white group-hover:bg-fadeOrange group-hover:bg-opacity-10 transition-all">
          <MdDelete size="32px" className="text-fadeOrange" />
        </div>
        <div className="underline text-sm text-fadeOrange font-bold">
          Delete
        </div>
      </div>
    </div>
    {!isDraftNote ? (
      <div className="pt-8 mt-8 border-t border-steel">
        <div className="flex justify-center">
          <CMContactSocialProfiles
            socialProfiles={socialProfiles}
            size="sm"
            testid="noteDialog.socialProfiles"
          />
        </div>
      </div>
    ) : null}
  </>
);

const EditNote = ({
  userId,
  createdTimestamp,
  body,
  contactId,
  status,
  onCancel,
  onSave,
  setNoteContentChanged,
  handleFollowUp,
}) => {
  const { fromDraftToContact, noteDialogOpen } = useContext(
    SharedDialoguesContext
  );

  const [noteBody, setNoteBody] = useState(body || '');

  const originalContactId = useRef(contactId); // For "Change contact" purposes
  const originalNoteBody = useRef(body || '');

  const { update: updateOriginalContact } = useContact(
    userId,
    originalContactId.current
  );

  const handleFolowUpBodyChange = e => setNoteBody(e.target.value);

  useEffect(() => {
    if (!noteDialogOpen) return; // If note dialog is closing ignore the rest

    if (
      fromDraftToContact ||
      noteBody !== originalNoteBody.current ||
      contactId !== originalContactId.current
    ) {
      setNoteContentChanged(true);
      return;
    }

    setNoteContentChanged(false);
  }, [
    noteBody,
    contactId,
    setNoteContentChanged,
    fromDraftToContact,
    noteDialogOpen,
  ]);

  const handleSaveClick = () => {
    const mode = createdTimestamp ? 'update' : 'add';

    // `shouldUpdateContact` if it is a draft and has `contactId` value (draft
    // has been assigned, `draftToAssigned`) or if contactId has changed
    // (reassigned, `contactAssignmentChanged`)
    const draftToAssigned = status === 'draft' && contactId !== null;
    const contactAssignmentChanged = originalContactId.current !== contactId;
    const shoudUpdateContact = draftToAssigned || contactAssignmentChanged;

    onSave(noteBody, contactId, mode, shoudUpdateContact).then(() => {
      // When reassigning a note to a different contact we need to substract
      // from the original contact's `notesCount`
      if (contactAssignmentChanged) {
        const nowUnix = Math.floor(Date.now() / 1000);

        updateOriginalContact({
          notesCount: firebase.firestore.FieldValue.increment(-1),
          updatedTimestamp: nowUnix,
        });
      }
    });

    // Open follow up dialog when saving a new note
    if ((mode === 'add' && contactId?.length) || draftToAssigned)
      handleFollowUp();

    onCancel();
  };

  const { keyDownHandler, keyUpHandler } = useKeySave(handleSaveClick, true);

  return (
    <div onKeyDown={keyDownHandler} onKeyUp={keyUpHandler}>
      <div className="uppercase font-bold py-3">
        {formatDate(
          new Date(
            Math.round(createdTimestamp ? createdTimestamp * 1000 : new Date())
          )
        )}
      </div>
      <textarea
        name="note"
        id="note"
        rows="5"
        autoFocus
        className="rounded border border-steel p-2 w-full"
        value={noteBody}
        placeholder="What do you want to remember?"
        onChange={handleFolowUpBodyChange}
        data-testid="noteDialog.editMode.bodyTextarea"
      ></textarea>
      <div className="pt-4 mt-4 border-t border-steel flex items-center justify-center space-x-4">
        <CMTextButton
          icon={MdCancel}
          onClick={onCancel}
          data-testid="noteDialog.editMode.cancelButton"
        >
          Cancel
        </CMTextButton>
        <CMButton
          onClick={handleSaveClick}
          disabled={!noteBody.length}
          data-testid="noteDialog.editMode.saveButton"
        >
          {contactId ? 'Save Note' : 'Save Draft'}
        </CMButton>
      </div>
    </div>
  );
};

const NoteDialog = () => {
  const { userId } = useContext(AuthContext);
  const {
    noteDialogData,
    noteDialogOpen,
    closeNoteDialog,
    openFollowUpDialog,
    openSelectContactDialog,
  } = useContext(SharedDialoguesContext);
  const { openOutstandingFollowUpDialog } = useContext(PaywallDialoguesContext);
  const { hasReachedMaxFollowUps } = useContext(PaywallContext);

  const { noteId, contactId, isEditing, closeOnCancelEdit } = noteDialogData;

  const { dataContact, dataNote, handleSaveNote, handleDeleteNote } =
    useNoteActions(userId, noteId, contactId);

  const [noteContentChanged, setNoteContentChanged] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const contactDisplayName = getContactDisplayName(
    dataContact?.fullName,
    dataContact?.company,
    dataContact?.phones[0]?.number,
    dataContact?.emails[0]?.email
  );

  useEffect(() => {
    // Revert edit/done mode when changing Note
    setIsEditMode(isEditing);
  }, [isEditing, noteDialogData]);

  const handleEdit = () => setIsEditMode(true);

  const handleCancel = () => {
    if (closeOnCancelEdit) {
      setNoteContentChanged(false);
      closeNoteDialog();
      return;
    }
    setNoteContentChanged(false);
    setIsEditMode(false);
  };

  const handleClose = cb => {
    if (noteContentChanged) {
      if (window.confirm('The changes will be lost.')) {
        setNoteContentChanged(false);
        closeNoteDialog();

        if (cb instanceof Function) cb();
      }
      return;
    }

    if (cb instanceof Function) cb();
    closeNoteDialog();
  };

  const handleDelete = () => {
    const userConfirmedDeletion = handleDeleteNote();

    if (userConfirmedDeletion) {
      closeNoteDialog();
    }
  };

  const handleFollowUp = () => {
    closeNoteDialog();
    hasReachedMaxFollowUps
      ? openOutstandingFollowUpDialog()
      : openFollowUpDialog({
          contactId,
          isEditing: true,
          closeOnCancelEdit: true,
        });
  };

  const handleAssign = () => {
    openSelectContactDialog(noteId || 'draft');
  };

  const socialProfiles = unifyInstantMessageAddresses(
    dataContact?.instantMessageAddresses,
    dataContact?.instantMessageAddressesCM
  );

  if (noteId && !dataNote) return null;

  return (
    <CMDialog
      width="sm"
      renderContent={
        !isEditMode ? (
          <div>
            <h2 className="text-2xl font-bold text-center pb-6">Note</h2>
            <AvatarAndName
              contactId={contactId}
              onAvatarClick={handleClose}
              contactDisplayStatus={dataContact?.displayStatus}
              contactName={contactDisplayName}
              isDraftNote={!contactId}
            />
            <ViewNote
              createdTimestamp={dataNote?.createdTimestamp}
              body={dataNote?.body}
              isDraftNote={!contactId}
              onFollowUp={handleFollowUp}
              onEdit={handleEdit}
              onDelete={handleDelete}
              onAssign={handleAssign}
              socialProfiles={socialProfiles}
            />
          </div>
        ) : (
          <div>
            <h2 className="text-2xl font-bold text-center pb-6">Note</h2>
            <AvatarAndName
              contactId={contactId}
              onAvatarClick={handleClose}
              contactDisplayStatus={dataContact?.displayStatus}
              contactName={contactDisplayName}
              isDraftNote={!contactId}
              onAssign={handleAssign}
            />
            <EditNote
              userId={userId}
              createdTimestamp={dataNote?.createdTimestamp}
              body={dataNote?.body}
              contactId={contactId}
              status={dataNote?.status}
              onCancel={handleCancel}
              onSave={handleSaveNote}
              setNoteContentChanged={setNoteContentChanged}
              handleFollowUp={handleFollowUp}
            />
          </div>
        )
      }
      open={noteDialogOpen}
      handleClose={handleClose}
    />
  );
};

export default NoteDialog;
