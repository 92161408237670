import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import React, { memo, useContext, useState } from 'react';

import Sidebar from '../sidebar/sidebar.component';
import WebAppHeader from '../web-app-header/web-app-header.component';

import { AuthContext } from '../../context/auth/auth.context';

type IProps = {
  children: JSX.Element | JSX.Element[];
  fullWidth?: boolean;
  digitalBussinessCard?: boolean;
  hiddenHeader?: boolean;
  /** Only applies on large media queries */
  hiddenSidebar?: boolean;
  darkBackground?: boolean;
};

const WebAppLayout = memo<IProps>(
  ({
    children,
    fullWidth = false,
    digitalBussinessCard = false,
    hiddenHeader = false,
    hiddenSidebar = false,
    darkBackground = false,
  }): JSX.Element => {
    const { userData } = useContext(AuthContext);

    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const handleToggleMenu = () => setMenuOpen(!menuOpen);

    const theme = useTheme();
    const matchesLg = useMediaQuery(theme.breakpoints.up('lg'));

    const showSidebar: boolean =
      userData !== null && (!hiddenSidebar || !matchesLg);

    return (
      <div
        className={
          menuOpen
            ? 'overflow-hidden h-screen lg:h-auto lg:overflow-auto'
            : 'lg:h-full lg:flex lg:flex-col'
        }
      >
        {!hiddenHeader ? <WebAppHeader toggleMenu={handleToggleMenu} /> : null}
        <Box
          className={`flex flex-grow ${
            darkBackground ? 'bg-slate text-white' : ''
          }`}
        >
          {showSidebar ? (
            <Sidebar open={menuOpen} toggleMenu={handleToggleMenu} />
          ) : null}
          <div
            className={
              digitalBussinessCard
                ? 'p-4 lg:p-8 max-w-3xl mx-auto w-full'
                : fullWidth
                ? 'w-full'
                : 'p-4 lg:p-8 mx-auto max-w-7xl w-full'
            }
          >
            {children}
          </div>
        </Box>
      </div>
    );
  }
);

export default WebAppLayout;
