import firebase from 'firebase/app';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MdCancel, MdDone, MdEdit } from 'react-icons/md';

import CMButton from '../../cm-button/cm-button.component';
import CMContactSocialProfiles from '../../cm-contact-social-profiles/cm-contact-social-profiles.component';
import CMDialog from '../../cm-dialog/cm-dialog.component';
import CMTextButton from '../../cm-text-button/cm-text-button.component';
import ContactAvatar from '../../contact-avatar/contact-avatar.component';
import DateButton from '../../date-button/date-button.component';
import HTMLText from '../../html-text/html-text.component';
import TimeDropdown from '../../time-dropdown/time-dropdown.component';

import useFeaturesSwitch from '../../../hooks/useFeaturesSwitch';
import useKeySave from '../../../hooks/useKeySave';

import { AuthContext } from '../../../context/auth/auth.context';
import { PaywallDialoguesContext } from '../../../context/paywall/paywall-dialogues.context';
import { PaywallContext } from '../../../context/paywall/paywall.context';
import { SharedDialoguesContext } from '../../../context/shared-dialogues/shared-dialogues.context';

import {
  useContact,
  useFollowUp,
  useFollowUps,
  useNotes,
} from '../../../firebase/firebase.utils';

import { PLATFORM_IDENTIFIER } from '../../../utils/constants';
import {
  dateToInputDateFormat,
  formatDate,
  getMinutes600s,
} from '../../../utils/dates.utils';
import { unifyInstantMessageAddresses } from '../../../utils/instant-message.utils';
import { getContactDisplayName } from '../../../utils/list.utils';

const DoneFollowUp = ({
  userId,
  contactId,
  contactFirstNoteTimestamp,
  updateContact,
  closeFollowUpDialog,
  openFollowUpDialog,
  updateFollowUp,
}) => {
  const [followUpNoteBody, setFollowUpNoteBody] = useState('');

  const { add: addNote } = useNotes(userId);

  useEffect(() => {
    const nowUnix = Math.floor(Date.now() / 1000);
    // Instead of updating the follow-up when users click on "Complete" or
    // "Done", we'll do it here, so we only implement this once.
    updateFollowUp({
      done: true,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedBy: PLATFORM_IDENTIFIER,
    }).then(() => {
      updateContact({
        unloggedInteractionReason: 'followup_complete',
        unloggedTimestamp: nowUnix,
        updatedTimestamp: nowUnix,
      });
    });
  }, [updateFollowUp, updateContact]);

  const handleFolowUpNoteChange = e => setFollowUpNoteBody(e.target.value);

  const handleSaveNoteClick = () => {
    const nowUnix = Math.floor(Date.now() / 1000);
    const startOfTodayUnix = Math.floor(new Date().setHours(0, 0, 0, 0) / 1000);

    addNote({
      contactId,
      body: followUpNoteBody,
      createdTimestamp: nowUnix,
      updatedTimestamp: nowUnix,
      status: null,
    }).then(() => {
      updateContact({
        notesCount: firebase.firestore.FieldValue.increment(1),
        unloggedInteractionReason: '',
        unloggedTimestamp: nowUnix,
        updatedTimestamp: nowUnix,
        firstNoteTimestamp: contactFirstNoteTimestamp || startOfTodayUnix,
      });
    });

    // It might look weird that we are closing and opening the follow up dialog
    // but `openFollowUpDialog` might open the paywall limit dialog instead, so
    // we need to close this one first. If paywall is not the case it will still
    // work as expected.
    closeFollowUpDialog();
    openFollowUpDialog({
      contactId,
      isEditing: true,
      closeOnCancelEdit: true,
    });
  };

  return (
    <div>
      <div className="font-bold py-4 text-xl">Add a note about how it went</div>
      <textarea
        autoFocus
        name="followupNoteBody"
        id="followupNoteBody"
        rows="5"
        className="rounded border border-steel p-2 w-full"
        placeholder="Remembering more of what colleagues, friends, and acquaintances share with you is about to become your secret weapon. Contact Mapping makes it as simple as pulling your phone out of your pocket."
        value={followUpNoteBody}
        onChange={handleFolowUpNoteChange}
        data-testid="followUpDialog.doneMode.noteTextarea"
      ></textarea>
      <div className="pt-4 mt-4 border-t border-steel flex items-center justify-center space-x-4">
        <CMTextButton
          icon={MdCancel}
          color="secondary"
          onClick={closeFollowUpDialog}
          data-testid="followUpDialog.doneMode.notNowButton"
        >
          Not Now
        </CMTextButton>
        <CMButton
          color="secondary"
          onClick={handleSaveNoteClick}
          data-testid="followUpDialog.doneMode.saveNoteButton"
          disabled={!followUpNoteBody.length}
        >
          Add Note
        </CMButton>
      </div>
    </div>
  );
};

const ViewFollowUp = ({
  followupTimestamp,
  body,
  timeBased,
  onEdit,
  onDone,
  socialProfiles,
}) => (
  <>
    <div className="text-softPurple uppercase font-bold py-3">
      {formatDate(new Date(Math.round(followupTimestamp * 1000)), timeBased)}
    </div>
    <HTMLText testid="followUpDialog.viewMode.body" text={body} />
    <div className="py-6 flex space-x-8 justify-center">
      {/* Edit */}
      <div
        className="flex flex-col items-center w-14 hover:cursor-pointer group"
        onClick={onEdit}
        data-testid="followUpDialog.viewMode.editButton"
      >
        <div className="rounded-full border-2 border-softPurple w-14 h-14 flex items-center justify-center mb-4 bg-white group-hover:bg-softPurple group-hover:bg-opacity-10 transition-all">
          <MdEdit size="32px" className="text-softPurple" />
        </div>
        <div className="underline text-sm text-softPurple font-bold">Edit</div>
      </div>
      {/* Done */}
      <div
        className="flex flex-col items-center w-14 hover:cursor-pointer group"
        onClick={onDone}
        data-testid="followUpDialog.viewMode.doneButton"
      >
        <div className="rounded-full border-2 border-softPurple w-14 h-14 flex items-center justify-center mb-4 bg-white group-hover:bg-softPurple group-hover:bg-opacity-10 transition-all">
          <MdDone size="32px" className="text-softPurple" />
        </div>
        <div className="underline text-center text-sm text-softPurple font-bold">
          Mark as Done
        </div>
      </div>
    </div>
    {socialProfiles ? (
      <div className="pt-8 mt-8 border-t border-steel">
        <div className="flex justify-center">
          <CMContactSocialProfiles
            socialProfiles={socialProfiles}
            size="sm"
            testid="followUpDialog.socialProfiles"
          />
        </div>
      </div>
    ) : null}
  </>
);

const EditFollowUp = ({
  userId,
  contactId,
  contactFullName,
  followupTimestamp,
  body,
  timeBased = false,
  onCancel,
  updateFollowUp,
  setFollowUpContentChanged,
}) => {
  const { isAboutToReachHalfFollowUps, isAboutToReachMaxFollowUps } =
    useContext(PaywallContext);
  const { openHalfwayPaywallFollowUpsDialog, openMaxFollowUpsReachedDialog } =
    useContext(PaywallDialoguesContext);
  const {
    settings: { notification_time_followups },
    userData: { utcoffset },
  } = useContext(AuthContext);

  const { time_based_follow_ups } = useFeaturesSwitch();

  const todayUnix = Math.floor(Date.now() / 1000);
  const baseDate = new Date(
    Math.floor((followupTimestamp || todayUnix) * 1000)
  );
  const baseTime = `${notification_time_followups?.slice(0, 2) ?? '09'}:${
    notification_time_followups?.slice(3, 5) ?? '00'
  }`;
  const formattedFollowupTimestampDate = dateToInputDateFormat(baseDate);

  const today = dateToInputDateFormat(new Date());
  const tomorrow = dateToInputDateFormat(
    new Date().setDate(new Date().getDate() + 1)
  );
  const oneWeek = dateToInputDateFormat(
    new Date().setDate(new Date().getDate() + 7)
  );
  const oneMonth = dateToInputDateFormat(
    new Date().setMonth(new Date().getMonth() + 1)
  );
  const threeMonths = dateToInputDateFormat(
    new Date().setMonth(new Date().getMonth() + 3)
  );
  const oneYear = dateToInputDateFormat(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );

  const { add: addFollowUp } = useFollowUps(userId);

  const [selectedDate, setSelectedDate] = useState(
    formattedFollowupTimestampDate
  );
  const [showTime, setShowTime] = useState(timeBased);
  const [selectedTime, setSelectedTime] = useState(
    timeBased
      ? `${`${baseDate.getHours()}`.padStart(2, 0)}:${getMinutes600s(baseDate)}`
      : baseTime
  );
  const [followUpBody, setFollowUpBody] = useState(body);
  const [dateTimeError, setDateTimeError] = useState(false);

  const originalSelectedDate = useRef(formattedFollowupTimestampDate);
  const originalSelectedTime = useRef(selectedTime);
  const originalFollowUpBody = useRef(body);

  // Effect when changing dates to a follow up somewhere else
  useEffect(() => {
    setSelectedDate(formattedFollowupTimestampDate);
    setShowTime(timeBased);

    const date = new Date(Math.floor(followupTimestamp * 1000));

    setSelectedTime(
      timeBased
        ? `${`${date.getHours()}`.padStart(2, 0)}:${getMinutes600s(date)}`
        : baseTime
    );
  }, [followupTimestamp, formattedFollowupTimestampDate, timeBased, baseTime]);

  // Effect to set a follow up as "changed" to show dirty prompt
  useEffect(() => {
    const followUpContentChanged =
      selectedDate !== originalSelectedDate.current ||
      selectedTime !== originalSelectedTime.current ||
      followUpBody !== originalFollowUpBody.current ||
      showTime !== timeBased;

    setFollowUpContentChanged(followUpContentChanged);
  }, [
    selectedDate,
    selectedTime,
    followUpBody,
    setFollowUpContentChanged,
    showTime,
    timeBased,
  ]);

  const handleDateChange = e => setSelectedDate(e.target.value);

  const handleDateClick = date => setSelectedDate(date);

  const handleFolowUpBodyChange = e => setFollowUpBody(e.target.value);

  const handleClearTime = () => {
    setSelectedTime(baseTime);
    setShowTime(false);
  };

  const handleSaveClick = () => {
    const nowUnix = Math.floor(Date.now() / 1000);

    const followupTimestamp = Math.floor(
      new Date(`${selectedDate}T${selectedTime}:00${utcoffset}`) / 1000
    );

    setDateTimeError(false);
    if (showTime && followupTimestamp < nowUnix) {
      setDateTimeError(true);
      return;
    }

    const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

    const baseFollowUp = {
      followupTimestamp,
      body: followUpBody,
      timeBased: showTime,
    };

    // Update or Add a new Follow Up
    updateFollowUp
      ? updateFollowUp({
          ...baseFollowUp,
          updatedAt: serverTimestamp,
          updatedBy: PLATFORM_IDENTIFIER,
        })
      : addFollowUp({
          ...baseFollowUp,
          contactId,
          contactName: contactFullName,
          createdAt: serverTimestamp,
          createdBy: PLATFORM_IDENTIFIER,
          done: false,
        }).then(() => {
          if (isAboutToReachHalfFollowUps) {
            // With the addition of this follow up, halfway is reached
            openHalfwayPaywallFollowUpsDialog();
          }

          if (isAboutToReachMaxFollowUps) {
            // With the addition of this follow up, max is reached
            openMaxFollowUpsReachedDialog();
          }
        });
    onCancel();
  };

  const { keyDownHandler, keyUpHandler } = useKeySave(
    handleSaveClick,
    selectedDate
  );

  return (
    <div onKeyDown={keyDownHandler} onKeyUp={keyUpHandler}>
      <div className="pt-4 my-4 border-t border-steel">
        <div className="grid grid-cols-2">
          <DateButton
            targetDate={today}
            selectedDate={selectedDate}
            label="Today"
            onDateClick={() => handleDateClick(today)}
            testid="followUpDialog.editMode"
          />
          <DateButton
            targetDate={tomorrow}
            selectedDate={selectedDate}
            label="Tomorrow"
            onDateClick={() => handleDateClick(tomorrow)}
            testid="followUpDialog.editMode"
          />
          <DateButton
            targetDate={oneWeek}
            selectedDate={selectedDate}
            label="One Week"
            onDateClick={() => handleDateClick(oneWeek)}
            testid="followUpDialog.editMode"
          />
          <DateButton
            targetDate={oneMonth}
            selectedDate={selectedDate}
            label="One Month"
            onDateClick={() => handleDateClick(oneMonth)}
            testid="followUpDialog.editMode"
          />
          <DateButton
            targetDate={threeMonths}
            selectedDate={selectedDate}
            label="Three Month"
            onDateClick={() => handleDateClick(threeMonths)}
            testid="followUpDialog.editMode"
          />
          <DateButton
            targetDate={oneYear}
            selectedDate={selectedDate}
            label="One Year"
            onDateClick={() => handleDateClick(oneYear)}
            testid="followUpDialog.editMode"
          />
        </div>
        <div className="flex items-center py-4 border-b border-steel">
          <strong>Follow-up on:</strong>
          <input
            type="date"
            className="rounded border border-coolGrey h-10 text-slate ml-2 px-2 w-44 hover:border-slate hover:cursor-pointer focus:border-2 focus:border-fadeOrange focus:outline-none focus:px-[7px]"
            min={today}
            value={selectedDate}
            onChange={handleDateChange}
            data-testid="followUpDialog.editMode.dateInput"
          />
        </div>
        {time_based_follow_ups ? (
          <div className="py-4 border-b border-steel">
            <div className="flex items-center justify-between">
              <strong>Follow-up at:</strong>
              {showTime ? (
                <>
                  <TimeDropdown time={selectedTime} setTime={setSelectedTime} />
                  <div className="flex-grow">
                    <CMTextButton color="secondary" onClick={handleClearTime}>
                      Clear time
                    </CMTextButton>
                  </div>
                </>
              ) : (
                <div className="flex-grow pl-2">
                  <CMTextButton onClick={() => setShowTime(true)}>
                    Set a time
                  </CMTextButton>
                </div>
              )}
            </div>
            {dateTimeError ? (
              <div className="text-error font-bold">
                Date and time cannot be in the past
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
      <textarea
        autoFocus
        name="followup"
        id="followup"
        rows="5"
        className="rounded border border-steel p-2 w-full"
        value={followUpBody}
        placeholder="Remembering more of what colleagues, friends, and acquaintances share with you is about to become your secret weapon. Contact Mapping makes it as simple as pulling your phone out of your pocket."
        onChange={handleFolowUpBodyChange}
        data-testid="followUpDialog.editMode.bodyTextarea"
      ></textarea>
      <div className="pt-4 mt-4 border-t border-steel flex items-center justify-center space-x-4">
        <CMTextButton
          icon={MdCancel}
          color="secondary"
          onClick={onCancel}
          data-testid="followUpDialog.editMode.cancelButton"
        >
          Cancel
        </CMTextButton>
        <CMButton
          color="secondary"
          onClick={handleSaveClick}
          disabled={!selectedDate}
          data-testid="followUpDialog.editMode.saveButton"
        >
          Save
        </CMButton>
      </div>
    </div>
  );
};

const FollowUpDialog = () => {
  const { userId } = useContext(AuthContext);
  const {
    followUpDialogData,
    followUpDialogOpen,
    openFollowUpDialog,
    closeFollowUpDialog,
  } = useContext(SharedDialoguesContext);

  const { followUpId, contactId, isEditing, isDone, closeOnCancelEdit } =
    followUpDialogData;

  const { data: dataContact, update: updateContact } = useContact(
    userId,
    contactId
  );
  const { data: dataFollowUp, update: updateFollowUp } = useFollowUp(
    userId,
    followUpId
  );

  const [followUpContentChanged, setFollowUpContentChanged] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDoneMode, setIsDoneMode] = useState(false);

  const contactDisplayName = getContactDisplayName(
    dataContact?.fullName,
    dataContact?.company,
    dataContact?.phones[0]?.number,
    dataContact?.emails[0]?.email
  );

  useEffect(() => {
    // Revert edit/done mode when changing Follow Up
    setIsEditMode(isEditing);
    setIsDoneMode(isDone);
  }, [isEditing, isDone, followUpDialogData]);

  const handleEdit = () => setIsEditMode(true);

  const handleCancel = () => {
    if (closeOnCancelEdit) {
      setFollowUpContentChanged(false);
      closeFollowUpDialog();
      return;
    }
    setFollowUpContentChanged(false);
    setIsEditMode(false);
  };

  const handleClose = cb => {
    if (followUpContentChanged) {
      if (window.confirm('The changes will be lost.')) {
        setFollowUpContentChanged(false);
        closeFollowUpDialog();

        if (cb instanceof Function) cb();
      }
      return;
    }

    if (cb instanceof Function) cb();
    closeFollowUpDialog();
  };

  const handleDone = () => {
    !isEditMode
      ? setIsDoneMode(true)
      : window.confirm('The follow up will be completed.') &&
        setIsDoneMode(true);
  };

  // Add mode is like Edit mode but without `followUpId` (and no `dataFollowUp`)
  // So `dataFollowUp` will always be undefined
  if (!dataContact) return null;

  const socialProfiles = unifyInstantMessageAddresses(
    dataContact.instantMessageAddresses,
    dataContact.instantMessageAddressesCM
  );

  // Add mode
  if (!followUpId && dataContact)
    return (
      <CMDialog
        width="sm"
        renderContent={
          <div>
            <h2 className="text-2xl font-bold text-center pb-6">Follow-up</h2>
            <div className="flex items-center">
              <ContactAvatar
                contactId={contactId}
                onAvatarClick={handleClose}
                contactDisplayStatus={dataContact.displayStatus}
                contactName={contactDisplayName}
              />
            </div>
            <EditFollowUp
              userId={userId}
              contactId={contactId}
              contactFullName={contactDisplayName}
              body={''}
              onCancel={handleCancel}
              setFollowUpContentChanged={setFollowUpContentChanged}
            />
          </div>
        }
        open={followUpDialogOpen}
        handleClose={handleClose}
      />
    );

  // View/Edit/Done mode with `dataFollowUp`
  if (!dataFollowUp) return null;

  return (
    <CMDialog
      width="sm"
      renderContent={
        isDoneMode ? (
          <div>
            <h2 className="text-2xl font-bold text-center pb-6">
              Complete Follow-up Notes
            </h2>
            <div className="flex items-center">
              <ContactAvatar
                contactId={contactId}
                contactDisplayStatus={dataContact.displayStatus}
                contactName={contactDisplayName}
                onAvatarClick={handleClose}
              />
            </div>
            <DoneFollowUp
              userId={userId}
              contactId={contactId}
              contactFirstNoteTimestamp={dataContact.firstNoteTimestamp}
              updateContact={updateContact}
              closeFollowUpDialog={handleClose}
              openFollowUpDialog={openFollowUpDialog}
              updateFollowUp={updateFollowUp}
            />
          </div>
        ) : !isEditMode ? (
          <div>
            <h2 className="text-2xl font-bold text-center pb-6">Follow-up</h2>
            <div className="flex items-center">
              <ContactAvatar
                contactDisplayStatus={dataContact.displayStatus}
                contactName={contactDisplayName}
                contactId={dataContact.id}
                onAvatarClick={handleClose}
              />
            </div>
            <ViewFollowUp
              followupTimestamp={dataFollowUp.followupTimestamp}
              body={dataFollowUp.body}
              timeBased={dataFollowUp.timeBased}
              onEdit={handleEdit}
              onDone={handleDone}
              socialProfiles={socialProfiles}
            />
          </div>
        ) : (
          <div>
            <h2 className="text-2xl font-bold text-center pb-6">Follow-up</h2>
            <div className="flex items-center">
              <ContactAvatar
                contactId={dataContact.id}
                onAvatarClick={handleClose}
                contactDisplayStatus={dataContact.displayStatus}
                contactName={contactDisplayName}
              />
            </div>
            <EditFollowUp
              followupTimestamp={dataFollowUp.followupTimestamp}
              body={dataFollowUp.body}
              timeBased={dataFollowUp.timeBased}
              onCancel={handleCancel}
              updateFollowUp={updateFollowUp}
              setFollowUpContentChanged={setFollowUpContentChanged}
            />
          </div>
        )
      }
      open={followUpDialogOpen}
      handleClose={!isDoneMode ? handleClose : null}
    />
  );
};

export default FollowUpDialog;
