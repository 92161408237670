import { CircularProgress } from '@mui/material';
import React from 'react';

export type IColors = 'primary' | 'secondary' | 'tertiary' | 'light' | 'none';
type IProps = {
  children: string;
  /** from the react-icons library only */
  icon?: any;
  color?: IColors;
  /** optional when icon color is different from text color */
  iconColor?: string;
  disabled?: boolean;
  component?: string | React.ElementType;
  /** it's recommended to use an icon if the button will have a loading state */
  loading?: boolean;
  [x: string]: any;
};

const colorToClass = {
  primary: 'fadeOrange',
  secondary: 'softPurple',
  tertiary: 'blueGrey',
  light: 'white',
  disabled: 'coolGrey',
  none: 'slate',
};

const CMTextButton = ({
  children,
  icon,
  color = 'primary',
  iconColor,
  disabled = false,
  component = 'button',
  loading = false,
  ...props
}: IProps) => {
  const colorClass = !disabled ? colorToClass[color] : colorToClass['disabled'];
  const Component = component;
  const Icon = icon;

  const base =
    'text-base group inline-flex items-center justify-center py-1.5 uppercase font-bold focus:outline-none';
  const textColor = `text-${colorClass}`;
  const cursor = disabled ? 'cursor-default' : 'cursor-pointer';
  const hoverNFocus = !disabled ? 'group-hover:w-1/2 group-focus:w-1/2' : '';

  return (
    <Component
      disabled={disabled}
      className={`${base} ${textColor} ${cursor}`}
      {...props}
    >
      {/* It's recommended to use an icon if the button will have a loading state */}
      {loading || icon ? (
        <span className="mr-2 flex w-5">
          {loading ? (
            <CircularProgress size={18} color="inherit" />
          ) : (
            <Icon size="19px" className={iconColor} />
          )}
        </span>
      ) : null}
      <span className="relative">
        {/* Underline */}
        <span
          className={`absolute bottom-0 h-0.5 transition-all w-full ${hoverNFocus} bg-${colorClass}`}
        ></span>
        {children}
      </span>
    </Component>
  );
};

export default CMTextButton;
