import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CMButton from '../../components/cm-button/cm-button.component';
import CMDialog from '../../components/cm-dialog/cm-dialog.component';
import WebAppLayout from '../../components/web-app-layout/web-app-layout.component';

import { AuthContext } from '../../context/auth/auth.context';
import { RemoteConfigContext } from '../../context/remote-config/remote-config.context';

import { timeGreeting } from '../../utils/dates.utils';

import QRCode from './../../assets/images/welcome-page/QRCode.png';
import chromeextension from './../../assets/images/welcome-page/chromeextension.png';
import community from './../../assets/images/welcome-page/community.png';
import dbc from './../../assets/images/welcome-page/dbc.png';
import desktopapp from './../../assets/images/welcome-page/desktopapp.png';
import eventsandtraining from './../../assets/images/welcome-page/eventsandtraining.png';
import getStartedVideo from './../../assets/images/welcome-page/getStartedVideo.png';
import mobileapp from './../../assets/images/welcome-page/mobileapp.png';

type ProductResource = {
  title: string;
  content: JSX.Element | string;
  img: string;
  onClick?: () => void;
};

interface IStep {
  position: number;
  title: string;
  content: JSX.Element;
  sideImg?: JSX.Element;
}

const StepItem: React.FC<IStep> = ({
  position,
  title,
  content,
  sideImg,
}): JSX.Element => (
  <div className="lg:flex lg:justify-between rounded bg-white p-4">
    <div className="text-4xl font-bold text-fadeOrange pr-4">{position}</div>
    <div className="text-slate">
      <h3 className="text-xl font-bold">{title}</h3>
      <div className="flex justify-between">
        <div className={`break-words ${sideImg ? 'w-2/3' : ''} pr-5`}>
          {content}
        </div>
        {sideImg}
      </div>
    </div>
  </div>
);

const WelcomePage = () => {
  const {
    configValues: {
      play_store_url,
      welcome_video,
      training_events_url,
      community_url,
      chrome_extension_url,
      apple_store_url,
      calendly_welcome_basic_url,
      calendly_welcome_pro_url,
      community_calendar_url,
      demo_video,
    },
  } = useContext(RemoteConfigContext);
  const [showVideoDialog, setShowVideoDialog] = useState(false);
  const [showCalendarDetailsDialog, setShowCalendarDetailsDialog] =
    useState(false);
  const navigate = useNavigate();
  const { userData, isPremium } = useContext(AuthContext);

  const steps = [
    {
      title: 'Get the mobile app',
      content: (
        <p>
          Scan to download or go to{' '}
          <a
            className="text-fadeOrange underline break-all"
            href="https://www.contactmapping.com/download"
          >
            contactmapping.com/download
          </a>
        </p>
      ),
      sideImg: <img src={QRCode} alt="Download" className="w-[100px]" />,
    },

    {
      title: 'Watch the demo video',
      content: <p>Elizabeth will show you how to start</p>,
      sideImg: (
        <img
          src={getStartedVideo}
          alt="Elizabeth will show you how to start"
          className="w-[150px] cursor-pointer"
          onClick={() => {
            setShowVideoDialog(true);
          }}
        />
      ),
    },
    {
      title: 'Schedule your Business Strategy call',
      content: (
        <div className="space-y-4">
          <p>
            {isPremium ? 'Elizabeth' : 'Don'} will welcome you and show you
            around
          </p>
          <CMButton
            component="a"
            target="_blank"
            href={
              isPremium ? calendly_welcome_pro_url : calendly_welcome_basic_url
            }
          >
            Schedule my call
          </CMButton>
        </div>
      ),
    },
    {
      title: 'Join us for Daily Business Accelerator',
      content: (
        <div className="space-y-4">
          <p>
            {isPremium
              ? 'Build the best habits for your business and relationships'
              : 'Get 30 days on us to build the best habits for your business'}
          </p>
          <CMButton onClick={() => setShowCalendarDetailsDialog(true)}>
            {isPremium ? 'Add to my calendar' : 'Register now'}
          </CMButton>
        </div>
      ),
    },
  ];

  const productsAndResources: ProductResource[] = [
    {
      title: 'Mobile app',
      content: (
        <span>
          Available for{' '}
          <a
            href={apple_store_url}
            className="underline"
            target="_blank"
            rel="noreferrer"
          >
            iOS
          </a>{' '}
          and{' '}
          <a
            href={play_store_url}
            className="underline"
            target="_blank"
            rel="noreferrer"
          >
            Android
          </a>
        </span>
      ),
      img: mobileapp,
    },
    {
      title: 'Desktop app',
      content: 'Get yourself going in a second',
      img: desktopapp,
      onClick: () => navigate('/dashboard'),
    },
    {
      title: 'Digital business card',
      content: 'Get your own personal webpage',
      img: dbc,
      onClick: () => navigate('/share-your-profile'),
    },
    {
      title: 'Chrome Extension',
      content: 'The best tool for social bees',
      img: chromeextension,
      onClick: () => window.open(chrome_extension_url, '_blank'),
    },
    {
      title: 'Community',
      content: 'Join our fantastic tribe',
      img: community,
      onClick: () => window.open(community_url, '_blank'),
    },
    {
      title: 'Events and training',
      content: 'Get some personal upgrades',
      img: eventsandtraining,
      onClick: () => window.open(training_events_url, '_blank'),
    },
  ];

  return (
    <WebAppLayout fullWidth darkBackground>
      <div className="p-6">
        <div className="max-w-3xl mx-auto">
          <h3 className="text-3xl font-bold">
            {isPremium ? `Good ${timeGreeting()} ` : 'Hi, '}
            {userData.firstname}!
          </h3>
          <h3 className="text-3xl font-bold mb-6">
            Welcome to Contact Mapping {isPremium && 'Pro!'}
          </h3>

          <iframe
            src={welcome_video}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            frameBorder="0"
            height="430px"
            width="100%"
            title="Welcome to Contact Mapping"
          ></iframe>
        </div>
      </div>

      <div className="bg-darkSlate p-6">
        <div className="max-w-3xl mx-auto">
          <h3 className="text-2xl font-bold text-white">Ready to start</h3>
          <div className="grid lg:grid-cols-2 gap-6 mt-6 grid-cols-1">
            {steps.map((step, index) => (
              <StepItem
                position={index + 1}
                key={index}
                title={step.title}
                content={step.content}
                sideImg={step.sideImg}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="p-6">
        <div className="max-w-3xl mx-auto">
          <h3 className="text-3xl font-bold mt-10">Know your tools</h3>
          <p>
            Our website is full of resources for you to explore at your own
            pace. <br /> These are some of our most popular solutions:
          </p>

          <div className="grid lg:grid-cols-3 grid-cols-1 gap-6 my-6">
            {productsAndResources.map(
              (product: ProductResource, key: number) => (
                <div
                  className={`bg-white rounded-md space-y-4 shadow-md ${
                    product.onClick !== undefined ? 'cursor-pointer' : ''
                  }`}
                  key={key}
                  onClick={() => {
                    if (product.onClick instanceof Function) product.onClick();
                  }}
                >
                  <img
                    src={product.img}
                    alt={product.title}
                    className="rounded-t-md"
                  />
                  <div className="px-3 pb-4 text-slate">
                    <h3 className="font-bold">{product.title}</h3>
                    <p>{product.content}</p>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <CMDialog
        width="sm"
        open={showVideoDialog}
        handleClose={() => setShowVideoDialog(false)}
        renderContent={
          <div className="p-4">
            <iframe
              src={demo_video}
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              frameBorder="0"
              height="430px"
              width="100%"
              title="Welcome to Contact Mapping"
            ></iframe>
          </div>
        }
      />
      <CMDialog
        width="xs"
        open={showCalendarDetailsDialog}
        title="Daily Business Accelerator"
        handleClose={() => setShowCalendarDetailsDialog(false)}
        renderContent={
          <div className="p-4 space-y-6">
            <p>
              Join us at our Daily Business Accelerator meeting, where we help
              you cultivate a successful business through the following:
            </p>
            <ul className="list-disc ml-6">
              <li>Daily goal setting</li>
              <li>Accountability</li>
              <li>Execution planning and advice </li>
            </ul>
            <p>
              At Contact Mapping, our customers have seen double-digit growth in
              their industries by joining this great group of like-minded
              entrepreneurs.
            </p>
            <CMButton
              component="a"
              target="_blank"
              href={community_calendar_url}
            >
              {isPremium ? 'Add to my calendar' : 'Register now'}
            </CMButton>
          </div>
        }
      />
    </WebAppLayout>
  );
};

export default WelcomePage;
