import React, { createContext, useCallback, useMemo, useReducer } from 'react';

import MainPaywallDialog from '../../components/paywall-dialogues/main-paywall-dialog/main-paywall-dialog.component';
import OutstandingFollowUpDialog from '../../components/paywall-dialogues/outstanding-follow-up-dialog/outstanding-follow-up-dialog.component';

const initialState = {
  // Main Dialog open state
  mainDialogOpenName: '',
  // New follow-up limit Dialog
  newFollowUpLimitDialogOpen: false,
  // New Rotation limit Dialog
  newRotationLimitDialogOpen: false,
  // Outstanding Follow-up Dialog
  outstandingFollowUpDialogOpen: false,
  // Halfway Paywall Follow-ups Dialog
  halfwayPaywallFollowUpsDialogOpen: false,
  // Max Follow-ups reached Dialog
  maxFollowUpsReachedDialogOpen: false,
  // Halfway Paywall Rotations Dialog
  halfwayPaywallRotationsDialogOpen: false,
  // Max Rotations reached Dialog
  maxRotationsReachedDialogOpen: false,
  // Dismiss Rotation Dialog
  dismissRotationDialogOpen: false,
};

type State = typeof initialState;

export interface IPaywallDialoguesContext extends State {
  openNewFollowUpLimitDialog: () => void;
  closeNewFollowUpLimitDialog: () => void;
  openNewRotationLimitDialog: () => void;
  closeNewRotationLimitDialog: () => void;
  openOutstandingFollowUpDialog: () => void;
  closeOutstandingFollowUpDialog: () => void;
  openHalfwayPaywallFollowUpsDialog: () => void;
  closeHalfwayPaywallFollowUpsDialog: () => void;
  openMaxFollowUpsReachedDialog: () => void;
  closeMaxFollowUpsReachedDialog: () => void;
  openHalfwayPaywallRotationsDialog: () => void;
  closeHalfwayPaywallRotationsDialog: () => void;
  openMaxRotationsReachedDialog: () => void;
  closeMaxRotationsReachedDialog: () => void;
  openDismissRotationDialog: () => void;
  closeDismissRotationDialog: () => void;
}

enum Actions {
  // New follow-up limit Dialog
  TOGGLE_NEW_FOLLOW_UP_LIMIT_DIALOG = 'TOGGLE_NEW_FOLLOW_UP_LIMIT_DIALOG',
  // New rotation limit Dialog
  TOGGLE_NEW_ROTATION_LIMIT_DIALOG = 'TOGGLE_NEW_ROTATION_LIMIT_DIALOG',
  // Outstanding Follow-up Dialog
  TOGGLE_OUTSTANDING_FOLLOW_UP_DIALOG = 'TOGGLE_OUTSTANDING_FOLLOW_UP_DIALOG',
  // Halfway Paywall Follow-ups Dialog
  TOGGLE_HALFWAY_PAYWALL_FOLLOW_UPS_DIALOG = 'TOGGLE_HALFWAY_PAYWALL_FOLLOW_UPS_DIALOG',
  // Max Follow-ups reached Dialog
  TOGGLE_MAX_FOLLOW_UPS_REACHED_DIALOG = 'TOGGLE_MAX_FOLLOW_UPS_REACHED_DIALOG',
  // Halfway Paywall Follow-ups Dialog
  TOGGLE_HALFWAY_PAYWALL_ROTATIONS_DIALOG = 'TOGGLE_HALFWAY_PAYWALL_ROTATIONS_DIALOG',
  // Max Follow-ups reached Dialog
  TOGGLE_MAX_ROTATIONS_REACHED_DIALOG = 'TOGGLE_MAX_ROTATIONS_REACHED_DIALOG',
  // Dismiss Rotation Dialog
  TOGGLE_DISMISS_ROTATION_DIALOG = 'TOGGLE_DISMISS_ROTATION_DIALOG',
}

type Action = {
  type: Actions;
  toggle: boolean;
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    // New follow-up limit Dialog
    case Actions.TOGGLE_NEW_FOLLOW_UP_LIMIT_DIALOG:
      return {
        ...state,
        newFollowUpLimitDialogOpen: action.toggle,
        mainDialogOpenName: 'newFollowUpLimitDialogOpen',
      };

    // New rotation limit Dialog
    case Actions.TOGGLE_NEW_ROTATION_LIMIT_DIALOG:
      return {
        ...state,
        newRotationLimitDialogOpen: action.toggle,
        mainDialogOpenName: 'newRotationLimitDialogOpen',
      };

    // Outstanding Follow-up Dialog
    case Actions.TOGGLE_OUTSTANDING_FOLLOW_UP_DIALOG:
      return {
        ...state,
        outstandingFollowUpDialogOpen: action.toggle,
      };

    // Halfway Paywall Follow-ups Dialog
    case Actions.TOGGLE_HALFWAY_PAYWALL_FOLLOW_UPS_DIALOG:
      return {
        ...state,
        halfwayPaywallFollowUpsDialogOpen: action.toggle,
        mainDialogOpenName: 'halfwayPaywallFollowUpsDialogOpen',
      };

    // Max Follow-ups reached Dialog
    case Actions.TOGGLE_MAX_FOLLOW_UPS_REACHED_DIALOG:
      return {
        ...state,
        maxFollowUpsReachedDialogOpen: action.toggle,
        mainDialogOpenName: 'maxFollowUpsReachedDialogOpen',
      };

    // Halfway Paywall Rotations Dialog
    case Actions.TOGGLE_HALFWAY_PAYWALL_ROTATIONS_DIALOG:
      return {
        ...state,
        halfwayPaywallRotationsDialogOpen: action.toggle,
        mainDialogOpenName: 'halfwayPaywallRotationsDialogOpen',
      };

    // Max Rotations reached Dialog
    case Actions.TOGGLE_MAX_ROTATIONS_REACHED_DIALOG:
      return {
        ...state,
        maxRotationsReachedDialogOpen: action.toggle,
        mainDialogOpenName: 'maxRotationsReachedDialogOpen',
      };

    // Dismiss Rotation Dialog
    case Actions.TOGGLE_DISMISS_ROTATION_DIALOG:
      return {
        ...state,
        dismissRotationDialogOpen: action.toggle,
        mainDialogOpenName: 'dismissRotationDialogOpen',
      };

    default:
      return state;
  }
};

export const PaywallDialoguesContext =
  createContext<IPaywallDialoguesContext | null>(null);

export const PaywallDialoguesContextProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer<React.Reducer<State, Action>>(
    reducer,
    initialState
  );

  // New follow-up limit Dialog
  const openNewFollowUpLimitDialog = useCallback(() => {
    dispatch({ type: Actions.TOGGLE_NEW_FOLLOW_UP_LIMIT_DIALOG, toggle: true });
  }, []);

  const closeNewFollowUpLimitDialog = useCallback(() => {
    dispatch({
      type: Actions.TOGGLE_NEW_FOLLOW_UP_LIMIT_DIALOG,
      toggle: false,
    });
  }, []);

  // New rotation limit Dialog
  const openNewRotationLimitDialog = useCallback(() => {
    dispatch({ type: Actions.TOGGLE_NEW_ROTATION_LIMIT_DIALOG, toggle: true });
  }, []);

  const closeNewRotationLimitDialog = useCallback(() => {
    dispatch({
      type: Actions.TOGGLE_NEW_ROTATION_LIMIT_DIALOG,
      toggle: false,
    });
  }, []);

  // Outstanding Follow-up Dialog
  const openOutstandingFollowUpDialog = useCallback(() => {
    dispatch({
      type: Actions.TOGGLE_OUTSTANDING_FOLLOW_UP_DIALOG,
      toggle: true,
    });
  }, []);

  const closeOutstandingFollowUpDialog = useCallback(() => {
    dispatch({
      type: Actions.TOGGLE_OUTSTANDING_FOLLOW_UP_DIALOG,
      toggle: false,
    });
  }, []);

  // Halfway Paywall Follow-ups Dialog
  const openHalfwayPaywallFollowUpsDialog = useCallback(() => {
    dispatch({
      type: Actions.TOGGLE_HALFWAY_PAYWALL_FOLLOW_UPS_DIALOG,
      toggle: true,
    });
  }, []);

  const closeHalfwayPaywallFollowUpsDialog = useCallback(() => {
    dispatch({
      type: Actions.TOGGLE_HALFWAY_PAYWALL_FOLLOW_UPS_DIALOG,
      toggle: false,
    });
  }, []);

  // Max Follow-ups reached Dialog
  const openMaxFollowUpsReachedDialog = useCallback(() => {
    dispatch({
      type: Actions.TOGGLE_MAX_FOLLOW_UPS_REACHED_DIALOG,
      toggle: true,
    });
  }, []);

  const closeMaxFollowUpsReachedDialog = useCallback(() => {
    dispatch({
      type: Actions.TOGGLE_MAX_FOLLOW_UPS_REACHED_DIALOG,
      toggle: false,
    });
  }, []);

  // Halfway Paywall Rotations Dialog
  const openHalfwayPaywallRotationsDialog = useCallback(() => {
    dispatch({
      type: Actions.TOGGLE_HALFWAY_PAYWALL_ROTATIONS_DIALOG,
      toggle: true,
    });
  }, []);

  const closeHalfwayPaywallRotationsDialog = useCallback(() => {
    dispatch({
      type: Actions.TOGGLE_HALFWAY_PAYWALL_ROTATIONS_DIALOG,
      toggle: false,
    });
  }, []);

  // Max Rotations reached Dialog
  const openMaxRotationsReachedDialog = useCallback(() => {
    dispatch({
      type: Actions.TOGGLE_MAX_ROTATIONS_REACHED_DIALOG,
      toggle: true,
    });
  }, []);

  const closeMaxRotationsReachedDialog = useCallback(() => {
    dispatch({
      type: Actions.TOGGLE_MAX_ROTATIONS_REACHED_DIALOG,
      toggle: false,
    });
  }, []);

  // Dismiss Rotation Dialog
  const openDismissRotationDialog = useCallback(() => {
    dispatch({
      type: Actions.TOGGLE_DISMISS_ROTATION_DIALOG,
      toggle: true,
    });
  }, []);

  const closeDismissRotationDialog = useCallback(() => {
    dispatch({
      type: Actions.TOGGLE_DISMISS_ROTATION_DIALOG,
      toggle: false,
    });
  }, []);

  const value = useMemo(
    () => ({
      // State
      mainDialogOpenName: state.mainDialogOpenName,
      newFollowUpLimitDialogOpen: state.newFollowUpLimitDialogOpen,
      newRotationLimitDialogOpen: state.newRotationLimitDialogOpen,
      outstandingFollowUpDialogOpen: state.outstandingFollowUpDialogOpen,
      halfwayPaywallFollowUpsDialogOpen:
        state.halfwayPaywallFollowUpsDialogOpen,
      maxFollowUpsReachedDialogOpen: state.maxFollowUpsReachedDialogOpen,
      halfwayPaywallRotationsDialogOpen:
        state.halfwayPaywallRotationsDialogOpen,
      maxRotationsReachedDialogOpen: state.maxRotationsReachedDialogOpen,
      dismissRotationDialogOpen: state.dismissRotationDialogOpen,
      // Actions
      openNewFollowUpLimitDialog,
      closeNewFollowUpLimitDialog,
      openNewRotationLimitDialog,
      closeNewRotationLimitDialog,
      openOutstandingFollowUpDialog,
      closeOutstandingFollowUpDialog,
      openHalfwayPaywallFollowUpsDialog,
      closeHalfwayPaywallFollowUpsDialog,
      openMaxFollowUpsReachedDialog,
      closeMaxFollowUpsReachedDialog,
      openHalfwayPaywallRotationsDialog,
      closeHalfwayPaywallRotationsDialog,
      openMaxRotationsReachedDialog,
      closeMaxRotationsReachedDialog,
      openDismissRotationDialog,
      closeDismissRotationDialog,
    }),
    [
      state.mainDialogOpenName,
      state.newFollowUpLimitDialogOpen,
      state.newRotationLimitDialogOpen,
      state.outstandingFollowUpDialogOpen,
      state.halfwayPaywallFollowUpsDialogOpen,
      state.maxFollowUpsReachedDialogOpen,
      state.halfwayPaywallRotationsDialogOpen,
      state.maxRotationsReachedDialogOpen,
      state.dismissRotationDialogOpen,
      openNewFollowUpLimitDialog,
      closeNewFollowUpLimitDialog,
      openNewRotationLimitDialog,
      closeNewRotationLimitDialog,
      openOutstandingFollowUpDialog,
      closeOutstandingFollowUpDialog,
      openHalfwayPaywallFollowUpsDialog,
      closeHalfwayPaywallFollowUpsDialog,
      openMaxFollowUpsReachedDialog,
      closeMaxFollowUpsReachedDialog,
      openHalfwayPaywallRotationsDialog,
      closeHalfwayPaywallRotationsDialog,
      openMaxRotationsReachedDialog,
      closeMaxRotationsReachedDialog,
      openDismissRotationDialog,
      closeDismissRotationDialog,
    ]
  );

  return (
    <PaywallDialoguesContext.Provider value={value}>
      <OutstandingFollowUpDialog />
      <MainPaywallDialog />
      {children}
    </PaywallDialoguesContext.Provider>
  );
};
