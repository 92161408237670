import React, { useContext, useEffect } from 'react';

import RotationToText from '../../../pages/contact-details/rotation-to-text.component';

import CMButton from '../../cm-button/cm-button.component';
import CMDialog from '../../cm-dialog/cm-dialog.component';
import DateViewDialogContent from '../../date-view-dialog-content/date-view-dialog-content.component';

import useNoteActions from '../../../hooks/useNoteActions';
import useRotationActions from '../../../hooks/useRotationActions';

import { AuthContext } from '../../../context/auth/auth.context';
import { SharedDialoguesContext } from '../../../context/shared-dialogues/shared-dialogues.context';

const RotationViewDialog: React.FC = () => {
  const { userId } = useContext(AuthContext);
  const {
    rotationViewDialogOpen,
    closeRotationViewDialog,
    rotationViewDialogData,
    openRotationCompleteDialog,
  } = useContext(SharedDialoguesContext);

  const { contactId, rotationId } = rotationViewDialogData;

  const { handleSaveNote } = useNoteActions(userId, undefined, contactId);
  const { dataRotation, handleSkipRotation } = useRotationActions(
    rotationId,
    contactId
  );

  useEffect(() => {
    // if rotation is deleted while this dialog is open, close it
    if (dataRotation && !dataRotation.exists) {
      closeRotationViewDialog();
    }
  }, [dataRotation, closeRotationViewDialog]);

  const handleTouch = () => {
    handleSaveNote('Touch', contactId, 'add', true).then(() => handleSkip());
  };

  const handleNote = () => {
    openRotationCompleteDialog({
      rotationId,
      contactId,
    });
  };

  const handleSkip = () => handleSkipRotation()?.then(closeRotationViewDialog);

  // `!dataRotation.exists` prevents errors when rotation is deleted
  if (!dataRotation || !dataRotation.exists) return null;

  const { frequency, interval, nextIterationDate } = dataRotation;

  return (
    <CMDialog
      width="sm"
      renderContent={
        <DateViewDialogContent
          contactId={contactId}
          title="Rotation"
          closeDialog={closeRotationViewDialog}
          handleNote={handleNote}
          handleTouch={handleTouch}
          renderContent={
            <div className="flex items-center justify-between">
              <RotationToText
                rotationFrequency={frequency}
                rotationInterval={interval}
                rotationNextIterationDate={nextIterationDate}
              />
            </div>
          }
          renderAdditionalActionButtons={
            <CMButton outline color="tertiary" onClick={handleSkip}>
              Skip this time
            </CMButton>
          }
        />
      }
      open={rotationViewDialogOpen}
      handleClose={closeRotationViewDialog}
    />
  );
};

export default RotationViewDialog;
