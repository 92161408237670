import React, { memo, useContext, useEffect, useState } from 'react';
import { BiWorld } from 'react-icons/bi';
import { FaPhoneAlt } from 'react-icons/fa';
import {
  MdCalendarToday,
  MdEmail,
  MdHome,
  MdLink,
  MdPayment,
  MdPerson,
} from 'react-icons/md';
import { Navigate, useParams } from 'react-router-dom';

import WebAppLayoutLoading from '../../../components/web-app-layout-loading/web-app-layout-loading.component';
import WebAppLayout from '../../../components/web-app-layout/web-app-layout.component';
import AdminCancelSubscription from '../../components/admin-cancel-subscription/admin-cancel-subscription.component';
import AdminDeleteUserData from '../../components/admin-delete-user-data/admin-delete-user-data.component';
import AdminEditDetails from '../../components/admin-edit-details/admin-edit-details.component';
import AdminExportData from '../../components/admin-export-data/admin-export-data.component';
import AdminExtendTrial from '../../components/admin-extend-trial/admin-extend-trial.component';
import AdminMakePermanent from '../../components/admin-make-permanent/admin-make-permanent.component';
import AdminRepeatPretrial from '../../components/admin-repeat-pretrial/admin-repeat-pretrial.component';
import AdminResetPassword from '../../components/admin-reset-password/admin-reset-password.component';
import AdminUnlockDevice from '../../components/admin-unlock-device/admin-unlock-device.component';

import { AuthContext } from '../../../context/auth/auth.context';

import { getUserByIdForAdmin } from '../../../services/users/users.service';

import { formatDate } from '../../../utils/dates.utils';

const AdminUserDetailsPage = memo(() => {
  const { userId } = useParams();

  const { authToken } = useContext(AuthContext);

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUserByIdForAdmin(userId, authToken)
      .then(data => setUserData(data))
      .finally(() => setLoading(false));
  }, [userId, authToken]);

  if (loading) return <WebAppLayoutLoading />;
  if (!userId || !userData) return <Navigate replace to="/404" />;

  const {
    active_subscription,
    firstname,
    lastname,
    email,
    state,
    until,
    id,
    phonenumber,
    referrallink,
    address,
    profileurl,
    dbc_enabled,
    role,
    utcoffset,
    device,
  } = userData;

  const hasExtendTrial = ['Trial'].includes(state);
  const hasMakePermanent = ['Canceled', 'PreTrial'].includes(state);
  const hasCancelSubscription = ['Subscribed', 'Trial', 'Overdue'].includes(
    state
  );
  const hasRepeatPreTrial = ['Canceled'].includes(state);
  const hasUnlockedDevice = ['UNLOCK-DEVICE'].includes(device);

  const getAddressValue = address => {
    const { city, country, state } = address,
      values = [];

    if (city) values.push(city);
    if (state) values.push(state);
    if (country) values.push(country);

    return `${values.join(', ')}.`;
  };

  return (
    <WebAppLayout>
      <h1 className="text-2xl md:text-5xl font-bold">
        {firstname} {lastname} (ID: {id})
      </h1>
      <div className="md:mt-16 md:grid grid-cols-3 gap-8">
        <div className="grid md:grid-cols-2 grid-cols-1 flex-1 pt-8 md:pt-0 gap-6 col-span-2">
          <div className="md:mx-0 mx-auto w-full">
            <h2 className="text-xl font-bold">User Details</h2>
            <ul className="space-y-2 mt-4">
              <li
                className="flex items-center"
                data-testid="admin.userManagement.userDetailsDialog.email"
              >
                <div>
                  <MdEmail className="mr-2" size="21px" />
                </div>
                <span className="truncate">{email}</span>
              </li>
              <li
                className="flex items-center"
                data-testid="admin.userManagement.userDetailsDialog.phone"
              >
                <div>
                  <FaPhoneAlt className="mr-2" size="21px" />
                </div>
                <span className="truncate">{phonenumber || 'Not added'}</span>
              </li>
              {address && (
                <li
                  className="flex items-center"
                  data-testid="admin.userManagement.userDetailsDialog.address"
                >
                  <div>
                    <MdHome className="mr-2" size="21px" />
                  </div>
                  <span className="truncate">{getAddressValue(address)}</span>
                </li>
              )}
              {utcoffset && (
                <li
                  className="flex items-center"
                  data-testid="admin.userManagement.userDetailsDialog.timezone"
                >
                  <div>
                    <BiWorld className="mr-2" size="21px" />
                  </div>
                  <span>
                    UTC {utcoffset.slice(0, utcoffset.length - 2)}:
                    {utcoffset.slice(utcoffset.length - 2, utcoffset.length)}
                  </span>
                </li>
              )}
              {referrallink && (
                <li
                  className="flex items-center"
                  data-testid="admin.userManagement.userDetailsDialog.timezone"
                >
                  <div>
                    <MdLink className="mr-2" size="21px" />
                  </div>
                  <span className="truncate">{referrallink}</span>
                </li>
              )}
            </ul>
          </div>
          <div className="md:mx-0 mx-auto w-full">
            <h2 className="text-xl font-bold">Digital Business Card</h2>
            <ul className="space-y-2 mt-4">
              <li className="flex items-center">
                Has Public DBC:{' '}
                {dbc_enabled === 'Y' && profileurl ? 'Yes' : 'No'}
              </li>
              {dbc_enabled === 'Y' && profileurl && (
                <li className="flex items-center">
                  URL:{' '}
                  <a
                    className="underline font-bold text-fadeOrange"
                    rel="noreferrer"
                    target="_blank"
                    href={`${window.location.origin}/hi/${profileurl}`}
                  >
                    {profileurl}
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="md:mx-0 mx-auto w-full">
            <h2 className="text-xl font-bold">Membership</h2>
            <ul className="space-y-2 mt-4">
              <li className="flex items-center">
                <div>
                  <MdPerson className="mr-2" size="21px" />
                </div>
                {role}
              </li>
              <li className="flex items-center">
                <div>
                  <MdPerson className="mr-2" size="21px" />
                </div>
                {state}
              </li>
              <li className="flex items-center">
                <div>
                  <MdCalendarToday className="mr-2" size="21px" />
                </div>
                Until: {formatDate(new Date(`${until}T00:00:00`))}
              </li>
              {active_subscription?.subscription_gateway && (
                <li className="flex items-center">
                  <div>
                    <MdPayment className="mr-2" size="21px" />
                  </div>
                  {active_subscription.subscription_gateway}
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="space-y-6 md:mt-0 mt-10">
          <div>
            <h2 className="text-xl font-bold">Customer Details</h2>
            <ul className="mt-2">
              <li>
                <AdminEditDetails
                  userData={{
                    userId: id,
                    firstname,
                    lastname,
                    referrallink,
                    email,
                    phonenumber,
                  }}
                  onSuccess={data => {
                    setUserData(prevState => ({
                      ...prevState,
                      ...data,
                    }));
                  }}
                  testid="admin.userManagement.userDetailsDialog.editDetails"
                />
              </li>
              <li>
                <AdminResetPassword
                  userId={id}
                  testid="admin.userManagement.userDetailsDialog.resetPassword"
                />
              </li>
            </ul>
          </div>
          {hasExtendTrial ||
          hasMakePermanent ||
          hasCancelSubscription ||
          hasRepeatPreTrial ? (
            <div>
              <h2 className="text-xl font-bold">Subscription</h2>
              <ul className="mt-2">
                {hasExtendTrial ? (
                  <li>
                    <AdminExtendTrial
                      userId={id}
                      onSuccess={until => {
                        setUserData(prevState => ({
                          ...prevState,
                          until,
                        }));
                      }}
                      until={until}
                      testid="admin.userManagement.userDetailsDialog.extendTrial"
                    />
                  </li>
                ) : null}
                {hasMakePermanent ? (
                  <li>
                    <AdminMakePermanent
                      fullName={`${firstname} ${lastname || ''}`.trim()}
                      userId={id}
                      onSuccess={() => {
                        setUserData(prevState => ({
                          ...prevState,
                          state: 'Permanent',
                        }));
                      }}
                      testid="admin.userManagement.userDetailsDialog.makePermanent"
                    />
                  </li>
                ) : null}
                {hasCancelSubscription ? (
                  <li>
                    <AdminCancelSubscription
                      fullName={`${firstname} ${lastname || ''}`.trim()}
                      userId={id}
                      onSuccess={() => {
                        setUserData(prevState => ({
                          ...prevState,
                          state: 'Canceled',
                        }));
                      }}
                      testid="admin.userManagement.userDetailsDialog.cancelSubscription"
                    />
                  </li>
                ) : null}
                {hasRepeatPreTrial ? (
                  <li>
                    <AdminRepeatPretrial
                      fullName={`${firstname} ${lastname || ''}`.trim()}
                      userId={id}
                      testid="admin.userManagement.userDetailsDialog.repeatPretrial"
                      onSuccess={() => {
                        setUserData(prevState => ({
                          ...prevState,
                          state: 'PreTrial',
                        }));
                      }}
                    />
                  </li>
                ) : null}
              </ul>
            </div>
          ) : null}
          <div>
            <h2 className="text-xl font-bold">Data</h2>
            <ul className="mt-2">
              <li>
                <AdminExportData
                  fullName={`${firstname} ${lastname || ''}`.trim()}
                  userId={id}
                  email={email}
                  testid="admin.userManagement.userDetailsDialog.exportData"
                />
              </li>
              <li>
                <AdminDeleteUserData
                  userId={id}
                  fullName={`${firstname} ${lastname || ''}`.trim()}
                  testid="admin.userManagement.userDetailsDialog.deleteData"
                />
              </li>
              {!hasUnlockedDevice ? (
                <li>
                  <AdminUnlockDevice
                    fullName={`${firstname} ${lastname || ''}`.trim()}
                    userId={id}
                    testid="admin.userManagement.userDetailsDialog.unlockDevice"
                    onSuccess={() => {
                      setUserData(prevState => ({
                        ...prevState,
                        device: 'UNLOCK-DEVICE',
                      }));
                    }}
                  />
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </WebAppLayout>
  );
});

export default AdminUserDetailsPage;
